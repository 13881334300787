import React, { PureComponent } from "react";

import trackPropType from "../../propTypes/track";

class Track extends PureComponent {
  media = React.createRef();

  static propTypes = {
    //  track: trackPropType.isRequired
  };

  componentDidMount() {
    const { track } = this.props;

    if (track.kind !== "data") track.attach(this.media.current);
  }

  render() {
    const { track, participant } = this.props;

    return (
      <div>
        <track.kind autoPlay ref={this.media} />
      </div>
    );
  }
}

export default Track;
