import html2canvas from "html2canvas";
export const takeComponentScreenShot = async (ref) => {
   try{

   
    return await html2canvas(ref.current, {
      scale: 1,
 useCORS: true, logging: true,allowTaint:true
    })
    
      .then(async (canvas) => {
        var img = canvas.toDataURL("image/png")
        return img
        img = b64toBlob(img.split(";base64,")[1], "image/png");
         return img
      
      })
      .catch((err) => {
        console.log("Screen shot failed", err);
        
      });
   }
   catch(e){
    console.log(e)
   }
  };
  // export const takeComponentScreenShot = async (ref) => {
  //   try{
   
    
  //    return await htmlToImage.toPng(ref.current)
     
  //      .then(async (canvas) => {
  //       //  var img = canvas.toDataURL("image/png")
  //        console.log(img)
  //        return img
  //        img = b64toBlob(img.split(";base64,")[1], "image/png");
  //         return img
       
  //      })
  //      .catch((err) => {
  //        console.log("Screen shot failed", err);
         
  //      });
  //   }
  //   catch(e){
  //    console.log(e)
  //   }
  //  };