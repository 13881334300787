import React from "react";
import "./Camtestexample.css";
import { useState, useEffect } from "react";

// import * as tf from "@tensorflow/tfjs";
// import * as bodyPix from "@tensorflow-models/body-pix";

// import "@tensorflow/tfjs-core";
// import "@tensorflow/tfjs-converter";
// import "@tensorflow/tfjs-backend-webgl";

import Webcam from "react-webcam";
import Timer from "./Timer";
import { Button } from "react-bootstrap";
import { Row, Col, Modal, Image, Spinner } from "react-bootstrap";
import StopRecordTimer from "./StopRecordTimer";

import { createLocalVideoTrack } from "twilio-video";
import { GaussianBlurBackgroundProcessor } from "@twilio/video-processors";
import TwilioVideo, { LocalDataTrack } from "twilio-video";
import * as VideoProcessors from "@twilio/video-processors";

import styles from "./CamMicTest.module.css";
import CAMERA_BLOCKED from "./CAMERA_BLOCKED.png";

import blurEffect from "../../assets/blurEffect.png";
import CustomBackground from "../../assets/CustomBackground.png";
import BlurOff from "../../assets/BlurOff.png";

import Miclogo from "../../assets/Miclogo.png";
import Cameralogo from "../../assets/Cameralogo.png";
import Wifilogo from "../../assets/Wifilogo.png";

export default function CheckAudio(props) {
  const webcamRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const isInitialMount = React.useRef(true);
  const [capturing, setCapturing] = React.useState(false);
  const [show, setShow] = useState(true);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [microphonestate, setMicrophone] = useState("");
  const [camerastate, setCamera] = useState(true);
  const [connection, setConnection] = useState("");
  const [bodypixnet, setBodypixnet] = useState();
  const [prevClassName, setPrevClassName] = useState();
  const [changebackground, setChangebackground] = useState(true);
  const [selectedOption, setSelected] = useState("");

  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  // function onChangeValue(event) {
  //   setSelected({ selectedOption: event.target.value });

  //   if (event.target.value == "custom") {
  //     // setChangebackground(false);
  //     // var effectcanvas = document.getElementById("canvas");
  //     // effectcanvas.hidden = false;
  //     // var blurcanvas = document.getElementById("canvassecond");
  //     // blurcanvas.hidden = true;
  //     // clickHandler(event.target.value);
  //     // againShowVideo();
  //     props.tryingfuction(event.target.value);
  //     return;
  //   }
  //   if (event.target.value == "blur") {
  //     // setChangebackground(false);
  //     // var blurcanvas = document.getElementById("canvassecond");
  //     // blurcanvas.hidden = false;
  //     // var effectcanvas = document.getElementById("canvas");
  //     // effectcanvas.hidden = true;
  //     // loadBodyPix();
  //     props.tryingfuction(event.target.value);
  //     return;
  //   }
  //   if (event.target.value == "none") {
  //     // setChangebackground(true);
  //     // var effectcanvas = document.getElementById("canvas");
  //     // var blurcanvas = document.getElementById("canvassecond");
  //     // var withoublur = document.getElementById("input");

  //     // effectcanvas.hidden = true;
  //     // blurcanvas.hidden = true;
  //     // withoublur.hidden = false;
  //     props.tryingfuction(event.target.value);
  //     return;
  //   }
  // }

  useEffect(() => {
    // performaction();
    CameraPermission();
    MicroPhonePermission();
    isOnline();
  }, [camerastate]);

  useEffect(() => {
    return () => {
      isInitialMount.current = true;
    };
  }, []);

  // async function againShowVideo() {
  // const localVideoTrack = await TwilioVideo.createLocalVideoTrack({
  // width: 640,
  // height: 480,
  // frameRate: 24,
  // });

  // document.getElementById("input").appendChild(localVideoTrack.attach());

  // const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
  // assetsPath: "",
  // maskBlurRadius: 10,
  // blurFilterRadius: 5,
  // });
  // await bg.loadModel();
  // localVideoTrack.addProcessor(bg);
  // }

  // function loadBodyPix() {
  // const options = {
  // multiplier: 0.75,
  // stride: 32,
  // quantBytes: 4,
  // };
  // bodyPix
  // .load(options)
  // .then((net) => perform(net))
  // .catch((err) => console.log(err));
  // }

  // async function perform(net) {
  // const videoElement = document.getElementById("input");
  // const canvas = document.getElementById("canvassecond");
  // const segmentation = await net.segmentPerson(videoElement);

  // const backgroundBlurAmount = 6;
  // const edgeBlurAmount = 2;
  // const flipHorizontal = true;

  // bodyPix.drawBokehEffect(
  // canvas,
  // videoElement,
  // segmentation,
  // backgroundBlurAmount,
  // edgeBlurAmount,
  // flipHorizontal
  // );

  // loadBodyPix();
  // }

  // const performaction = async () => {
  // await startFuction();
  // bodyPix.load().then((net) => {
  // setBodypixnet(net);
  // });
  // };

  // const startFuction = async () => {
  // const mediaStream = await navigator.mediaDevices.getUserMedia({
  // audio: true,
  // video: {
  // height: 600,
  // width: 600,
  // },
  // });

  // var videoElement = document.getElementById("input");

  // // If the below two lines are missed out, the error appears!
  // videoElement.width = 400;
  // videoElement.height = 400;

  // videoElement.srcObject = mediaStream;

  // videoElement = await new Promise((resolve, reject) => {
  // videoElement.onloadedmetadata = () => resolve(videoElement);
  // });

  // videoElement.play();
  // };

  // const drawimage = async (videoElement, context, canvas) => {
  // // create tempCanvas
  // const tempCanvas = document.createElement("canvas");

  // tempCanvas.width = videoElement.videoWidth;
  // tempCanvas.height = videoElement.videoHeight;
  // const tempCtx = tempCanvas.getContext("2d");
  // (async function drawMask() {
  // requestAnimationFrame(drawMask);
  // // draw mask on tempCanvas
  // const segmentation = await bodypixnet.segmentPerson(videoElement);

  // const mask = bodyPix.toMask(segmentation);
  // tempCtx.putImageData(mask, 0, 0);
  // // draw original image
  // context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  // // use destination-out, then only masked area will be removed
  // context.save();
  // context.globalCompositeOperation = "destination-out";
  // context.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
  // context.restore();
  // })();
  // setChangebackground(false);
  // };

  // const clickHandler = async (className) => {
  // // const webcam = webcamRef.current.video;
  // const webcam = document.getElementById("input");
  // const canvas = canvasRef.current;

  // webcam.width = canvas.width = webcam.videoWidth;
  // webcam.height = canvas.height = webcam.videoHeight;
  // const context = canvas.getContext("2d");
  // context.clearRect(0, 0, canvas.width, canvas.height);

  // if (prevClassName) {
  // canvas.classList.remove(prevClassName);
  // setPrevClassName(className);
  // } else {
  // setPrevClassName(className);
  // }
  // canvas.classList.add(className);

  // if (bodypixnet) {
  // const videoElement = document.getElementById("input");
  // drawimage(videoElement, context, canvas);
  // }
  // };

  function isOnline() {
    if (isSafari) {
      return;
    }
    // setConnection(navigator.connection.effectiveType);
    return navigator.onLine;
  }

  const CameraPermission = async () => {
    if (isSafari) {
      return;
    }
    await navigator.permissions
      .query({ name: "camera" })
      .then(function (PermissionStatus) {
        PermissionStatus.addEventListener("change", function () {
          console.log("Permisson state", PermissionStatus);
          if (PermissionStatus.state === "denied") {
            setCamera(false);
          } else {
            setCamera(true);
          }
        });
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  };

  const MicroPhonePermission = () => {
    if (isSafari) {
      return;
    }
    navigator.permissions
      .query({ name: "microphone" })
      .then((permission) => {
        setMicrophone(permission.state);
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  };

  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      webcamRef.current = node;
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!capturing) {
        handleDownload();
      }
    }
  }, [recordedChunks]);

  const handleStartCaptureClick = React.useCallback(() => {
    if (!camerastate) {
      setCamera(false);
    }
    // setChangebackground(true);
    setCapturing(true);
    setShow(true);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",

      handleDataAvailable
    );

    mediaRecorderRef.current.start();

    setTimeout(() => {
      if (!isInitialMount.current) handleStopCaptureClick();
    }, 10000);
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    console.log("Recorded Chunks", recordedChunks);
    mediaRecorderRef.current.stop();
    setShow(false);
    setCapturing(false);

    const video = document.getElementById("video-replay");
    video.addEventListener("ended", function (e) {
      setShow(true);
      // window.location.reload();
    });
  }, [mediaRecorderRef, setCapturing, webcamRef]);

  const handleDownload = React.useCallback(() => {
    console.log("Blobs", recordedChunks);
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, { type: "video/mp4" });
      const url = URL.createObjectURL(blob);
      const video = document.getElementById("video-replay");
      video.src = url;
      if (recordedChunks.length > 0) {
        recordedChunks.splice(0, recordedChunks.length);
      }
    }
  }, [recordedChunks]);

  return (
    <div className="audiomodule">
      <nav className="navbar">
        {!camerastate || microphonestate == "denied" ? (
          <div className="camerablockpopupdiv">
            <img
              className="camerablockimg"
              src={CAMERA_BLOCKED}
              alt="camerablock"
            ></img>
          </div>
        ) : (
          <div className="previewbuttonscreen">
            <div
              className="changebackgroundstyles"
              // style={{
              // marginTop: "20px",
              // marginLeft: "10px",
              // textAlign: "center",
              // marginRight: "256px",
              // zIndex: "999",
              // position: "absolute",
              // }}
            >
              <Row>
                <Col md={12} className="text-center">
                  <Image
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/static/media/bg-Logo.svg"}
                    alt="begalileo Logo"
                  />
                </Col>
              </Row>
              {/* <Row>
 <Col md={12} className="text-center">
 <div className="changebackground_maindiv">
 <h6 className="backgroundtext">Change Background</h6>
 <div className="outsideborder">
 <div
 className={styles.effects}
 style={{
 width: "260px",
 gap: "5px",
 display: "flex",
 }}
 onChange={onChangeValue}
 >
 <label>
 <input
 style={{ marginTop: "5px" }}
 type="radio"
 value="none"
 name="gender"
 />{" "}
 <img src={BlurOff}></img>
 </label>
 <label>
 <input
 style={{ marginTop: "5px" }}
 type="radio"
 value="blur"
 name="gender"
 />{" "}
 <img src={blurEffect}></img>
 </label>
 <label>
 <input
 style={{ marginTop: "5px" }}
 type="radio"
 value="custom"
 name="gender"
 />{" "}
 <img src={CustomBackground}></img>
 </label>
 </div>
 </div>
 </div>
 </Col>
 </Row> */}
            </div>
            <div className="mainScreen">
              {show ? (
                <div className="otherscreen">
                  {changebackground ? (
                    <Webcam
                      className="previewscreens"
                      audio={true}
                      id="input"
                      width={240}
                      height={240}
                      // muted={false}
                      ref={measuredRef}
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      // ref={webcamRef}
                    />
                  ) : (
                    <Webcam
                      hidden
                      className="previewscreens"
                      audio={true}
                      id="input"
                      width={240}
                      height={240}
                      // muted={false}
                      ref={measuredRef}
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      // ref={webcamRef}
                    />
                  )}
                  <canvas
                    ref={canvasRef}
                    id="canvas"
                    hidden
                    width={240}
                    height={240}
                    style={{
                      width: "250px",
                      height: "210px",
                      transform: "scaleX(-1)",
                    }}
                    className="previewscreens"
                  ></canvas>
                  <canvas
                    // ref={canvasRef}
                    hidden
                    width={240}
                    height={240}
                    id="canvassecond"
                    style={{
                      width: "250px",
                      height: "210px",
                    }}
                    className="previewscreens"
                  ></canvas>

                  {/* <video
 controls
 autoPlay
 id="video-stream"
 className="previewscreens"
 ></video> */}
                </div>
              ) : (
                <div>
                  <div className="otherscreen">
                    <video
                      ref={canvasRef}
                      className={
                        isSafari ? "recordedscreensafari" : "recordedscreen"
                      }
                      id="video-replay"
                      controls
                      autoPlay
                    ></video>
                  </div>
                  {!isSafari && (
                    <div style={{ marginTop: "5px" }}>
                      <h4 className="playing_video">Playing Video ...</h4>
                    </div>
                  )}
                </div>
              )}

              {capturing ? (
                <div className="pleasesaysomething">
                  <h3 className="h3textt">Say something</h3>
                  <p>
                    {/* for eg : <strong>"I am testing my audio and video"</strong> */}
                    {/* <Timer></Timer> */}
                    <StopRecordTimer />
                  </p>
                </div>
              ) : (
                <div className="previewtextstyle">
                  <Button
                    disabled={!show}
                    className="primary-button"
                    variant="secondary"
                    onClick={handleStartCaptureClick}
                  >
                    {/* Start Capture */}
                    Test Audio and Video
                  </Button>
                </div>
              )}
            </div>

            <div className="previewtopbox">
              {/* <div className="previewtextstyle">
 <h3 className="h3text">Preview how you'll look and sound</h3>
 </div> */}

              <div className="technicalinformation">
                <div className="innerboxtechnicalsecond">
                  <div style={{ padding: "10px" }}>
                    <img
                      className="technicallogo"
                      // src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Google_mic.svg/1200px-Google_mic.svg.png"
                      src={Miclogo}
                      alt="miclogo"
                    ></img>
                  </div>
                  <div className="othertextstyle">
                    <h3 className="spanclass">Microphone</h3>
                    <span className="spanclasstwo">
                      {microphonestate === "denied"
                        ? "Please turn on mic"
                        : "Okay"}
                    </span>
                  </div>
                </div>
                <div className="innerboxtechnicalsecond">
                  <div style={{ padding: "10px" }}>
                    <img
                      className="technicallogo"
                      // src="https://e7.pngegg.com/pngimages/615/392/png-clipart-camera-icon-logo-camera-icon-black-and-white-camera-logo-rectangle-photography.png"
                      src={Cameralogo}
                      alt="miclogo"
                    ></img>
                  </div>
                  <div className="othertextstyle">
                    <h3 className="spanclass">Camera</h3>
                    <span className="spanclasstwo">
                      {!camerastate ? "Please turn on camera" : "Okay"}
                    </span>
                  </div>
                </div>

                <div className="innerboxtechnicalsecond">
                  <div style={{ padding: "10px" }}>
                    <img
                      className="technicallogo"
                      // src="https://www.freepnglogos.com/uploads/logo-wifi-png/logo-wifi-some-countries-banning-wifi-schools-should-south-13.png"
                      src={Wifilogo}
                      alt="miclogo"
                    ></img>
                  </div>
                  <div className="othertextstyle">
                    <h3 className="spanclass">Connection</h3>
                    <span className="spanclasstwo">
                      {connection === "slow-2g" || connection === "2g"
                        ? "Bad"
                        : "Good"}
                    </span>
                  </div>
                </div>
              </div>

              {/* <button onClick={() => clickHandler("custom")}>Custom</button>
 <button onClick={() => clickHandler("blur")}>Blur Image</button> */}
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}
