import React, { useState, useEffect } from "react";
import randomColor from "randomcolor";
import participantPropType from "../../propTypes/participant";
import Track from "../Track/Track";
import { mapToArray, studentName } from "../../utils";

import styles from "./Participant.module.scss";
import { BlockPicker } from "react-color";
import { excludeParticipant } from "../../StudentActivitys/ExcludeParticipant";

const Participant = ({ rparticipants, selected, participant }) => {
  let remoteParticipants = rparticipants + 1;

  let height;

  if (selected === 1) {
    if (remoteParticipants === 2 && participant.identity != "tutor") {
      height = "350px";
    } else {
      height = "350px";
    }
  } else if (selected === 2 && participant.identity == "tutor") {
    height = "600px";
  } else if (selected === 2 && participant.identity != "tutor") {
    height = "140px";
  } else {
    height = "140px";
  }

  function hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  const [isVideoAvilable, setIsVideoAvailable] = useState(true);
  const [bgColor, setBgColor] = useState("DodgerBlue");
  let colorRand = intToRGB(hashCode(participant.identity));

  // const containerStyle = {
  //   display: 'flex',
  //   width: '100%',
  //   height: '330px',
  //   backgroundColor: '#'+colorRand,
  //   justifyContent: 'center'
  // };

  const containerStyle = {
    display: "flex",
    width: "100%",
    height: height,
    backgroundColor: "#" + colorRand,
    justifyContent: "center",
    borderTopLeftRadius: selected != 1 ? "6px" : "",
    borderTopRightRadius: selected != 1 ? "6px" : "",
  };

  useEffect(() => {
    // let color = randomColor();
    // setBgColor(color);
    //
    mapToArray(participant.tracks).map((track) => {
      if (track.kind == "video" && track.trackName !== "screen_tutor") {
        setIsVideoAvailable(false);
      }
    });
  });
const specialParticipant=excludeParticipant

  return (
    <div className={`${styles.participant} studentscreenshot-${participant?.identity?.split("-")[0]}`} id={`${specialParticipant.includes(participant?.identity)?"":"ActivityCaptureParticipant"}`} >
      {isVideoAvilable && (
        <div style={containerStyle}>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            {studentName(participant.identity)}
          </h1>
        </div>
      )}
      {/* {mapToArray(participant.tracks).map((trackPublication)=>{
        console.log("TRACK NAME",trackPublication.track.kind)
        return trackPublication.track &&
         trackPublication.trackName !== "screen_tutor" && (
           <div key={trackPublication.track.name}>
             <Track track={trackPublication.track} participant={participant} />
           </div>
         )
      })} */}
      {mapToArray(participant.tracks).map(
        (trackPublication) =>
          trackPublication.track &&
          trackPublication.trackName !== "screen_tutor" && (
            <div key={trackPublication.track.name}>
              <Track track={trackPublication.track} participant={participant} />
            </div>
          )
      )}
    </div>
  );
};

Participant.propTypes = {
  participant: participantPropType.isRequired,
};

export default Participant;
