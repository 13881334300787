import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Typography, Select } from "@material-ui/core";
// import { useAppState } from '../../../state';
import useDevices from "../hooks/useDevices";

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();

  // const [audioDevices, setAudioDevices] = useState("");

  // const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   var arr = [];
  //   let close = true;

  //   if (close) {
  //     if (audioOutputDevices.length > 1) {
  //       audioOutputDevices.map((device) => {
  //         console.log("deviceId", device.deviceId);
  //         arr.push(device.deviceId);
  //       });
  //       let id = setTimeout(() => {
  //         if (audioDevices == "" && arr[0] != undefined) {
  //           console.log("setting first time now useEffect close");
  //           setAudioDevices(arr[0]);
  //           setRefresh(true);
  //           close = false;
  //         }

  //         clearTimeout(id);
  //       }, 3000);
  //     }
  //   }
  // }, [audioDevices, refresh]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flexBasis: "93%" }}>
        <div className="inputSelect">
          {audioOutputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Typography variant="subtitle2" gutterBottom>
                Audio Output
              </Typography>
              <Select value={""} variant="outlined">
                {audioOutputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <>
              <Typography variant="subtitle2">Audio Output</Typography>
              <Typography>{"System Default Audio Output"}</Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
