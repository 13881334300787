import React, { useState, useEffect } from "react";

function StopRecordTimer() {
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    // if (counter === 60) {
    //   if (typeof onGameTimerStartStop === "function")
    //     onGameTimerStartStop(identity, "start")
    // }
    // if (counter === 0) {
    //   if (typeof onGameTimerStartStop === "function") onGameStop();
    //   callBack();
    // }
  }, [counter]);

  //   function onGameStop() {
  //     setTimeout(() => onGameTimerStartStop(identity, "stop"), 3000);
  //   }

  return (
    <React.Fragment>
      <div
        // className="countdown-res"
        style={{
          width: "55px",
          height: "55px",
          backgroundColor: "#fff",
          boxShadow: "0px 5px 10px rgba(11, 66, 171, 0.15)",
          borderRadius: "50%",
          position: "absolute",
          right: "15.5rem",
          textAlign: "center !important",
          top: "16rem",
        }}
      >
        <div
          id="countdown"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="timer-counter" id="countdown-number">
            {counter}
          </div>
          <svg>
            <circle r="18" cx="20" cy="20"></circle>
          </svg>
        </div>
      </div>
      {/* <span className="timer-counter">{counter}</span> */}
    </React.Fragment>
  );
}

export default StopRecordTimer;
