import React from "react";
import { Button, Col, Container, Row, Image, Modal } from "react-bootstrap";

class ErrorPageShowPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraName: "",
      MicroPhoneName: "",
    };

    this.checkingCameraNamesList.bind(this);
  }

  refreshPage = () => {
    window.location.reload();
  };

  componentDidMount() {
    this.checkingCameraNamesList();
  }

  checkingCameraNamesList = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      for (var i = 0; i < devices.length; i++) {
        var device = devices[i];
        if (device.kind === "videoinput") {
          var option = document.createElement("option");
          option.value = device.deviceId;
          option.text = device.label || "camera " + (i + 1);
          if (option.value != "") {
            this.setState({
              cameraName: option.text,
            });
          } else {
            this.setState({
              cameraName: "No Device Found",
            });
          }

          console.log("List of Devices", option);
        }
        if (device.kind === "audioinput") {
          var option = document.createElement("option");
          option.value = device.deviceId;
          option.text = device.label || "Audio" + (i + 1);
          if (option.value != "") {
            this.setState({
              MicroPhoneName: option.text,
            });
          } else {
            this.setState({
              MicroPhoneName: "No Device Found",
            });
          }
          console.log("List of Devices", option);
        }
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal.Dialog centered>
          <Modal.Body style={{ padding: "40px", border: "1px solid #E2E4EE" }}>
            <Row>
              <Col md={12} className="text-center">
                <h3
                  style={{ color: "#233584", fontWeight: "bold" }}
                  className="text-center"
                >
                  Error
                </h3>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <Image
                  style={{ width: "150px" }}
                  src={process.env.PUBLIC_URL + "/static/media/Error-image.png"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <h6 className="page-sub-head text-center">Uh Ohhhh</h6>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <div className="showcameraandmicinfo">
                  <div className="cameraNametop">
                    <h5 className="cameraheading">Camera</h5>
                    <div className="cameraNameshow">
                      <h4
                        // className={
                        //   this.state.cameraName == "" || "No Device Found"
                        //     ? "cameraNameshowsecondred"
                        //     : "cameraNameshowsecond"
                        // }
                        className="cameraNameshowsecond"
                      >
                        {this.state.cameraName == ""
                          ? "No Device Found"
                          : this.state.cameraName}
                      </h4>
                    </div>
                  </div>
                  <div className="MicrophoneNametop">
                    <h5 className="cameraheading">Microphone</h5>
                    <div className="microphoneNameshow">
                      <h4
                        // className={
                        //   this.state.MicroPhoneName == "No Device Found" || ""
                        //     ? "cameraNameshowsecondred"
                        //     : "cameraNameshowsecond"
                        // }
                        className="cameraNameshowsecond"
                      >
                        {this.state.MicroPhoneName == ""
                          ? "No Device Found"
                          : this.state.MicroPhoneName}
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <p
                  style={{ fontSize: "18px", color: "#233584" }}
                  className="text-center"
                >
                  {this.props.errMessage}
                </p>
              </Col>
            </Row>

            <br />

            <Container>
              <Row className="text-right">
                <Col md={12} className="text-center">
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="primary"
                    onClick={this.props.callSupport}
                  >
                    Call Tech Support
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.props.hideErrorPage}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.refreshPage}
                  >
                    Retry
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </React.Fragment>
    );
  }
}

export default ErrorPageShowPopup;
