const in1 = ["IN", "PK", "LK", "KE"];
const au1 = ["AU", "NL", "NZ"];
const br1 = ["NONE"];
const de1 = [
  "AE",
  "QA",
  "KW",
  "SA",
  "BH",
  "OM",
  "IT",
  "FR",
  "EG",
  "DE",
  "NG",
  "SE",
  "SY",
  "SD",
  "LB",
  "JO",
  "PL",
  "TR",
];
const ie1 = ["GB", "ES", "GM", "IE"];
const jp1 = ["HK", "CN"];
const sg1 = ["SG", "ID", "MY", "PH", "VN"];
const us1 = ["US"];
const us2 = ["US"];

export const getTwilioRegionsWithCountry = (code) => {
  if (in1.includes(code)) return "in1";
  else if (au1.includes(code)) return "au1";
  else if (br1.includes(code)) return "br1";
  else if (de1.includes(code)) return "de1";
  else if (ie1.includes(code)) return "ie1";
  else if (jp1.includes(code)) return "jp1";
  else if (sg1.includes(code)) return "sg1";
  else if (us1.includes(code)) return "us1";
  else if (us2.includes(code)) return "us2";
  else return "gll";
};
