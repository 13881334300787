import React from "react";

import participantPropType from "../../propTypes/participant";
import Track from "../Track/Track";
import { mapToArray } from "../../utils";

import styles from "./Participant.module.scss";

const ScreenParticipant = ({ participant }) => (
  <div className={styles.participant}>
    {mapToArray(participant.tracks).map(
      (trackPublication) =>
        trackPublication.track &&
        trackPublication.trackName === "screen_tutor" && (
          <Track
            key={trackPublication.trackName}
            track={trackPublication.track}
          />
        )

      //

      //  trackPublication.trackName === "screen_tutor" ?
      //       <Track
      //       key={trackPublication.trackName}
      //       track={trackPublication.track}
      //     />
      //   : ""
    )}
    {/* { mapToArray(participant.tracks).map(
      trackPublication =>trackPublication.track && (

      
          <Track
            key={trackPublication.track.name}
            track={trackPublication.track}
          />
        )
    )} */}
  </div>
);

export default ScreenParticipant;
