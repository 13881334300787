import React from "react";
import { Button, Col, Container, Row, Image, Modal } from "react-bootstrap";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <Modal.Dialog centered>
          <Modal.Body style={{ padding: "40px", border: "1px solid #E2E4EE" }}>
            <Row>
              <Col md={12} className="text-center">
                <h3
                  style={{ color: "#233584", fontWeight: "bold" }}
                  className="text-center"
                >
                  Error
                </h3>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <Image
                  style={{ width: "250px" }}
                  src={process.env.PUBLIC_URL + "/static/media/Error-image.png"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <h6 className="page-sub-head text-center">Uh Ohhhh</h6>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <p
                  style={{ fontSize: "18px", color: "#233584" }}
                  className="text-center"
                >
                  {this.props.errMessage}
                </p>
              </Col>
            </Row>

            <br />

            <Container>
              <Row className="text-right">
                <Col md={12} className="text-center">
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="primary"
                    onClick={this.props.callSupport}
                  >
                    Call Tech Support
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.props.hideErrorPage}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.refreshPage}
                  >
                    Retry
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </React.Fragment>
    );
  }
}

export default ErrorPage;
