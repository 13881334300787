import React from "react";
import { Button, Col, Container, Row, Image, Modal } from "react-bootstrap";

import Microphoneimg from "../../assets/MicrophoneBlocked.png";

class ErrorPageformicrophone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: true,
    };
  }

  showmicimage = () => {
    this.setState({
      showmodal: false,
    });
  };

  refreshPage = () => {
    window.location.reload();
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <Modal.Dialog centered>
          <Modal.Body style={{ padding: "40px", border: "1px solid #E2E4EE" }}>
            {this.state.showmodal ? (
              <Row>
                <Col md={12} className="text-center">
                  <h3
                    style={{ color: "#233584", fontWeight: "bold" }}
                    className="text-center"
                  >
                    Error
                  </h3>
                </Col>
              </Row>
            ) : null}
            {this.state.showmodal ? (
              <Row>
                <Col md={12} className="text-center">
                  <Image
                    style={{ width: "180px" }}
                    src={
                      process.env.PUBLIC_URL + "/static/media/Error-image.png"
                    }
                  />
                </Col>
              </Row>
            ) : null}

            {this.state.showmodal ? (
              <Modal.Dialog>
                <Modal.Header closeButton>
                  <Modal.Title>Can't find your microphone</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ padding: "0.5rem" }}>
                  <p>
                    Check your system settings to make sure that a microphone is
                    available. You might need to restart your browser
                  </p>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" onClick={() => this.showmicimage()}>
                    Show steps to troubleshoot issue
                  </Button>
                </Modal.Footer>
              </Modal.Dialog>
            ) : (
              <>
                <Row>
                  <Col md={12} className="text-center">
                    <h3
                      style={{ color: "#233584", fontWeight: "bold" }}
                      className="text-center"
                    >
                      Please turn on microphone from navbar at the top left
                      corner and after that click on Retry and rejoin the
                      session.
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-center">
                    <Image
                      style={{ width: "330px", height: "250px" }}
                      src={Microphoneimg}
                    />
                  </Col>
                </Row>
              </>
            )}

            <br />

            <br></br>

            <Container>
              <Row className="text-right">
                <Col md={12} className="text-center">
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="primary"
                    onClick={this.props.callSupport}
                  >
                    Call Tech Support
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.props.hideErrorPage}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ margin: "0 10px" }}
                    className="primary-button"
                    variant="secondary"
                    onClick={this.refreshPage}
                  >
                    Retry
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal.Dialog>
      </React.Fragment>
    );
  }
}

export default ErrorPageformicrophone;
