import React from "react";
import { Button, Col, Container, Row, Image, Modal } from "react-bootstrap";


const ShowModalMessage = (props) => {
    return (
        <Modal show={true}>

            <Modal.Body>

                <div dangerouslySetInnerHTML={{ __html: props.message }} />
            </Modal.Body>
            <Modal.Footer>
            <Button style={{ margin: "0 10px" }} className="primary-button" variant="secondary" onClick={props.hideModalMessage}>Close</Button>
            </Modal.Footer>

        </Modal>

        //     <FlashMessage>
        //         <div dangerouslySetInnerHTML={{__html: props.message }} />

        //   </FlashMessage>
    )
}
export default ShowModalMessage;