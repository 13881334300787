import React, { useState, useEffect, useRef } from "react";

import Video from "twilio-video";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  Switch,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";

import WebcamPreview from "./WebCamPreview/WebcamPreview";

import SettingsMenu from "./Buttons/SettingsMenu";
import ToggleAudioButton from "./Buttons/ToggleAudioButton";
import ToggleVideoButton from "./Buttons/ToggleVideoButton";
import VideoTrack from "./TestingVideoPreview/VideoTrack";

import CAMERA_BLOCKED from "../Test/CAMERA_BLOCKED.png";
import BegalileoIcon from "./Icons/begalileoIcon";

// import useVideoContext from "./VideoContext/useVideoContext";

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  marginTop: {
    marginTop: "1em",
  },
  deviceButton: {
    width: "100%",
    border: "2px solid #aaa",
    margin: "1em 0",
  },
  localPreviewContainer: {
    display: "flex",
    paddingRight: "2em",
    marginBottom: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2.5em",
    },
  },
  joinButtons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
      "& button": {
        margin: "0.5em 0",
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em",
    },
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0,
  },
  toolTipContainer: {
    display: "flex",
    alignItems: "center",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
    "& svg": {
      marginLeft: "0.3em",
    },
  },

  bottomBar: {
    display: "flex",
    width: "100%",
    height: "50px",
    flexDirection: "row",
    marginTop: "20px",
  },

  settingBar: {
    flexBasis: "60%",
    maxWidth: "60%",
    height: "100%",
    marginTop: "12px",
  },

  joinBar: {
    flexBasis: "40%",
    maxWidth: "40%",

    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  cancelButton: {
    flexBasis: "30%",
    maxWidth: "30%",
    flexWrap: "wrap",
    height: "30px",
  },

  joinButton: {
    flexBasis: "60%",
    maxWidth: "60%",
    flexWrap: "wrap",
    height: "30px",
    marginLeft: "30px",
  },
}));

export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
  startLiveClass,
}) {
  const [videoEnable, setVideoEnable] = useState(true);
  const [audioEnable, setAudioEnable] = useState(true);
  const rejoinIdRef = useRef(null);
  const [microphonestate, setMicrophone] = useState("");
  const [camerastate, setCamera] = useState(true);
  const [videoTracks, setVideoTracks] = useState("");
  const [audioTracks, setAudioTracks] = useState("");
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();

  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  // useEffect(() => {
  //   let check = sessionStorage.getItem("rejoin");
  //   if (check === "true") {
  //     rejoinIdRef.current = setTimeout(() => {
  //       joinLiveClassBtn();
  //     }, 2500);
  //   }
  //   return () => clearTimeout(rejoinIdRef.current);
  // }, []);

  useEffect(() => {
    CameraPermission();
    MicroPhonePermission();
  }, [camerastate]);

  const handleToggleVideo = (e) => {
    setVideoEnable(e);

    if (e) {
      getVideoTracks();
    } else {
      if (videoTracks?.isEnabled) {
        videoTracks.stop();
        console.log("Stopping video tracks");
      }
    }
  };

  const handleToggleAudio = (e) => {
    setAudioEnable(e);

    if (e) {
      getAudioTracks();
    } else {
      if (audioTracks?.isEnabled) {
        audioTracks.stop();
        console.log("Stopping audio tracks");
      }
    }
  };

  const CameraPermission = async () => {
    if (isSafari) {
      return;
    }
    await navigator.permissions
      .query({ name: "camera" })
      .then(function (PermissionStatus) {
        PermissionStatus.addEventListener("change", function () {
          console.log("CameraPermission State", PermissionStatus.state);
          // console.log("Permisson state", PermissionStatus);
          if (PermissionStatus.state === "denied") {
            setCamera(false);
          } else {
            setCamera(true);
          }
        });
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  };

  const MicroPhonePermission = () => {
    if (isSafari) {
      return;
    }
    navigator.permissions
      .query({ name: "microphone" })
      .then((permission) => {
        console.log("Microphone State", permission.state);
        setMicrophone(permission.state);
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  };

  useEffect(() => {
    if (videoTracks == "") {
      getVideoTracks();
    }

    if (videoTracks != "") {
      setRefresh(true);
      console.log("videoTracks are not null");
    }
  }, [videoTracks, refresh]);

  const getVideoTracks = async () => {
    try {
      let localVideoTrack = await Video.createLocalVideoTrack();

      if (localVideoTrack.kind === "video") {
        setVideoTracks(localVideoTrack);
      }
    } catch (error) {
      console.log("camera connecting error", error);
    }
  };

  useEffect(() => {
    if (audioTracks == "") {
      getAudioTracks();
    }
  }, [audioTracks]);

  const getAudioTracks = async () => {
    try {
      let localAudioTrack = await Video.createLocalAudioTrack();

      console.log("localAudioTrack", localAudioTrack);

      if (localAudioTrack.kind === "audio") {
        setAudioTracks(localAudioTrack);
      }
    } catch (error) {
      console.log("audio connecting error", error);
    }
  };

  const joinLiveClassBtn = async () => {
    // clearTimeout(rejoinIdRef.current);
    startLiveClass();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "5vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* <div>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Class
          </Typography>
        </div> */}
        <div className="begalileoIcon">
          <BegalileoIcon />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "460px",
          height: "180px",
        }}
      >
        <div
          style={{
            maxWidth: "60%",
            flexBasis: "60%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {refresh && (!camerastate || microphonestate !== "denied") ? (
            <VideoTrack
              track={videoTracks}
              isLocal
              enabled={videoEnable}
              camerastate={camerastate}
              microphonestate={microphonestate}
              audioEnable={audioEnable}
              audioTracks={audioTracks}
              showMicIcon={true}
              refresh={refresh}
            />
          ) : (
            <div
              style={{
                width: "220px",
                height: "190px",
              }}
            >
              <img src={CAMERA_BLOCKED}></img>
            </div>
          )}

          {/* <WebcamPreview
            enabled={videoEnable}
            camerastate={camerastate}
            microphonestate={microphonestate}
          /> */}
        </div>
        <div
          style={{
            flexBasis: "40%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              width: "90%",
              minWidth: "90%",
              // padding: "5px",
              margin: "auto",
              height: "25%",
              marginTop: "30px",
              borderRadius: "8px",
            }}
          >
            <ToggleAudioButton
              className={classes.mobileButton}
              handleToggleAudio={handleToggleAudio}
              audioTracks={audioTracks}
              audioEnable={audioEnable}
            />
          </div>
          <div
            style={{
              border: "1px solid black",
              minWidth: "90%",
              borderRadius: "8px",
              width: "90%",
              padding: "5px",
              margin: "auto",
              height: "25%",
              marginTop: "15px",
            }}
          >
            <ToggleVideoButton
              className={classes.mobileButton}
              handleToggleVideo={handleToggleVideo}
              videoTracks={videoTracks}
            />
          </div>
        </div>
      </div>

      <div className={classes.bottomBar}>
        <div className={classes.settingBar}>
          <SettingsMenu
            enabled={videoEnable}
            track={videoTracks}
            isLocal
            camerastate={camerastate}
            microphonestate={microphonestate}
            audioEnable={audioEnable}
            audioTracks={audioTracks}
          />
        </div>
        <div className={classes.joinBar}>
          <div className={classes.cancelButton}>
            <Button
              style={{ textTransform: "none" }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
          <div className={classes.joinButton}>
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              data-cy-join-now
              onClick={joinLiveClassBtn}
            >
              Join Now
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "2px",
          marginTop: "4px",
        }}
      >
        <h4
          style={{
            fontWeight: "bold",
            color: "#233584",
            textAlign: "right",
            margin: "0",
            fontSize: "14px",
          }}
        >
          V3.2.31
        </h4>
      </div>
    </>
  );
}
