export const excludeParticipant = [
  "tech",
  "tutor",
  "parent",
  "liveadmin",
  "sm",
  "audit",
  "smmanager",
];
export const excludeRecordingParticipant = [
  "parent",
  "sm",
  "audit",
  "smmanager",
];
