import React from "react";

const Signal = (props) => {
  let list = [];
  for (let i = 1; i <= 5; i++) {
    list.push(
      <span
        key={"signal-" + i}
        id={"signal-" + i}
        className={i <= props.signalLevel ? "active-bar bar" : "bar"}
      ></span>
    );
  }
  return (
    <React.Fragment>
      <span id="bars">{list}</span>
    </React.Fragment>
  );
};

export default Signal;
