import React from "react";
import AudioPlayer from "react-h5-audio-player";
const audioType = 'audio/*';

class MicTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        recording: false,
        audios: [],
        playUrl : ""
    }
  }
  async componentDidMount(){
    const stream = await navigator.mediaDevices.getUserMedia({audio: true});
   
    // init recording
    this.mediaRecorder = new MediaRecorder(stream);
    // init data storage for video chunks
    this.chunks = [];
    // listen for data from media recorder
    this.mediaRecorder.ondataavailable = e => {
      if (e.data && e.data.size > 0) {
        this.chunks.push(e.data);
      }
    };
  }


  startRecording(e){
  
      e.preventDefault();
      this.chunks = [];
      // start recorder with 10ms buffer
      this.mediaRecorder.start(10);
      // say that we're recording
      this.setState({recording: true});
    
  }
  stopRecording(e){
    e.preventDefault();
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({recording: false});
    // save the video to memory
    this.playAudio();
   
  }

  playAudio(){
     
      const blob = new Blob(this.chunks, {type: audioType});
        // generate video url from blob
        const audioURL = window.URL.createObjectURL(blob);
        this.setState({
            playUrl: audioURL
        });
       
  }


  

 

  render() {
    return (
      <div>
        <button  onClick={e => this.startRecording(e)} type="button">Start</button>
        <button  onClick={e => this.stopRecording(e)} type="button">Stop</button>
        <AudioPlayer
               style={{ display: "none" }}
              autoPlay
              src={this.state.playUrl}
              // onPlay={e => 
              // onEnded={e => 

              // other props here
            />
      </div>
    );
  }
}
export default MicTest;