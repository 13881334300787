import React, { Component } from 'react';
import { getNetworkTestToken } from '../../api';
import { ProgressBar, Row, Spinner, Col, Image, Modal } from "react-bootstrap";
import { Button } from "react-bulma-components";
import GaugeChart from 'react-gauge-chart';

const Video = require('twilio-video');

class NetworkTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tokenData: null,
            enableTest: true,
            errorMessage: null,
            networkResult: null,
            testCompleted: false,
            networkProgress: null,
            progressCount: -10,
            loadingMessage: null

        };
    }
    componentDidMount() {

        //  this.extractNetworkTestToken();
    }

    resetAndClearData = () => {
        this.setState({
            enableTest: false,
            networkResult: null,
            networkProgress: null,
            errorMessage: null,
            progressCount: 0,
            loadingMessage: null
        })
    }


    loadWithMessage = (message) => {
        this.setState({
            loading: true,
            loadingMessage: message
        })
    }

    extractNetworkTestToken = () => {
        this.resetAndClearData();

        this.loadWithMessage("Getting ready for test");
        getNetworkTestToken().then(response => {

            
            if (response.data.status) {
                this.setState({
                    enableTest: true,
                    loading: false,
                    tokenData: response.data,
                    networkProgress: null
                })
                this.doNetworkTest();
            }

            else
                this.setState({
                    loading: false,
                    errorMessage: response.data.message
                })
            //this.doNetworkTest(data.publisher_token,data.subscriber_token);
        });
    }

    doNetworkTest = () => {
        this.setState({
            enableTest: false
        })



        const publisherToken = this.state.tokenData.publisher_token;
        const subscriberToken = this.state.tokenData.subscriber_token;
      
        const preflightTest = Video.testPreflight(publisherToken, subscriberToken);
        preflightTest.on('completed', function (report) {
            
            
            

            // network score is available only for group rooms.
            
            
            this.setState({
                enableTest: true,
                testCompleted: true,
                networkResult: report,
                progressCount: 100,
                loading: false,
                networkProgress: null
            });
        }.bind(this));

        preflightTest.on('failed', function (error) {

            
            this.setState({
                enableTest: true,
                testCompleted: true,
                progressCount: 100,
                loading: false,
                networkProgress: null
            })
        }.bind(this));

        preflightTest.on('progress', function (progressState) {
            var pgCount = this.state.progressCount;
            pgCount += 20;
            this.setState({
                networkProgress: progressState,
                progressCount: pgCount
            });
            if (progressState == "mediaStarted") {
                //setTimeout(this.loadWithMessage("Packing Result"), 5000);
                this.loadWithMessage("Packing Result");

            }

            
        }.bind(this));
    }

    closeNetworkTest = () => {
        this.props.closeNetworkTest();
    }

    averageInPercent = (value,totalValue) =>{
        if(value == 0 || value == null)
            return 0;
        else
        {
            return ((value*100)/totalValue);  
        }
         
    }



    render() {
        const { loading, enableTest, errorMessage, networkProgress, networkResult, testCompleted, progressCount, loadingMessage } = this.state;
        return (
            <React.Fragment>
                <Modal.Dialog centered>
                    <Modal.Body style={{ padding: "40px", border: "1px solid #E2E4EE" }}>
                        <Row>
                            <Col md={1}>
                                <a
                                    style={{ marginBottom: "20px" }}
                                    onClick={this.closeNetworkTest}
                                    variant="secondary"
                                >
                                    <i className="fa fa-arrow-left"></i>
                                </a>
                            </Col>
                            <Col md={10} className="text-center">
                                <Image
                                    className="img-fluid"
                                    src={process.env.PUBLIC_URL + "/static/media/bg-Logo.svg"}
                                    alt="begalileo Logo"
                                />
                            </Col>
                            <Col md={1}></Col>
                        </Row>

                        <br />
                        <Row>
                            <Col md={12} className="text-center">
                                <h4
                                    style={{
                                        color: "rgb(35, 53, 132)",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Network Test
                </h4>
                            </Col>
                        </Row>

                        <br />

                        {loading ? (
                            <div className="network-test-loader">
                                <Row>
                                    <Col md={12} className="text-center">
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                        <Spinner as="span" size="sm" animation="grow" variant="primary" />
                                    </Col>
                                </Row>

                                <br />
                                <h4
                                    style={{
                                        color: "rgb(35, 53, 132)",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    {loadingMessage}
                                </h4>
                                <br />
                            </div>
                        ) : (
                            ""
                        )}
                        {enableTest ? (
                            <Row>
                                <Col md={12} className="text-center">
                                    <Button
                                        style={{ marginBottom: "20px" }}
                                        onClick={this.extractNetworkTestToken}
                                        className="check-audio"
                                        variant="secondary"
                                    >
                                        {testCompleted ? "Check Again" : "Check"}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                        {errorMessage ? <h1>{errorMessage}</h1> : ""}
                        {networkProgress ? (
                            <div>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <ProgressBar now={progressCount} />
                                    </Col>
                                </Row>

                                <br />

                                <h4
                                    style={{
                                        color: "rgb(35, 53, 132)",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    {networkProgress}
                                </h4>
                            </div>
                        ) : (
                            ""
                        )}
                        {networkResult ? (
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <h4
                                            style={{
                                                color: "rgb(35, 53, 132)",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Your Test Report
                    </h4>
                                    </Col>
                                </Row>

                                <ul style={{ paddingLeft: "2rem" }}>
                                    <li style={{ paddingTop: "5px", paddingBottom: "3px" }}>
                                        Test Completed in {networkResult.testTiming.duration}{" "}
                    milliseconds
                  </li>
                                    <li style={{ paddingBottom: "3px" }}>
                                        It took {networkResult.networkTiming.connect.duration}{" "}
                    milliseconds to connect
                  </li>
                                    <li style={{ paddingBottom: "3px" }}>
                                        It took {networkResult.networkTiming.media.duration}{" "}
                    milliseconds to receive media
                  </li>
                                    {
                                        networkResult.stats.networkQuality != null &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Min : {networkResult.stats.networkQuality.min}
                                        </li>
                                    }
                                    {
                                        networkResult.stats.networkQuality != null &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Max : {networkResult.stats.networkQuality.max}
                                        </li>
                                    }

                                    {
                                        networkResult.stats.networkQuality != null &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Average : {networkResult.stats.networkQuality.average}
                                            <GaugeChart id="gauge-chart1" nrOfLevels={3} style={chartStyle} colors={["#FF0000","#F7FF00","#3AFF00"]} 

                                            
                                                percent={this.averageInPercent(networkResult.stats.networkQuality.average,500)} />
                                        </li>
                                    }
                                    {
                                        networkResult.stats.incomingBitrate.average &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Average Incoming bit rate : {networkResult.stats.incomingBitrate.average}
                                        </li>
                                    }
                                    {
                                        networkResult.stats.outgoingBitrate.average &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Average Outgoing bit rate : {networkResult.stats.outgoingBitrate.average}
                                        </li>
                                    }
                                    {
                                        networkResult.stats.jitter.average &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Average Jitter : {networkResult.stats.jitter.average}
                                        </li>
                                    }

                                    {
                                        networkResult.signalingRegion &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Signalling Region : {networkResult.signalingRegion}
                                        </li>
                                    }
                                    {
                                        networkResult.signalingRegion &&
                                        <li style={{ paddingBottom: "3px" }}>
                                            Media Region : {networkResult.mediaRegion}
                                        </li>
                                    }



                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal.Body>
                </Modal.Dialog>
            </React.Fragment>


        );
    }
}

const chartStyle = {
  
    
    textColor : '#000000'
}

export default NetworkTest;
