import React from "react";
import { Row, Col, Modal, Image, Spinner, Button } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import Webcam from "react-webcam";
import NetworkTest from "./NetworkTest";
import { TrackNameCharsInvalidError } from "twilio-video";
import Camtestexample from "./Camtestexample";
import styles from "./CamMicTest.module.css";
import blurEffect from "../../assets/blurEffect.png";
import CustomBackground from "../../assets/CustomBackground.png";
import BlurOff from "../../assets/BlurOff.png";
import ScreenRecording from "../Recorder/ScreenRecording";

const audioType = "audio/*";

class CamMicTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      playUrl: "",
      askTest: true,
      playEnd: false,
      videoCheck: false,
      audioCheck: false,
      webcam: false,
      networkTest: false,
      userReady: true,
      isCameraBlocked: false,
      audios: [],
      name: "React",
      selectedOption: "",
      finalvalue: "",
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.tryingfuction = this.tryingfuction.bind(this);
  }

  // startRecording = () => {
  //   this.setState({
  //     record: true,
  //     askTest:false,
  //     playEnd:false
  //   });
  //   setTimeout(() => {

  //     this.stopRecording();
  //   }, 3000);
  // };

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }

  tryingfuction(event) {
    console.log(event);
    this.setState({
      finalvalue: event,
    });
  }

  onChangeValue(event) {
    this.setState({
      selectedOption: event.target.value,
    });

    console.log(event.target.value);
  }

  formSubmit(event) {
    event.preventDefault();
    console.log(this.state.selectedOption);
  }

  componentDidMount() {}

  onCheckCameraPermission = () => {
    console.log("On CLick join");
    this.onUserReady();
    return;

    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      this.onUserReady();
    } else {
      navigator.permissions.query({ name: "camera" }).then((res) => {
        if (res.state == "granted") {
          // has permission
          this.onUserReady();
        } else {
          this.setState({
            isCameraBlocked: true,
          });
          //  this.getCameraPermission();
        }
      });
    }
  };

  getCameraPermission = () => {
    var now = Date.now();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {})
      .catch(function (err) {});
  };

  onUserReady = async () => {
    this.props.startLiveClass(this.state.finalvalue);

    return;

    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);

    if (isSafari) {
      // alert("Safari browser...");
      this.setState({
        audioCheck: false,
        videoCheck: true,
        userReady: false,
      });
      // this.props.startLiveClass();
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // init recording
      this.mediaRecorder = new MediaRecorder(stream);
      // init data storage for video chunks
      this.chunks = [];
      // listen for data from media recorder
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };
      this.setState({
        audioCheck: true,
        userReady: false,
      });
    }
  };

  goToNetWorkTest = () => {
    this.setState({
      audioCheck: false,
      videoCheck: false,
      networkTest: true,
      userReady: false,
    });
  };

  // displayRadioValue = () => {
  //   var ele = document.getElementsByName("gender");

  //   for (i = 0; i < ele.length; i++) {
  //     if (ele[i].checked) console.log(ele[i].value);
  //   }
  // };

  async startRecording() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // init recording
    this.mediaRecorder = new MediaRecorder(stream);

    this.chunks = [];
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    // say that we're recording
    this.setState({
      record: true,
      askTest: false,
      playEnd: false,
    });
    setTimeout(() => {
      this.stopRecording();
    }, 5000);
  }
  stopRecording() {
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({
      record: false,
    });
    // save the video to memory
    this.playAudio();
  }

  closeNetworkTest = () => {
    this.setState({
      audioCheck: false,
      videoCheck: false,
      networkTest: false,
      userReady: true,
    });
  };

  playAudio() {
    const blob = new Blob(this.chunks, { type: audioType });
    // generate video url from blob
    const audioURL = window.URL.createObjectURL(blob);
    this.setState({
      playUrl: audioURL,
    });
  }

  onPlayEnd() {
    this.setState({
      playEnd: true,
    });
  }

  handleCameraMessageModalClose = () => {
    this.setState({
      isCameraBlocked: false,
    });
  };

  videoCheck() {
    this.setState({
      videoCheck: true,
      audioCheck: false,
    });
  }

  render() {
    const {
      askTest,
      record,
      playEnd,
      playUrl,
      videoCheck,
      audioCheck,
      webcam,
      networkTest,
      userReady,
      isCameraBlocked,

      name,
    } = this.state;
    const { startLiveClass } = this.props;

    return (
      <React.Fragment>
        {networkTest ? (
          <NetworkTest closeNetworkTest={this.closeNetworkTest} />
        ) : null}

        {/* <div style={{ display: "none" }}>
          <ScreenRecording userJoined={false} />
        </div> */}

        <Modal
          show={isCameraBlocked}
          onHide={this.handleCameraMessageModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Camera blocked</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            beGalileo requires camera access to your camera and microphone.Click
            the camera blocked icon{" "}
            <Image
              className="img-fluid"
              src={process.env.PUBLIC_URL + "/static/media/ic_camera_cross.svg"}
              alt="begalileo Logo"
            />
            in your browser address bar
            <Image
              className="img-fluid"
              src={process.env.PUBLIC_URL + "/static/media/CAMERA_BLOCKED.png"}
              alt="begalileo Logo"
            />
          </Modal.Body>
        </Modal>
        {videoCheck ? (
          <Row>
            <Col
              style={{
                backgroundColor: "#F5F6FB",
                width: "100%",
                height: "100%",
              }}
              md={12}
              className="text-center"
            >
              <Modal.Dialog centered>
                <Modal.Body
                  style={{ padding: "40px", border: "1px solid #E2E4EE" }}
                >
                  <Row>
                    <Col md={12} className="text-center">
                      <h4
                        style={{
                          fontWeight: "bold",
                          paddingBottom: "40px",
                          color: "#233584",
                          textAlign: "center",
                          margin: "0",
                          fontSize: "14px",
                        }}
                      >
                        Webcam Check
                      </h4>
                    </Col>
                  </Row>

                  {/* <div>
                    <Webcam audio={true} />
                  </div> */}

                  <br />

                  <Row>
                    <Col md={12} className="text-center">
                      <Button
                        style={{ marginBottom: "20px" }}
                        onClick={startLiveClass}
                        className="check-audio"
                        variant="secondary"
                      >
                        Good to Go
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal.Dialog>
            </Col>
          </Row>
        ) : null}

        {userReady ? (
          <Row>
            <Col
              style={{
                backgroundColor: "#F5F6FB",
                width: "100%",
                height: "100%",
              }}
              md={12}
              className="text-center"
            >
              <Modal.Dialog style={{ maxWidth: "700px" }} centered>
                <Modal.Body
                  style={{
                    padding: "40px",
                    border: "1px solid #E2E4EE",
                  }}
                >
                  {/* <Row>
                    <Col md={12} className="text-center">
                      <Image
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL + "/static/media/bg-Logo.svg"
                        }
                        alt="begalileo Logo"
                      />
                    </Col>
                  </Row> */}

                  {/* <br />
                  <br /> */}
                  <Col md={12}>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="text-center">
                          <h4
                            style={{
                              fontWeight: "bold",
                              // paddingBottom: "40px",
                              color: "#233584",
                              textAlign: "center",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            {/* Are you ready? */}
                            Audio and Video Check
                          </h4>
                        </Col>
                      </Row>

                      <Camtestexample
                        tryingfuction={this.tryingfuction}
                        // backgroundClicked={this.state.selectedOption}
                      />

                      {/* <Webcam /> */}
                      {/* <br /> */}

                      {/* <div
                        style={{
                          marginTop: "118px",
                          marginLeft: "10px",
                          textAlign: "center",
                          zIndex: "999",
                          position: "absolute",
                        }}
                      >
                        <Row>
                          <Col md={12} className="text-center">
                            <h6 className={styles.backgroundtext}>
                              Change Background
                            </h6>
                            <div
                              className={styles.effects}
                              style={{
                                width: "260px",
                                gap: "6px",
                                display: "flex",
                              }}
                              onChange={this.onChangeValue}
                            >
                              <label>
                                <input
                                  style={{ marginTop: "5px" }}
                                  type="radio"
                                  value="none"
                                  name="gender"
                                />{" "}
                                <img src={BlurOff}></img>
                              </label>
                              <label>
                                <input
                                  style={{ marginTop: "5px" }}
                                  type="radio"
                                  value="blur"
                                  name="gender"
                                />{" "}
                                <img src={blurEffect}></img>
                              </label>
                              <label>
                                <input
                                  style={{ marginTop: "5px" }}
                                  type="radio"
                                  value="custom"
                                  name="gender"
                                />{" "}
                                <img src={CustomBackground}></img>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div> */}

                      <div>
                        <Row>
                          <Col md={12} className="text-center">
                            <Button
                              onClick={this.onCheckCameraPermission}
                              className="primary-button"
                              variant="secondary"
                            >
                              Join Class
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <br />

                      {/* <Row>
                        <Col md={12}>
                          <h4
                            style={{
                              fontWeight: "bold",
                              paddingBottom: "40px",
                              color: "#233584",
                              textAlign: "center",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            Do you need to check ?
                          </h4>

                          <a
                            title="Network Test"
                            onClick={() => this.goToNetWorkTest()}
                            style={{ margin: "0 10px", color: "#233584" }}
                          >
                            <Image
                              style={{ width: "40px" }}
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Network-Test.svg"
                              }
                              alt="Network Test"
                            />
                          </a>

                          <a
                            title="Audio Test"
                            onClick={() => this.startRecording()}
                            style={{ margin: "0 10px", color: "#233584" }}
                          >
                            <Image
                              style={{ width: "40px" }}
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Audio-Test.svg"
                              }
                              alt="Audio Test"
                            />
                          </a>

                          <a
                            title="Video Test"
                            style={{ margin: "0 10px", color: "#233584" }}
                          >
                            <Image
                              style={{ width: "40px" }}
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Video-Test.svg"
                              }
                              alt="Video Test"
                            />
                          </a>
                        </Col>
                      </Row> */}
                    </Col>
                  </Col>

                  {/* <Row>
                    <Col md={12} className="text-center">
                      <Button variant="secondary" onClick={() => this.goToNetWorkTest()}>Take Network Test</Button>
                    </Col>

                  </Row> */}

                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "#233584",
                      textAlign: "right",
                      margin: "0",
                      fontSize: "14px",
                    }}
                  >
                    V3.0.90
                  </h4>
                </Modal.Body>
              </Modal.Dialog>
            </Col>
          </Row>
        ) : null}

        {audioCheck ? (
          <div>
            {playEnd ? (
              <Row>
                <Col
                  style={{
                    backgroundColor: "#F5F6FB",
                    width: "100%",
                    height: "100%",
                  }}
                  md={12}
                  className="text-center"
                >
                  <Modal.Dialog centered>
                    <Modal.Body
                      style={{ padding: "40px", border: "1px solid #E2E4EE" }}
                    >
                      <Row>
                        <Col md={12} className="text-center">
                          <h4
                            style={{
                              fontWeight: "bold",
                              paddingBottom: "40px",
                              color: "#233584",
                              textAlign: "center",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            Audio Check
                          </h4>

                          <Image
                            style={{ marginBottom: "40px" }}
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Audio-check.png"
                            }
                            alt="Logo"
                          />
                        </Col>
                      </Row>

                      {!record ? (
                        <Row>
                          <Col md={12} className="text-center">
                            <Button
                              style={{ marginBottom: "20px" }}
                              onClick={() => this.videoCheck()}
                              className="check-audio"
                              variant="secondary"
                            >
                              Continue
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col md={12} className="text-center">
                          <a
                            style={{ fontSize: "12px", color: "#233583" }}
                            onClick={() => this.startRecording()}
                          >
                            Try Again
                          </a>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {askTest ? (
              <div>
                <Row>
                  <Col
                    style={{
                      backgroundColor: "#F5F6FB",
                      width: "100%",
                      height: "100%",
                    }}
                    md={12}
                    className="text-center"
                  >
                    <Modal.Dialog centered>
                      <Modal.Body
                        style={{ padding: "40px", border: "1px solid #E2E4EE" }}
                      >
                        <Row>
                          <Col md={12} className="text-center">
                            <h4
                              style={{
                                fontWeight: "bold",
                                color: "#233584",
                                textAlign: "center",
                                margin: "0",
                                fontSize: "14px",
                              }}
                            >
                              Audio Check
                            </h4>
                          </Col>
                        </Row>
                        <Image
                          style={{ marginTop: "40px", marginBottom: "40px" }}
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Audio-check.png"
                          }
                          alt="Logo"
                        />

                        <Row>
                          <Col md={12} className="text-center">
                            <Button
                              onClick={() => this.startRecording()}
                              className="check-audio"
                              variant="secondary"
                            >
                              Check Audio
                            </Button>
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row>
                  <Col
                    style={{
                      backgroundColor: "#F5F6FB",
                      width: "100%",
                      height: "100%",
                    }}
                    md={12}
                    className="text-center"
                  >
                    <h3>
                      {record ? (
                        <Modal.Dialog centered>
                          <Modal.Body
                            style={{
                              padding: "40px",
                              border: "1px solid #E2E4EE",
                            }}
                          >
                            <Row>
                              <Col md={12} className="text-center">
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    color: "#233584",
                                    textAlign: "center",
                                    margin: "0",
                                    fontSize: "14px",
                                  }}
                                >
                                  Audio Check - Recording
                                </h4>
                              </Col>
                            </Row>
                            <Image
                              style={{
                                marginTop: "40px",
                                marginBottom: "40px",
                              }}
                              className="img-fluid pulse"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Audio-check.png"
                              }
                              alt="Logo"
                            />

                            {/* <Row>
                    <Col md={12} className="text-left">
                      <h5 style={{ fontSize: "12px", fontWeight: "bold", color: "#233584" }}>Mircrophone</h5>
                    </Col>
                  </Row> */}

                            <Row>
                              <Col md={12} className="text-center">
                                <p
                                  style={{ color: "#233584", fontSize: "12px" }}
                                >
                                  Speak something
                                </p>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} className="text-center">
                                <Button
                                  className="check-audio"
                                  variant="secondary"
                                >
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    variant="light"
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </Modal.Body>
                        </Modal.Dialog>
                      ) : !playEnd ? (
                        <div>
                          <Modal.Dialog centered>
                            <Modal.Body
                              style={{
                                padding: "40px",
                                border: "1px solid #E2E4EE",
                              }}
                            >
                              <Row>
                                <Col md={12} className="text-center">
                                  <h4
                                    style={{
                                      fontWeight: "bold",
                                      color: "#233584",
                                      textAlign: "center",
                                      margin: "0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Audio Check - Playing Audio
                                  </h4>
                                </Col>
                              </Row>
                              <Image
                                style={{
                                  marginTop: "40px",
                                  marginBottom: "40px",
                                }}
                                className="img-fluid pulse"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Audio-check.png"
                                }
                                alt="Logo"
                              />

                              <Row>
                                <Col md={12} className="text-center">
                                  <Button
                                    className="check-audio"
                                    variant="secondary"
                                  >
                                    <Spinner
                                      size="sm"
                                      animation="grow"
                                      variant="light"
                                    />
                                  </Button>
                                </Col>
                              </Row>
                            </Modal.Body>
                          </Modal.Dialog>
                        </div>
                      ) : (
                        ""
                      )}
                    </h3>
                  </Col>
                </Row>

                <AudioPlayer
                  style={{ display: "none" }}
                  autoPlay
                  src={playUrl}
                  // onPlay={e =>
                  onEnded={(e) => this.onPlayEnd()}

                  // other props here
                />
              </div>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CamMicTest;
