import React, { useState, useEffect } from "react";

import WebcamPreview from "../WebCamPreview/WebcamPreview";

import { makeStyles } from "@material-ui/core/styles";

import VideoTrack from "../TestingVideoPreview/VideoTrack";

const useStyles = makeStyles({
  preview: {
    width: "300px",
    maxHeight: "200px",
    margin: "0.5em auto",
    "& video": {
      maxHeight: "200px",
    },
  },
});

export default function VideoInputList({
  enabled,
  track,
  isLocal,
  camerastate,
  microphonestate,
  showMicIcon,
}) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.preview}>
        <VideoTrack
          enabled={enabled}
          track={track}
          isLocal
          camerastate={camerastate}
          microphonestate={microphonestate}
          showMicIcon={showMicIcon}
        />

        {/* <WebcamPreview
          enabled={enabled}
          camerastate={camerastate}
          microphonestate={microphonestate}
        /> */}
      </div>
    </div>
  );
}
