import React, { PureComponent } from "react";
import PropTypes, { func } from "prop-types";
import TwilioVideo, { Logger, LocalDataTrack } from "twilio-video";
//import TwilioVideo, { LocalDataTrack } from "twilio-video";
import { isTutorCheck } from "../../utils/";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import { joinRoomError } from "../../utils/Constants";
import { confirmAlert } from "react-confirm-alert";
import * as VideoProcessors from "@twilio/video-processors";
import class_room from "../../assets/class_room.jpg";
import class_roomnew from "../../assets/bg.jpg";
import { getTwilioRegionsWithCountry } from "../../utils/CommonUtils";
import {
  getToken,
  getRoomToken,
  closeLiveSession,
  submitAStar,
  submitASmiley,
  submitAClapping,
  baseURL,
  updateDuration,
  updateStudentsFeedback,
  updateTeachersFeedback,
  submitErrorLog,
  baseURL2,
} from "../../api";

const ipad = /Macintosh/.test(navigator.userAgent) && "ontouchend" in document;
class AppContainer extends PureComponent {
  static propTypes = {
    render: PropTypes.func.isRequired,
  };
  screenRecordingRef = React.createRef();
  screenRecordingRef2 = React.createRef();
  teacherFeedbackRef = React.createRef({ flag: false });
  state = {
    videoRoom: null,
    isJoining: false,
    userName: "",
    roomName: "",
    errorMessage: null,
    errorMessageshowPopup: null,
    errorMessageformic: null,
    flashMessage: null,
    showWhiteBoard: "",
    room_id: "",
    audioTest: false,
    tutor_name: "",
    parent_country_code: "",
    students: [],
    students_arr: [],
    new_coding_plan: null,
    class_type: null,
    class_start_time: "",
    show_new_codings: false,
    env: null,
    recordingEnabled: false,
    connectionStatus: true,
    userSharingScreen: false,
  };

  componentDidMount() {
    // if (adapter.browserDetails.browser === "firefox") {
    //   adapter.browserShim.shimGetDisplayMedia(window, "screen");
    // }
    // console.log("Props", this.props);
  }

  startLiveClass = (background_type) => {
    this.setState({
      audioTest: true,
    });
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let roomID = params.get("roomID");
    let userID = params.get("userID");
    let liveClassID = params.get("liveClassID");
    let background = background_type;

    if (roomID != null && userID != null && liveClassID != null)
      this.joinRoomWithRoomId(userID, roomID, liveClassID);
    else if (userID != null)
      this.CreateAndJoinRoom(userID, liveClassID, background);
  };

  getToken = async () => {
    const { userName } = this.state;

    const response = await getToken(userName);

    return response.data.token;
  };

  getUserToken = async (userId, roomID, liveClassId) => {
    const response = await getRoomToken(userId, roomID, liveClassId);
    this.setState({
      tutor_name: response.teacher_name,
      showWhiteBoard: response.data.white_board_url,
      students: response.student_ids,
      new_coding_plan: response.new_coding_plan,
      class_type: response.class_type,
      show_new_codings: response.show_new_codings,
      class_start_time: response.class_start_time,
      env: response.env,
      isGroupClass: response?.group_class,
      student_grade: response?.grade,
    });
    console.log("Students", response.data.students);
    return response.data.token;
  };

  createRoomToken = async (userId, liveClassId) => {
    const response = await getToken(userId, liveClassId);

    console.log("Response Data Live Class", response.data);
    return response.data;
  };

  checkIsCameraAvailable = async () => {
    let cameraCheck = false;
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);
    if (isSafari) {
      return true;
    } else {
      await navigator.permissions.query({ name: "camera" }).then((res) => {
        console.log("Camera result", res);

        if (res.state == "granted") {
          // has permission
          cameraCheck = true;
        } else {
          cameraCheck = false;
        }
      });

      return cameraCheck;
    }
  };

  connectWithoutCameraConfirmation = async (token, roomId) => {
    confirmAlert({
      title: "Are you sure want to connect without camera",
      message:
        "If you want to connect with camera pleas enable the camera permission and reload to connect",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("."),
        },
        {
          label: "No",
          onClick: () =>
            alert(
              "If you want to connect with camera pleas enable the camera permission and reload to connect"
            ),
        },
      ],
    });
  };

  connectWithoutCamera = async (token, roomId, isoCode) => {
    const localAudioTrack = await TwilioVideo.createLocalAudioTrack({
      noiseCancellationOptions: {
        sdkAssetsPath: "/dist",
        vendor: "krisp",
      },
    });

    if (!localAudioTrack.noiseCancellation) {
      console.log("Not Loaded");
    } else {
      console.log("Loaded");

      if (!localAudioTrack.noiseCancellation.isEnabled) {
        console.log("Not Possible");
      }
    }

    console.log("LocalAudioTracks", localAudioTrack);

    this.setState({ localAudioTrack });

    const localDataTrack = new LocalDataTrack();
    this.setState({ localDataTrack });

    const videoRoom = await TwilioVideo.connect(token, {
      audio: true,
      name: roomId,
      // region: getTwilioRegionsWithCountry(isoCode),
      region: "gll",
      tracks: [localAudioTrack, localDataTrack],
      insights: true,
      preferredVideoCodecs: "auto",
      // preferredVideoCodecs: ["VP8"],
      // logLevel: 'debug',

      networkQuality: {
        local: 1,
        remote: 2,
      },
      _useTwilioConnection: true,
    });
    return videoRoom;
  };

  connectWithCamera = async (token, roomId, isoCode) => {
    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();

    // const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
    //   assetsPath: '',
    //   maskBlurRadius: 10,
    //   blurFilterRadius: 5,
    // });
    // await bg.loadModel();
    // localVideoTrack.addProcessor(bg);

    // const img = new Image();

    // //img.src = class_room;

    //  img.onload = async () => {
    //   console.log("Image Loaded");
    //   const bg = new Twilio.VideoProcessors.VirtualBackgroundProcessor({
    //     assetsPath: '/',
    //     backgroundImage: img,
    //     maskBlurRadius: 3,
    //   });
    //   await bg.loadModel();

    //   localVideoTrack.addProcessor(bg);
    // };

    this.setState({ localVideoTrack });

    // const localAudioTrack = await TwilioVideo.createLocalAudioTrack({
    //   noiseCancellationOptions: {
    //     sdkAssetsPath: "/dist",
    //     vendor: "krisp",
    //   },
    // });

    // if (!localAudioTrack.noiseCancellation) {
    //   console.log("Not Loaded");
    // } else {
    //   console.log("Loaded");

    //   if (!localAudioTrack.noiseCancellation.isEnabled) {
    //     console.log("Not Possible");
    //   }
    // }

    // const localAudioTrack = await TwilioVideo.createLocalAudioTrack();

    const localAudioTrack = await TwilioVideo.createLocalAudioTrack({
      noiseCancellationOptions: {
        sdkAssetsPath: "/dist",
        vendor: "krisp",
      },
    });

    if (!localAudioTrack.noiseCancellation) {
      console.log("Not Loaded");
    } else {
      console.log("Loaded");

      if (!localAudioTrack.noiseCancellation.isEnabled) {
        console.log("Not Possible");
      }
    }

    console.log("LocalAudioTracks", localAudioTrack);
    this.setState({ localAudioTrack });

    const localDataTrack = new LocalDataTrack();
    this.setState({ localDataTrack });

    const videoRoom = await TwilioVideo.connect(token, {
      audio: true,
      name: roomId,
      //region: getTwilioRegionsWithCountry(isoCode),
      region: "gll",
      tracks: [localVideoTrack, localAudioTrack, localDataTrack],
      insights: true,
      preferredVideoCodecs: "auto",
      bandwidthProfile: {
        video: {
          contentPreferencesMode: "auto",
          clientTrackSwitchOffControl: "auto",
        },
      },
      // preferredVideoCodecs: ["VP8"],
      // logLevel: 'debug',
      // bandwidthProfile: {
      //   video: {
      //     mode: "grid",
      //   },
      // },
      networkQuality: {
        local: 1,
        remote: 2,
      },

      // preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
      _useTwilioConnection: true,
    });

    console.log("Video Room ", videoRoom);

    return videoRoom;
  };

  checkInternetInLiveClass = (e) => {
    this.setState({
      connectionStatus: e,
    });
  };

  blurBackground = async (token, roomId, isoCode) => {
    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    console.log(localVideoTrack);

    const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
      assetsPath: "",
      maskBlurRadius: 10,
      blurFilterRadius: 5,
    });
    await bg.loadModel();
    localVideoTrack.addProcessor(bg);

    this.setState({ localVideoTrack });

    const localAudioTrack = await TwilioVideo.createLocalAudioTrack();
    this.setState({ localAudioTrack });

    const localDataTrack = new LocalDataTrack();
    this.setState({ localDataTrack });

    const videoRoom = await TwilioVideo.connect(token, {
      audio: true,
      name: roomId,
      region: getTwilioRegionsWithCountry(isoCode),
      tracks: [localVideoTrack, localAudioTrack, localDataTrack],
      insights: false,
      preferredVideoCodecs: ["VP8"],
      // logLevel: 'debug',
      networkQuality: {
        local: 1,
        remote: 2,
      },
      _useTwilioConnection: true,
    });

    return videoRoom;
  };

  custombg = async (videoRoom, callback) => {
    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    const img = new Image();
    img.src = class_roomnew;

    img.onload = async () => {
      console.log("Image Loaded");
      const bg = new Twilio.VideoProcessors.VirtualBackgroundProcessor({
        assetsPath: "/",
        backgroundImage: img,
        maskBlurRadius: 3,
      });
      await bg.loadModel();
      localVideoTrack.addProcessor(bg);
      callback();
    };

    videoRoom.localParticipant
      .publishTrack(localVideoTrack)
      .then((data) => {
        console.log("Custom Bg applied");
        console.log("Data", data);
      })
      .catch((err) => {
        console.log("Error", err);

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");

        submitErrorLog(userID, liveClassID, error.message, error.code, 0);
      });
  };

  updateBackground = async (token, roomId, isoCode) => {
    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    const img = new Image();
    img.src = class_room;
    img.onload = async () => {
      console.log("Image Loaded");
      const bg = new Twilio.VideoProcessors.VirtualBackgroundProcessor({
        assetsPath: "/",
        backgroundImage: img,
        maskBlurRadius: 3,
      });
      await bg.loadModel();

      localVideoTrack.addProcessor(bg);
    };

    this.setState({ localVideoTrack });

    const localAudioTrack = await TwilioVideo.createLocalAudioTrack();
    this.setState({ localAudioTrack });

    const localDataTrack = new LocalDataTrack();
    this.setState({ localDataTrack });

    const videoRoom = await TwilioVideo.connect(token, {
      audio: true,
      name: roomId,
      region: getTwilioRegionsWithCountry(isoCode),
      tracks: [localVideoTrack, localAudioTrack, localDataTrack],
      insights: false,
      preferredVideoCodecs: ["VP8"],
      // logLevel: 'debug',
      networkQuality: {
        local: 1,
        remote: 2,
      },
      _useTwilioConnection: true,
    });
    return videoRoom;
  };

  CreateAndJoinRoom = async (userID, liveClassID, background) => {
    this.setState({ isJoining: true });

    try {
      const response = await this.createRoomToken(userID, liveClassID);

      this.setState({
        tutor_name: response.teacher_name,
        students: response.student_ids,
        new_coding_plan: response.new_coding_plan,
        class_type: response.class_type,
        parent_country_code: response.iso_code,
        class_type: response.class_type,
        class_start_time: response.class_start_time,
        show_new_codings: response.show_new_codings,
        env: response.env,
        isGroupClass: response?.group_class,
        student_grade: response?.grade,
        students_arr: response?.students,
      });
      const token = response.token;
      const roomId = response.room_id;
      const whiteBoardUrl = response.white_board_url;
      const country_code = response.iso_code;

      console.log("Response", response);

      let videoRoom = "";
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;

      if (
        /CriOS/i.test(navigator.userAgent) &&
        /iphone|ipod|ipad/i.test(navigator.userAgent)
      ) {
        this.setState({
          errorMessage: "Please login from Safari Browser",
        });
      }

      const logger = Logger.getLogger("twilio-video");

      const originalFactory = logger.methodFactory;
      logger.methodFactory = function (methodName, logLevel, loggerName) {
        const method = originalFactory(methodName, logLevel, loggerName);

        return function (datetime, logLevel, component, message, data) {
          const prefix = "[My Application]";
          if (component.includes("connect")) {
          }
          // method(prefix, datetime, logLevel, component, message, data);
        };
      };
      logger.setLevel("debug");

      var isCameraAvailable = await this.checkIsCameraAvailable();
      console.log("IscameraAvailable", isCameraAvailable);
      var updatebg = background;

      var country = "";

      if (isCameraAvailable) {
        if (updatebg == "blur") {
          videoRoom = await this.blurBackground(token, roomId, country_code);
        } else if (updatebg == "custom") {
          videoRoom = await this.updateBackground(token, roomId, country_code);
        } else {
          videoRoom = await this.connectWithCamera(token, roomId, country_code);
        }
      } else {
        videoRoom = await this.connectWithoutCamera(
          token,
          roomId,
          country_code
        );
      }

      console.log("Video Room", videoRoom);

      videoRoom.localParticipant.setNetworkQualityConfiguration({
        local: 2,
        remote: 1,
      });

      // videoRoom.on("reconnecting", (error) => {
      //   if (videoRoom.localParticipant?.identity != "tutor") {
      //     this.stopDataTrack();
      //   }
      //   if (!this.state.connectionStatus) {
      //     this.setState({
      //       errorMessage:
      //         "hmmn! You are strong but can’t say the same for your connection. Your connection speed is too low",
      //     });
      //     return;
      //   }
      //   if (error.code === 53001) {
      //     this.setState({
      //       errorMessage: error.message,
      //     });
      //   } else if (error.code === 53405) {
      //     this.setState({
      //       errorMessage: error.message,
      //     });
      //   }
      // });

      // videoRoom.on("reconnected", () => {
      //   // if (videoRoom.localParticipant?.identity == "tutor")
      //   //   window.location.reload();
      //   this.hideErrorMessage();
      // });

      videoRoom.on("disconnected", () => {
        this.stopVideoTrack();
        this.stopAudioTrack();
        this.stopScreenTrack();

        this.setState({
          videoRoom: null,
        });
        this.redirectToDashboard();
      });

      //   https://5cd09c16.ngrok.io/blc_live_class/index?roomID=3TZgv4ld&userID=6

      this.setState({
        videoRoom,
        showWhiteBoard: whiteBoardUrl,
        room_id: roomId,
      });
    } catch (error) {
      this.stopVideoTrack();
      this.stopAudioTrack();

      console.error("Error on Video connect", error);
      submitErrorLog(userID, liveClassID, error.message, error.code, 0);
      var err = joinRoomError + " : " + error + " : -- " + error.code;

      if (error.code == 0) {
        this.setState({
          errorMessageformic: "Cant connect with microphone",
        });
        // this.setState({
        //   errorMessage:
        //     "Oops! I can’t see you- we can plan Peeka-boo after. :) .For now- Please enable cam access" +
        //     error,
        // });
      } else if (error.code == 53106) {
        this.setState({
          errorMessage:
            "oh you left me mid-way! Your session timed out. Please relogin",
        });
      } else if (
        error.code == 20103 ||
        error.code == 20107 ||
        error.code == 20151 ||
        error.code == 20105 ||
        error.code == 20157
      ) {
        this.setState({
          errorMessage:
            "oh you left me mid-way! Your request timed out. Please relogin from portal",
        });
      } else if (error.code == 53001 || error.code == 53000) {
        this.setState({
          errorMessage: error.message,
        });
      } else if (
        error.code == "ECONNABORTED" ||
        error.message == "Network Error"
      ) {
        this.setState({
          errorMessage:
            "hmmn! You are strong but can’t say the same for your connection. Your connection speed is too low",
        });
      } else if (
        error.code == 20103 ||
        error.code == 20104 ||
        error.code == 20107 ||
        error.code == 20151 ||
        error.code == 20105 ||
        error.code == 20157
      ) {
        this.setState({
          errorMessage:
            "oh you left me mid-way! Your request timed out. Please relogin from portal",
        });
      } else if (error.code == 31201 || 31208) {
        this.setState({
          errorMessageshowPopup:
            "Oops, You might need to restart your system once or call tech support",
        });
        // this.setState({
        //   errorMessage: "Please enable microphone from system settings",
        // });
      } else {
        this.setState({
          errorMessageshowPopup:
            "Oops, you need to allow the camera and microphone permission",
        });

        this.setState({
          errorMessage:
            "Uh-Oh! We need to start the fun right now! But Something is stopping us- please reach out to tech support!",
        });
      }
    }

    this.setState({ isJoining: false });
  };

  redirectToDashboard = () => {
    console.log(
      this.screenRecordingRef.current?.stopClassRecordingOnClassLeave,
      "dddddd",
      typeof this.screenRecordingRef.current?.stopClassRecordingOnClassLeave ===
        "function"
    );
    typeof this.screenRecordingRef.current?.stopClassRecordingOnClassLeave ===
      "function" &&
      this.screenRecordingRef.current.stopClassRecordingOnClassLeave();
    let intervalTims = 120;
    if (!this.state.recordingEnabled) {
      let identity = sessionStorage.getItem("localParticipantIdentity");

      if (identity == "tutor") {
        window.location.href = "https://www.begalileo.com/online_teachers";
      } else {
        window.location.href = "https://www.begalileo.com/student/activity";
      }
    } else {
      let id = setTimeout(() => {
        clearTimeout(id);
        this.setState({ recordingEnabled: false });
        let identity = sessionStorage.getItem("localParticipantIdentity");

        if (identity == "tutor") {
          window.location.href = "https://www.begalileo.com/online_teachers";
        } else {
          window.location.href = "https://www.begalileo.com/student/activity";
        }
      }, intervalTims);
    }
  };
  setRecordingEnabledStatus = () => {
    this.setState({
      recordingEnabled: true,
    });
  };
  submitFeedback = async (feedBack, comments) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");

    const response = await updateStudentsFeedback(
      liveClassID,
      userId,
      feedBack,
      comments
    );

    this.leaveRoom();
  };

  submitTeacherFeedback = async (feedBack, comments, isCompleted) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");

    const response = await updateTeachersFeedback(
      liveClassID,
      userId,
      feedBack,
      comments,
      isCompleted
    );

    this.leaveRoom();
  };

  leaveRoom = async () => {
    const WHITE_BOARD_POINTS = "WhiteBoardPoints";
    localStorage.setItem(WHITE_BOARD_POINTS, JSON.stringify([]));
    localStorage.clear();
    localStorage.setItem("smnotificationsend", false);
    typeof this.screenRecordingRef.current?.stopClassRecordingOnClassLeave ===
      "function" &&
      this.screenRecordingRef.current.stopClassRecordingOnClassLeave();
    const { videoRoom } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    if (isTutorCheck(videoRoom?.localParticipant?.identity)) {
      const response = await closeLiveSession(liveClassID);
    }

    const response = await updateDuration(liveClassID, userId);

    if (videoRoom) {
      typeof videoRoom?.disconnect == "function" && videoRoom.disconnect();
    }
  };

  stopTrack = (trackName) => {
    console.log("trackName", trackName);
    if (trackName == "screenTrack") {
      this.setState({
        userSharingScreen: false,
      });
    }

    const track = this.state[trackName];

    if (track != undefined) {
      if (track.stop) {
        track.stop();
        this.setState({ [trackName]: null });
      }
    }
  };

  isMacintosh = () => {
    return navigator.platform.indexOf("Mac") > -1;
  };

  isWindows = () => {
    return navigator.platform.indexOf("Win") > -1;
  };

  stopScreenTrack = () => this.stopTrack("screenTrack");

  stopDataTrack = () => this.stopTrack("localDataTrack");

  stopVideoTrack = () => this.stopTrack("localVideoTrack");

  stopAudioTrack = () => this.stopTrack("localAudioTrack");

  shareScreen = async () => {
    try {
      const { videoRoom, localVideoTrack, screenTrack } = this.state;

      if (!screenTrack) {
        this.setState({
          userSharingScreen: true,
        });
        console.log("Share screen on");
        const stream = await navigator.mediaDevices.getDisplayMedia({
          // video: true,
          video: {
            displaySurface: "monitor",
          },
        });
        console.log("Streammmmm live class", stream);

        const newScreenTrack = first(stream.getVideoTracks());

        console.log("NewScreenTrack", newScreenTrack);
        const localScreenTrack = new TwilioVideo.LocalVideoTrack(
          newScreenTrack
        );

        console.log("LocalScreenTrack", localScreenTrack);
        this.setState({
          screenTrack: localScreenTrack,
        });
        localScreenTrack.once("stopped", () => {
          this.stopScreenTrack();

          videoRoom.localParticipant.unpublishTrack(localScreenTrack);
        });

        videoRoom.localParticipant.publishTrack(newScreenTrack, {
          name: "screen_tutor",
        });
        // videoRoom.localParticipant.unpublishTrack(localVideoTrack);
      } else {
        this.setState({
          userSharingScreen: false,
        });
        console.log("Share screen off");
        videoRoom.localParticipant.unpublishTrack(screenTrack);
        //videoRoom.localParticipant.publishTrack(localVideoTrack);
        this.stopScreenTrack();
      }
    } catch (error) {
      this.stopScreenTrack();

      if (error.code == 0 && this.isMacintosh()) {
        this.setState(
          {
            flashMessage:
              '<span>Google Chrome might not have screen-recording permission on your computer. Go to <a href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture" > System Preferences </a>. For more help, go to <a href="https://support.google.com/meet/answer/7380413?hl=en&amp;ref_topic=7290455#present" target="_blank">Issues presenting</a>.</span>',
          },
          this.hideModalMessage()
        );
      }
    }
  };

  sendDataMessage = (message) => {
    try {
      const dataTr = this.state.localDataTrack;

      dataTr.send(message);
    } catch (error) {
      console.error("Send Data Track Exception");
      console.error(error);
    }
  };

  muteUser = (videoRoom) => {
    var localParticipant = videoRoom.localParticipant;

    localParticipant.audioTracks.forEach(function (audioTrack) {
      audioTrack.track.disable();
    });
  };
  unMuteUser = (videoRoom) => {
    var localParticipant = videoRoom.localParticipant;

    localParticipant.audioTracks.forEach(function (audioTrack) {
      audioTrack.track.enable();
    });
  };

  geoGebraUpdateData = (id) => {
    console.log("this is appcontainer");
    this.sendDataMessage(`GeoGebraUpdateData | ${id}`);
  };

  // updateTeacherQuestionCountInComputerMode = (question) => {
  //   var speedMessage =
  //     "updateTeacherQuestionCountInComputerMode|" + JSON.stringify(question);

  //   this.sendDataMessage(speedMessage);
  // };
  startPrePostTest = (obj) => {
    this.sendDataMessage("startPrePostTest |" + JSON.stringify(obj));
  };

  stundentClickedNextPrePostTestQue = (obj) => {
    this.sendDataMessage(
      "stundentClickedNextPrePostTestQue |" + JSON.stringify(obj)
    );
  };

  switchPrePostScreenForTeacher = (obj) => {
    this.sendDataMessage(
      "switchPrePostScreenForTeacher |" + JSON.stringify(obj)
    );
  };

  setStudentGlobalIdForCheckPrePostTest = (id) => {
    this.sendDataMessage("setStudentGlobalIdForCheckPrePostTest |" + id);
  };

  // geoGebraUpdateData = () => {
  //   console.log("this is appcontainer");
  //   this.sendDataMessage("GeoGebraUpdateData|");
  // };

  currentStudentState = (state) => {
    this.sendDataMessage("StudentIpadState|" + state);
  };

  changeStateDynamic = (identity, state, elem1, elem2, elem3, elem4) => {
    if (state == 1) {
      this.sendDataMessage("CurrentState|" + identity + "|" + state);
    }
    if (state == 2) {
      this.sendDataMessage("CurrentState|" + identity + "|" + state);
    }
    if (state == 3) {
      if (elem1 == "ShowcurrentPen") {
        this.sendDataMessage(
          "CurrentState|" +
            identity +
            "|" +
            state +
            "|" +
            elem1 +
            "|" +
            elem2 +
            "|" +
            elem3 +
            "|" +
            elem4
        );
        return;
      }
      if (elem1 == "ShowcurrentIndex") {
        this.sendDataMessage(
          "CurrentState|" +
            identity +
            "|" +
            state +
            "|" +
            elem1 +
            "|" +
            elem2 +
            "|" +
            elem3 +
            "|" +
            elem4
        );
        return;
      }
      if (elem1 == "WhiteBoard") {
        this.sendDataMessage(
          "CurrentState|" + identity + "|" + state + "|" + elem1
        );
        return;
      }
      if (elem1 == "NormalLessonView") {
        this.sendDataMessage(
          "CurrentState|" + identity + "|" + state + "|" + elem1 + "|" + elem2
        );
        return;
      }
    }

    if (state == 4) {
      if (elem2 != undefined) {
        this.sendDataMessage(
          "CurrentState|" + identity + "|" + state + "|" + elem1 + "|" + elem2
        );
      } else {
        this.sendDataMessage(
          "CurrentState|" + identity + "|" + state + "|" + elem1
        );
      }
    }

    if (state == 5) {
      this.sendDataMessage(
        "CurrentState|" + identity + "|" + state + "|" + elem1
      );
    }
    if (state == 6) {
      this.sendDataMessage(
        "CurrentState|" + identity + "|" + state + "|" + elem1
      );
    }
  };
  raiseDoubtHand = (identity) => {
    this.sendDataMessage("RaiseHand|" + identity);
  };
  sendStarToStudents = (identity) => {
    this.sendDataMessage("StarReceived|" + identity);
  };
  sendSmileyToStudents = (identity) => {
    this.sendDataMessage("SmileyReceived|" + identity);
  };
  sendThumbsUpToStudents = (identity) => {
    this.sendDataMessage("ThumbsUpReceived|" + identity);
  };
  sendClappingToStudents = (identity) => {
    this.sendDataMessage("ClappingReceived|" + identity);
  };
  muteRemoteStudent = (identity) => {
    this.sendDataMessage("MuteStudent|" + identity);
  };

  gameTimerStartStop = (identity, status) => {
    this.sendDataMessage("GameTimer|" + identity + "|" + status);
  };

  dropChatMessage = (identity, message) => {
    this.sendDataMessage("ChatMessage|" + identity + "|" + message);
  };

  studentScreenShare = (identity) => {
    this.sendDataMessage("ShareScreen|" + identity);
  };
  openFlagQuestion = (obj) => {
    this.sendDataMessage("FlagQuestion|" + JSON.stringify(obj));
  };
  handleFlagQuestionChange = (obj) => {
    this.sendDataMessage("ChangeQuestionFlagQuestion|" + JSON.stringify(obj));
  };
  sendWhiteBoardLines = (lines) => {
    console.log("linesssssssss", lines);
    this.sendDataMessage("CanvasLines|" + JSON.stringify(lines));
  };

  sendQuizCanvasLines = (lines) => {
    try {
      this.sendDataMessage("QuizCanvasLines|" + JSON.stringify(lines));
    } catch (error) {}
  };

  clearCanvas = (pageNumber) => {
    this.sendDataMessage("ClearCanvas|" + pageNumber);
  };
  unMuteRemoteStudent = (identity) => {
    this.sendDataMessage("UnMuteStudent|" + identity);
  };
  hideRaiseHandStudent = (identity) => {
    this.sendDataMessage("HideHand|" + identity);
  };
  muteAllStudents = () => {
    this.sendDataMessage("MuteAllStudents");
  };
  unMuteAllStudents = () => {
    this.sendDataMessage("UnMuteAllStudents");
  };

  openPrePostTestReviewQuestionForStudent = (exercise_id) => {
    this.sendDataMessage(
      "openPrePostTestReviewQuestionForStudent|" + exercise_id
    );
  };

  changePrePostPagination = (val) => {
    this.sendDataMessage("changePrePostPagination|" + val);
  };

  closePrePostTestReviewQues = (val) => {
    this.sendDataMessage("closePrePostTestReviewQues");
  };

  openWhiteBoard = (pdfUrl) => {
    var testMessage = "WhiteBoard|" + pdfUrl;

    this.sendDataMessage(testMessage);
  };

  openGeoGebraLinks = (ggbLinks) => {
    var testMessage = "GeoGebraLinks|" + ggbLinks;

    this.sendDataMessage(testMessage);
  };
  openMyScreen = () => {
    this.sendDataMessage("MyScreen");
  };
  openAllScreen = () => {
    this.sendDataMessage("AllScreen");
  };

  openCodingScreen = () => {
    this.sendDataMessage("CodingScreen");
  };

  openLessonScratch = (pdfUrl) => {
    var testMessage = "LessonScratch|" + pdfUrl;

    this.sendDataMessage(testMessage);
  };

  openScratchScreen = () => {
    this.sendDataMessage("ScratchScreen");
  };

  openRoughBoard = (status) => {
    console.log("Open rough board");
    var testMessage = "RoughBoardScreen|" + status;

    this.sendDataMessage(testMessage);
  };

  openPlayVideo = (videoURL) => {
    var testMessage = "PlayVideo|" + videoURL;

    this.sendDataMessage(testMessage);
  };

  resumePlayVideo = (videoURL, time) => {
    var testMessage = "ResumeVideo|" + videoURL + "|" + time;
    this.sendDataMessage(testMessage);
  };

  changeVideoState = (state) => {
    var testMessage = "ChangeVideoState|" + state;
    this.sendDataMessage(testMessage);
  };

  openSpeedMath = (level, game_id, play_mode) => {
    var speedMessage = "SpeedMath|" + level + "|" + game_id + "|" + play_mode;
    this.sendDataMessage(speedMessage);
  };

  closeSpeedMath = (level, game_id, play_mode) => {
    var speedMessage =
      "SpeedMathClose|" + level + "|" + game_id + "|" + play_mode;
    this.sendDataMessage(speedMessage);
  };

  openGraphImage = (status) => {
    var graphMessage = "GraphImage|" + status;
    this.sendDataMessage(graphMessage);
  };

  changeLessonPageNumber = (pageNumber) => {
    var speedMessage = "ChangeLessonPage|" + pageNumber;
    this.sendDataMessage(speedMessage);
  };
  changeAffirmationStoriesPageNumber = (pageNumber) => {
    var speedMessage = "ChangeAffirmationStoriesPageNumber|" + pageNumber;
    this.sendDataMessage(speedMessage);
  };

  updateSpeedMathLiveScore = (userId, identity, score) => {
    var speedMessage =
      "LiveScoreSpeedMath|" + userId + "|" + identity + "|" + score;
    this.sendDataMessage(speedMessage);
  };

  endPlayQuiz = (practiceId) => {
    var quizURL =
      baseURL + "online_classes/result?live_class_practice_id=" + practiceId;
    var dataMessage = "PlayQuiz|" + quizURL;

    this.sendDataMessage(dataMessage);
  };

  //* i comented this // openPlayQuiz = (conceptId, tagID, quizLevel) => {
  //   console.log(conceptId,tagID,quizLevel,'calling')
  //   var quizURL = "";
  //   if (conceptId == 0) {
  //     quizURL = baseURL + "online_classes/quiz_loading";
  //   } else {
  //     let search = window.location.search;
  //     let params = new URLSearchParams(search);
  //     let liveClassID = params.get("liveClassID");
  //     var today = Math.round(new Date().getTime() / 1000);
  //     quizURL =
  //       baseURL +
  //       "online_classes/start_quiz?live_class_id=" +
  //       liveClassID +
  //       "&sub_concept_id=" +
  //       conceptId +
  //       "&today=" +
  //       today +
  //       "&tag_id=" +
  //       tagID +
  //       "&level=" +
  //       quizLevel;
  //   }

  //   var dataMessage = "PlayQuiz|" + quizURL;
  //   this.sendDataMessage(dataMessage);
  // };

  // try
  openPlayQuiz = (obj) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    var today = Math.round(new Date().getTime() / 1000);
    //   if(practiceId=="")
    //  {var quizURL =
    //     baseURL2 +
    //     "app_teachers/start_practice?live_class_id=" +
    //     liveClassID +
    //     "&tag_id=" +
    //     tagID +
    //     "&level=" +
    //     quizLevel;
    //     var mounting=0
    // }

    // else{
    //   quizURL=baseURL2 +
    //   "app_teachers/next_question?live_class_id="+
    //   liveClassID +
    //   "&live_class_practice_id=" +
    //    +practiceId +
    //   "&tag_id=" +
    //   tagID+
    //   "&level=" +
    //  quizLevel
    //  mounting=1
    // }

    var dataMessage = "PlayQuiz|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };
  openActivity = (obj) => {
    var dataMessage = "MainActivity|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };
  handleShapeChallengeStudentResponseSaved = () => {
    var dataMessage = "studentShapeChallengeResponseSaved";
    this.sendDataMessage(dataMessage);
  };
  openHomeWorkResponse = (obj) => {
    var dataMessage = "ViewHomeWorkQuestion|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };

  handleShowActivityNotification = (obj) => {
    var dataMessage = "ActivityNotification|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };
  handleCloseActivity = (val) => {
    var dataMessage = "EndActivityRequest|" + val;
    this.sendDataMessage(dataMessage);
  };
  sendShapeChallengeImage = (obj) => {
    var dataMessage = "SendShapeActivityImage|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };

  handleCaptureStudentScreen = (msg) => {
    var dataMessage = `${msg}|`;
    this.sendDataMessage(dataMessage);
  };
  sendActiveWhiteBoardData = (obj) => {
    var dataMessage = "ShapeActivityWhiteboardPoints|" + JSON.stringify(obj);
    console.log(obj);
    this.sendDataMessage(dataMessage);
  };
  sendSelectedAffirmationValue = (obj) => {
    var dataMessage = "AffirmationSelectedValue|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };
  handleSendTeacherActivityResponse = () => {
    var dataMessage = "TeacherActivityResponseSaved";
    this.sendDataMessage(dataMessage);
  };
  onAffirmationCheckoutNextButton = (val) => {
    var dataMessage = "CheckoutAffirmationNextButton|" + JSON.stringify(val);
    this.sendDataMessage(dataMessage);
  };
  onSendStoriesToStudent = (obj) => {
    var dataMessage = "ShowAffirmationStories|" + JSON.stringify(obj);
    this.sendDataMessage(dataMessage);
  };
  handleShowPreviewImageAffrimation = (val) => {
    var dataMessage = "showAffirmationPreviewImageStudent|" + val;
    this.sendDataMessage(dataMessage);
  };
  onSendSelectedFeelingImg = (val) => {
    var dataMessage = "FeelingChartSelectedImg|" + val;
    this.sendDataMessage(dataMessage);
  };
  onSendOpenDropBox = (val) => {
    var dataMessage = "FeelingChartOpenDropBox|" + val;
    this.sendDataMessage(dataMessage);
  };
  onGeogebra = (obj) => {
    console.log({ obj });
    obj = JSON.stringify(obj);
    this.sendDataMessage("Geogebra|" + obj);
  };
  onDataUpdateONGGB = (obj) => {
    let obj2 = {
      selected: 10,
      ggbXml: obj,
    };
    obj = JSON.stringify(obj2);
    this.sendDataMessage("GeogebraXml|" + obj);
  };

  onDataUpdateONGGBState = (obj) => {
    let obj2 = {
      selected: 10,
      ggbState: { ...obj },
    };
    obj = JSON.stringify(obj2);
    this.sendDataMessage("GeogebraState|" + obj);
  };
  sendingReportToTeacherStatus = async (report, identity) => {
    var message =
      "1ReportSendingPlayQuiz|" + JSON.stringify(report) + "|" + identity;
    let id2 = setTimeout(() => {
      this.sendDataMessage(message);

      clearTimeout(id2);
    }, 20);
  };
  onStudentResponseReceivedMathzone = async (identity, temp) => {
    temp = JSON.stringify(temp);
    var message = "receivedMathzoneReceived|" + identity + "|" + temp;
    this.sendDataMessage(message);
  };
  // end try

  hideErrorMessage = () => this.setState({ errorMessage: null });
  hideModalMessage = () => {
    this.setState({
      flashMessage: null,
    });
  };
  changeUserName = (userName) => this.setState({ userName });

  changeRoomName = (roomName) => this.setState({ roomName });

  render() {
    const { render } = this.props;

    const {
      videoRoom,
      isJoining,
      userName,
      roomName,
      errorMessage,
      errorMessageshowPopup,
      errorMessageformic,
      flashMessage,
      screenTrack,
      showWhiteBoard,
      audioTest,
      isMuteEnabled,
      tutor_name,
      students,
      new_coding_plan,
      class_type,
      show_new_codings,
      class_start_time,
      env,
      currentState,
      userSharingScreen,
      students_arr,
    } = this.state;
    return render({
      videoRoom,
      userName,
      roomName,
      isVideoSupported: TwilioVideo.isSupported,
      isAudioSupported: audioTest,
      isScreenSharingSupported: Boolean(
        navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
      ),
      isScreenSharingEnabled: Boolean(screenTrack),
      canJoin: !isEmpty(userName) && !isEmpty(roomName),
      isJoining,
      tutorName: tutor_name,
      students: students,
      students_arr: students_arr,
      new_coding_plan: new_coding_plan,
      class_type: class_type,
      show_new_codings: show_new_codings,
      class_start_time: class_start_time,
      env: env,
      screenRecordingRef: this.screenRecordingRef,
      screenRecordingRef2: this.screenRecordingRef2,
      currentState: currentState,
      onJoin: this.joinRoom,
      userSharingScreen,
      onLeave: this.leaveRoom,
      onShare: this.shareScreen,
      onWhiteBoard: this.openWhiteBoard,
      onGeoGebraLinks: this.openGeoGebraLinks,
      onMyScreen: this.openMyScreen,
      onPlayVideo: this.openPlayVideo,
      onResumePlayVideo: this.resumePlayVideo,
      onAllScreen: this.openAllScreen,
      onSpeedMath: this.openSpeedMath,
      onPlayQuiz: this.openPlayQuiz,
      setRecordingEnabledStatus: this.setRecordingEnabledStatus,
      recordingEnabled: this.state.recordingEnabled,
      onPlayQuizForTeacher: this.openPlayQuizForTeacher,
      onEndQuiz: this.endPlayQuiz,
      handleFlagQuestionChange: this.handleFlagQuestionChange,
      onStudentScreenShare: this.studentScreenShare,
      onSendWhiteBoardLines: this.sendWhiteBoardLines,
      onSendQuizCanvasLines: this.sendQuizCanvasLines,
      onClearCanvas: this.clearCanvas,
      onChangeLessonPageNumber: this.changeLessonPageNumber,
      changeAffirmationStoriesPageNumber:
        this.changeAffirmationStoriesPageNumber,
      openGraphImage: this.openGraphImage,
      showWhiteBoard,
      startLiveClass: this.startLiveClass,
      onRoomNameChange: this.changeRoomName,
      onUserNameChange: this.changeUserName,
      onDropChatMessage: this.dropChatMessage,
      onMuteUser: this.muteUser,
      onUnMuteUser: this.unMuteUser,
      isMuteEnabled: isMuteEnabled,
      isGroupClass: this.state?.isGroupClass,
      student_grade: this.state?.student_grade,
      openFlagQuestion: this.openFlagQuestion,
      handleCloseActivity: this.handleCloseActivity,
      openActivity: this.openActivity,
      openHomeWorkResponse: this.openHomeWorkResponse,
      handleShowPreviewImageAffrimation: this.handleShowPreviewImageAffrimation,
      onSendSelectedFeelingImg: this.onSendSelectedFeelingImg,
      onSendOpenDropBox: this.onSendOpenDropBox,
      handleShowActivityNotification: this.handleShowActivityNotification,
      sendShapeChallengeImage: this.sendShapeChallengeImage,
      sendActiveWhiteBoardData: this.sendActiveWhiteBoardData,
      sendSelectedAffirmationValue: this.sendSelectedAffirmationValue,
      onSendStoriesToStudent: this.onSendStoriesToStudent,
      handleSendTeacherActivityResponse: this.handleSendTeacherActivityResponse,
      onSubmitFeedback: this.submitFeedback,
      handleShapeChallengeStudentResponseSaved:
        this.handleShapeChallengeStudentResponseSaved,
      onUpdateLiveSpeedMathScore: this.updateSpeedMathLiveScore,
      onAffirmationCheckoutNextButton: this.onAffirmationCheckoutNextButton,
      onSubmitTeacherFeedback: this.submitTeacherFeedback,
      onRaiseHandDoubt: this.raiseDoubtHand,
      onSendStarToStudent: this.sendStarToStudents,
      onSendSmileyToStudent: this.sendSmileyToStudents,
      onSendThumbsUpToStudent: this.sendThumbsUpToStudents,
      onSendClappingToStudent: this.sendClappingToStudents,
      onMuteRemoteStudent: this.muteRemoteStudent,
      onMuteAllStudents: this.muteAllStudents,
      onUnMuteAllStudents: this.unMuteAllStudents,
      onUnMuteRemoteStudent: this.unMuteRemoteStudent,
      hideRaiseHandStudent: this.hideRaiseHandStudent,
      onChangeVideoState: this.changeVideoState,
      onOpenCodingScreen: this.openCodingScreen,
      onOpenLessonScratch: this.openLessonScratch,
      onScratchScreen: this.openScratchScreen,
      onOpenRoughBoardScreen: this.openRoughBoard,
      onHideModalMessage: this.hideModalMessage,
      onGameTimerStartStop: this.gameTimerStartStop,
      onDataUpdateONGGB: this.onDataUpdateONGGB,
      onDataUpdateONGGBState: this.onDataUpdateONGGBState,
      errorMessage,
      errorMessageshowPopup,
      errorMessageformic,
      flashMessage,
      onErrorMessageHide: this.hideErrorMessage,
      updateBackground: this.updateBackground,
      custombg: this.custombg,
      closeSpeedMath: this.closeSpeedMath,
      changeStateDynamic: this.changeStateDynamic,
      checkInternetInLiveClass: this.checkInternetInLiveClass,
      currentStudentState: this.currentStudentState,
      onGeogebra: this.onGeogebra,
      geoGebraUpdateData: this.geoGebraUpdateData,
      handleCaptureStudentScreen: this.handleCaptureStudentScreen,
      startPrePostTest: this.startPrePostTest,
      stundentClickedNextPrePostTestQue: this.stundentClickedNextPrePostTestQue,
      switchPrePostScreenForTeacher: this.switchPrePostScreenForTeacher,
      setStudentGlobalIdForCheckPrePostTest:
        this.setStudentGlobalIdForCheckPrePostTest,
      openPrePostTestReviewQuestionForStudent:
        this.openPrePostTestReviewQuestionForStudent,
      changePrePostPagination: this.changePrePostPagination,
      closePrePostTestReviewQues: this.closePrePostTestReviewQues,
      // geoGebraUpdateData: this.geoGebraUpdateData,
    });
  }
}

export default AppContainer;
