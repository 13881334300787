import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import "normalize.css";
import "./index.scss";
import { CookiesProvider } from "react-cookie";

import ErrorBoundary from "../src/components/ErrorHandling/ErrorBoundary";

ReactDOM.render(
  <CookiesProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </CookiesProvider>,
  document.getElementById("root")
);
