/*global chrome */

import React from "react";
import videoRoomPropType from "../../propTypes/videoRoom";
import Participant from "../Participant/Participant";
import LocalParticipant from "../Participant/LocalParticipant";
import EventSubscriber from "../EventSubscriber/EventSubscriber";
import { mapToArray, isTutorCheck, studentIDfullname } from "../../utils";
import Cookies from "js-cookie";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import moment from "moment-timezone";
import * as VideoProcessors from "@twilio/video-processors";
import TwilioVideo from "twilio-video";
import blurEffect from "../../assets/blurEffect.png";
import CustomBackground from "../../assets/CustomBackground.png";
import BlurOff from "../../assets/BlurOff.png";
import RedIndicator from "../../assets/RedIndicator.png";
import styles from "../Test/CamMicTest.module.css";
import ScreenRecording from "../Recorder/ScreenRecording";
import menusecond from "../../assets/menus.png";
import FiveStar from "../LottieTransformation/FiveStar";

import {
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Form,
  Modal,
  ToggleButton,
  ButtonGroup,
  Image,
  Navbar,
  Container,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Signal from "../CommonComponents/Signal";
import {
  getConceptList,
  getUploadResourcesList,
  submitAStar,
  submitASmiley,
  submitAThumbsUp,
  submitAClapping,
  baseURL,
  updateTeachersDemoFeedback,
  updateIncompleteTeachersDemo,
  storeVideoLog,
  baseURL2,
  liveOnlineQuizStartQuestion,
  PrePostStartTest,
  updateLiveClassQuizQuestion,
  updatePrePostTestQuizQuestion,
  updateCurrentQuestionTeacherPrePostTest,
  getFlagQuestionConceptList,
  storeGeogebraLog,
  uploadStudentScreenShot,
  getDeviceInfoForTech,
} from "../../api/index";
import * as Constants from "../../utils/Constants";
import $ from "jquery";
import ScreenParticipant from "../Participant/ScreenParticipant";
import {
  createLiveClassTicket,
  ParentFeedbackApi,
  studentNotPresentApi,
  teacherNotPresentApi,
  techNotPresentApi,
} from "../../api";
import { Rnd } from "react-rnd";
import { studentName, studentID } from "../../utils";
import { getSpeedMathLevels, submitErrorLog } from "../../api/index";
import SmileyLottie from "../LottieComponents/SmileyLottie";
import StarLottie from "../LottieComponents/StarLottie";
import ThumbsUpLottie from "../LottieComponents/ThumbsUpLottie";
import ClappingLottie from "../LottieComponents/ClappingLottie";
import homeiconsecond from "../../assets/Homes.png";
import ConfirmationModal2 from "../CommonComponents/ConfirmationModal2";
import { takeComponentScreenShot } from "../../utils/TakeComponentScreenShot";
import b64toBlob from "../../StudentActivitys/b64toBlob";
import ConfirmationModal from "../CommonComponents/ConfirmationModal";
import PreviewPic from "../CommonComponents/PreviewPic";
import { excludeParticipant } from "../../StudentActivitys/ExcludeParticipant";
import { isGradeAllowed } from "../../StudentActivitys/allowedGrade";
import AllImportFile from "./AllImportFile";
const LAST_INDEX_WHITE_BOARD = "LastIndexWhiteBoard";
let audioDelayDuration = 2000;
const lottieAnimationDelay = 5000;
const deadlineForActvity = 3600 * 1000;
const EVENTS = [
  "dominantSpeakerChanged",
  "participantConnected",
  "participantDisconnected",
  "reconnected",
  "reconnecting",
  "trackDimensionsChanged",
  "trackDisabled",
  "trackEnabled",
  "trackPublished",
  "trackStarted",
  "trackSubscribed",
  "trackUnpublished",
  "trackUnsubscribed",
];

const ipad = /Macintosh/.test(navigator.userAgent) && "ontouchend" in document;

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  background: "#f0f0f0",
  zIndex: "9",
};

class VideoRoom extends React.Component {
  constructor(props) {
    super(props);
    this.allScreenElement = React.createRef();
    this.videoScreenElement = React.createRef();
    this.quizScreenElement = React.createRef();
    this.canvasScreenElement = React.createRef();
    this.lessonScratchScreenElement = React.createRef();
    this.speedMathScreenElement = React.createRef();
    this.screenRecordingElement = React.createRef();
    this.mutedUnmutedRef = React.createRef();
    this.lottieAnimationDelayRef = React.createRef();
    this.collapseScreenRef = React.createRef(null);
    this.collapseStudentRef = React.createRef(null);
    this.storyBookPageNumber = React.createRef(null || 0);

    this.canvas = React.createRef();
    this.studentPrePostReview = React.createRef();
    this.changePaginationStudentSideRef = React.createRef();
    // this.rightthumbnail = React.createRef(null);
    this.updateLocalRaiseHand = this.updateLocalRaiseHand.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.addCustombg = this.addCustombg.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);

    this.onIncomingGameTimerStartStop =
      this.onIncomingGameTimerStartStop.bind(this);

    this.state = {
      starFlag: false,
      options_old: [
        {
          id: 1,
          checked: true,
          value: 1,
          name: "All Screen",
          image: "/static/media/AllScreen.svg",
          activeImage: "/static/media/Images-v2/AllScreen.svg",
          link: "/",
        },

        // {
        //   id: 16,
        //   checked: false,
        //   value: 16,
        //   name: "Pre-PostTest",
        //   image: "/static/media/Images-v2/Miscellaneous.png",
        //   activeImage: "/static/media/Images-v2/Miscellaneous.png",
        //   link: "/Pre-PostTest",
        // },
        {
          id: 2,
          checked: false,
          value: 2,
          name: "My Screen",
          image: "/static/media/MyScreen.svg",
          activeImage: "/static/media/Images-v2/MyScreen.svg",
          link: "/myscreen",
        },

        // {
        //   id: 15,
        //   checked: false,
        //   value: 15,
        //   name: "Whiteboard",
        //   image: "/static/media/whiteboard.svg",
        //   activeImage: "/static/media/Images-v2/WhiteBoard.svg",
        //   link: "/whiteboard",
        // },
        {
          id: 3,
          checked: false,
          value: 3,
          name: "Lessons",
          image: "/static/media/whiteboard.svg",
          activeImage: "/static/media/Images-v2/WhiteBoard.svg",
          link: "/whiteboard",
        },
        {
          id: 4,
          checked: false,
          value: 4,
          name: "Play video",
          image: "/static/media/Playvideo.svg",
          activeImage: "/static/media/Images-v2/PlayVideo.svg",
          link: "/video",
        },
        {
          id: 5,
          checked: false,
          value: 5,
          name: "Speed Math",
          image: "/static/media/Speedmath.svg",
          activeImage: "/static/media/Images-v2/SpeedMath.svg",
          link: "/speedmath",
        },
        {
          id: 6,
          checked: false,
          value: 6,
          name: "Math Zone",
          image: "/static/media/PlayQuiz.svg",
          activeImage: "/static/media/Images-v2/PlayQuiz.svg",
          link: "/quiz",
        },
        {
          id: 7,
          checked: false,
          value: 7,
          name: "Logic Zone",
          image: "/static/media/Images-v2/logic_zone.png",
          activeImage: "/static/media/Images-v2/logic_zone.png",
          link: "/coding",
        },

        // {
        //   id: 8,
        //   checked: false,
        //   value: 8,
        //   name: "Logical Resoning",
        //   image: "/static/media/Logical-reasoning.svg",
        //   link: '/logical'
        // },
        // {
        //   id: 7,
        //   checked: false,
        //   value: 7,
        //   name: "Settings",
        //   image: "/static/media/Settings.svg",
        //   link: '/settings'
        // }
        {
          id: 11,
          checked: false,
          value: 11,
          name: "CICO",
          image: "/static/media/Images-v2/LogicZone.svg",
          activeImage: "/static/media/Images-v2/LogicZone.svg",
          link: "/mainactivity",
        },
        {
          id: 12,
          checked: false,
          value: 11,
          name: "Miscellaneous",
          image: "/static/media/Images-v2/Miscellaneous.png",
          activeImage: "/static/media/Images-v2/Miscellaneous.png",
          link: "/coding",
        },
      ],
      options_math: [
        {
          id: 1,
          checked: true,
          value: 1,
          name: "All Screen",
          image: "/static/media/AllScreen.svg",
          activeImage: "/static/media/Images-v2/AllScreen.svg",
          link: "/",
        },
        {
          id: 2,
          checked: false,
          value: 2,
          name: "My Screen",
          image: "/static/media/MyScreen.svg",
          activeImage: "/static/media/Images-v2/MyScreen.svg",
          link: "/myscreen",
        },
        // {
        //   id: 15,
        //   checked: false,
        //   value: 15,
        //   name: "Whiteboard",
        //   image: "/static/media/whiteboard.svg",
        //   activeImage: "/static/media/Images-v2/WhiteBoard.svg",
        //   link: "/whiteboard",
        // },
        {
          id: 3,
          checked: false,
          value: 3,
          name: "Lessons",
          image: "/static/media/whiteboard.svg",
          activeImage: "/static/media/Images-v2/WhiteBoard.svg",
          link: "/whiteboard",
        },
        {
          id: 4,
          checked: false,
          value: 4,
          name: "Play video",
          image: "/static/media/Playvideo.svg",
          activeImage: "/static/media/Images-v2/PlayVideo.svg",
          link: "/video",
        },
        {
          id: 5,
          checked: false,
          value: 5,
          name: "Speed Math",
          image: "/static/media/Speedmath.svg",
          activeImage: "/static/media/Images-v2/SpeedMath.svg",
          link: "/speedmath",
        },
        {
          id: 6,
          checked: false,
          value: 6,
          name: "Math Zone",
          image: "/static/media/PlayQuiz.svg",
          activeImage: "/static/media/Images-v2/PlayQuiz.svg",
          link: "/quiz",
        },
        // {
        //   id: 7,
        //   checked: false,
        //   value: 7,
        //   name: "Logic Zone",
        //   image: "/static/media/Logic-zone.svg",
        //   activeImage: "/static/media/Images-v2/LogicZone.svg",
        //   link: "/coding",
        // },
        {
          id: 11,
          checked: false,
          value: 11,
          name: "CICO",
          image: "/static/media/Images-v2/LogicZone.svg",
          activeImage: "/static/media/Images-v2/LogicZone.svg",
          link: "/mainactivity",
        },
        {
          id: 12,
          checked: false,
          value: 11,
          name: "Miscellaneous",
          image: "/static/media/Images-v2/Miscellaneous.png",
          activeImage: "/static/media/Images-v2/Miscellaneous.png",
          link: "/coding",
        },
      ],
      options_coding: [
        {
          id: 1,
          checked: true,
          value: 1,
          name: "All Screen",
          image: "/static/media/AllScreen.svg",
          activeImage: "/static/media/Images-v2/AllScreen.svg",
          link: "/",
        },
        {
          id: 2,
          checked: false,
          value: 2,
          name: "My Screen",
          image: "/static/media/MyScreen.svg",
          activeImage: "/static/media/Images-v2/MyScreen.svg",
          link: "/myscreen",
        },
        // {
        //   id: 15,
        //   checked: false,
        //   value: 15,
        //   name: "Whiteboard",
        //   image: "/static/media/whiteboard.svg",
        //   activeImage: "/static/media/Images-v2/WhiteBoard.svg",
        //   link: "/whiteboard",
        // },
        // {
        //   id: 3,
        //   checked: false,
        //   value: 3,
        //   name: "Whiteboard",
        //   image: "/static/media/whiteboard.svg",
        //   activeImage: "/static/media/Images-v2/WhiteBoard.svg",
        //   link: "/whiteboard",
        // },
        {
          id: 9,
          checked: false,
          value: 9,
          name: "Coding",
          image: "/static/media/Images-v2/Coding.png",
          activeImage: "/static/media/Images-v2/Coding.png",
          link: "/coding",
        },
        {
          id: 11,
          checked: false,
          value: 11,
          name: "CICO",
          image: "/static/media/Images-v2/LogicZone.svg",
          activeImage: "/static/media/Images-v2/LogicZone.svg",
          link: "/mainactivity",
        },
        {
          id: 12,
          checked: false,
          value: 11,
          name: "Miscellaneous",
          image: "/static/media/Images-v2/Miscellaneous.png",
          activeImage: "/static/media/Images-v2/Miscellaneous.png",
          link: "/coding",
        },
      ],
      options_math_coding: [
        {
          id: 1,
          checked: true,
          value: 1,
          name: "All Screen",
          image: "/static/media/AllScreen.svg",
          activeImage: "/static/media/Images-v2/AllScreen.svg",
          link: "/",
        },
        // {
        //   id: 16,
        //   checked: false,
        //   value: 16,
        //   name: "Pre-PostTest",
        //   image: "/static/media/Images-v2/Miscellaneous.png",
        //   activeImage: "/static/media/Images-v2/Miscellaneous.png",
        //   link: "/Pre-PostTest",
        // },
        {
          id: 2,
          checked: false,
          value: 2,
          name: "My Screen",
          image: "/static/media/MyScreen.svg",
          activeImage: "/static/media/Images-v2/MyScreen.svg",
          link: "/myscreen",
        },

        // {
        //   id: 15,
        //   checked: false,
        //   value: 15,
        //   name: "Whiteboard",
        //   image: "/static/media/whiteboard.svg",
        //   activeImage: "/static/media/Images-v2/WhiteBoard.svg",
        //   link: "/whiteboard",
        // },
        {
          id: 3,
          checked: false,
          value: 3,
          name: "Lessons",
          image: "/static/media/whiteboard.svg",
          activeImage: "/static/media/Images-v2/WhiteBoard.svg",
          link: "/whiteboard",
        },
        {
          id: 4,
          checked: false,
          value: 4,
          name: "Play video",
          image: "/static/media/Playvideo.svg",
          activeImage: "/static/media/Images-v2/PlayVideo.svg",
          link: "/video",
        },
        {
          id: 5,
          checked: false,
          value: 5,
          name: "Speed Math",
          image: "/static/media/Speedmath.svg",
          activeImage: "/static/media/Images-v2/SpeedMath.svg",
          link: "/speedmath",
        },
        {
          id: 6,
          checked: false,
          value: 6,
          name: "Math Zone",
          image: "/static/media/PlayQuiz.svg",
          activeImage: "/static/media/Images-v2/PlayQuiz.svg",
          link: "/quiz",
        },
        // {
        //   id: 7,
        //   checked: false,
        //   value: 7,
        //   name: "Logic Zone",
        //   image: "/static/media/Logic-zone.svg",
        //   activeImage: "/static/media/Images-v2/LogicZone.svg",
        //   link: "/coding",
        // },
        {
          id: 13,
          checked: false,
          value: 13,
          name: "Coding",
          image: "/static/media/Images-v2/Coding.png",
          activeImage: "/static/media/Images-v2/Coding.png",
          link: "/coding",
        },
        {
          id: 11,
          checked: false,
          value: 11,
          name: "CICO",
          image: "/static/media/Logic-zone.svg",
          activeImage: "/static/media/Images-v2/LogicZone.svg",
          link: "/mainactivity",
        },
        {
          id: 12,
          checked: false,
          value: 11,
          name: "Miscellaneous",
          image: "/static/media/Images-v2/Miscellaneous.png",
          activeImage: "/static/media/Images-v2/Miscellaneous.png",
          link: "/coding",
        },
      ],

      LocalParticipant: [
        {
          name: "Gautham",
          img: "https://pngimage.net/wp-content/uploads/2018/06/profile-avatar-png-5.png",
        },
        {
          name: "Kishore",
          img: "https://pngimage.net/wp-content/uploads/2018/06/profile-avatar-png-5.png",
        },
        {
          name: "Anns",
          img: "https://pngimage.net/wp-content/uploads/2018/06/profile-avatar-png-5.png",
        },
        {
          name: "Praveen",
          img: "https://pngimage.net/wp-content/uploads/2018/06/profile-avatar-png-5.png",
        },
      ],
      resizeWidth: 400,
      resizeHeight: 400,
      starAnimation: false,
      smileyAnimation: false,
      thumbsUpAnimation: false,
      clappingAnimation: false,
      mouseX: 0,
      mouseY: 0,
      selected: 1,
      screenShotCaptureButton: true,
      isShowConfirmationModal: false,
      showScreenShotPreview: false,
      allScreenScreenShot: false,
      confirmationForGoingAllScreen: false,
      screenShotArray: [],
      miscellaneousOptions: [
        {
          name: "Flagged Question",
          subId: "1",
          conceptName: [
            {
              name: "Additon",
              levels: ["level1", "level2", "level3", "level4"],
            },
          ],
        },
        {
          name: "Homework",
          subId: "2",
          conceptName: [
            {
              name: "Additon",
              levels: ["level1", "level2", "level3", "level4"],
            },
          ],
        },
      ],
      // showipadscreen: false,
      countStar: 0,
      stateChangeChild: false,
      showSpeedMathMenus: false,
      showMainActivity: false,
      isCheckInActivity: false,
      renderShapeChallenge: false,
      isStudentShapeChallengeResponseSaved: false,
      responseUrlAnswer: [],
      hideteacherfromchildView: false,
      //finalPdfstate: localStorage.getItem("allProjects") || "",
      justchecking: "",
      justcheckingallpdf: [],
      logicalZoneTrue: "",
      whiteboardopen: false,
      changeScreenConfirmationModal: false,
      preserveStateCicoActivty: {},
      showLogicZone: false,
      logicZoneToggle: false,
      enableStarButton: true,
      showLesson: false,
      selectedVideoUrl: "",
      selectedPdfUrl: "",
      selectedPdfIndex: 0,
      selectedQuizUrl: "",
      textareaValue: "",
      ggbXml: "",
      ggbState: { state: "", label: "" },

      quizApiCall: false,
      mounting: true,
      errorState: false,
      waitingState: true,
      practiceId: "",

      conceptName: "",
      conceptTag: "",
      mathZoneQuizLevel: "",
      quizObj: "",
      allStudentScore: [],
      screenShotPrgress: false,
      showIframe: false,
      active: 1,
      muteAllState: false,
      isCheckInActivityEnd: false,
      isCheckOutActivityEnd: false,
      participantNameColor: "black",
      selectedConceptId: "",
      selectedTagId: "",
      studentIpadStatesss: false,
      selectedQuizLevel: "",
      selectedSpeedMathLevel: "",
      speedMathGameId: 0,
      speedMathGameMode: "computer",
      selectedSubItemId: 0,
      selectGGbDataInConceptsList: {},
      speedMathScoreDataItem: [],
      studentMathZoneResponseList: {},
      mLiveClassId: 0,
      onlineQuizMathzoneScore: {
        totalQuestion: 0,
        correctAnswer: 0,
      },
      currentSpeedmathid: 0,
      selectedSubItemId: 0,
      speedMathScoreDataItem: [],
      localParticipantNetworkQuality: 0,
      changechildlessonpdf: 0,
      changepenstrokesforChild: "",
      childjoinCounter: 0,
      mLiveClassId: 0,
      whiteBoardUrl: "https://www.begalileo.com/",
      shown: true,
      concept_list: [],
      flagQuestionConceptList: [],
      logical_concept: [],
      game_levels: [],
      activity_type: ["Check In", "Check Out"],
      showCheckoutAffirmationNextBtn: { val: 0 },
      showActivityNotification: true,
      trial_pdfs: [],
      remoteParticpantsValues: [],
      currentDataTrackTime: "",
      showMenus: false,
      nowPlayingVideo: "",
      nowPlayingVideoTime: 1,
      nowPlayingVideoStart: 1,
      showteacherRecordingPopup: false,
      starClicked: false,
      smileyClicked: false,
      thumbsUpClicked: false,
      starClickedStudent: null,
      smileyClickedStudent: null,
      thumbsUpClickedStudent: null,
      clappingClickedStudent: null,
      showQuizMenus: false,
      showPreTestMenus: false,
      studentScreenShareConfirmation: false,
      isSharingScreen: false,
      sharedScreenTrack: null,
      userOperatingSystemName: "",

      sendBigStar: false,
      status: false,
      showLoading: false,
      showit: false,
      hover: null,
      hoversecond: null,
      showraiseAlarm: false,
      showParentSuggestion: false,
      showeverythinggood: false,
      showRateSuggestion: false,
      identityArray: [],
      temporaryChromeExtensionPopupModal: false,
      changespeedmathfn: "notreset",
      codeFrameUrl: "",
      parentfeedbackvalue: "",
      everything_seems_happy: {
        how_happy: 0,
        improvement: 0,
        positive_feeling: 0,
      },
      feedbackValue: 0,
      updateQuestionState: false,
      all_ids: [],
      showspeedmathprogressmodal: false,
      openEndClassforChild: false,
      showspeedmathchildscore: false,
      child_score: 0,
      child_name: "",
      roughBoardforchildren: false,
      closerenderingforChild: false,
      startQuizforchild: false,
      quiz_url_list: [
        "Url 1",
        "Url 2",
        "Url 3",
        "Url 4",
        "Url 5",
        "Url 6",
        "Url 7",
        "Url 7",
      ],
      main: { md: 6 },
      right: { md: 3 },
      incomingData: "",
      width: 900,
      height: 700,
      x: 10,
      y: 10,
      quizLevels: [
        {
          id: "level1",
          name: "Level 1",
        },
        {
          id: "level2",
          name: "Level 2",
        },
        {
          id: "level3",
          name: "Level 3",
        },
      ],
      newMessageCounter: 0,
      isChatBoxOpen: false,
      chatMessageArray: [],
      demoFlag: false,
      example: false,
      demoStatus: "",
      ismzDropdownOpen: false,
      issmDropdownOpen: false,
      islzDropdownOpen: false,
      collapsScreenTeacher: false,
      // loggedIn: false,
      selectedmathZone: [],
      selectedspeedMath: [],
      selectedlogicZone: [],
      gamePlayerCompleted: [],
      connectionOffline: false,
      connectionOnline: false,
      currentScreenName: "AllScreen" || "",
      status: false,
      showsfinalalertofstayorleave: false,

      fields: {
        firstName: "",
        gender: "",
        demoTopic: "",
        learningStyle: "",
        communication: "",
        conceptualKnowledge: "",
        higherOrder: "",
        coding: "",
        childInterest: "",
        classBehaviour: "",
        begalileoUseful: "",
        internal_comments: "",
      },
      errors: {},
      geo_id: 0,
      fetchagain: null,
      fetchAginStudentId: null,
      studentJoinedClass: false,
      teacherJoinedClass: false,
      user_joined_time: "",
      userSharingScreen: false,
      changeListItemHeight: false,
      updateSpeedMathQueForTeacher: "",
      pre_post_test_id: "",
      test_type: "",
      showUploadResourcesModel: false,
      dummyResources: ["Test1", "Test2"],
      errorWhileReconnecting: false,
      errorWhileReconnectingMessage: "",
    };

    this.setMouseMove = this.setMouseMove.bind(this);

    // console.log("Students Arrays", this.props.students);
  }

  setMouseMove(e) {
    e.preventDefault();
    this.setState({
      mouseX: e.nativeEvent.clientX,
      mouseY: e.nativeEvent.clientY,
    });

    this.getMouseMoveStatus(this.state.mouseX, this.state.mouseY);
  }

  getMouseMoveStatus(x, y) {
    this.props.getMouseStatus(x, y);
  }

  geoGebraUpdateData = (id) => {
    console.log("helelllll");
    this.props.geoGebraUpdateData(id);
  };

  smPresent = (iden) => {
    if (iden == "sm" || iden == "audit" || iden == "smmanager") {
      return true;
    } else {
      return false;
    }
  };

  studentPresent = (iden) => {
    let excludeParticipant = ["tutor", "parent", "tech"];

    if (excludeParticipant.includes(iden)) {
      return false;
    } else {
      return true;
    }
  };

  teacherPresent = (iden) => {
    if (iden == "tutor") {
      return true;
    } else {
      return false;
    }
  };

  checkOldClassType = (e) => {
    if (e === "old") {
      return true;
    } else if (e === "math_coding" || e === "math" || e === "coding") {
      return false;
    } else {
      return false;
    }
  };

  codingOutside = (e) => {
    if (e === "math_coding" || e === "math") {
      return true;
    } else {
      return false;
    }
  };

  closeLessonScratch = () => {
    this.openScratchScreen();
  };

  studentPresent = (iden) => {
    let excludeParticipant = ["tutor", "parent", "tech"];

    if (excludeParticipant.includes(iden)) {
      return false;
    } else {
      return true;
    }
  };

  teacherPresent = (iden) => {
    if (iden == "tutor") {
      return true;
    } else {
      return false;
    }
  };

  checkOldClassType = (e) => {
    if (e === "old") {
      return true;
    } else if (e === "math_coding" || e === "math" || e === "coding") {
      return false;
    } else {
      return false;
    }
  };

  codingOutside = (e) => {
    if (e === "math_coding" || e === "math") {
      return true;
    } else {
      return false;
    }
  };

  closeraisealert = () => {
    this.sendonlyalarmalert();

    this.setState({
      showsfinalalertofstayorleave: true,
    });
  };

  showLessonScratch = (pdfImages) => {
    this.setState({
      selectedPdfUrl: pdfImages,
      selected: 14,
    });
    this.props.openLessonScratch(JSON.stringify(pdfImages));
  };

  openResourcesLessonScreen = (pdfImages) => {
    this.setState({
      selectedPdfUrl: pdfImages,
      selected: 14,
      hideBackButtonInLessonScratch: true,
    });
    this.props.openLessonScratch(JSON.stringify(pdfImages));
  };

  leaveclass = () => {
    this.props.onLeave();
  };

  LeaveSessionForParent = () => {
    console.log("close session");
    window.history.back();
  };

  stayinclass = () => {
    this.setState({
      showsfinalalertofstayorleave: false,
      showraiseAlarm: false,
    });
  };

  showgameinprogresstoparent = () => {
    this.setState({
      showspeedmathprogressmodal: true,
    });
  };

  sendonlyalarmalert = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    let id = this.props.students;
    let student_id = studentID(id);
    let alarm = true;

    const bodyFormData = new FormData();
    bodyFormData.append("live_class_id", liveClassID);
    bodyFormData.append("user_id", userId);
    bodyFormData.append("student_id", student_id);
    bodyFormData.append("alarm", alarm);
    console.log("Student Id", student_id);

    const response = await ParentFeedbackApi(bodyFormData);
    console.log("Response", response);

    if (response.status) alert("Your Response has been Submited");
    else alert("Unable to send feeback at the moment, please try again later");
  };

  ParentFeedbackSubmit = async () => {
    // e.preventDefault();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    let suggestions = this.state.parentfeedbackvalue;
    let id = this.props.students;
    let student_id = studentID(id);

    const bodyFormData = new FormData();
    bodyFormData.append("live_class_id", liveClassID);
    bodyFormData.append("user_id", userId);
    bodyFormData.append("student_id", student_id);
    bodyFormData.append("suggestions", suggestions);

    const response = await ParentFeedbackApi(bodyFormData);
    console.log("Response", response);
    this.setState({
      showsfinalalertofstayorleave: true,
      showParentSuggestion: false,
    });

    if (response.status) alert("Your Response has been Submited");
    else alert("Unable to send feeback at the moment, please try again later");
  };

  ParentFeedbackSubmitEverytingisgood = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    let id = this.props.students;
    let student_id = studentID(id);
    let how_happy = this.state.everything_seems_happy.how_happy;
    let improvement = this.state.everything_seems_happy.improvement;
    let positive_feeling = this.state.everything_seems_happy.positive_feeling;

    const bodyFormData = new FormData();
    bodyFormData.append("live_class_id", liveClassID);
    bodyFormData.append("user_id", userId);
    bodyFormData.append("student_id", student_id);
    bodyFormData.append("how_happy", how_happy);
    bodyFormData.append("improvement", improvement);
    bodyFormData.append("positive_feeling", positive_feeling);

    const response = await ParentFeedbackApi(bodyFormData);
    console.log("Response", response);
    this.setState({
      showRateSuggestion: false,
      showsfinalalertofstayorleave: true,
    });

    if (response.status) alert("Your Response has been Submited");
    else alert("Unable to send feeback at the moment, please try again later");
  };

  takeScreenshotforparent = () => {
    this.setState({
      showraiseAlarm: false,
    });
    this.takeScreenshotforparentfirst();
  };

  showOnClick = () => {
    this.setState({
      logicZoneToggle: true,
    });
  };

  turnOffLogicZone = () => {
    this.setState({
      logicZoneToggle: false,
    });
  };

  submitScreenShotforparent = async (screenShot) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    let student_idchange = this.props.students;
    let change = student_idchange.toString();
    let x = change.split("-");
    let student_id = x[0];
    let alarm = true;

    const bodyFormData = new FormData();
    bodyFormData.append("live_class_id", liveClassID);
    bodyFormData.append("user_id", userId);
    bodyFormData.append("student_id", student_id);
    bodyFormData.append("alarm", alarm);
    bodyFormData.append("screenshot", screenShot, "image.png");

    // console.log("Response", response);

    const response = await ParentFeedbackApi(bodyFormData);

    console.log("Response", response);

    this.screenShotUploadStatusforparent(response.data);

    this.setState({
      screenShotPrgress: false,
    });
  };

  screenShotUploadStatusforparent = (response) => {
    if (response.status) {
      alert("Your error has been reported");
      this.setState({
        showsfinalalertofstayorleave: true,
      });
    } else
      alert(
        "Unable to send screen shot : " + response.message + " Please try again"
      );
  };

  recordScreenShotforparent = (stream) => {
    const canvas = this.canvas.current;

    setTimeout(() => {
      let track = stream.getVideoTracks()[0];
      let capture = new ImageCapture(track);
      capture.grabFrame().then((bitmap) => {
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;

        canvas.getContext("2d").drawImage(bitmap, 0, 0);

        track.stop();

        canvas.toBlob((blob) => {
          // Do things with blob here
          console.log("output blob:", blob);

          this.submitScreenShotforparent(blob);
        });
      });
    }, 2000);
  };

  takeScreenshotforparentfirst = () => {
    if (this.state.screenShotPrgress) return;
    this.setState({
      screenShotPrgress: true,
    });

    navigator.mediaDevices
      .getDisplayMedia()
      .then((stream) => {
        // Grab frame from stream
        this.recordScreenShotforparent(stream);
      })
      .catch((e) => console.log(e));
  };

  handleSliderChange = (e) => {
    this.setState({
      feedbackValue: e.target.value,
    });

    let fields = this.state.everything_seems_happy;
    fields[e.target.name] = e.target.value;
    this.setState({
      everything_seems_happy: this.state.everything_seems_happy,
    });
  };

  parentfeedbackvaluefn = (e) => {
    this.setState({
      parentfeedbackvalue: e.target.value,
    });
  };

  showRateSuggestionfn = (e) => {
    e.preventDefault();
    this.setState({
      showRateSuggestion: true,
    });
    this.setState({
      showeverythinggood: false,
    });
  };

  raiseAlarmbutton = (e) => {
    e.preventDefault();
    this.setState({
      showraiseAlarm: true,
    });
  };

  SuggestionParentbutton = (e) => {
    e.preventDefault();
    this.setState({
      showParentSuggestion: true,
    });
  };

  EverythinggoodParentbutton = (e) => {
    e.preventDefault();
    this.setState({
      showeverythinggood: true,
    });
  };

  closeParentFeedback = () => {
    this.setState({
      showParentSuggestion: false,
      showraiseAlarm: false,
      showeverythinggood: false,
      showspeedmathchildscore: false,
      connectionOffline: false,
      showIframe: false,
      openEndClassforChild: false,
      showDeviceInfoToTech: false,
    });
  };

  handleOptionChange(e) {
    this.setState({
      demoStatus: e.target.value,
    });
  }

  handleMouseIn = () => {
    this.setState({
      hover: true,
    });
  };

  handleMouseOut = () => {
    this.setState({
      hover: false,
    });
  };

  handleMouseInsecond = () => {
    this.setState({
      hoversecond: true,
    });
  };

  handleMouseOutsecond = () => {
    this.setState({
      hoversecond: false,
    });
  };

  getidofAllParticipant = () => {
    const { videoRoom } = this.props;
    const x = mapToArray(videoRoom.participants);
    let ids = x.map((id) => {
      return id.identity;
    });
    let allStudentIds = ids.filter(
      (item) => !excludeParticipant.includes(item)
    );
    this.setState({
      all_ids: [...ids],
      allStudentIds,
    });
  };

  StudentIpadState = (elem) => {
    console.log("Main Video Room", elem);

    this.setState({
      studentIpadStatesss: elem,
    });
    this.props.currentStudentState(elem);
  };

  onResize = (event, { element, size, handle }) => {
    this.setState({ resizeWidth: size.width, resizeHeight: size.height });
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: this.state.fields,
    });
  }

  handleClose = () => {
    this.setState({
      demoFlag: false,
    });
  };

  collapsTeacherScreen = () => {
    this.setState({
      collapsScreenTeacher: !this.state.collapsScreenTeacher,
    });

    if (!this.state.collapsScreenTeacher) {
      this.collapseScreenRef.current = setTimeout(() => {
        this.setState({
          collapsScreenTeacher: false,
        });

        clearTimeout(this.collapseScreenRef.current);
        this.collapseScreenRef.current = null;
      }, 10000);
    } else if (this.collapseScreenRef.current) {
      clearTimeout(this.collapseScreenRef.current);

      this.collapseScreenRef.current = null;
    }
  };

  openClassEndforStudent = () => {
    this.setState({
      openEndClassforChild: !this.state.openEndClassforChild,
    });

    if (!this.state.openEndClassforChild) {
      this.collapseStudentRef.current = setTimeout(() => {
        this.setState({
          openEndClassforChild: false,
        });

        clearTimeout(this.collapseStudentRef.current);
        this.collapseStudentRef.current = null;
      }, 5000);
    } else if (this.collapseStudentRef.current) {
      clearTimeout(this.collapseStudentRef.current);
      this.collapseStudentRef.current = null;
    }
  };

  closeWithoutDemoFeebback = async () => {
    if (this.state.demoStatus == "") {
      return;
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userID = params.get("userID");
    let liveClassID = params.get("liveClassID");

    const dataRepsonse = await updateIncompleteTeachersDemo(
      liveClassID,
      userID,
      "Incomplete"
    );

    this.props.onLeave();
  };

  handleSelectedCheckbox = (name) => {
    if (name === "mathzone") {
      var mathzoneIDS = $("input[name='mathzone']:checked")
        .map(function () {
          return $(this).val();
        })
        .get();

      this.setState({
        selectedmathZone: mathzoneIDS,
      });
    } else if (name === "speedmath") {
      var speedmathIDs = $("input[name='speedmath']:checked")
        .map(function () {
          return $(this).val();
        })
        .get();

      this.setState({
        selectedspeedMath: speedmathIDs,
      });
    } else if (name === "logiczone") {
      var logiczoneIDs = $("input[name='logiczone']:checked")
        .map(function () {
          return $(this).val();
        })
        .get();

      this.setState({
        selectedlogicZone: logiczoneIDs,
      });
    }
  };

  submitDemoFeedback = async (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      const { selectedmathZone, selectedspeedMath, selectedlogicZone } =
        this.state;
      let fields = this.state.fields;

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let userID = params.get("userID");
      let liveClassID = params.get("liveClassID");
      // let fields = {};
      // fields["demoStatus"] = "";
      // fields["firstName"] = "";
      // fields["gender"] = "";
      // fields["demoTopic"] = "";
      // fields["mathZOne"] = "";
      // fields["speedMath"] = "";
      // fields["logicZone"] = "";
      // fields["learningStyle"] = "";
      // fields["communication"] = "";
      // fields["conceptualKnowledge"] = "";
      // fields["higherOrder"] = "";
      // fields["coding"] = "";
      // fields["childInterest"] = "";
      // fields["classBehaviour"] = "";
      // fields["begalileoUseful"] = "";

      // this.setState({ fields: fields });
      var mathZoneValues = "";
      var logicZoneValues = "";
      var speedMathValues = "";

      selectedlogicZone.map((value) => {
        logicZoneValues += value + ",";
      });
      selectedmathZone.map((value) => {
        mathZoneValues += value + ",";
      });
      selectedspeedMath.map((value) => {
        speedMathValues += value + ",";
      });
      const dataRepsonse = await updateTeachersDemoFeedback(
        liveClassID,
        userID,
        fields["firstName"],
        fields["gender"],
        fields["demoTopic"],
        "Completed",
        mathZoneValues,
        speedMathValues,
        logicZoneValues,
        fields["learningStyle"],
        fields["conceptualKnowledge"],
        fields["communication"],
        fields["higherOrder"],
        fields["coding"],
        fields["childInterest"],
        fields["classBehaviour"],
        fields["begalileoUseful"],
        fields["internal_comments"]
      );

      this.props.onLeave();
    }
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "Enter your first name *";
    }

    if (!fields["gender"]) {
      formIsValid = false;
      errors["gender"] = "Please choose gender *";
    }

    if (!fields["demoTopic"]) {
      formIsValid = false;
      errors["demoTopic"] = "Please choose demo topic *";
    }

    if (this.state.selectedmathZone.length <= 0) {
      formIsValid = false;
      $(".mathzone-error").show();
    } else {
      $(".mathzone-error").hide();
    }

    if (this.state.selectedspeedMath.length <= 0) {
      formIsValid = false;
      $(".speedmath-error").show();
    } else {
      $(".speedmath-error").hide();
    }

    if (this.state.selectedlogicZone.length <= 0) {
      formIsValid = false;
      $(".logiczone-error").show();
    } else {
      $(".logiczone-error").hide();
    }

    if (!fields["learningStyle"]) {
      formIsValid = false;
      errors["learningStyle"] = "Please choose learning style *";
    }

    if (!fields["communication"]) {
      formIsValid = false;
      errors["communication"] = "Please choose communication *";
    }

    if (!fields["conceptualKnowledge"]) {
      formIsValid = false;
      errors["conceptualKnowledge"] = "Please choose conceptual knowledge *";
    }

    if (!fields["higherOrder"]) {
      formIsValid = false;
      errors["higherOrder"] = "Please choose higher order *";
    }

    if (!fields["coding"]) {
      formIsValid = false;
      errors["coding"] = "Please choose coding *";
    }

    if (!fields["childInterest"]) {
      formIsValid = false;
      errors["childInterest"] = "Please choose child interest *";
    }

    if (!fields["classBehaviour"]) {
      formIsValid = false;
      errors["classBehaviour"] = "Please choose class behaviour *";
    }

    if (!fields["begalileoUseful"]) {
      formIsValid = false;
      errors["begalileoUseful"] = "Please choose begalileo useful *";
    }

    if (!fields["internal_comments"]) {
      formIsValid = false;
      errors["internal_comments"] = "Please enter comments";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  closeChatBox = () => {
    this.setState({
      isChatBoxOpen: false,
    });

    $(".chatbox-popup, .chatbox-close").fadeOut();
  };

  onIncomingGameTimerStartStop = (data) => {
    console.log("Game Completed", data);
    let student_idchange = this.props.students;
    let change = student_idchange.toString();
    let x = change.split("-");
    let student_id = x[0];
    let child_name = x[1];
    if (isTutorCheck(this.props.videoRoom.localParticipant.identity))
      this.speedMathScreenElement?.current?.showGlobeResultTeacher();
    if (this.props.videoRoom.localParticipant.identity == "parent") {
      let mainScore = this.state.speedMathScoreDataItem;
      mainScore.forEach((e) => {
        if (e.id == student_id) {
          this.setState({
            child_score: e.score,
            child_name: child_name,
          });
        }
      });
      this.setState({
        selected: 1,
        showspeedmathprogressmodal: false,
        showspeedmathchildscore: true,
      });
    } else if (this.smPresent(this.props.videoRoom.localParticipant.identity)) {
      let mainScore = this.state.speedMathScoreDataItem;
      mainScore.forEach((e) => {
        console.log("e", e);
        this.setState({
          child_score: e.score,
          child_name: e.name,
        });
      });
      this.setState({
        selected: 1,
        showspeedmathprogressmodal: false,
        showspeedmathchildscore: true,
      });
    }
  };

  showDropdown = (name) => {
    if (name === "mathzone") {
      this.setState({
        ismzDropdownOpen: !this.state.ismzDropdownOpen,
        issmDropdownOpen: false,
        islzDropdownOpen: false,
      });
    } else if (name === "speedmath") {
      this.setState({
        issmDropdownOpen: !this.state.issmDropdownOpen,
        ismzDropdownOpen: false,
        islzDropdownOpen: false,
      });
    } else {
      this.setState({
        islzDropdownOpen: !this.state.islzDropdownOpen,
        ismzDropdownOpen: false,
        issmDropdownOpen: false,
      });
    }
  };

  openChatBox = () => {
    this.setState({
      newMessageCounter: 0,
      isChatBoxOpen: true,
    });
  };

  printLocalNetworkQualityStats = (qualityLevel) => {
    this.setState({
      localParticipantNetworkQuality: qualityLevel,
    });
  };

  readCookie = () => {
    const user = Cookies.get("user");
    if (user) {
      this.setState({
        status: true,
      });
    }
  };

  showErrorLogstoTech = () => {
    console.log("hey there I am tech ");
    const { videoRoom } = this.props;
    VideoRoomMonitor.registerVideoRoom(videoRoom);
    VideoRoomMonitor.openMonitor();
    VideoRoomMonitor.on("opened", () =>
      console.log("the monitor has been opened")
    );
  };

  filterDeviceDataForTech = (data) => {
    let finalObj = [];
    for (let i = 0; i < data.length; i = i + 2) {
      let obj = { ...data[i], ...data[i + 1] };

      let finalObject = Object.keys(obj)
        .filter((k) => (obj[k] != "" ? 1 : 0))
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

      finalObj.push(finalObject);
    }
    return finalObj;
  };

  showDeviceInfoToTech = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let live_class_id = params.get("liveClassID");
    let user_id = params.get("userID");

    let deviceInfoData = await getDeviceInfoForTech(live_class_id, user_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("Error", err);
      });
    if (deviceInfoData.status) {
      let finalData = this.filterDeviceDataForTech(deviceInfoData.device_data);

      this.setState({
        finalDeviceDataToShowTech: [...finalData],
        showDeviceInfoToTech: true,
      });
    }
  };

  showIframefn = () => {
    console.log("HLELLLELELEL");
    this.setState({
      showIframe: true,
    });
  };

  handleVideoChildState = () => {
    this.videoScreenElement.current.onVideoPlay();
  };

  handleSpeedChildState = (elem) => {
    console.log("Elem", elem);
    this.setState({
      currentSpeedmathid: elem,
    });
    console.log("Elem", elem);
  };

  stopScreenShareafterDisconnect = (studentIdentity) => {
    const dynamic_screen_var = studentIdentity + "_screen";
    this.setState({
      [dynamic_screen_var]: false,
      isSharingScreen: false,
    });
  };

  // mounting

  //

  mutealldynamicallystate = () => {
    console.log("Muting all icons");
    const f = this.state.all_ids.length;
    if (this.state.muteAllState) {
      for (let i = 0; i < f; i++) {
        this.muteRemoteStudent(this.state.all_ids[i]);
      }
    } else {
      for (let i = 0; i < f; i++) {
        this.unMuteRemoteStudent(this.state.all_ids[i]);
      }
    }
  };

  youNeedtoRenderAgain = () => {
    let WHITE_BOARD_POINTS = "WhiteBoardPoints";
    //let storedLine = JSON.parse(localStorage.getItem("CurrentLines"));
    let storedLine = JSON.parse(localStorage.getItem(WHITE_BOARD_POINTS));
    let currentChildPdfIndex = this.state.changechildlessonpdf;

    if (this.state.closerenderingforChild) {
      return;
    }
    // console.log("Storeline", storedLine[currentChildPdfIndex]);
    else if (storedLine[currentChildPdfIndex] == undefined) {
      console.log("Not available");
      return;
    } else {
      this.retainedCanvas();
    }
  };

  retainedCanvas = () => {
    let WHITE_BOARD_POINTS = "WhiteBoardPoints";
    let storedLine = JSON.parse(localStorage.getItem(WHITE_BOARD_POINTS));
    //let storedLine = JSON.parse(localStorage.getItem("CurrentLines"));
    let currentChildPdfIndex = this.state.changechildlessonpdf;
    if (this.state.closerenderingforChild) {
      return;
    } else if (storedLine[currentChildPdfIndex] == undefined) {
      console.log("Not available");
      return;
    }

    for (let i = 0; i < storedLine[currentChildPdfIndex].length; i++) {
      setTimeout(() => {
        console.log("Print", storedLine[currentChildPdfIndex][i]);
        this.props.sendWhiteBoardLines(storedLine[currentChildPdfIndex][i]);
      }, 2000);
    }
  };

  removeLogicZoneLogoHighlight = () => {
    const selectedID = this.state.selected;
    if (
      selectedID == 1 ||
      selectedID == 2 ||
      selectedID == 3 ||
      selectedID == 4 ||
      selectedID == 5
    ) {
      setTimeout(() => {
        let y = document.getElementsByClassName("d-flex flex-column")[6];
        y.getElementsByTagName("label")[0].className =
          "disabled btn btn-default btn-block";
        y.getElementsByTagName("img")[0].src =
          "/static/media/Images-v2/LogicZone.svg";
      }, 0);
    }
  };

  adjustAllScreenWidth = () => {
    let AdjustwidthofTopRow = document.getElementsByClassName("row")[0];
    if (this.state.selected == 1) {
      AdjustwidthofTopRow.style.marginRight = "-80px";
    } else {
      AdjustwidthofTopRow.style.marginRight = "-15px";
    }
  };

  // fnBrowserDetect = () => {
  //   let userAgent = navigator.userAgent;
  //   let browserName;

  //   if (userAgent.match(/chrome|chromium|crios/i)) {
  //     browserName = "chrome";
  //   } else if (userAgent.match(/firefox|fxios/i)) {
  //     browserName = "firefox";
  //   } else if (userAgent.match(/safari/i)) {
  //     browserName = "safari";
  //   } else if (userAgent.match(/opr\//i)) {
  //     browserName = "opera";
  //   } else if (userAgent.match(/edg/i)) {
  //     browserName = "edge";
  //   } else {
  //     browserName = "No browser detection";
  //   }

  //   console.log("Browser Name", browserName);
  // };

  setDemoFlag = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let demoStatusCheck = params.get("demo");

    if (demoStatusCheck == "true") {
      return true;
    } else {
      return false;
    }

    console.log("Demo_class_type", demoStatusCheck);
  };

  checkBrowser = () => {
    let browserName;
    if (navigator.platform.indexOf("Mac") > -1) {
      browserName = "MacOS";
    }
    if (navigator.platform.indexOf("Win") > -1) {
      browserName = "Windows";
    }

    this.setState({
      userOperatingSystemName: browserName,
    });

    //console.log("Browser Name", browserName);
  };

  // isMacintosh = () => {
  //   return navigator.platform.indexOf("Mac") > -1;
  // };

  // isWindows = () => {
  //   return navigator.platform.indexOf("Win") > -1;
  // };

  teacherGiveRecordingPermission = (status) => {
    console.log("Give permission Or Not", status);
    if (status === false) {
      let againAskRecordingPermission = setTimeout(() => {
        this.screenRecordingElement.current.startRecordingOnClassStart();
        clearTimeout(againAskRecordingPermission);
      }, 5000);
    }
  };
  callUserNotPresentTimeout = () => {
    const { videoRoom } = this.props;
    let checkingId = setTimeout(() => {
      const remoteParticipants = mapToArray(videoRoom.participants);
      const RemoteParticipantLength = remoteParticipants.filter(
        (participant) => participant.identity != "tech"
      );

      if (RemoteParticipantLength?.length === 0) {
        if (videoRoom.localParticipant.identity == "tutor") {
          console.log("Student is not present");
          this.studentNotPresentApiCall();
        } else {
          console.log("Teacher is not present");
          this.teacherNotPresentApiCall();
        }
      } else if (RemoteParticipantLength?.length >= 1) {
        if (videoRoom.localParticipant.identity != "tutor") {
          for (let item of RemoteParticipantLength) {
            if (item?.identity === "tutor") return;
          }

          console.log("Teacher is not present");

          this.teacherNotPresentApiCall();
        }
      }
      console.log("SetTimeOut Over");

      clearTimeout(checkingId);
    }, 300000);
  };

  callTechNotPresentCall = () => {
    const { videoRoom } = this.props;

    let checkingTechId = setTimeout(() => {
      const remoteParticipants = mapToArray(videoRoom.participants);

      if (remoteParticipants?.length === 0) {
        if (videoRoom.localParticipant.identity == "tutor") {
          this.techNotPresentApiCall();
          console.log("Tech is not present when teacher present");
        } else {
          this.techNotPresentApiCall();
          console.log("Tech is not present when student present");
        }
      } else if (remoteParticipants?.length >= 1) {
        console.log("in else if condition tech not joined");
        for (let item of remoteParticipants) {
          if (item?.identity === "tech") return;
        }
        this.techNotPresentApiCall();
      }
      console.log("SetTimeOut is Over");

      clearTimeout(checkingTechId);
    }, 300000);
  };

  checkFiveMinutesTime(class_start_date, class_start_time) {
    var eventMoment = moment(
      class_start_date + class_start_time,
      "YYYY-MM-DDLT"
    );
    var eventEndMoment = moment(
      class_start_date + class_start_time,
      "YYYY-MM-DDLT"
    ).add(5, "minute");
    var currentMoment = moment();

    return currentMoment.isBetween(eventMoment, eventEndMoment);
  }
  getCurrentDateInFormat() {
    const dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var momentDate = moment().tz("Asia/Kolkata").format();
    let dates = momentDate.split("T")[0];
    dates = dates.split("-");
    for (let i = 0; i < dates.length; i++) dates[i] = Number(dates[i]);
    dates = dates.join("");
    let y = year + "" + month + "" + day;
    return dates;
  }
  getDateAndTimeZone = (item) => {
    let result = item.split(" ");
    let date = result.shift();
    let y = result.join(" ");
    let z = y.trim();
    let timearr = z.split(" ");
    let timeInUppperCase = timearr[2].toUpperCase();
    let changeTimeFormat = [timearr[0], timearr[1]];
    let finalChangeTimeFormat = changeTimeFormat.join(":");
    let final = [finalChangeTimeFormat, timeInUppperCase];
    let time = final.join(" ");
    return [date, time];
  };
  teacherNotPresentApiCall = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let live_class_id = params.get("liveClassID");
    let student_id = params.get("userID");
    let joined_time = this.state.user_joined_time;

    let checkApiCalled = localStorage.getItem("smnotificationsend");

    if (checkApiCalled == "true") {
      console.log("notification already send so returning from fn");
      return;
    } else {
      console.log("send notification");
      let result = await teacherNotPresentApi(
        live_class_id,
        student_id,
        joined_time
      );
      localStorage.setItem("smnotificationsend", true);
    }
  };
  studentNotPresentApiCall = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let live_class_id = params.get("liveClassID");
    let joined_time = this.state.user_joined_time;

    let checkApiCalled = localStorage.getItem("smnotificationsend");

    if (checkApiCalled == "true") {
      console.log("notification already send so returning from fn");
      return;
    } else {
      console.log("send notification");
      let result = await studentNotPresentApi(live_class_id, joined_time);
      localStorage.setItem("smnotificationsend", true);
    }
  };

  techNotPresentApiCall = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let live_class_id = params.get("liveClassID");

    let result = await techNotPresentApi(live_class_id);
  };

  setUserJoinedTime = () => {
    let joined_time = moment().tz("Asia/Kolkata").format();

    this.setState({
      user_joined_time: joined_time,
    });
  };

  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  startUserPresentFunction = (start_time) => {
    let class_start_time = start_time;
    let current = new Date();
    let getHours = current.getHours();
    let getMinutes = current.getMinutes();

    if (getMinutes <= 9) {
      getMinutes = "0" + getMinutes;
    }
    let user_current_ti = "" + getHours + ":" + getMinutes;
    // let user_current_time = +user_current_ti;

    const number = moment(class_start_time, ["h:mm A"]).format("HH:mm");
    const number1 = moment(user_current_ti, ["h:mm A"]).format("HH:mm");

    let finalClassStartTime = Number(number.replace(":", ""));
    let finalUserTime = Number(number1.replace(":", ""));

    console.log("finalClassStartTime", finalClassStartTime);
    console.log("finalUserTime", finalUserTime);

    if (finalUserTime >= finalClassStartTime) {
      return true;
    } else {
      return false;
    }
  };

  // getSizes() {
  //   let currentRatio = window.devicePixelRatio;
  //   if (currentRatio !== window.devicePixelRatio) {
  //     console.log("zooming");
  //   }
  // }

  rejoinTeacherClass = () => {
    sessionStorage.setItem("rejoin", true);
    window.location.reload();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selected != this.state.selected) {
      if (this.state.allScreenScreenShot) {
        this.setState({ allScreenScreenShot: false });
        this.takeFullScreenShotComponent();
      }
      this.adjustAllScreenWidth();
      if (prevState.selected == 6) {
        this.removeLogicZoneLogoHighlight();
      }
    }
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    if (remoteParticipants.length >= 1) {
      remoteParticipants.forEach((e) => {
        if (e.identity == "parent" && !this.state.showit) {
          this.setState({
            showit: true,
          });
          console.log("Parent join");
        }
      });
    }
    if (prevState.selected != this.state.selected) {
      this.setState({
        showspeedmathprogressmodal: false,
      });
      let whiteBoardLinesnext = "";
      localStorage.setItem(
        "quizcanvasretainedlines",
        JSON.stringify(whiteBoardLinesnext)
      );
    }

    if (prevState.muteAllState != this.state.muteAllState) {
      this.mutealldynamicallystate();
    }

    if (
      this.state.stateChangeChild &&
      this.state.selected != prevState.selected
    ) {
      console.log("LocalStorageData is erased success");

      localStorage.removeItem("WhiteBoardPoints");

      this.setState({
        closerenderingforChild: true,
      });
    }
    if (
      this.state.changepenstrokesforChild != 0 &&
      this.state.stateChangeChild
    ) {
      if (prevState.changechildlessonpdf != this.state.changechildlessonpdf) {
        if (this.state.childjoinCounter > 0) {
          this.youNeedtoRenderAgain();
        } else {
          console.log("Sorry you are not allowed to retain state again");
          return;
        }
      }
    }

    if (prevProps.userSharingScreen != this.props?.userSharingScreen) {
      this.setState({
        userSharingScreen: this.props?.userSharingScreen,
      });
    }

    if (prevProps.techDidNotJoinTimer != this.props?.techDidNotJoinTimer) {
      if (this.props?.techDidNotJoinTimer) {
        console.log("calling fn");
        this.callTechNotPresentCall();
      }
    }
  };

  initialUserIdAndLiveClassId = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userID = params.get("userID");

    this.setState({
      userIdWhenJoining: userID,
      liveClassIdWhenJoining: liveClassID,
    });
  };

  componentDidMount = () => {
    console.log("VideoRoom Component Mounting");
    this.initialUserIdAndLiveClassId();
    this.getidofAllParticipant();
    this.readCookie();
    $(".chatbox-open").click(function () {
      $(".chatbox-popup, .chatbox-close").fadeIn();
    });

    $(".chatbox-close").click(function () {
      $(".chatbox-popup, .chatbox-close").fadeOut();
    });
    const { videoRoom } = this.props;

    let localParticipantIdentityToSet = videoRoom.localParticipant.identity;

    sessionStorage.setItem(
      "localParticipantIdentity",
      localParticipantIdentityToSet
    );

    const checkkkking =
      this.getDateAndTimeZone(this.props.class_start_time) || [];
    const class_start_date_present = checkkkking[0];
    const class_start_time_present = checkkkking[1];

    // const zoomEvent = new Event("zoom");
    // let currentRatio = window.devicePixelRatio;

    // function checkZooming() {
    //   let pixelValue = window.devicePixelRatio;

    //   if (pixelValue > 2) {
    //     this.setState({
    //       changeListItemHeight: true,
    //     });
    //   }
    //   if (pixelValue < 2 || pixelValue == 2) {
    //     this.setState({
    //       changeListItemHeight: false,
    //     });
    //   }

    //   // if (currentRatio !== window.devicePixelRatio) {
    //   //   window.dispatchEvent(zoomEvent);
    //   // }
    // }

    // window.addEventListener("resize", () => {
    //   let pixelValue = window.devicePixelRatio;

    //   if (pixelValue > 2) {
    //     this.setState({
    //       changeListItemHeight: true,
    //     });
    //   } else {
    //     this.setState({
    //       changeListItemHeight: false,
    //     });
    //   }
    // });

    // usage
    // window.addEventListener("zoom", () => {
    //   console.log("zoomed!");
    // });

    // window.addEventListener("resize", this.getSizes(), false);

    window.addEventListener("offline", (e) => {
      this.setState({
        connectionOffline: true,
      });

      this.props.checkInternetInLiveClass(false);
    });

    window.addEventListener("online", (e) => {
      this.setState({
        connectionOnline: true,
      });
      this.props.checkInternetInLiveClass(true);
    });

    this.adjustAllScreenWidth();

    this.checkBrowser();

    this.setDemoFlag();
    this.setUserJoinedTime();

    this.fd = setInterval(() => {
      if (!class_start_time_present) {
        clearInterval(this.fd);
        return;
      }

      let result = this.startUserPresentFunction(class_start_time_present);
      let checkFiveMinutesTime = this.checkFiveMinutesTime(
        class_start_date_present,
        class_start_time_present
      );

      if (result) {
        if (checkFiveMinutesTime) {
          this.callUserNotPresentTimeout();
          clearInterval(this.fd);
        } else {
          clearInterval(this.fd);
        }
      }
    }, 60000);
    // let checkingId = setTimeout(() => {
    //   if (
    //     this.state.studentJoinedClass &&
    //     !this.studentPresent(videoRoom.localParticipant.identity)
    //   ) {
    //     console.log("Student is present in class");
    //   } else {
    //     console.log("Student is not present in class");
    //   }

    //   if (
    //     this.state.teacherJoinedClass &&
    //     !this.teacherPresent(videoRoom.localParticipant.identity)
    //   ) {
    //     console.log("Teacher is present in class");
    //   } else {
    //     console.log("Teacher is not present in class");
    //   }

    //   clearTimeout(checkingId);
    // }, 150000);

    //this.fnBrowserDetect();

    // if (videoRoom.localParticipant.identity == "tutor") {
    //   let teacherRecordingID = setTimeout(() => {
    //     this.screenRecordingElement.current.startRecordingOnClassStart();

    //     clearTimeout(teacherRecordingID);
    //   }, 5000);
    // }

    videoRoom.on("participantConnected", (participant) => {
      //console.log("Participant CONNETED", participant.identity);

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let liveClassID = params.get("liveClassID");
      //Sentry.captureException("on Participant connected", participant.identity);

      // if (this.studentPresent(participant.identity)) {
      //   this.setState({
      //     studentJoinedClass: true,
      //   });
      // }
      // if (this.teacherPresent(participant.identity)) {
      //   this.setState({
      //     teacherJoinedClass: true,
      //   });
      // } else {
      //   this.setState({
      //     studentJoinedClass: false,
      //     teacherJoinedClass: false,
      //   });
      // }

      if (participant.identity == "parent") {
        this.setState({
          showit: true,
        });
      }
      if (participant.identity == "tutor") {
        this.setState({
          hideteacherfromchildView: false,
        });

        // let teacherRecordingId = setTimeout(() => {
        //   console.log("Teacher Recording Started or Not");
        //   clearTimeout(teacherRecordingId);
        // }, 5000);
      }

      // if (participant.identity != "tutor") {
      //   this.setState({
      //     hideteacherfromchildView: true,
      //   });
      // }

      this.getidofAllParticipant();
      if (
        participant.identity != "tutor" &&
        participant.identity != "parent" &&
        !this.smPresent(participant.identity)
      ) {
        setTimeout(() => {
          let identity = participant.identity;
          let state = this.state.selected;
          let pdfUrl = JSON.stringify(this.state.selectedPdfUrl);
          let pdfIndex = this.state.selectedPdfIndex;
          let selectedVideoUrl = this.state.selectedVideoUrl;
          let currentVideotime = this.state.nowPlayingVideoStart;
          let currentChildSpeedMathState = this.state.currentSpeedmathid;
          let currentChildPdfIndex = this.state.changechildlessonpdf;
          let currentPanStokesforchild = this.state.changepenstrokesforChild;

          ///////////////////////////
          let selected = this.state.selected;
          let selectedTagId = this.state.selectedTagId;
          let selectedQuizLevel = this.state.selectedQuizLevel;

          let mounting = true;
          let errorState = false;
          let waitingState = false;
          let practiceId = this.state.practiceId;
          let quizObj = this.state.quizObj;
          let conceptName = this.state.conceptName;
          let conceptTag = this.state.conceptTag;
          let logicalZoneTrue = this.state.logicalZoneTrue;

          this.setState({
            childjoinCounter: this.state.childjoinCounter + 1,
          });

          if (state == 1) {
            this.props.changeStateDynamic(identity, state);
          }
          if (state == 2) {
            this.props.changeStateDynamic(identity, state);
          }
          if (state == 3) {
            if (this.state?.selectGGbDataInConceptsList?.type == "ggb") {
              console.log("I am heare");
              this.props.onGeoGebraLinks(
                JSON.stringify(this.state.selectGGbDataInConceptsList)
              );
              return;
            }

            if (currentPanStokesforchild.length != 0 && pdfUrl == undefined) {
              this.props.changeStateDynamic(identity, state, "WhiteBoard");
              this.retainedCanvas();
              return;
            } else if (
              currentPanStokesforchild.length == 0 &&
              pdfUrl == undefined
            ) {
              this.props.changeStateDynamic(identity, state, "WhiteBoard");

              return;
            } else if (
              currentPanStokesforchild.length != 0 &&
              pdfUrl != undefined
            ) {
              this.props.changeStateDynamic(
                identity,
                state,
                "ShowcurrentPen",
                pdfUrl,
                currentChildPdfIndex
                // pdfIndex,
                //canvasLines
              );
              this.retainedCanvas();
              this.setState({
                stateChangeChild: true,
              });
              return;
            } else {
              console.log("Hello2"),
                this.props.changeStateDynamic(
                  identity,
                  state,
                  "ShowcurrentPen",
                  pdfUrl,
                  currentChildPdfIndex
                  // pdfIndex,
                  //canvasLines
                );
              this.setState({
                stateChangeChild: true,
              });
              return;
            }
          }
          if (state == 4) {
            if (currentVideotime > 1) {
              this.props.changeStateDynamic(
                identity,
                state,
                selectedVideoUrl,
                currentVideotime
              );
            } else {
              this.props.changeStateDynamic(identity, state, selectedVideoUrl);
            }
          }
          if (state == 5) {
            if (currentChildSpeedMathState != 0) {
              this.props.changeStateDynamic(identity, state, "speedmathopen");
            } else {
              this.props.changeStateDynamic(
                identity,
                state,
                "speedmathdontopen"
              );
            }
          }

          if (state == 6) {
            console.log("hello");
            // if (
            //   this.state.startQuizforchild &&
            //   !this.state.roughBoardforchildren
            // )

            this.props.onPlayQuiz({
              selected,
              selectedTagId,
              selectedQuizLevel,
              mounting,
              errorState,
              waitingState,
              practiceId,
              quizObj,
              conceptName,
              conceptTag,
              logicalZoneTrue,
            });

            // if (this.state.roughBoardforchildren) {
            //   this.props.changeStateDynamic(
            //     identity,
            //     state,
            //     "openRoughBoardforchild"
            //   );
            //   this.startPracticeToStudents();
            //   this.props.sendWhiteBoardLines(this.state.justchecking);
            // }
          }
          if (state == 11) {
            this.props.openActivity({
              selected: 11,
              selectedActivity: this.state?.selectedActivity,
              currentScreenName: this.state?.currentScreenName,
              isCheckInActivity: this.state?.isCheckInActivity,
              showActivityNotification: this.state?.showActivityNotification,
              showAffirmationNextButton: this.state.showAffirmationNextButton,
              showAffirmationStories: this.state.showAffirmationStories,
              showCheckoutAffirmationNextBtn:
                this.state.showCheckoutAffirmationNextBtn,
              cicoApiData: this.state.cicoApiData,
            });
          }
          if (state == 12 || state == 12) {
            this.props.openFlagQuestion({
              subId: this.state.miscellaneousId,
              level: "level1",
              conceptName: this.state.conceptName,
              conceptId: this.state.selectedConceptId,
              tagName: this.state.conceptTag,
              tagId: this.state.selectedTagId,
              showFlagQuestion: this.state.showFlagQuestion || false,
            });
          }
        }, 5000);

        // if (participant.identity != "parent") {
        //   setTimeout(() => {
        //     let identity = participant.identity;
        //     let state = this.state.selected;
        //     let pdfUrl = JSON.stringify(this.state.selectedPdfUrl);
        //     let pdfIndex = this.state.selectedPdfIndex;
        //     let selectedVideoUrl = this.state.selectedVideoUrl;
        //     let currentVideotime = this.state.nowPlayingVideoStart;
        //     let currentChildSpeedMathState = this.state.currentSpeedmathid;

        //     let currentChildPdfIndex = this.state.changechildlessonpdf;
        //     let lastIndexofPdf = localStorage.getItem(LAST_INDEX_WHITE_BOARD);

        //     let currentPanStokesforchild = this.state.changepenstrokesforChild;

        //     this.setState({
        //       childjoinCounter: this.state.childjoinCounter + 1,
        //     });

        //     if (state == 1) {
        //       this.props.changeStateDynamic(identity, state);
        //     }
        //     if (state == 2) {
        //       this.props.changeStateDynamic(identity, state);
        //     }
        //     if (state == 3) {
        //       if (currentPanStokesforchild.length != 0 && pdfUrl == undefined) {
        //         this.props.changeStateDynamic(identity, state, "WhiteBoard");
        //         this.retainedCanvas();
        //         return;
        //       } else if (
        //         currentPanStokesforchild.length == 0 &&
        //         pdfUrl == undefined
        //       ) {
        //         this.props.changeStateDynamic(identity, state, "WhiteBoard");

        //         return;
        //       } else if (
        //         currentPanStokesforchild.length != 0 &&
        //         pdfUrl != undefined
        //       ) {
        //         this.props.changeStateDynamic(
        //           identity,
        //           state,
        //           "ShowcurrentPen",
        //           pdfUrl,
        //           currentChildPdfIndex
        //           // pdfIndex,
        //           //canvasLines
        //         );
        //         this.retainedCanvas();
        //         this.setState({
        //           stateChangeChild: true,
        //         });
        //         return;
        //       } else {
        //         console.log("CurrentIndex", currentChildPdfIndex);
        //         this.props.changeStateDynamic(
        //           identity,
        //           state,
        //           "ShowcurrentPen",
        //           pdfUrl,
        //           currentChildPdfIndex
        //           // pdfIndex,
        //           //canvasLines
        //         );
        //         this.setState({
        //           stateChangeChild: true,
        //         });
        //         return;
        //       }
        //     }
        //     if (state == 4) {
        //       if (currentVideotime > 1) {
        //         this.props.changeStateDynamic(
        //           identity,
        //           state,
        //           selectedVideoUrl,
        //           currentVideotime
        //         );
        //       } else {
        //         this.props.changeStateDynamic(identity, state, selectedVideoUrl);
        //       }
        //     }
        //     if (state == 5) {
        //       if (
        //         currentChildSpeedMathState != 0 ||
        //         this.state.speedMathGameId != 0
        //       ) {
        //         this.props.changeStateDynamic(identity, state, "speedmathopen");
        //       } else {
        //         this.props.changeStateDynamic(
        //           identity,
        //           state,
        //           "speedmathdontopen"
        //         );
        //         //this.speedMathScreenElement.current.storeGameResponse();
        //       }
        //     }

        //     if (state == 6) {
        //       if (identity == "tutor") {
        //         console.log("Identity", identity);
        //         console.log(
        //           "After Reload States",
        //           "Concept id",
        //           this.state.selectedConceptId,
        //           "Tag iD",
        //           this.state.selectedTagId,
        //           "Level",
        //           this.state.selectedQuizLevel
        //         );

        //         this.props.onPlayQuizForTeacher(
        //           this.state.selectedConceptId,
        //           this.state.selectedTagId,
        //           this.state.selectedQuizLevel
        //         );
        //       }
        //       // if (
        //       //   this.state.startQuizforchild &&
        //       //   !this.state.roughBoardforchildren
        //       // ) {
        //       //   this.startPracticeToStudents();
        //       //   return;
        //       // }

        //       // if (this.state.roughBoardforchildren) {
        //       //   this.props.changeStateDynamic(
        //       //     identity,
        //       //     state,
        //       //     "openRoughBoardforchild"
        //       //   );
        //       //   this.startPracticeToStudents();
        //       //   this.props.sendWhiteBoardLines(this.state.justchecking);
        //       // }
        //     }
        //   }, 5000);

        //   return;
        // }
      }
    });

    videoRoom.on("participantDisconnected", (participant) => {
      if (participant.identity == "parent") {
        this.setState({
          showit: false,
        });
      }
      if (participant.identity == "tutor") {
        this.setState({
          hideteacherfromchildView: true,
        });
      }

      if (this.state.sharedScreenTrack != null) {
        if (this.state.sharedScreenTrack.identity == participant.identity) {
          console.log("Hey there");
          this.setState({
            sharedScreenTrack: null,
          });
        }
      }
      this.getidofAllParticipant();
    });

    videoRoom.localParticipant.on("networkQualityLevelChanged", (level) => {
      this.setState({
        localParticipantNetworkQuality: level,
      });
    });

    videoRoom.on("trackPublished", (track, participant) => {
      if (
        videoRoom.localParticipant.identity == "tutor" ||
        videoRoom.localParticipant.identity == "tech"
      ) {
        if (track.trackName === "screen_tutor") {
          this.setState({
            sharedScreenTrack: participant,
          });
        }
      } else {
        if (isTutorCheck(participant.identity)) {
          if (track.trackName === "screen_tutor") {
            this.setState({
              sharedScreenTrack: participant,
            });
          }
        }
      }
    });
    videoRoom.on("trackUnpublished", (track, participant) => {
      if (track.trackName === "screen_tutor") {
        this.setState({
          sharedScreenTrack: null,
        });
      }
    });

    videoRoom.on("reconnecting", (error) => {
      if (this.state.connectionOffline) {
        this.setState({
          errorWhileReconnecting: true,
          errorWhileReconnectingMessage:
            "hmmn! You are strong but can’t say the same for your connection. Your connection speed is too low",
        });
        return;
      }

      if (error.code === 53001 || error.code === 53405) {
        this.setState({
          errorWhileReconnecting: true,
          errorWhileReconnectingMessage: error.message,
          connectionOffline: false,
        });
      }
    });

    videoRoom.on("reconnected", () => {
      // if (videoRoom.localParticipant?.identity == "tutor") {
      //   window.location.reload();
      // }
      this.setState({
        errorWhileReconnecting: false,
      });
    });

    this.getConceptList();
    this.getFlagQuestionConceptList();
    this.callUploadResourceList();
    getSpeedMathLevels(4).then((res) => {
      // console.log("Levels", res.data);
      this.onSynchronizeMutedState();
      this.setState({
        game_levels: res.data.levels,
      });
    });

    this.subscribeDominantSpeaker(this.props.videoRoom);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let currentUserId = params.get("userID");
    var codeUrlFormat =
      baseURL + "online_classes/coding_links?live_class_id=" + liveClassID;
    this.setState({
      codeFrameUrl: codeUrlFormat,
      mLiveClassId: liveClassID,
      currentUserId: currentUserId,
    });
  };

  updateLocalRaiseHand(studentIdentity) {
    alert(studentIdentity + " From Local");
  }

  setDemoFlag = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let demoStatusCheck = params.get("demo");

    if (demoStatusCheck == "true") {
      return true;
    } else {
      return false;
    }

    console.log("Demo_class_type", demoStatusCheck);
  };

  expandMain(e) {
    if (this.state.shown === true) {
      this.setState({
        main: { md: 3 },
        right: { md: 6 },
        shown: !this.state.shown,
      });
    } else {
      this.setState({
        main: { md: 6 },
        right: { md: 3 },
        shown: !this.state.shown,
      });
    }
  }

  expandRight() {
    this.setState({
      main: { md: 3 },
      right: { md: 6 },
    });
  }
  componentWillUnmount() {
    console.warn("componentWillUnmount call");
    clearInterval(this.fd);
  }

  radioHandler = (id) => {
    localStorage.setItem(Constants.currentScreenLocal, id);
    console.log(id);
    this.closeChatBox();

    // if (id == 15) {
    //   this.openLessonPdf(1, []);

    //   let cleaActiveImage = setTimeout(() => {
    //     let x = document.getElementsByClassName("d-flex flex-column")[3];
    //     x.getElementsByTagName("label")[0].className =
    //       "disabled btn btn-default btn-block";
    //     x.getElementsByTagName("img")[0].src =
    //       "/static/media/Images-v2/WhiteBoard.svg";

    //     let y = document.getElementsByClassName("d-flex flex-column")[2];
    //     y.getElementsByTagName("label")[0].className =
    //       "disabled active btn btn-default btn-block";
    //     y.getElementsByTagName("img")[0].src =
    //       "/static/media/Images-v2/WhiteBoard.svg";

    //     clearTimeout(cleaActiveImage);
    //   }, 2000);

    //   return;
    // }

    let z = document.getElementsByClassName("d-flex flex-column")[2];
    z.getElementsByTagName("label")[0].className =
      "disabled btn btn-default btn-block";
    z.getElementsByTagName("img")[0].src =
      "/static/media/Images-v2/WhiteBoard.svg";

    if (id == 13 && this.props.class_type == "math_coding") {
      console.log("I am in coding screen");
      this.openCodingScreen();
      return;
    }

    // if (this.props.class_type == "math_coding") {
    //   if (this.setDemoFlag()) {
    //     if (this.props.show_new_codings) {
    //       this.openScratchScreen();
    //     } else {
    //       this.openCodingScreen();
    //     }
    //   } else {
    //     this.openScratchScreen();
    //   }
    // }

    // if (
    //   id === 7 &&
    //   this.setDemoFlag() &&
    //   this.props.class_type == "math_coding" &&
    //   this.props.show_new_codings
    // ) {
    //   this.openScratchScreen();
    //   return;
    // } else {
    //   this.openCodingScreen();
    // }

    console.log("I am here else condition");

    if (id === 6) {
      this.openQuizPlay(
        this.state.selectedConceptId,
        this.state.selectedTagId,
        this.state.selectedQuizLevel
      );
      this.setState({
        logicZoneValues: false,
      });
    } else {
      // if (id !== 2)
      this.setState({ selected: id, active: id });
    }
    switch (id) {
      case 1:
        this.props.onAllScreen();
      case 2:
        this.props.onMyScreen();
      case 3:
        this.props.onWhiteBoard();

      case 13:
        this.props.openCodingScreen();

      case 9:
        this.props.onScratchScreen();
      // case 4:
      //   this.props.closeSpeedMath();

      // case 7:
      //   this.props.openCodingScreen();

      default:
        return null;
    }
  };

  handleCloseConfirmationModalCico = () => {
    this.setState({
      preserveStateCicoActivty: "",
      changeScreenConfirmationModal: false,
    });
  };
  handleYesConfirmationModalCico = () => {
    typeof this.state?.preserveStateCicoActivty === "function" &&
      this.state?.preserveStateCicoActivty();
    this.setState({
      preserveStateCicoActivty: "",
      changeScreenConfirmationModal: false,
    });
  };
  openConfirmationChangeScreenModal = (callback) => {
    let identity = this.props?.videoRoom.localParticipant.identity;
    console.log(identity);
    if (identity === "tutor") {
      console.log("identity");
      if (this.state.selected === 11) {
        if (this.state.isCheckInActivity) {
          if (!this.state.isCheckInActivityEnd) {
            this.setState({
              preserveStateCicoActivty: callback,
              changeScreenConfirmationModal: true,
            });
            this.hideMenus();
            return;
          }
        } else {
          if (!this.state.isCheckOutActivityEnd) {
            this.setState({
              preserveStateCicoActivty: callback,
              changeScreenConfirmationModal: true,
            });
            this.hideMenus();
            return;
          }
        }
      }
    }
    typeof callback === "function" && callback();
  };

  openCodingScreen = () => {
    if (this.props.class_type === "math_coding") {
      if (this.setDemoFlag()) {
        if (this.props.show_new_codings) {
          this.openScratchScreen();
        } else {
          this.setState({
            selected: 7,
          });
          // this.openCodingScreen();
        }
      } else {
        this.openScratchScreen();
      }
    } else {
      this.setState({
        selected: 7,
      });
    }
    // this.setState({
    //   selected: 7,
    // });

    console.log("Now in coding screen");
    this.props.openCodingScreen();
  };

  openScratchScreen = () => {
    this.setState({
      selected: 9,
    });
    this.props.onScratchScreen();
  };
  openGeogebraScreen = () => {
    this.setState({
      selected: 10,
    });
    this.props.onGeogebra({
      geo_id: 0,
      selected: 10,
    });
  };
  nextQuestionGeogebra = (value) => {
    this.setState({ geo_id: value });
    this.props.onGeogebra({
      geo_id: value,
      selected: this.state.selected,
    });
  };
  onLogicalConceptClick = (
    conceptId,
    tagId,
    level,
    conceptName,
    conceptTag
  ) => {
    console.log("mathzone---");
    this.setState({
      logicalZoneTrue: "logicalZoneTrue",
    });
    this.openQuizPlay(
      conceptId,
      tagId,
      level,
      conceptName,
      conceptTag,
      "logicalZoneTrue"
    );
  };

  starAnimate = (studentIdentity) => {
    this.setState({
      starClicked: true,
      starClickedStudent: studentIdentity,
    });
    setTimeout(() => {
      this.setState({
        starClicked: !this.state.starClicked,
        starClickedStudent: null,
      });
    }, 5000);
  };

  smileyAnimate = (studentIdentity) => {
    this.setState({
      smileyClicked: true,
      smileyClickedStudent: studentIdentity,
    });
    setTimeout(() => {
      this.setState({
        smileyClicked: false,
        smileyClickedStudent: null,
      });
    }, 5000);
  };

  thumbsUpAnimate = (studentIdentity) => {
    this.setState({
      thumbsUpClicked: true,
      thumbsUpClickedStudent: studentIdentity,
    });
    setTimeout(() => {
      this.setState({
        thumbsUpClicked: false,
        thumbsUpClickedStudent: null,
      });
    }, 5000);
  };

  clappingAnimate = (studentIdentity) => {
    this.setState({
      clappingClicked: true,
      clappingClickedStudent: studentIdentity,
    });
    setTimeout(() => {
      this.setState({
        clappingClicked: false,
        clappingClickedStudent: null,
      });
    }, 5000);
  };

  openAllScreen = () => {
    this.setState({
      selected: 1,
    });
  };

  openWhiteBoard = () => {
    this.setState({
      selected: 3,
    });
    this.props.onWhiteBoard();
  };
  openMyScreen = () => {
    this.setState({
      selected: 2,
    });
    this.props.onMyScreen();
  };

  resumeLessonPdf = (objId) => {
    //alert(localStorage.getItem(LAST_INDEX_WHITE_BOARD));
    const lastPageIndex = parseInt(
      localStorage.getItem(LAST_INDEX_WHITE_BOARD)
    );
    console.log("lastPageIndex", lastPageIndex);
    if (objId != 3) return;
    if (this.state.selectedPdfUrl != "") {
      this.setState({
        selected: 3,
        selectedPdfIndex: lastPageIndex,
      });
      this.props.onWhiteBoard(
        JSON.stringify(this.state.selectedPdfUrl) + "|" + lastPageIndex
      );
    }
  };

  openLessonPdf = (lessonId, pdfData) => {
    if (pdfData.length == 0) {
      this.setState({
        selected: 15,
        selectedPdfUrl: "",
      });

      this.props.onWhiteBoard([]);
      return;
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");

    if (pdfData.type == "ggb") {
      storeGeogebraLog(liveClassID, "", pdfData.id);
    } else {
      try {
        storeVideoLog(liveClassID, "", pdfData.id);
      } catch (error) {
        console.log("error", error);
      }
    }

    this.setState(
      {
        selected: 3,
        selectedConceptId: lessonId,
        selectedPdfUrl: pdfData.images,
        selectedPdfIndex: 0,
        selectedSubItemId: pdfData.id,
        refreshTime: Math.round(new Date().getTime() / 1000),
        selectGGbDataInConceptsList: pdfData,
      },
      () => {
        console.log("LessonId", lessonId);
        console.log("PdfId", pdfData.id);
      }
    );

    // console.log("sassasasasa", this.state.selectedPdfUrl);

    if (this.state.selected != 3) {
      this.setState({
        selected: 3,
        selectedConceptId: lessonId,
        selectedPdfUrl: pdfData.images,
        selectedPdfIndex: 0,
        selectedSubItemId: pdfData.id,
        selectGGbDataInConceptsList: pdfData,
        refreshTime: Math.round(new Date().getTime() / 1000),
      });
    } else {
      if (pdfData.type == "ggb") {
        this.setState(
          {
            selected: 1,
          },
          () => this.refreshGGbLinks(pdfData)
        );
      } else {
        this.setState(
          {
            selected: 1,
          },
          () => this.refreshWhiteBoard(pdfData.images)
        );
      }
    }
    if (pdfData.type == "ggb") {
      this.props.onGeoGebraLinks(JSON.stringify(pdfData));
    } else {
      this.props.onWhiteBoard(JSON.stringify(pdfData.images));
    }
  };

  refreshWhiteBoard = (pdfImages) => {
    this.setState({
      selected: 3,
      selectedPdfUrl: pdfImages,
      selectedPdfIndex: 0,
      selectGGbDataInConceptsList: null,
      refreshTime: Math.round(new Date().getTime() / 1000),
    });
  };

  refreshGGbLinks = (pdfImages) => {
    this.setState({
      selected: 3,
      selectedPdfUrl: null,
      selectGGbDataInConceptsList: pdfImages,
      selectedPdfIndex: 0,
      refreshTime: Math.round(new Date().getTime() / 1000),
    });
  };

  setResumeVideoTime = (resTime) => {
    console.log("Setting Video time", resTime);
    this.setState({
      nowPlayingVideoTime: resTime,
    });
  };

  checkplayingornot = (elem) => {
    this.setState({
      nowPlayingVideoStart: elem,
    });
  };

  resumeVideoforchild = () => {};

  resumePlayVideo = () => {
    console.log("Now Playing Time", this.state.nowPlayingVideoTime);
    this.setState({
      selected: 4,
    });
    this.props.onResumePlayVideo(
      this.state.selectedVideoUrl,
      this.state.nowPlayingVideoTime
    );
    this.hideMenus();
  };

  openPlayVideo = (lessonId, listVideo) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");

    storeVideoLog(liveClassID, "", listVideo.id);
    const videoURL = listVideo.url;
    this.setState({
      selected: 4,
      selectedConceptId: lessonId,
      selectedVideoUrl: videoURL,
      selectedSubItemId: listVideo.id,
      nowPlayingVideo: listVideo.name,
    });
    this.hideMenus();
    this.props.onPlayVideo(videoURL);
  };

  randomfn = () => {
    this.setState({
      selected: 1,
    });
  };

  changeStatefunction = () => {
    this.props.closeSpeedMath();
  };

  openSpeedMath = (level) => {
    console.log("SPeed math level ", level);

    this.setState({
      selectedSpeedMathLevel: level,
      selected: 5,
      speedMathScoreDataItem: [],
    });
  };

  runSpeedMath = (studentGameId, studentPlayMode, game_level) => {
    if (game_level != this.state.selectedSpeedMathLevel) {
      this.props.closeSpeedMath(
        this.state.selectedSpeedMathLevel,
        studentGameId,
        studentPlayMode
      );
    }
    this.props.onSpeedMath(
      this.state.selectedSpeedMathLevel,
      studentGameId,
      studentPlayMode
    );
  };

  openQuizPlay = async (
    conceptId,
    tagId,
    quizLevel,
    conceptName,
    conceptTag,
    logicalZoneTrue = ""
  ) => {
    this.setState({ logicalZoneTrue: logicalZoneTrue });
    if (tagId == "") {
      return;
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    var quizFormatUrl =
      baseURL2 +
      "app_teachers/start_practice?live_class_id=" +
      liveClassID +
      "&tag_id=" +
      tagId +
      "&level=" +
      quizLevel;

    this.setState({
      mounting: false,
      waitingState: true,
      quizObj: false,
    });

    liveOnlineQuizStartQuestion(quizFormatUrl)
      .then((res) => {
        console.log("response pretest", res);
        this.setState({
          selected: 6,
          selectedConceptId: conceptId,
          selectedTagId: tagId,
          selectedQuizLevel: quizLevel,
          conceptName: conceptName,
          conceptTag: conceptTag,
          quizApiCall: false,
          mounting: true,
          errorState: false,
          waitingState: false,
          practiceId: res?.data?.live_class_practice_id,
          quizObj: res?.data || {},
          mathZoneQuizLevel: quizLevel,
          startQuizforchild: true,
        });

        this.props.onPlayQuiz({
          selected: 6,
          selectedTagId: tagId,
          selectedQuizLevel: quizLevel,
          mounting: true,
          errorState: false,
          waitingState: false,
          practiceId: res?.data?.live_class_practice_id,
          quizObj: res?.data || {},
          conceptName: conceptName,
          conceptTag: conceptTag,
          logicalZoneTrue: logicalZoneTrue,
          mathZoneQuizLevel: quizLevel,
        });
        // this.props.updatestartnowbuttonforchild(true);
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          selected: 6,
          selectedConceptId: conceptId,
          selectedTagId: tagId,
          selectedQuizLevel: quizLevel,
          quizApiCall: false,
          mounting: true,
          errorState: true,
          waitingState: false,
          practiceId: "",
          quizObj: false,
        });
        this.props.onPlayQuiz({
          selected: 6,
          selectedTagId: tagId,
          selectedQuizLevel: quizLevel,
          mounting: true,
          errorState: true,
          waitingState: false,
          practiceId: "",
          quizObj: false,
        });
      });
    this.hideMenus();

    if (this.quizScreenElement.current != null) {
      try {
        this.quizScreenElement.current.onLoadQuiz();
      } catch (e) {
        console.log();
      }
    }

    if (this.state.logicZoneToggle) {
      let logicZoneid = setTimeout(() => {
        let x = document.getElementsByClassName("d-flex flex-column")[5];
        x.getElementsByTagName("label")[0].className =
          "disabled btn btn-default btn-block";
        x.getElementsByTagName("img")[0].src =
          "/static/media/Images-v2/PlayQuiz.svg";
        let y = document.getElementsByClassName("d-flex flex-column")[6];
        y.getElementsByTagName("label")[0].className =
          "disabled active btn btn-default btn-block";
        y.getElementsByTagName("img")[0].src = "/static/media/Logic-zone.svg";

        clearTimeout(logicZoneid);
      }, 0);
    } else {
      let logicZoneid = setTimeout(() => {
        let x = document.getElementsByClassName("d-flex flex-column")[5];
        x.getElementsByTagName("label")[0].className =
          "disabled active btn btn-default btn-block";
        x.getElementsByTagName("img")[0].src = "/static/media/PlayQuiz.svg";
        let y = document.getElementsByClassName("d-flex flex-column")[6];
        y.getElementsByTagName("label")[0].className =
          "disabled btn btn-default btn-block";
        y.getElementsByTagName("img")[0].src =
          "/static/media/Images-v2/LogicZone.svg";

        clearTimeout(logicZoneid);
      }, 0);
    }
  };

  openPrePostTestPlay = async (sub_concept_id, test_type, concept_name) => {
    const { videoRoom } = this.props;

    this.setState({
      prepostMouting: false,
    });

    setTimeout(() => {
      this.setState({
        prepostMouting: true,
      });
    }, 0);

    let identity = videoRoom.localParticipant.identity;

    if (sub_concept_id == "") {
      return;
    }

    if (identity == "tutor") {
      if (test_type == "pre-post") {
        test_type = "pre_test";
      } else {
        test_type = "post_test";
      }
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let student_id = params.get("userID");
    let liveClassID = params.get("liveClassID");

    if (this.props?.students_arr.length > 0 && identity === "tutor") {
      student_id = this.props?.students_arr[0].id;
    }

    var quizFormatUrl =
      baseURL2 +
      "app_teachers/start_pre_post_test?student_id=" +
      student_id +
      "&test_type=" +
      test_type +
      "&sub_concept_id=" +
      sub_concept_id +
      "&live_class_id=" +
      liveClassID;

    this.setState({
      mounting: false,
      waitingState: true,
      quizObj: false,
      prepostSubConceptId: sub_concept_id,
      prepostTestType: test_type,
      prepostConceptName: concept_name,
    });
    if (identity === "tutor") {
      let allStudentIds = this.props?.students_arr;
      for (let item of allStudentIds) {
        let quizFormatUrl =
          baseURL2 +
          "app_teachers/start_pre_post_test?student_id=" +
          item.id +
          "&test_type=" +
          test_type +
          "&sub_concept_id=" +
          sub_concept_id +
          "&live_class_id=" +
          liveClassID;
        let data = await PrePostStartTest(quizFormatUrl);
        if (data?.data?.status) {
          item.pre_post_test_id = data?.data?.pre_post_test_id;
        }
      }

      this.setState({
        allStudentPrePostIds: [...allStudentIds],
      });
    }

    PrePostStartTest(quizFormatUrl)
      .then((res) => {
        console.log("StartPrePostTest res", res.data);
        if (res.data.status) {
          console.log("StartPrePostTest status true");
          this.setState({
            selected: 16,
            selectedConceptId: "",
            selectedTagId: "",
            selectedQuizLevel: "",
            conceptName: "",
            conceptTag: "",
            quizApiCall: false,
            mounting: true,
            errorState: false,
            waitingState: false,
            practiceId: res?.data?.live_class_practice_id || "",
            quizObj: res?.data || {},
            mathZoneQuizLevel: "",
            startQuizforchild: true,
            pre_post_test_id: res?.data?.pre_post_test_id || "",
            test_type: test_type,
          });

          identity === "tutor" &&
            this.props.startPrePostTest({
              sub_concept_id,
              test_type,
              concept_name,
            });
        }
      })
      .catch((e) => {
        this.setState({
          selected: 16,
          selectedConceptId: "",
          selectedTagId: "",
          selectedQuizLevel: "",
          conceptName: "",
          conceptTag: "",
          quizApiCall: false,
          mounting: true,
          errorState: true,
          waitingState: false,
          practiceId: res?.data?.live_class_practice_id || "",
          quizObj: false,
          mathZoneQuizLevel: "",
          startQuizforchild: true,
          pre_post_test_id: res?.data?.pre_post_test_id || "",
          test_type: test_type,
        });
        console.log("error", e);
      });
    this.hideMenus();

    if (this.quizScreenElement.current != null) {
      try {
        this.quizScreenElement.current.onLoadQuiz();
      } catch (e) {
        console.log();
      }
    }
  };

  openHomeWorkResponse = async (obj) => {
    let {
      studentId,
      currentQuestion,
      studentName,
      displayHomeWorkQuestion,
      quizId,
      homeWorkId,
      fetchAgain,
    } = obj;
    console.log(obj);
    this.props.openHomeWorkResponse({
      studentId,
      currentQuestion,
      studentName,
      miscellaneousId: this.state.miscellaneousId,
      displayHomeWorkQuestion,
      quizId,
      homeWorkId,
      fetchAgainHomeWorkQuestion: fetchAgain
        ? (Number(this.state.fetchAgainHomeWorkQuestion) || 1) + 1
        : false,
    });
  };
  openFlagQuestion = (
    subId,
    level,
    conceptName,
    tagName,
    conceptId,
    tagId,
    showFlagQuestion
  ) => {
    this.onSetFlagQuestionState({
      subId,
      level,
      conceptName,
      conceptId,
      tagName,
      tagId,
      showFlagQuestion: showFlagQuestion || false,
    });
    this.props.openFlagQuestion({
      subId,
      level,
      conceptName,
      conceptId,
      tagName,
      tagId,
      showFlagQuestion: showFlagQuestion || false,
    });
  };

  setCurrentSelectedStudentId = (id) => {};

  swithTabPrePostTestTeacher = (student_id) => {
    let quizFormatUrl =
      baseURL2 +
      "app_teachers/start_pre_post_test?student_id=" +
      student_id +
      "&test_type=" +
      this.state.test_type +
      "&sub_concept_id=" +
      this.state.prepostSubConceptId +
      "&live_class_id=" +
      this.state.liveClassIdWhenJoining;

    this.setState({
      mounting: false,
      waitingState: true,
      quizObj: false,
      currentSelectedStudentId: student_id,
    });

    PrePostStartTest(quizFormatUrl)
      .then((res) => {
        this.setState({
          selected: 16,
          selectedConceptId: "",
          selectedTagId: "",
          selectedQuizLevel: "",
          conceptName: "",
          conceptTag: "",
          quizApiCall: false,
          mounting: true,
          errorState: false,
          waitingState: false,
          practiceId: res?.data?.live_class_practice_id || "",
          quizObj: res?.data || {},
          mathZoneQuizLevel: "",
        });
      })
      .catch((e) => {
        console.log("error", e);
      });
    this.hideMenus();

    if (this.quizScreenElement.current != null) {
      try {
        this.quizScreenElement.current.onLoadQuiz();
      } catch (e) {
        console.log();
      }
    }

    console.log("quizFormatUrl swithTabPrePostTestTeacher", quizFormatUrl);
  };

  handleFlagQuestionChange = (val, fetchAgain) => {
    this.props.handleFlagQuestionChange({
      value: val,
      fetchAgain: fetchAgain
        ? (Number(this.state.flagQuestionMarksAsCompleted) || 0) + 1
        : fetchAgain,
    });
  };
  onSetFlagQuestionState = ({
    subId,
    level,
    conceptName,
    conceptId,
    tagId,
    tagName,
    showFlagQuestion,
  }) => {
    this.setState({
      selected: 12,
      miscellaneousId: subId,
      flagQuestionLevel: level,
      conceptName: conceptName,
      conceptTag: tagName,
      selectedConceptId: conceptId,
      selectedTagId: tagId,
      showFlagQuestion: showFlagQuestion,
      currentFlagQuestion: 0,
    });
  };
  openPdf = () => {
    this.setState({
      selected: 9,
    });
    //   this.props.onMyScreen();
  };

  addStarToStudent = (studentIdentity) => {
    const dyanmic_star_var = studentIdentity + "_star";
    var prevStar = this.state[dyanmic_star_var];
    if (this.state[dyanmic_star_var] == undefined) {
      prevStar = 1;
    } else {
      prevStar = prevStar + 1;
    }

    this.setState({
      [dyanmic_star_var]: prevStar,
    });
  };

  addSmileyToStudent = (studentIdentity) => {
    const dyanmic_smiley_var = studentIdentity + "_smiley";
    var prevSmiley = this.state[dyanmic_smiley_var];
    if (this.state[dyanmic_smiley_var] == undefined) {
      prevSmiley = 1;
    } else {
      prevSmiley = prevSmiley + 1;
    }

    this.setState({
      [dyanmic_smiley_var]: prevSmiley,
    });
  };

  addThumbsUpToStudent = (studentIdentity) => {
    const dyanmic_thumbsUp_var = studentIdentity + "_thumbsUp";
    var prevThumbsUp = this.state[dyanmic_thumbsUp_var];
    if (this.state[dyanmic_thumbsUp_var] == undefined) {
      prevThumbsUp = 1;
    } else {
      prevThumbsUp = prevThumbsUp + 1;
    }

    this.setState({
      [dyanmic_thumbsUp_var]: prevThumbsUp,
    });
  };

  addClappingToStudent = (studentIdentity) => {
    const dyanmic_clapping_var = studentIdentity + "_clapping";
    var prevClapping = this.state[dyanmic_clapping_var];
    if (this.state[dyanmic_clapping_var] == undefined) {
      prevClapping = 1;
    } else {
      prevClapping = prevClapping + 1;
    }

    this.setState({
      [dyanmic_clapping_var]: prevClapping,
    });
  };

  sendStarToStudent = (studentIdentity) => {
    if (this.lottieAnimationDelayRef.current) return;
    this.lottieAnimationThrottling();
    console.log(
      "Send Star Identity ",
      this.props.videoRoom.localParticipant.identity
    );

    if (this.props.videoRoom.localParticipant.identity != "tutor") return;

    this.addStarToStudent(studentIdentity);

    this.props.onSendStarToStudent(studentIdentity);

    this.setState({
      starAnimation: true,
      starClicked: true,
      starClickedStudent: studentIdentity,
      countStar: this.state.countStar + 1,
      enableStarButton: false,
    });

    let id = setTimeout(
      function () {
        this.setState({
          starAnimation: false,
          starClicked: false,
          starClickedStudent: null,
          enableStarButton: true,
        });
        clearTimeout(id);
      }.bind(this),

      4000
    );
  };

  sendSmileyToStudent = (studentIdentity) => {
    if (this.lottieAnimationDelayRef.current) return;
    this.lottieAnimationThrottling();
    console.log(
      "Send Smiley Identity ",
      this.props.videoRoom.localParticipant.identity
    );

    if (this.props.videoRoom.localParticipant.identity != "tutor") return;

    this.addSmileyToStudent(studentIdentity);

    this.props.onSendSmileyToStudent(studentIdentity);

    this.setState({
      smileyAnimation: true,
      smileyClicked: true,
      smileyClickedStudent: studentIdentity,
    });

    let id = setTimeout(() => {
      this.setState({
        smileyAnimation: false,
        smileyClicked: false,
        smileyClickedStudent: null,
      });
      clearTimeout(id);
    }, 3000);
  };
  lottieAnimationThrottling = () => {
    this.lottieAnimationDelayRef.current = setTimeout(() => {
      clearTimeout(this.lottieAnimationDelayRef.current);
      this.lottieAnimationDelayRef.current = null;
    }, lottieAnimationDelay);
  };
  sendClappingToStudent = (studentIdentity) => {
    if (this.lottieAnimationDelayRef.current) return;
    this.lottieAnimationThrottling();
    console.log(
      "Send Clapping Identity ",
      this.props.videoRoom.localParticipant.identity
    );

    if (this.props.videoRoom.localParticipant.identity != "tutor") return;

    this.addClappingToStudent(studentIdentity);

    this.props.onSendClappingToStudent(studentIdentity);

    this.setState({
      clappingAnimation: true,
      clappingClicked: true,
      clappingClickedStudent: studentIdentity,
    });

    setTimeout(() => {
      this.setState({
        clappingAnimation: false,
        clappingClicked: false,
        clappingClickedStudent: null,
      });
    }, 3000);
  };

  sendThumbsUpToStudent = (studentIdentity) => {
    if (this.lottieAnimationDelayRef.current) return;
    this.lottieAnimationThrottling();
    console.log(
      "Send ThumbsUp Identity ",
      this.props.videoRoom.localParticipant.identity
    );

    if (this.props.videoRoom.localParticipant.identity != "tutor") return;

    this.addThumbsUpToStudent(studentIdentity);

    this.props.onSendThumbsUpToStudent(studentIdentity);

    this.setState({
      thumbsUpAnimation: true,
      thumbsUpClicked: true,
      thumbsUpClickedStudent: studentIdentity,
    });

    setTimeout(() => {
      this.setState({
        thumbsUpAnimation: false,
        thumbsUpClicked: false,
        thumbsUpClickedStudent: null,
      });
    }, 3000);
  };

  muteRemoteStudent = (studentIdentity) => {
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    console.log(remoteParticipants.length);

    if (videoRoom.localParticipant.identity == studentIdentity) {
      this.props.onMuteClick();
    }
    const dyanmic_mute_var = studentIdentity + "_mute";
    this.setState({
      [dyanmic_mute_var]: true,
    });

    this.props.onMuteRemoteStudent(studentIdentity);
  };

  isValidUrl(string) {
    try {
      new URL(string);

      return true;
    } catch (err) {
      return false;
    }
  }

  appendMessage = (message) => {
    const result = this.isValidUrl(message);

    if (result) {
      return true;
    } else {
      return false;
    }
  };

  dropChatMessage = () => {
    const { videoRoom } = this.props;

    this.chatMessageUpdate(
      videoRoom.localParticipant.identity,
      this.state.textareaValue
    );
    this.props.onDropChatMessage(
      videoRoom.localParticipant.identity,
      this.state.textareaValue
    );
  };

  chatMessageUpdate = (id, msg) => {
    const { newMessageCounter, isChatBoxOpen } = this.state;
    const { videoRoom } = this.props;

    var senderID = "";

    const newelement = {
      identity: id,
      message: msg,
    };
    this.setState({
      chatMessageArray: [...this.state.chatMessageArray, newelement],
    });
    this.scrollBottom();

    if (id == videoRoom.localParticipant.identity) {
      this.setState({
        textareaValue: "",
      });
    } else {
      if (!isChatBoxOpen) {
        this.setState({
          newMessageCounter: newMessageCounter + 1,
        });
      }
    }
  };

  startStudentScreenShare = (studentIdentity) => {
    const dynamic_screen_var = studentIdentity + "_screen";
    this.setState({
      [dynamic_screen_var]: true,
    });
    console.log("Dynamic Screen", "True");

    this.props.studentScreenShare(studentIdentity);
  };

  stopStudentScreenShare = (studentIdentity) => {
    console.log("Student Identity", studentIdentity);
    const dynamic_screen_var = studentIdentity + "_screen";
    this.setState({
      [dynamic_screen_var]: false,
    });
    console.log("Dynamic Screen", "False");

    this.props.studentScreenShare(studentIdentity);
  };

  // this.state.muteAllState

  unMuteRemoteStudent = (studentIdentity) => {
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    console.log(remoteParticipants.length);
    console.log(videoRoom.localParticipant.identity);
    if (videoRoom.localParticipant.identity == studentIdentity) {
      this.props.onUnMuteClick();
    }
    const dyanmic_mute_var = studentIdentity + "_mute";
    this.setState({
      [dyanmic_mute_var]: false,
    });

    this.props.onUnMuteRemoteStudent(studentIdentity);
  };
  unMuteClickBtn = (studentIdentity) => {
    if (this.mutedUnmutedRef.current) {
      // alert('please wait...')
      return;
    }
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    this.unMuteRemoteStudent(studentIdentity);
    this.mutedUnmutedRef.current = setTimeout(() => {
      clearTimeout(this.mutedUnmutedRef.current);
      this.mutedUnmutedRef.current = null;
    }, audioDelayDuration);

    if (
      studentIdentity === "tutor" &&
      remoteParticipants.length !== 0 &&
      this?.props?.isMuteEnabled === true
    ) {
      this.onLocalParticipantMuted(false);
      this.props.setIsMuteEnabled(false);
    }
  };

  muteClickBtn = (studentIdentity) => {
    if (this.mutedUnmutedRef.current) {
      // alert('please wait...')
      return;
    }
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    this.muteRemoteStudent(studentIdentity);
    this.mutedUnmutedRef.current = setTimeout(() => {
      clearTimeout(this.mutedUnmutedRef.current);
      this.mutedUnmutedRef.current = null;
    }, audioDelayDuration);
    if (
      studentIdentity === "tutor" &&
      remoteParticipants.length !== 0 &&
      this?.props?.isMuteEnabled === false
    ) {
      this.onLocalParticipantMuted(true);
      this.props.setIsMuteEnabled(true);
    }
  };

  refreshQuizPlay = async (practiceId) => {
    console.log("hello there hello there");

    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");

    var today = Math.round(new Date().getTime() / 1000);

    try {
      var quizFormatUrl =
        baseURL2 +
        "app_teachers/next_question?live_class_id=" +
        liveClassID +
        "&live_class_practice_id=" +
        practiceId +
        "&tag_id=" +
        this.state.selectedTagId +
        "&level=" +
        this.state.selectedQuizLevel;
      this.setState({ mounting: false, waitingState: true });
      let result = await updateLiveClassQuizQuestion(quizFormatUrl);

      result = result?.data || {};
      this.setState({
        quizApiCall: false,
        mounting: false,
        errorState: result?.status == false ? true : false,
        waitingState: false,
        quizObj: result,
      });
      let id = setTimeout(() => {
        this.setState({
          mounting: true,
          quizObj: result,
          errorState: result?.status == false ? true : false,
        });
        clearTimeout(id);
      }, 0);
      this.props.onPlayQuiz({
        selected: 6,
        quizApiCall: false,
        mounting: true,
        errorState: false,
        waitingState: false,
        quizObj: result,
        conceptName: this.state.conceptName,
        conceptTag: this.state.conceptTag,
        mathZoneQuizLevel: this.state.mathZoneQuizLevel,
        logicalZoneTrue: this.state.logicalZoneTrue,
      });
    } catch (e) {
      this.setState({
        quizApiCall: false,
        mounting: false,
        errorState: true,
        waitingState: false,
        quizObj: false,
      });
      this.props.onPlayQuiz({
        selected: 6,
        quizApiCall: false,
        mounting: false,
        errorState: false,
        waitingState: false,
        quizObj: false,
        mathZoneQuizLevel: this.state.mathZoneQuizLevel,
      });
    }
    // this.setState({mounting:false})

    //   let y=[quizFormatUrl]
    //   this.setState({
    //   selectedQuizUrl: [...y],
    //   updateQuestionState:true,
    //   quizApiCall:true,
    //   mounting:false
    // });

    // if (dataResponse.data.quiz_completed) {
    //   if (this.quizScreenElement.current != null)
    //     this.quizScreenElement.current.onEndQuiz();
    //   this.props.onEndQuiz(dataResponse.data.live_class_practice_id);
    // } else
    //   this.props.onPlayQuiz(
    //     this.state.selectedConceptId,
    //     this.state.selectedTagId,
    //     this.state.selectedQuizLevel
    //   );
  };

  refreshPrePostTestQuizPlay = async (skipped_review) => {
    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );

    try {
      var quizFormatUrl =
        baseURL2 +
        `app_teachers/skip_pre_post_test?pre_post_test_id=${this.state.pre_post_test_id}&skipped_review=${skipped_review}`;
      this.setState({ mounting: false, waitingState: true });
      let result = await updatePrePostTestQuizQuestion(quizFormatUrl);
      if (result.data.status) {
        console.log("Status is true in skip test");
        result = result?.data || {};

        this.setState({
          quizApiCall: false,
          mounting: false,
          errorState: result?.status == false ? true : false,
          waitingState: false,
          quizObj: result,
        });
        let id = setTimeout(() => {
          this.setState({
            mounting: true,
            quizObj: result,
            errorState: result?.status == false ? true : false,
          });
          clearTimeout(id);
        }, 0);

        this.props.stundentClickedNextPrePostTestQue({
          pre_post_test_id: this.state.pre_post_test_id,
          student_id: this.state.userIdWhenJoining,
          skipped_review: skipped_review,
        });
      }
    } catch (e) {
      this.setState({
        quizApiCall: false,
        mounting: false,
        errorState: true,
        waitingState: false,
        quizObj: false,
      });
    }
  };

  nextPrePostTestQuizPlay = async (result) => {
    console.log("result nextPrePostTestQuizPlay", result);
    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );

    this.setState({ mounting: false, waitingState: true });
    let skipped_review = result.skipped_review;
    result = result?.data || {};
    this.setState({
      quizApiCall: false,
      mounting: false,
      errorState: result?.status == false ? true : false,
      waitingState: false,
      quizObj: result,
    });
    let id = setTimeout(() => {
      this.setState({
        mounting: true,
        quizObj: result,
        errorState: result?.status == false ? true : false,
      });
      clearTimeout(id);
    }, 0);

    this.props.stundentClickedNextPrePostTestQue({
      pre_post_test_id: this.state.pre_post_test_id,
      student_id: this.state.userIdWhenJoining,
      skipped_review: skipped_review,
    });
  };

  teacherCallingCurrentQuestionPrePostTest = async (
    pre_post_test_id,
    student_id,
    skipped_review
  ) => {
    console.log("skipped_review", skipped_review);

    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );

    try {
      var quizFormatUrl =
        baseURL2 +
        `app_teachers/current_pre_post_question?pre_post_test_id=${pre_post_test_id}&skipped_review=${skipped_review}`;
      this.setState({ mounting: false, waitingState: true });
      let result = await updateCurrentQuestionTeacherPrePostTest(quizFormatUrl);

      console.log("Current question", result);
      if (result.data.status) {
        result = result?.data || {};
        this.setState({
          quizApiCall: false,
          mounting: false,
          errorState: result?.status == false ? true : false,
          waitingState: false,
          quizObj: result,
        });
        let id = setTimeout(() => {
          this.setState({
            mounting: true,
            quizObj: result,
            errorState: result?.status == false ? true : false,
          });
          clearTimeout(id);
        }, 0);
      }
    } catch (e) {
      this.setState({
        quizApiCall: false,
        mounting: false,
        errorState: true,
        waitingState: false,
        quizObj: false,
      });
      this.props.onPlayQuiz({
        selected: 16,
        quizApiCall: false,
        mounting: false,
        errorState: false,
        waitingState: false,
        quizObj: false,
        mathZoneQuizLevel: this.state.mathZoneQuizLevel,
      });
    }
  };
  //showResultPage
  /*my coding */

  resettingMathZoneResult = () => {
    this.setState({ responseUrlAnswer: [] });
    let temp = {
      totalQuestion: 0,
      correctAnswer: 0,
    };
    this.setState({
      onlineQuizMathzoneScore: {
        ...temp,
      },
      allStudentScore: [],
    });
    this.setState({ studentMathZoneResponseList: {} });
  };

  mathZoneScoreUpdate = (val1, val2) => {
    // onlineQuizMathzoneScore:{
    //   totalQuestion:0,
    //   correctAnswer:0
    // },
    let { onlineQuizMathzoneScore } = this.state;
    onlineQuizMathzoneScore = {
      totalQuestion: onlineQuizMathzoneScore.totalQuestion + val1,
      correctAnswer: onlineQuizMathzoneScore.correctAnswer + val2,
    };
    this.setState({ onlineQuizMathzoneScore: { ...onlineQuizMathzoneScore } });
  };
  sendStudentReportMathzone = (identity, checker) => {
    this.props.sendingReportToTeacher(
      this.state.onlineQuizMathzoneScore,
      identity
    );
  };
  studentResponseSenderMathZone = (
    identity,
    questionNo,
    isCorrect,
    htmlContent
  ) => {
    let temp = {
      [questionNo]: {
        isCorrect,
        htmlContent,
      },
    };
    this.props.onStudentResponseReceivedMathzone(identity, { ...temp });
  };
  /* my coding */
  startPracticeAction = () => {
    this.setState({
      studentMathZoneResponseList: {},
      logicalZoneTrue: "",
    });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    var quizFormatUrl =
      baseURL +
      "online_classes/start_practice?live_class_id=" +
      liveClassID +
      "&sub_concept_id=" +
      this.state.selectedConceptId +
      "&tag_id=" +
      this.state.selectedTagId +
      "&level=" +
      this.state.selectedQuizLevel;

    this.setState({
      selectedQuizUrl: quizFormatUrl,
    });
    setTimeout(this.startPracticeToStudents, 3000);
    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );
  };

  startPracticeToStudents = () => {
    this.setState({ logicalZoneTrue: "" });
    this.props.onPlayQuiz(
      this.state.selectedConceptId,
      this.state.selectedTagId,
      this.state.selectedQuizLevel,
      this.state.mathZoneQuizLevel
    );
  };
  /* Shape Activity */
  openActivity = (type, i) => {
    let notification = true;
    let search = window.location.search;
    let urlParams = new URLSearchParams(search);
    let liveClassID = urlParams.get("liveClassID");
    liveClassID = liveClassID?.trim();
    let keys = "activityNotification" + liveClassID;
    try {
      notification = JSON.parse(localStorage.getItem(keys));
      let startTime = Number(notification?.startTime) || 0;
      let currentTime = Date.now();
      let differenceTime = currentTime - startTime;
      if (differenceTime >= deadlineForActvity) {
        notification = true;
        startTime = currentTime;
        localStorage.setItem(
          keys,
          JSON.stringify({
            notification: true,
            startTime: currentTime,
          })
        );
      } else {
        notification = notification?.notification;
      }
    } catch (e) {
      let obj = {
        notification: true,
        startTime: Date.now(),
      };
      localStorage.setItem(keys, JSON.stringify(obj));
    }
    this.setState({
      selected: 11,
      selectedActivity: type,
      currentScreenName: `${type} Activity`,
      isCheckInActivity: !i,
      showActivityNotification: notification,
      sendActiveWhiteBoardData: [],
      mounting: false,
      isCheckOutActivityEnd: false,
      isCheckInActivityEnd: false,
      cicoApiData: {},
    });
    let id = setTimeout(() => {
      this.setState({ mounting: true });
    }, 0);
  };
  handleCaptureStudentScreen = (msg) => {
    if (msg === "TakefullScreenStudentPicture") {
      if (this.state.selected !== 1) {
        this.setState({ isShowConfirmationModal: false });
        this.setState({ confirmationForGoingAllScreen: true });
      } else {
        this.takeFullScreenShotComponent();
      }
    } else {
      this.takeStudentPictureComponent();
    }
    this.setState({ isShowConfirmationModal: false });
    this.setState({ screenShotCaptureButton: false });
    setTimeout(() => {
      this.setState({ screenShotCaptureButton: true });
    }, 7000);
  };
  redirectToAllScreen = () => {
    this.setState({
      confirmationForGoingAllScreen: false,
      allScreenScreenShot: true,
      selected: 1,
    });
  };
  takeFullScreenShotComponent = async () => {
    try {
      setTimeout(async () => {
        let img = await takeComponentScreenShot(this.props.componentRef);
        let arr = [];
        let blob = b64toBlob(img.split(";base64,")[1], "image/png");

        console.log(URL.createObjectURL(blob));
        arr.push({ data: blob, user: "all" });
        this.setState({ screenShotArray: arr, showScreenShotPreview: true });
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleShowScreenShotCaptureModal = () => {
    let ids = this.state.all_ids || [];
    ids = ids.filter((item) => !excludeParticipant?.includes(item));
    if (!ids.length) {
      alert("You can only capture the picture after student is joined.");
      return;
    }
    this.setState({
      isShowConfirmationModal: true,
    });
  };

  uploadResources = () => {
    this.setState({
      selected: 12,
    });
    // this.setState({
    //   showUploadResourcesModel: !this.state.showUploadResourcesModel,
    // });
  };

  uploadScreenShotToServer = async (index) => {
    try {
      let arr = [...this.state.screenShotArray];
      let arr2 = [...this.state.screenShotArray];
      let allIds = this.state.all_ids || [];

      arr2 = arr2.filter((_, i) => i !== index);
      if (arr2.length) {
        this.setState({ screenShotArray: arr2 });
      } else {
        this.setState({ screenShotArray: [], showScreenShotPreview: false });
      }
      if (arr[index]?.user != "all") {
        allIds = [this.state.all_ids[index]];
      }
      for (let i = 0; i < allIds.length; i++) {
        let id = allIds[i]?.split("-")[0];
        const bodyFormData = new FormData();
        bodyFormData.append("live_class_id", this.state.mLiveClassId);
        bodyFormData.append("user_id", id);
        bodyFormData.append("screenshot", arr[index]?.data, "image.png");
        await uploadStudentScreenShot(bodyFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  handleCloseScreenshotConfirmationModal = () => {
    this.setState({ isShowConfirmationModal: false });
  };
  takeStudentPictureComponent = async () => {
    try {
      let allIds = this.state.all_ids || [];
      let arr = [];
      for (let i = 0; i < allIds.length; i++) {
        let ids = allIds[i]?.split("-")[0];
        if (ids) {
          let img = await takeComponentScreenShot({
            current: document.querySelector(`.studentscreenshot-${ids}`),
          });
          let blob = b64toBlob(img.split(";base64,")[1], "image/png");
          arr.push({ data: blob, user: ids });
        }
      }
      this.setState({
        screenShotArray: arr,
        showScreenShotPreview: arr.length ? true : false,
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleRemoveScreenShotData = (index) => {
    let flag = true;

    let arr = this.state.screenShotArray || [];

    if (arr[index]?.user == "all") {
      arr = [];
      flag = false;
    } else {
      arr = arr.filter((_, i) => i != index);
    }
    if (arr.length == 0) {
      flag = false;
    }
    this.setState({ screenShotArray: [...arr], showScreenShotPreview: flag });
  };
  handleCloseActivity = (val) => {
    this.closeActivityMethod(val);

    this.props.handleCloseActivity(val);
  };
  closeActivityMethod = (val) => {
    console.log(val);
    if (val?.startsWith("feelingchart")) {
      this.setState({ showEndActivityAnimation: val });
      this.resetActivityLocalStorage();
      return;
    }
    this.setState({ showEndActivityAnimation: true });
    let id = setTimeout(
      () => {
        clearTimeout(id);
        this.setState({ showEndActivityAnimation: false });
        this.resetActivityLocalStorage();
      },
      val === "feelingchart" ? 0 : 5000
    );
  };
  handleShapeChallengeStudentResponseSaved = () => {
    console.log("student side");
    this.setState({ isStudentShapeChallengeResponseSaved: true });
    this.props.handleShapeChallengeStudentResponseSaved();
  };
  handleShowActivityNotification = () => {
    let search = window.location.search;
    let urlParams = new URLSearchParams(search);
    let liveClassID = urlParams.get("liveClassID");
    liveClassID = liveClassID?.trim();
    let keys = "activityNotification" + liveClassID;
    let obj = JSON.parse(localStorage.getItem(keys));
    localStorage.setItem(keys, JSON.stringify({ ...obj, notification: false }));
    this.setState({ showActivityNotification: false });
    this.props.handleShowActivityNotification({
      showActivityNotification: false,
    });
  };
  handleSendTeacherActivityResponse = () => {
    this.setState({
      teacherActivityResponseSaved:
        this.state?.teacherActivityResponseSaved === true ? 1 : true,
    });

    this.props?.handleSendTeacherActivityResponse();
  };

  resetActivityLocalStorage = () => {
    let search = window.location.search;
    let urlParams = new URLSearchParams(search);
    let liveClassID = urlParams.get("liveClassID");
    liveClassID = liveClassID?.trim();
    let keys1 = "activityNotification" + liveClassID;
    let keys2 = "";
    let keys3 =
      "activityTimer" +
      (this.state.isCheckInActivity ? "Check-In" : "Check-Out") +
      liveClassID;
    let keys4 =
      "AffirmationActivityTimer" +
      (this.state.isCheckInActivity ? "Check-in" : "Check-out") +
      liveClassID;
    let keys5 =
      "feelingChart" +
      (this.state.isCheckInActivity ? "check-in" : "check-out") +
      liveClassID +
      "student";
    let keys6 =
      "feelingChart" +
      (this.state.isCheckInActivity ? "check-in" : "check-out") +
      liveClassID +
      "teacher";
    let keys7 =
      "selfaffirmation" +
      (this.state.isCheckInActivity ? "check-in" : "check-out") +
      liveClassID +
      "student";
    let keys8 =
      "shapechallenge" +
      (this.state.isCheckInActivity ? "check-in" : "check-out") +
      liveClassID +
      "student";
    localStorage.removeItem(keys1);
    localStorage.removeItem(keys2);
    localStorage.removeItem(keys3);
    localStorage.removeItem(keys4);
    localStorage.removeItem(keys5);
    localStorage.removeItem(keys6);
    localStorage.removeItem(keys7);
    localStorage.removeItem(keys8);
    this.setState({
      isStudentActivityEnd: true,
    });
  };
  sendShapeChallengeImage = (data) => {
    let obj = {
      identity: this.props?.videoRoom?.localParticipant?.identity,
      image: "dkdkdkddk",
    };
    this.setState({
      isActivityScreenShotCapture:
        this.state?.isActivityScreenShotCapture === true ? 1 : true,
    });
    this.props.sendShapeChallengeImage(obj);
  };
  sendActiveWhiteBoardData = (data) => {
    let obj = {
      identity: this.props?.videoRoom?.localParticipant?.identity,
      coordinates: data,
    };
    this.props.sendActiveWhiteBoardData(obj);
  };

  /* End Shape Activity */
  /* Affirmation Activity */
  onAffirmationCheckoutNextButton = (val) => {
    console.log(val);
    this.setState({
      showCheckoutAffirmationNextBtn: {
        val: val + 1,
      },
    });
    this.props.onAffirmationCheckoutNextButton({
      val: val + 1,
      cicoApiData: this.state.cicoApiData,
      isCheckInActivity: this.state.isCheckInActivity,
      currentScreenName: this.state.currentScreenName,
      selectedActivity: this.state.selectedActivity,
    });
  };
  handleChangeActivityPageNumber = (val) => {
    this.setState({
      storyBookPageNumber: Number(val) || 0,
    });

    this.props.changeAffirmationStoriesPageNumber(val);
  };
  sendSelectedAffirmationValue = (value) => {
    this.setState({
      affirmationActivityStudentResponse: Number(value) + 1,
      showAffirmationNextButton: true,
    });
    let obj = {
      identity: this.props?.videoRoom?.localParticipant?.identity,
      value: value,
    };
    this.props.sendSelectedAffirmationValue(obj);
  };
  onSendStoriesToStudent = () => {
    this.setState({
      showAffirmationStories: true,
      showAffirmationNextButton: false,
    });
    this.props.onSendStoriesToStudent({
      showAffirmationStories: true,
      cicoApiData: this.state.cicoApiData,
      isCheckInActivity: this.state.isCheckInActivity,
      currentScreenName: this.state.currentScreenName,
      selectedActivity: this.state.selectedActivity,
    });
  };
  handleShowPreviewImageAffrimation = (val) => {
    this.setState({
      isAffirmationPreviewImage: val,
    });
    this.props.handleShowPreviewImageAffrimation(val);
  };
  /* End Affirmation Activity */
  /*  Feeling Chart Activity  */
  onSendSelectedFeelingImg = (val) => {
    let keys =
      this.state?.selectedActivity === "Check In"
        ? "FeelingChartSelectedImg"
        : "FeelingChartSelectedImgCheckout";
    this.setState({
      [keys]: Number(val) + 1,
    });
    this.props.onSendSelectedFeelingImg(val);
  };
  sendCicoDataToStudent = ({ data, type, isCheckInActivity }) => {
    this.setState({
      cicoApiData: data,
    });
    this.props.openActivity({
      selected: 11,
      selectedActivity: type,
      currentScreenName: `${type} Activity`,
      isCheckInActivity: isCheckInActivity,
      showActivityNotification: true,
      sendActiveWhiteBoardData: [],
      cicoApiData: data,
    });
  };
  onSendOpenDropBox = (val) => {
    this.setState({
      feelingChartDropBox: true,
    });
    this.props.onSendOpenDropBox(val);
  };
  /* Ending Chart*/

  static propTypes = {
    videoRoom: videoRoomPropType.isRequired,
  };

  update = () => this.forceUpdate();

  subscribeDominantSpeaker = (room) => {
    room.on("dominantSpeakerChanged", (participant) => {
      console.log("Participant Name", participant.identity);
    });
  };

  submitDemoForm = () => {};

  callUploadResourceList = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    const response = await getUploadResourcesList(liveClassID);

    if (response.data.status) {
      this.setState({
        uploadedResources: response.data.resource_data,
      });

      console.log("response callUploadResourceList", response);
    }
  };

  getConceptList = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    const response = await getConceptList(liveClassID);

    if (response.data.status) {
      this.setState({
        concept_list: response.data.concept_list,
        logical_concept: response.data.logical_concepts,
        trial_pdfs: response.data.trial_pdfs,
      });
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let userID = params.get("userID");
      let liveClassID = params.get("liveClassID");
      submitErrorLog(userID, liveClassID, response.data.message, 0, 0);
    }
  };
  getFlagQuestionConceptList = async () => {
    // console.log("working,,,,");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    const { data } = await getFlagQuestionConceptList(liveClassID);
    // console.log(data);
    this.setState({
      flagQuestionConceptList: data?.flagged_concepts_data || [],
    });
  };
  hideRaiseHand(studentIdentity) {
    if (isTutorCheck(this.props.videoRoom.localParticipant.identity)) {
      this.props.hideRaiseHandStudent(studentIdentity);
      this.hideRaiseHandMessage(studentIdentity);
    }
  }

  hideRaiseHandMessage(studentIdentity) {
    const dyanmic_hand_var = studentIdentity + "_hand";

    this.setState({
      [dyanmic_hand_var]: false,
    });

    // if (this.state.selected == 1) {
    //   this.allScreenElement.current.hideRaiseHandLocal(studentIdentity);
    // }
    // else {
    //   const dyanmic_hand_var = studentIdentity + "_hand"

    //   this.setState({
    //     [dyanmic_hand_var]: false
    //   });
    //
    // }
  }

  showDemoForm() {
    this.setState({
      demoFlag: true,
    });
  }

  stopClassRecordingOnClassLeave() {
    console.log(
      "Stop Class Recording on class leave",
      this.screenRecordingElement
    );
    console.log(
      this.screenRecordingElement.current?.leaveClassStopRecording,
      "videoroom",
      typeof this.screenRecordingElement.current?.leaveClassStopRecording ===
        "function"
    );
    typeof this.screenRecordingElement.current?.leaveClassStopRecording ===
      "function" &&
      this.screenRecordingElement.current?.leaveClassStopRecording();
  }

  checkRecordingStatus() {
    return this.screenRecordingElement.current.checkRecordingStatus();
  }

  showParentDemoForm() {
    this.setState({
      selected: 5,
    });
  }

  changepenstrokesforchild = (elem) => {
    this.setState({
      changepenstrokesforChild: elem,
    });
  };

  changechildlessonpdf = (elem) => {
    console.log("ELEM", elem);
    this.setState({
      changechildlessonpdf: elem,
    });
  };

  onLocalParticipantMuted(isMuted) {
    const { videoRoom } = this.props;
    if (!isTutorCheck(videoRoom?.localParticipant?.identity)) return;
    // console.log(
    //   this.screenRecordingElement.current.localParticipantMutedStatus
    // );
    this.screenRecordingElement.current?.localParticipantMutedStatus(isMuted);
  }
  onSynchronizeMutedState = () => {
    let {
      videoRoom: { participants },
    } = this.props;
    participants?.forEach((item) => {
      if (!isTutorCheck(item?.identity)) {
        let audioTrack = mapToArray(item?.audioTracks);
        let temp = audioTrack[0];
        if (temp?.track?.isEnabled === false) {
          const dyanmic_mute_var = item?.identity + "_mute";
          this.setState({
            [dyanmic_mute_var]: true,
          });
        } else {
        }
      }
    });
  };
  updateRaiseHandMessage(message) {
    this.showRaiseHand(message);
    //if (videoRoom.localParticipant.identity == "tutor") {

    //this.allScreenElement.current.showRaiseHand(message);

    // }
  }

  showRaiseHand(studentIdentity) {
    const dyanmic_hand_var = studentIdentity + "_hand";

    this.setState(
      {
        [dyanmic_hand_var]: true,
      },
      () => {}
    );
  }

  showStar() {
    this.setState({
      starFlag: true,
    });
  }

  hideStar() {
    this.setState({
      starFlag: false,
    });
  }

  handleChangeTextarea = (event) => {
    this.setState({ textareaValue: event.target.value });
  };

  scrollBottom = () => {
    $(".chatbox-popup__main").animate(
      { scrollTop: $(document).height() },
      "slow"
    );
  };

  showMenus = (objectId) => {
    if (objectId === 4) {
      this.setState({ showMenus: true });
    } else if (objectId === 5) {
      this.setState({ showSpeedMathMenus: true });
    } else if (objectId === 7) {
      this.setState({ showLogicZone: true });
    } else if (objectId === 3) {
      this.setState({
        showLesson: true,
      });
    } else if (objectId === 11) {
      this.setState({ showMainActivity: true });
    } else if (objectId === 12) {
      this.setState({ showMiscellaneous: true });
    } else if (objectId === 16) {
      this.setState({ showPreTestMenus: true });
    } else {
      this.setState({ showQuizMenus: true });
    }
  };

  hideMenus = () => {
    this.setState({
      showMenus: false,
      showQuizMenus: false,
      showSpeedMathMenus: false,
      showLogicZone: false,
      showLesson: false,
      showMainActivity: false,
      showMiscellaneous: false,
      showPreTestMenus: false,
    });
  };

  muteAllStudents = () => {
    if (this.mutedUnmutedRef.current) return;
    this.mutedUnmutedRef.current = setTimeout(() => {
      clearTimeout(this.mutedUnmutedRef.current);
      this.mutedUnmutedRef.current = null;
    }, audioDelayDuration);
    if (this.state.muteAllState) {
      this.props.onUnMuteAllStudents();
    } else {
      this.props.onMuteAllStudents();
    }
    this.setState({
      muteAllState: !this.state.muteAllState,
    });
  };

  submitScreenShot = async (screenShot) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    console.log("Selected : ", this.state.selected);
    console.log(
      "Screen Shot data",
      "CONCEPT : " + this.state.selectedConceptId,
      "SUB ITEM " + this.state.selectedSubItemId,
      "SELECTED TAG " + this.state.selectedTagId
    );

    const bodyFormData = new FormData();
    bodyFormData.append("live_class_id", liveClassID);
    bodyFormData.append("user_id", userId);
    bodyFormData.append("screenshot", screenShot, "image.png");

    if (this.state.selected == 1)
      bodyFormData.append("from_page", "All Screen");

    if (this.state.selected == 2) bodyFormData.append("from_page", "My Screen");

    if (this.state.selected == 3) {
      bodyFormData.append("concept_resource_id", this.state.selectedSubItemId);
      bodyFormData.append("from_page", "Lesson PDF");
    }

    if (this.state.selected == 4) {
      bodyFormData.append("sub_concept_video_id", this.state.selectedSubItemId);
      bodyFormData.append("from_page", "Video");
    }
    if (this.state.selected == 5)
      bodyFormData.append("from_page", "Speed Math");

    if (this.state.selected == 6) {
      bodyFormData.append("sub_concept_id", this.state.selectedConceptId);
      bodyFormData.append("exercise_id", this.state.selectedTagId);
      bodyFormData.append("from_page", "Quiz");
    }

    // bodyFormData.append('concept_id', this.state.selectedConceptId);
    // bodyFormData.append('sub_item_id', this.state.selectedSubItemId);
    // bodyFormData.append('tag_id', this.state.selectedTagId);

    console.log(bodyFormData);
    //alert(this.state.selected);
    // console.log(this.state.selectedSubItemId);
    // console.log(bodyFormData);
    const response = await createLiveClassTicket(bodyFormData);

    // console.log(response);

    this.screenShotUploadStatus(response.data);

    this.setState({
      screenShotPrgress: false,
    });
  };

  screenShotUploadStatus = (response) => {
    if (response.status) alert("Your error has been reported");
    else
      alert(
        "Unable to send screen shot : " + response.message + " Please try again"
      );
  };

  // recordScreenShot = (stream) => {
  //   const canvas = this.canvas.current;
  //   let track = stream.getVideoTracks()[0];
  //   let capture = new ImageCapture(track);
  //   capture.grabFrame().then(bitmap => {
  //     // Stop sharing
  //     track.stop();

  //     // Draw the bitmap to canvas
  //     canvas.width = bitmap.width;
  //     canvas.height = bitmap.height;
  //     canvas.getContext('2d').drawImage(bitmap, 0, 0);
  //     var dataUrl = canvas.toDataURL("image/png");

  //     // Grab blob from canvas
  //     canvas.toBlob(blob => {
  //       // Do things with blob here
  //       console.log('output blob:', blob);

  //       this.submitScreenShot(blob);
  //     });
  //   });
  // }

  recordScreenShot = (stream) => {
    const canvas = this.canvas.current;

    setTimeout(() => {
      let track = stream.getVideoTracks()[0];
      let capture = new ImageCapture(track);
      capture.grabFrame().then((bitmap) => {
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;

        canvas.getContext("2d").drawImage(bitmap, 0, 0);

        track.stop();

        canvas.toBlob((blob) => {
          // Do things with blob here
          console.log("output blob:", blob);

          this.submitScreenShot(blob);
        });
      });
    }, 500);
  };

  takeScreenshot = () => {
    if (this.state.screenShotPrgress) return;
    this.setState({
      screenShotPrgress: true,
    });
    console.log(this.canvas.current);

    navigator.mediaDevices
      .getDisplayMedia()
      .then((stream) => {
        // Grab frame from stream
        this.recordScreenShot(stream);
      })
      .catch((e) =>
        this.setState({
          screenShotPrgress: false,
        })
      );
  };
  handleEndCheckInActivity = () => {
    this.setState({ isCheckInActivityEnd: true });
  };
  handleEndCheckOutActivity = () => {
    this.setState({ isCheckOutActivityEnd: true });
  };
  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      if (!this.state.textareaValue || this.state.textareaValue.trim() == "") {
        alert("Please type something");
        return;
      } else {
        this.dropChatMessage();
      }
    }
  };

  sendWhiteBoardLines = (allLines) => {
    this.setState({
      justchecking: allLines,
    });

    this.props.sendWhiteBoardLines(allLines);
  };

  updatestateforchild = (elem) => {
    this.setState({
      roughBoardforchildren: elem,
    });
  };

  updatestartnowbuttonforchild = (elem) => {
    this.setState({
      startQuizforchild: elem,
    });
    localStorage.setItem("quizState", true);
  };

  // handlerandom = () => {
  //   alert("this is also random");
  // };

  // temporaryChromeExtensionPopup = () => {
  //   this.setState({
  //     temporaryChromeExtensionPopupModal: true,
  //   });
  //   setTimeout(() => {
  //     this.setState({
  //       temporaryChromeExtensionPopupModal: false,
  //     });
  //   }, 3000);
  // };

  startExtension = () => {
    var checkbox = $('input[type="checkbox"]');
    if ($(checkbox).prop("checked")) {
      Cookies.set("user", "loginTrue");
      this.setState({ status: !this.state.status });
      // var editorExtensionId = "moabgkljjgcopbkecpmnanifhblchoeo";

      var editorExtensionId = "hgiaoanecpkmobgopgnagjmfilngenhg";

      let url = "test";

      // Make a simple request:
      chrome.runtime.sendMessage(
        editorExtensionId,
        { openUrlInEditor: url },
        function (response) {
          console.log(response);
          // if (response.success) {
          //   alert("Hello");
          //   // this.handlerandom();
          // }
          if (!response.success);
          return;
        }
      );
    } else {
      Cookies.remove("user");

      this.setState({ status: !this.state.status });
      // var editorExtensionId = "moabgkljjgcopbkecpmnanifhblchoeo";

      var editorExtensionId = "hgiaoanecpkmobgopgnagjmfilngenhg";

      let url = "testtest";
      // Make a simple request:
      chrome.runtime.sendMessage(
        editorExtensionId,
        { openUrlInEditor: url },
        function (response) {
          // if (!response.success) {
          //   alert("this is not accepted");
          // }
          if (!response.success);
          return;
        }
      );
    }
  };

  onChangeValue = (event) => {
    console.log(event.target.value);

    this.changeBackground(event.target.value);
  };

  changeBackground = async (event) => {
    if (this.props.isScreenSharingEnabled) {
      console.log("We are returning from the fn");
      return;
    }

    if (this.state.showLoading) {
      console.log("You cant change it now");
      return;
    }
    const { videoRoom } = this.props;

    await videoRoom.localParticipant.videoTracks.forEach(
      async (publication) => {
        console.log("Publicationssss", publication);
        if (publication) {
          await publication.track.stop();
          await publication.unpublish();
          console.log("Track Unpublished");
        } else {
          console.log("returning from fn at publication is empty");
          return;
        }

        this.setState({
          showLoading: true,
        });

        if (event === "custom") {
          this.addCustombg();
        } else if (event === "blur") {
          this.againShowVideo();
        } else {
          this.normalWithoutblur();
        }
      }
    );
  };

  normalWithoutblur = async () => {
    const { videoRoom } = this.props;

    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();

    videoRoom.localParticipant.publishTrack(localVideoTrack);
    this.setState({
      showLoading: false,
    });
  };

  againShowVideo = async () => {
    const { videoRoom } = this.props;

    const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    console.log(localVideoTrack);

    const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
      assetsPath: "",
      maskBlurRadius: 10,
      blurFilterRadius: 5,
    });
    await bg.loadModel();
    localVideoTrack.addProcessor(bg);
    videoRoom.localParticipant
      .publishTrack(localVideoTrack)
      .then((data) => {
        console.log("Blur background Updated", data);
      })
      .catch((err) => {
        console.log("err", err);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");
        submitErrorLog(userID, liveClassID, err.message, err.code, 0);
      });
    this.setState({
      showLoading: false,
    });
  };

  callbackBackgroundChange = () => {
    this.setState({
      showLoading: false,
    });
  };

  addCustombg = async () => {
    const { videoRoom } = this.props;
    this.props.custombg(videoRoom, this.callbackBackgroundChange);
  };

  videoCount = (count) => {
    let vidCollection = document.getElementsByTagName("video");
    let vidHeight = window.innerHeight - 70;
    if (count === 1) {
      for (let item of vidCollection) {
        if (!ipad) {
          item.style.height = vidHeight + "px";
          item.style.objectFit = "cover";
          item.style.borderRadius = "6px";
        }
      }
    } else if (count === 2) {
      for (let item of vidCollection) {
        if (!ipad) {
          item.style.height = vidHeight + "px";
          item.style.objectFit = "cover";
          item.style.borderRadius = "6px";
        }
      }
    } else if (count === 3 || count === 4) {
      for (let item of vidCollection) {
        if (!ipad) {
          item.style.height = vidHeight / 2 + "px";
          item.style.objectFit = "cover";
          item.style.borderRadius = "6px";
        }
      }
    }
  };

  rightVideo = (count) => {
    let element = [...document.querySelectorAll(".right-thumbnail video")];

    let vidHeight = window.innerHeight - 70;
    //console.log(count);

    for (let i = 0; i < element.length; i++) {
      if (count == 2) {
        element[i].style.height = "180px";
      } else if (count == 3) {
        element[i].style.height = "180px";
      } else if (count == 4) {
        element[i].style.height = "160px";
      } else if (count > 4) {
        element[i].style.height = "100px";
      }
    }
  };

  rightVideoOther = (count) => {
    let element = [...document.querySelectorAll(".right-thumbnail video")];

    let vidHeight = window.innerHeight - 70;

    // console.log("Count", count);
    for (let i = 0; i < element.length; i++) {
      if (count == 2) {
        element[i].style.height = "180px";
      } else if (count == 3) {
        element[i].style.height = "150px";
      } else if (count == 4) {
        element[i].style.height = "120px";
      } else if (count > 4) {
        element[i].style.height = "85px";
      }
    }
  };
  handleShowCicoActivity = (item) => {
    if (item?.id === 11 && this.state.mLiveClassId == "175762") return false;
    else
      return (
        (item?.id === 11 && this.props.isGroupClass) ||
        (item?.id === 11 && this.setDemoFlag()) ||
        (item?.id === 11 && isGradeAllowed(this.props.student_grade))
      );
  };
  // callparent = () => {
  //   const { videoRoom } = this.props;
  //   let obj = mapToArray(videoRoom.participants);
  //   for (let i = 0; i < obj.length; i++) {
  //     if (obj[i].identity == "parent") {
  //       this.setState({
  //         showit: true,
  //       });
  //     } else {
  //       this.setState({
  //         showit: false,
  //       });
  //     }
  //   }
  // };

  render() {
    const {
      videoRoom,
      showBoard,
      onCallTechSupport,
      onLeaveClicked,
      onRaiseHandClicked,
      onMuteClick,
      onUnMuteClick,
      incomingData,
      onScreenShare,
      all_ids,
    } = this.props;

    const remoteParticipants = mapToArray(videoRoom.participants);

    const {
      logical_concept,
      trial_pdfs,
      game_levels,
      concept_list,
      selectedVideoUrl,
      nowPlayingVideoTime,
      nowPlayingVideoStart,
      selectedPdfUrl,
      selectedPdfIndex,
      selectedQuizUrl,
      isSharingScreen,
      participantNameColor,
      remoteParticpantsValues,
      currentDataTrackTime,
      muteAllState,
      studentScreenShareConfirmation,
      changespeedmathfn,
      activity_type,
    } = this.state;

    let leftFlex,
      middleFlex,
      rightFlex,
      leftMaxWidth,
      middleMaxWidth,
      rightMaxWidth;

    if (ipad === true) {
      if (this.state.selected === 1) {
        (leftFlex = "0 0 10%"),
          (middleFlex = "0 0 90%"),
          (leftMaxWidth = "10%"),
          (middleMaxWidth = "90%");
      } else {
        (leftFlex = "0 0 10%"),
          (middleFlex = "0 0 70%"),
          (rightFlex = "0 0 20%"),
          (leftMaxWidth = "10%"),
          (middleMaxWidth = "70%"),
          (rightMaxWidth = "20%");
      }
    }

    let ActualWidth = window.screen.width;

    if (ipad === false) {
      if (this.state.selected === 1) {
        (leftFlex = "0 0 6%"),
          (middleFlex = "0 0 94%"),
          (leftMaxWidth = "6%"),
          (middleMaxWidth = "94%");
      } else if (ActualWidth < 1400 && this.state.selected == 3) {
        (leftFlex = "0 0 6%"),
          (middleFlex = "0 0 77%"),
          (rightFlex = "0 0 17%"),
          (leftMaxWidth = "6%"),
          (middleMaxWidth = "77%"),
          (rightMaxWidth = "12%");
        if (
          this.state.selectGGbDataInConceptsList != null &&
          this.state.selectGGbDataInConceptsList.type == "ggb"
        ) {
          rightMaxWidth = "17%";
        }
      } else {
        (leftFlex = "0 0 6%"),
          (middleFlex = "0 0 77%"),
          (rightFlex = "0 0 17%"),
          (leftMaxWidth = "6%"),
          (middleMaxWidth = "77%"),
          (rightMaxWidth = "17%");
      }
    }

    const isScreen = this.state.selected;
    let maxDevice;

    const finalRemoteParticipant = remoteParticipants.filter(
      (participant) =>
        participant.identity != "parent" &&
        participant.identity != "sm" &&
        participant.identity != "audit" &&
        participant.identity != "smmanager"
    );

    if (isScreen === 1 && finalRemoteParticipant.length + 1 === 1) {
      maxDevice = 12;

      this.videoCount(1);
    } else if (isScreen === 1 && finalRemoteParticipant.length + 1 === 2) {
      maxDevice = 6;
      this.videoCount(2);
    } else if (isScreen === 1 && finalRemoteParticipant.length + 1 === 3) {
      maxDevice = 6;
      this.videoCount(3);
    } else if (isScreen === 1 && finalRemoteParticipant.length + 1 === 4) {
      maxDevice = 6;
      this.videoCount(4);
    } else if (isScreen === 1 && finalRemoteParticipant.length + 1 > 4) {
      maxDevice = 4;
      this.videoCount(4);
    }

    if (isScreen === 2) {
      maxDevice = 12;
    }

    if (isScreen === 2 && finalRemoteParticipant.length + 1 === 1) {
      this.rightVideo(1);
    } else if (isScreen === 2 && finalRemoteParticipant.length + 1 === 2) {
      this.rightVideo(2);
    } else if (isScreen === 2 && finalRemoteParticipant.length + 1 === 3) {
      this.rightVideo(3);
    } else if (isScreen === 2 && finalRemoteParticipant.length + 1 === 4) {
      this.rightVideo(4);
    } else if (isScreen === 2 && finalRemoteParticipant.length + 1 > 4) {
      this.rightVideo(5);
    }

    if (
      (isScreen === 3 || isScreen === 4 || isScreen === 5) &&
      finalRemoteParticipant.length + 1 === 1
    ) {
      this.rightVideoOther(1);
    } else if (
      (isScreen === 3 || isScreen === 4 || isScreen === 5) &&
      finalRemoteParticipant.length + 1 === 2
    ) {
      this.rightVideoOther(2);
    } else if (
      (isScreen === 3 || isScreen === 4 || isScreen === 5) &&
      finalRemoteParticipant.length + 1 === 3
    ) {
      this.rightVideoOther(3);
    } else if (
      (isScreen === 3 || isScreen === 4 || isScreen === 5) &&
      finalRemoteParticipant.length + 1 === 4
    ) {
      this.rightVideoOther(4);
    } else if (
      (isScreen === 3 || isScreen === 4 || isScreen === 5) &&
      finalRemoteParticipant.length + 1 === 5
    ) {
      this.rightVideoOther(5);
    }

    remoteParticipants.map((participant) => {
      participant.on("trackSubscribed", (track) => {
        if (track.kind === "data") {
          track.on("message", (data) => {
            if (this.state.currentDataTrackTime == "") {
              this.setState({
                currentDataTrackTime: Date.now(),
              });

              handleIncomingDatas(data);
            } else {
              var seconds =
                (Date.now() - this.state.currentDataTrackTime) / 1000;
              if (seconds > 1) {
                this.setState({
                  currentDataTrackTime: Date.now(),
                });

                handleIncomingDatas(data);
              } else {
                if (data.startsWith("CanvasLines")) {
                  onCanvasLineIncoming(data);
                }
                if (data.startsWith("ChangeLessonPage")) {
                  var pageNumber = data.replace("ChangeLessonPage|", "");
                  if (this.canvasScreenElement.current != null)
                    this.canvasScreenElement.current.onPageNumberUpdate(
                      pageNumber
                    );
                }
                // if (data.startsWith("GameTimer")) {
                //   var dataArray = data.split('|');

                //   this.onIncomingGameTimerStartStop(dataArray);
                // }
              }
            }
          });
        }
      });
    });

    // {
    //   isTutorCheck(videoRoom.localParticipant.identity)
    //     ? setTimeout(() => {
    //         this.callparent();
    //       }, 1000)
    //     : null;
    // }

    const mathZone = ["Lesson", "Video", "Math game"].map((cur, ind) => {
      return (
        <li key={ind} className="dropdown-item">
          <Form.Group controlId={"mathzone-" + ind}>
            <Form.Check
              onChange={() => this.handleSelectedCheckbox("mathzone")}
              type="checkbox"
              name="mathzone"
              value={cur}
              label={cur}
            />
          </Form.Group>
        </li>
      );
    });

    const speedMath = [
      "Level 1",
      "Level 2",
      "Level 3",
      "Level 4",
      "Level 5",
    ].map((cur, ind) => {
      return (
        <li key={ind} className="dropdown-item">
          <Form.Group controlId={"speedmath-" + ind}>
            <Form.Check
              onChange={() => this.handleSelectedCheckbox("speedmath")}
              type="checkbox"
              name="speedmath"
              value={cur}
              label={cur}
            />
          </Form.Group>
        </li>
      );
    });

    const logicZone = ["Logic puzzles", "Coding"].map((cur, ind) => {
      return (
        <li key={ind} className="dropdown-item">
          <Form.Group controlId={"logiczone-" + ind}>
            <Form.Check
              onChange={() => this.handleSelectedCheckbox("logiczone")}
              type="checkbox"
              name="logiczone"
              value={cur}
              label={cur}
            />
          </Form.Group>
        </li>
      );
    });

    // {
    //   this.state.showipadscreen ?

    //   <div className="ghhhh"></div> : null;
    // }

    // {
    //   this.state.showIframe ? (
    //     <div style={{ position: "relative", padding: 20 }}>
    //       {/* OVERLAY */}
    //       <div
    //         style={{
    //           position: "absolute",
    //           top: 0,
    //           left: 0,
    //           width: "100%",
    //           height: "100%",
    //           pointerEvents: "none",
    //           background: "red",
    //           opacity: 0.1,
    //         }}
    //       />
    //       {/* IFRAME */}
    //       <iframe
    //         width="420"
    //         height="315"
    //         src="https://www.youtube.com/embed/tgbNymZ7vqY"
    //       />
    //     </div>
    //   ) : null;
    // }

    const { class_type } = this.props;
    const optionsToRender =
      class_type === "math_coding"
        ? this.state.options_math_coding
        : class_type === "math"
        ? this.state.options_math
        : class_type === "coding"
        ? this.state.options_coding
        : this.state.options_old;
    let currentMenus = optionsToRender?.filter((item) =>
      this.handleShowCicoActivity(item) ? false : true
    );
    const leftMenu = currentMenus?.map((obj) => {
      return (
        <ListGroup.Item key={obj.id}>
          {obj.id === 3 ||
          obj.id === 4 ||
          obj.id === 5 ||
          obj.id == 6 ||
          obj.id === 11 ||
          obj.id === 12 ||
          obj.id === 7 ||
          obj.id === 16 ? (
            <ButtonGroup
              disabled
              style={{
                cursor: "default",
                pointerEvents: !isTutorCheck(
                  videoRoom.localParticipant.identity
                )
                  ? "none"
                  : "unset",
              }}
              onMouseEnter={() => this.showMenus(obj.id)}
              onClick={() => this.resumeLessonPdf(obj.id)}
              onMouseLeave={() => this.hideMenus(obj.id)}
              onTouchStart={() => this.showMenus(obj.id)}
              className="d-flex flex-column"
              toggle
            >
              <ToggleButton
                disabled
                style={{
                  cursor: "default",
                  pointerEvents: !isTutorCheck(
                    videoRoom.localParticipant.identity
                  )
                    ? "none"
                    : "unset",
                  opacity: !isTutorCheck(videoRoom.localParticipant.identity)
                    ? "0.5"
                    : "1",
                }}
                variant="default"
                block
                type="radio"
                value={obj.value}
                onChange={(e) =>
                  this.openConfirmationChangeScreenModal(() => {
                    this.radioHandler(obj.id);
                  })
                }
                checked={this.state.selected === obj.id}
                name="options"
              >
                <Image
                  style={{ width: "32px", height: "32px" }}
                  src={
                    this.state.selected === obj.id ? obj.image : obj.activeImage
                  }
                />
                <p>
                  {obj.name} &nbsp;
                  {videoRoom.localParticipant.identity == "tutor" ? (
                    <Image
                      className="right-arrow"
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/ArrowRight.svg"
                      }
                    />
                  ) : null}
                </p>
              </ToggleButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup className="d-flex flex-column" toggle>
              <ToggleButton
                variant="default"
                block
                type="radio"
                value={obj.value}
                onChange={(e) =>
                  this.openConfirmationChangeScreenModal(() => {
                    this.radioHandler(obj.id);
                  })
                }
                checked={this.state.selected === obj.id}
                name="options"
                style={{
                  pointerEvents: !isTutorCheck(
                    videoRoom.localParticipant.identity
                  )
                    ? "none"
                    : "unset",
                  opacity: !isTutorCheck(videoRoom.localParticipant.identity)
                    ? "0.5"
                    : "1",
                }}
              >
                <Image
                  style={{ width: "32px", height: "32px" }}
                  src={
                    this.state.selected === obj.id ? obj.image : obj.activeImage
                  }
                />
                <p>{obj.name}</p>
              </ToggleButton>
            </ButtonGroup>
          )}
        </ListGroup.Item>
      );
    });

    const onStarReceived = (studentIdentity) => {
      var mess = videoRoom.localParticipant.identity + " --" + studentIdentity;
      console.log("Star Received ", mess);
      if (videoRoom.localParticipant.identity == studentIdentity) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");
        var dataRepsonse = submitAStar(liveClassID, userID);
      }

      this.addStarToStudent(studentIdentity);
      this.starAnimate(studentIdentity);

      // this.allScreenElement.current.onStarReceived(studentIdentity);
    };

    const onSmileyReceived = (studentIdentity) => {
      console.log("calldldlld");
      var mess = videoRoom.localParticipant.identity + " --" + studentIdentity;
      console.log("Smiley Received ", mess);
      if (videoRoom.localParticipant.identity == studentIdentity) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");
        var dataRepsonse = submitASmiley(liveClassID, userID, true);
      }

      this.addSmileyToStudent(studentIdentity);
      this.smileyAnimate(studentIdentity);

      // this.allScreenElement.current.onStarReceived(studentIdentity);
    };

    const onThumbsUpReceived = (studentIdentity) => {
      var mess = videoRoom.localParticipant.identity + " --" + studentIdentity;
      console.log("ThumbsUp Received ", mess);
      if (videoRoom.localParticipant.identity == studentIdentity) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");
        var dataRepsonse = submitAThumbsUp(liveClassID, userID, true);
      }

      this.addThumbsUpToStudent(studentIdentity);
      this.thumbsUpAnimate(studentIdentity);

      // this.allScreenElement.current.onStarReceived(studentIdentity);
    };

    const onClappingReceived = (studentIdentity) => {
      var mess = videoRoom.localParticipant.identity + " --" + studentIdentity;
      console.log("Clapping Received ", mess);
      if (videoRoom.localParticipant.identity == studentIdentity) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userID = params.get("userID");
        let liveClassID = params.get("liveClassID");
        var dataRepsonse = submitAClapping(liveClassID, userID, true);
      }

      this.addClappingToStudent(studentIdentity);
      this.clappingAnimate(studentIdentity);

      // this.allScreenElement.current.onStarReceived(studentIdentity);
    };

    const onChatMessageReceived = (identity, message) => {};

    const onScreenShareReceived = (studentIdentity) => {
      if (this.state.userSharingScreen && !this.props.isScreenSharingEnabled) {
        console.log("already shared returning from fn");
        return;
      }

      if (videoRoom.localParticipant.identity == studentIdentity) {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          return;
          /* your code here */
        }
        const isSafari =
          /Safari/.test(navigator.userAgent) &&
          /Apple Computer/.test(navigator.vendor);

        if (isSafari) {
          // alert("Safari browser...");
          if (this.state.isSharingScreen) {
            stopScreenSharingForSafari();
          } else {
            screenShareConfirmationModal();
          }
        } else this.props.onScreenShare();
      } else {
      }
    };

    const stopScreenSharingForSafari = () => {
      this.props.onScreenShare();
      this.setState({
        isSharingScreen: false,
      });
    };

    const screenShareConfirmationModal = () => {
      this.setState({
        studentScreenShareConfirmation: true,
      });
    };

    const shareScreenAllowed = () => {
      this.setState({
        studentScreenShareConfirmation: false,
        isSharingScreen: true,
      });
      this.props.onScreenShare();
    };

    const onMuteReceived = (studentIdentity) => {
      if (videoRoom.localParticipant.identity == studentIdentity) {
        //console.log("Identity Match");
        var localParticipant = videoRoom.localParticipant;
        this.props.onUserMutedStatus(true);

        localParticipant.audioTracks.forEach(function (audioTrack) {
          audioTrack.track.disable();
        });
      }
      //console.log("Identity Match Outside");
      this.muteRemoteStudent(studentIdentity);
    };
    const onUnMuteReceived = (studentIdentity) => {
      if (videoRoom.localParticipant.identity == studentIdentity) {
        var localParticipant = videoRoom.localParticipant;
        this.props.onUserMutedStatus(false);
        localParticipant.audioTracks.forEach(function (audioTrack) {
          audioTrack.track.enable();
        });
      }
      console.log("Identity Match Outside");
      this.unMuteRemoteStudent(studentIdentity);
    };

    const showRaiseHand = (studentIdentity) => {
      const dyanmic_hand_var = studentIdentity + "_hand";

      this.setState({
        [dyanmic_hand_var]: true,
      });
    };

    // const updateRaiseHandMessage = (message) => {

    //

    //  //if (videoRoom.localParticipant.identity == "tutor") {

    //     //this.allScreenElement.current.showRaiseHand(message);
    //     if (this.state.selected == 1) {
    //       this.allScreenElement.current.showRaiseHand(message);
    //     }
    //     else
    //       this.showRaiseHand(message);

    //  // }

    // }

    // console.log("APUN PARTICIPANT HAI -", videoRoom.localParticipant.identity)

    let raiseHand = {
      position: "absolute",
      right: "2%",
      top: "13%",
      width: "50px",
    };

    let studentraiseHand = {
      position: "absolute",
      right: "2%",
      top: "25%",
      width: "50px",
    };

    let mute = {
      position: "absolute",
      right: "5%",
      bottom: "5%",
      cursor: "pointer",
    };

    let screenshare = {
      position: "absolute",
      right: "8%",
      top: "5%",
      width: "32px",
      cursor: "pointer",
    };

    let star = {
      position: "absolute",
      left: "6%",
      top: "1%",
      cursor: "pointer",
    };

    let starCount = {
      position: "absolute",
      right: "35%",
      bottom: "12%",
      cursor: "pointer",
    };

    let childName = {
      position: "absolute",
      left: "7%",
      top: "20px",
      // bottom: "12%",
      color: "#FFFFFF",
      fontSize: "12px",
      textTransform: "capitalize",
      fontWeight: "bold",
      margin: "0",
    };

    let studentMute = {
      position: "absolute",
      right: "7%",
      bottom: "13%",
    };

    let centeredTutor = {};

    const onGraphImageIncoming = (data) => {
      var graphImageStatus = data.replace("GraphImage|", "");
      if (this.canvasScreenElement.current != null)
        this.canvasScreenElement.current.onGraphImageClick(graphImageStatus);
    };

    // const onCanvasLineIncoming = (data) => {
    //   // var whiteBoardLines = data.replace("CanvasLines|", "");
    //   var whiteBoardLines = data;
    //   console.log("Type of ", whiteBoardLines);
    //   console.log("Selected  : ", this.state.selected);

    //   if (this.state.selected == 6) {
    //     this.quizScreenElement.current.onRemoteLineUpdate(
    //       JSON.parse(whiteBoardLines)
    //     );
    //   } else {
    //     if (this.canvasScreenElement.current != null)
    //       this.canvasScreenElement.current.onRemoteLineUpdate(whiteBoardLines);
    //   }
    // };

    const onCanvasLineIncoming = (data) => {
      console.log("data", data);

      var whiteBoardLines = data.replace("CanvasLines|", "");
      // var whiteBoardLines = data;
      // console.log("Type of ", whiteBoardLines);
      // console.log("Selected  : ", this.state.selected);
      // console.log("Whiteboard lines live class data tracks", whiteBoardLines);
      if (
        this.state.selected == 6 ||
        this.state.selected == 12 ||
        this.state.selected == 16
      ) {
        if (this.quizScreenElement.current != null) {
          this.quizScreenElement.current.onRemoteLineUpdate(
            JSON.parse(whiteBoardLines)
          );
        }
      } else if (this.state.selected == 14) {
        if (this.lessonScratchScreenElement.current != null) {
          this.lessonScratchScreenElement.current.onRemoteLineUpdate(
            JSON.parse(whiteBoardLines)
          );
        }
      } else {
        if (this.canvasScreenElement.current != null)
          this.canvasScreenElement.current.onRemoteLineUpdate(
            JSON.parse(whiteBoardLines)
          );
        console.log(
          "whiteBoardLines onCanvasLineIncoming",
          JSON.parse(whiteBoardLines)
        );
      }
    };

    const onQuizCanvasLineIncoming = (data) => {
      var whiteBoardLines = data.replace("QuizCanvasLines|", "");

      if (this.quizScreenElement.current != null)
        this.quizScreenElement.current.onRemoteLineUpdate(
          JSON.parse(whiteBoardLines)
        );
    };

    function isNumeric(num) {
      return !isNaN(num);
    }

    const handleIncomingDatas = (data) => {
      console.log("Data");
      // if (data === "hellothere") {
      //   alert("Hello there this datatracks is from mobile devices");
      //   return;
      // }
      const isSafari =
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor);
      let x = data.split("|");
      let urlString = data;
      let paramString = urlString.split("?")[1];
      let queryString = new URLSearchParams(paramString);
      for (let pair of queryString.entries()) {
        if (pair[0] == "sub_concept_id") {
          this.setState({
            selectedConceptId: +pair[1],
          });
        }
        if (pair[0] == "tag_id") {
          this.setState({
            selectedTagId: +pair[1],
          });
        }
        if (pair[0] == "level") {
          this.setState({
            selectedQuizLevel: pair[1],
          });
        }
      }

      let checkNumber = isNumeric(x[1]);

      this.setState({
        incomingData: data,
        changechildlessonpdf: checkNumber ? +x[1] : 0,
      });

      if (data === "AllScreen") {
        this.closeChatBox();
        this.setState({
          selected: 1,
          currentScreenName: data,
        });
      }
      if (data === "MyScreen") {
        this.closeChatBox();
        this.setState({
          selected: 2,
          currentScreenName: data,
        });
      }

      if (data === "SpeedMathClose") {
        this.closeChatBox();
        this.setState({
          selected: 2,
        });
      }

      if (data.startsWith("startPrePostTest")) {
        console.log("Start Pre Post Test student side");
        let dataArray = data.split("|");
        dataArray.shift();
        let obj = JSON.parse(dataArray[0]);

        this.setState({
          prepostMouting: false,
        });

        setTimeout(() => {
          this.setState({
            prepostMouting: true,
          });
        }, 0);

        this.openPrePostTestPlay(
          obj?.sub_concept_id,
          obj?.test_type,
          obj?.concept_name
        );

        this.setState({
          currentScreenName:
            obj?.test_type == "pre_test" ? "Pre-Test" : "Post-Test",
        });
        // this.setState({
        //   prepostSubConceptId: obj?.sub_concept_id,
        //   prepostTestType: obj.test_type,
        // });
      }

      if (data.startsWith("stundentClickedNextPrePostTestQue")) {
        console.log("calling student side");
        let dataArray = data.split("|");
        dataArray.shift();
        let obj = JSON.parse(dataArray[0]);

        const { videoRoom } = this.props;

        if (videoRoom.localParticipant.identity == "tutor") {
          this.teacherCallingCurrentQuestionPrePostTest(
            obj?.pre_post_test_id,
            obj?.student_id,
            obj?.skipped_review
          );
        }
      }

      if (data.startsWith("setStudentGlobalIdForCheckPrePostTest")) {
        let dataArray = data.split("|");
        dataArray.shift();
        let student_id = dataArray[0];

        this.setState({
          currentSelectedStudentCheckBeforeChangeQues: student_id,
        });
      }

      if (data.startsWith("StudentIpadState")) {
        let dataArray = data.split("|");
        let state = +dataArray[1];
        console.log("Data Array", dataArray);
        console.log("State", state);

        if (state) {
          this.setState({
            studentIpadStatesss: true,
          });
        }
      }
      if (data.startsWith("ChangeAffirmationStoriesPageNumber")) {
        var pageNumber = data.split("ChangeAffirmationStoriesPageNumber|");
        pageNumber.shift();
        pageNumber = pageNumber[0];
        this.setState({ storyBookPageNumber: Number(pageNumber) });
      }
      if (data.startsWith("CurrentState")) {
        var dataArray = data.split("|");
        let state = dataArray[2];
        if (state == 1) {
          this.closeChatBox();
          this.setState({
            selected: 1,
            currentScreenName: "AllScreen",
          });
        }

        if (state == 2) {
          this.closeChatBox();
          this.setState({
            selected: 2,
            currentScreenName: "MyScreen",
          });
        }

        if (state == 3) {
          var dataArray = data.split("|");

          if (dataArray[3] == "ShowcurrentPen") {
            let IMAGE_ARRAY = JSON.parse(dataArray[4]);
            let pdfIndex = dataArray[5];
            console.log("PdfIndex", pdfIndex);
            this.setState({
              selected: 3,
              selectedPdfUrl: IMAGE_ARRAY,

              selectedPdfIndex: pdfIndex,
              refreshTime: Date.now(),
              currentScreenName: "Lessons",
              changechildlessonpdf: +pdfIndex,
            });
            return;
          }

          if (dataArray[3] == "WhiteBoard") {
            console.log("DATAARRAT", dataArray[3]);
            this.setState(
              {
                selected: 1,
                currentScreenName: dataArray[3],
                // changechildlessonpdf: pdfIndex,
              },
              () => this.refreshWhiteBoard(IMAGE_ARRAY)
            );
            return;
          }
        }

        if (state == 4) {
          let dataArray = data.split("|");
          let videoUrl = dataArray[3];
          let currentTime = dataArray[4];

          if (currentTime != undefined) {
            this.setState({
              selected: 4,
              nowPlayingVideoTime: currentTime,
              selectedVideoUrl: videoUrl,
              currentScreenName: "PlayVideo",
            });
            this.handleVideoChildState();
          } else {
            this.closeChatBox();
            this.setState({
              selected: 4,
              selectedVideoUrl: videoUrl,
              currentScreenName: "PlayVideo",
            });
          }
        }

        if (state == 5) {
          let dataArray = data.split("|");

          let identity = dataArray[1].toString();
          let message = dataArray[3].toString();

          if (message == "speedmathopen") {
            setTimeout(() => {
              if (videoRoom.localParticipant.identity == identity) {
                alert("Speed Math is Running, Please wait!");
              }
            }, 1000);
          } else {
            console.log("Speed Math is not open");
          }
        }

        if (state == 6) {
          let dataArray = data.split("|");

          console.log("dataatatatta", dataArray);

          if (dataArray[3] == "openRoughBoardforchild") {
            this.setState({
              selected: 6,
            });
            let boardStatus = "true";
            if (this.quizScreenElement.current != null)
              this.quizScreenElement.current.onRemoteShowRoughBoard(
                boardStatus
              );
          }
        }
      }

      if (data.startsWith("GameTimer")) {
        var dataArray = data.split("|");

        this.onIncomingGameTimerStartStop(dataArray);
      }

      if (data.startsWith("CanvasLines")) {
        onCanvasLineIncoming(data);
      }
      if (data.startsWith("GraphImage")) {
        onGraphImageIncoming(data);
      }

      if (data.startsWith("QuizCanvasLines")) {
        onQuizCanvasLineIncoming(data);
      }

      if (data.startsWith("ClearCanvas")) {
        console.log("clearCanvas");
        var clearPage = data.replace("ClearCanvas|", "");

        if (
          this.state.selected == 6 ||
          this.state.selected == 12 ||
          this.state.selected == 16
        ) {
          if (this.quizScreenElement.current) {
            console.log(this.quizScreenElement, "quizCanvas");
            this.quizScreenElement.current.onRemoteClearCanvas(0);
          }
        } else {
          if (this.canvasScreenElement.current) {
            console.log(this.quizScreenElement, "whiteboard");
            this.canvasScreenElement.current.onRemoteClearCanvas(clearPage);
          }
        }
      }

      if (data.startsWith("RoughBoardScreen")) {
        console.log("Hellllo");
        var boardStatus = data.replace("RoughBoardScreen|", "");

        if (
          this.state.selected == 6 ||
          this.state.selected == 12 ||
          this.state.selected == 16
        ) {
          if (this.quizScreenElement.current)
            this.quizScreenElement.current.onRemoteShowRoughBoard(boardStatus);
        } else {
          if (this.canvasScreenElement.current) console.log("I am here");
          this.canvasScreenElement.current.onRemoteShowRoughBoard(boardStatus);
        }
      }

      if (data.startsWith("WhiteBoard")) {
        this.closeChatBox();
        try {
          var DELIMETER_ARRAY = data.split("|");
          // alert(DELIMETER_ARRAY.length)

          console.log("DataArrayTeacher", DELIMETER_ARRAY);

          var IMAGE_ARRAY = JSON.parse(DELIMETER_ARRAY[1]);
          console.log("Image Array", IMAGE_ARRAY);
          var pageINdex =
            DELIMETER_ARRAY[2] == undefined ? 0 : DELIMETER_ARRAY[2];

          console.log("pageINdex", pageINdex);
          console.log("Image array", IMAGE_ARRAY);

          if (this.state.selected != 3) {
            this.setState({
              selected: 3,
              selectedPdfUrl: IMAGE_ARRAY,
              selectedPdfIndex: pageINdex,
              refreshTime: Date.now(),
              currentScreenName: "Lessons",
              //changechildlessonpdf: pageINdex,
            });
          } else {
            console.log("1st");
            this.setState(
              {
                selected: 1,
                currentScreenName: "Lessons",
                // selectGGbDataInConceptsList: IMAGE_ARRAY,
                //changechildlessonpdf: pageINdex,
              },
              () => this.refreshWhiteBoard(IMAGE_ARRAY)
            );
          }
        } catch (e) {
          console.log("2nd");
          this.setState(
            {
              selected: 1,
              currentScreenName: "Whiteboard",

              // selectGGbDataInConceptsList: IMAGE_ARRAY,
            },
            () => this.refreshWhiteBoard(IMAGE_ARRAY)
          );
        }
      }

      if (data.startsWith("GeoGebraLinks")) {
        console.log("GeograbraLinks handleIncoming");
        this.closeChatBox();
        console.log("Data Geogebra", data);
        try {
          var DELIMETER_ARRAY = data.split("|");
          // alert(DELIMETER_ARRAY.length)
          console.log("DataArrayTeacher", DELIMETER_ARRAY);
          var IMAGE_ARRAY = JSON.parse(DELIMETER_ARRAY[1]);
          console.log("Image Array", IMAGE_ARRAY);
          if (this.state.selected != 3) {
            this.setState({
              selected: 3,
              selectGGbDataInConceptsList: IMAGE_ARRAY,
              currentScreenName: "Lessons",
              //changechildlessonpdf: pageINdex,
            });
          } else {
            console.log("1st");
            this.setState(
              {
                selected: 1,
                currentScreenName: "Lessons",
                // selectGGbDataInConceptsList: IMAGE_ARRAY,
                //changechildlessonpdf: pageINdex,
              },
              () => this.refreshGGbLinks(IMAGE_ARRAY)
            );
          }
        } catch (e) {
          console.log("2nd");
          this.setState(
            {
              selected: 1,
              currentScreenName: "Whiteboard",
              // selectGGbDataInConceptsList: IMAGE_ARRAY,
            },
            () => this.refreshGGbLinks(IMAGE_ARRAY)
          );
        }
      }

      if (data.startsWith("LessonScratch")) {
        this.closeChatBox();

        try {
          var DELIMETER_ARRAY = data.split("|");
          var IMAGE_ARRAY = JSON.parse(DELIMETER_ARRAY[1]);
          var pageINdex =
            DELIMETER_ARRAY[2] == undefined ? 0 : DELIMETER_ARRAY[2];

          console.log("pageINdex", pageINdex);

          if (this.state.selected != 3) {
            this.setState({
              selected: 3,
              selectedPdfUrl: IMAGE_ARRAY,
              selectedPdfIndex: pageINdex,
              refreshTime: Date.now(),
              currentScreenName: "Scratch Lessons",
              //changechildlessonpdf: pageINdex,
            });
          } else {
            console.log("1st");
            this.setState(
              {
                selected: 1,
                currentScreenName: "Scratch Lessons",
                //changechildlessonpdf: pageINdex,
              },
              () => this.refreshWhiteBoard(IMAGE_ARRAY)
            );
          }
        } catch (e) {
          console.log("2nd");
          this.setState(
            {
              selected: 1,
              currentScreenName: "Whiteboard",
            },
            () => this.refreshWhiteBoard(undefined)
          );
        }
      }

      if (data.startsWith("PlayVideo")) {
        this.closeChatBox();
        this.setState({
          selected: 4,
          selectedVideoUrl: data.replace("PlayVideo|", ""),
          currentScreenName: "PlayVideo",
        });
      }

      if (data.startsWith("ResumeVideo")) {
        console.log("ResumeVideo Datatrack live class");
        var dataArray = data.split("|");

        this.setState({
          selected: 4,
          nowPlayingVideoTime: dataArray[2],
          selectedVideoUrl: dataArray[1],
        });
      }

      if (data.startsWith("SpeedMath")) {
        if (
          videoRoom.localParticipant.identity != "parent" &&
          !this.smPresent(videoRoom.localParticipant.identity)
        ) {
          this.closeChatBox();
          var dataArray = data.split("|");

          this.setState({
            selected: 5,
            selectedSpeedMathLevel: dataArray[1],
            speedMathGameId: dataArray[2],
            speedMathGameMode: dataArray[3],
            currentScreenName: "SpeedMath",
          });
        } else {
          this.showgameinprogresstoparent();
        }
      }

      if (data.startsWith("SpeedMathClose")) {
        this.closeChatBox();
        var dataArray = data.split("|");

        this.setState({
          selected: 1,
          selectedSpeedMathLevel: dataArray[1],
          speedMathGameId: dataArray[2],
          speedMathGameMode: dataArray[3],
        });

        // this.setState({
        //   selected: 5,
        //   selectedSpeedMathLevel: data.replace('SpeedMath|', '')
        // })
      }

      if (data.startsWith("LiveScoreSpeedMath")) {
        this.closeChatBox();
        var dataArray = data.split("|");
        var newObject = {
          id: dataArray[1],
          name: dataArray[2],
          score: dataArray[3],
        };
        var cpScoreData = this.state.speedMathScoreDataItem;
        cpScoreData[dataArray[1]] = newObject;
        this.setState({
          speedMathScoreDataItem: cpScoreData,
        });
        this.state.speedMathScoreDataItem.map((item) => {
          console.log("Result : ", item);
        });
      }

      if (data.startsWith("updateTeacherQuestionCountInComputerMode")) {
        this.closeChatBox();
        var dataArray = data.split("|");
        let speedMathQuestion = JSON.parse(dataArray[1]);

        console.log("Speedmathquestion in datatrack", speedMathQuestion);

        this.setState({
          updateSpeedMathQueForTeacher: speedMathQuestion,
        });
      }

      if (data.startsWith("CodingScreen")) {
        this.closeChatBox();
        console.log("Student Side Class Type", this.props.class_type);
        if (this.props.class_type === "math_coding") {
          // this.setState({
          //   selected: 13,
          //   currentScreenName: "Coding",
          // });

          if (this.setDemoFlag()) {
            if (this.props.show_new_codings) {
              this.openScratchScreen();
            } else {
              this.setState({
                selected: 7,
              });
              //this.openCodingScreen();
            }
          } else {
            this.openScratchScreen();
          }
        } else {
          this.setState({
            selected: 7,
            currentScreenName: "Logic Zone",
          });
        }

        // if (this.props.class_type === "math_coding") {
        //   this.setState({
        //     selected: 13,
        //     currentScreenName: "Coding",
        //   });
        // } else {
        //   this.setState({
        //     selected: 7,
        //     currentScreenName: "Logic Zone",
        //   });
        // }
        // this.setState({
        //   selected: 7,
        //   currentScreenName: "Logic Zone",
        // });
      }

      if (data.startsWith("ScratchScreen")) {
        this.closeChatBox();
        this.setState({
          selected: 9,
        });
      }
      if (data.startsWith("FlagQuestion")) {
        let arr = data.split("|");
        arr.shift();
        let obj = arr.join("|");
        obj = JSON.parse(obj);

        this.onSetFlagQuestionState({ ...obj });
      }
      if (data.startsWith("ChangeQuestionFlagQuestion")) {
        let arr = data.split("|");

        arr.shift();
        let obj = arr.join("|");
        obj = JSON.parse(obj);
        console.log(obj);
        let temp = Number(this.state.flagQuestionMarksAsCompleted) || 0;
        temp = obj?.fetchAgain ? temp + 1 : obj?.fetchAgain;

        this.setState({
          selected: 12,
          subId: 1,
          currentFlagQuestion: obj?.value || 0,
          flagQuestionMarksAsCompleted: temp,
        });
      }
      if (data.startsWith("MainActivity")) {
        this.closeChatBox();
        if (!isSafari) {
          let audio = new Audio(
            "https://soundbible.com/mp3/Elevator%20Ding-SoundBible.com-685385892.mp3"
          );
          // audio.play();
        }
        let arr = data.split("|");
        arr.shift();
        let temp = JSON.parse(arr.join("|"));
        let temp2 = { ...this.state, ...temp };
        let specialParticipant = ["liveadmin", "tech"];
        if (
          specialParticipant.includes(
            String(videoRoom?.localParticipant?.identity.trim())
          )
        ) {
          try {
            console.log(JSON.stringify(temp));
          } catch (e) {
            console.log(temp);
          }
        }
        temp2 = { ...temp2, mounting: false };
        this.setState({ ...temp2 });
        let id = setTimeout(() => {
          this.setState({ mounting: true });
          clearTimeout(id);
        }, 0);
      }
      if (data.startsWith("ActivityNotification")) {
        let arr = data.split("|");
        arr.shift();
        let temp = JSON.parse(arr.join("|"));
        let temp2 = { ...this.state, ...temp };
        this.setState({ ...temp2, sendActiveWhiteBoardData: [], selected: 11 });
      }
      if (data.startsWith("EndActivityRequest")) {
        let arr = data.split("|");
        this.closeActivityMethod(arr[1]);
      }
      if (data.startsWith("ViewHomeWorkQuestion")) {
        let arr = data.split("|");
        arr.shift();
        arr = arr.join("|");
        let obj = JSON.parse(arr);
        console.log(obj);
        this.setState({
          selected: 12,
          miscellaneousId: obj?.miscellaneousId,
          homeWorkCurrentQuestion: obj?.currentQuestion || 0,
          homeWorkStudentId: obj?.studentId || "",
          homewWorkStudentName: obj?.studentName || "",
          displayHomeWorkQuestion:
            obj?.displayHomeWorkQuestion === true
              ? (this.state.displayHomeWorkQuestion || 0) + 1
              : false,
          homework_tag_quiz_id: obj?.quizId,
          homeWorkId: obj?.homeWorkId,
          fetchAgainHomeWorkQuestion:
            Number(obj?.fetchAgainHomeWorkQuestion) || 0,
        });
      }
      if (data.startsWith("FlaggedQuestionsScreen")) {
        this.closeChatBox();
        this.setState({
          selected: 12,
        });
      }
      if (data.startsWith("CheckoutAffirmationNextButton")) {
        let arr = data.split("|");

        arr.shift();
        let obj = JSON.parse(arr[0]);
        this.setState({
          showCheckoutAffirmationNextBtn: { val: obj?.val || 0 },
          selected: 11,
          selectedActivity: obj?.selectedActivity,
          isCheckInActivity: obj?.isCheckInActivity,
          currentScreenName: obj?.currentScreenName,
        });
      }
      if (data.startsWith("TeacherActivityResponseSaved")) {
        this.setState({
          teacherActivityResponseSaved:
            this.state.teacherActivityResponseSaved === true ? 1 : true,
        });
      }
      if (data.startsWith("SendShapeActivityImage")) {
        let arr = data.split("|");
        arr.shift();
        arr = arr.join("|");
        let obj = JSON.parse(arr);
        if (this.props.videoRoom.identity === "tutor") {
          this.setState({
            shapeActivityStudentResponse: {
              [obj?.identity]: obj?.image,
            },
            selected: 11,
          });
        }
      }
      if (data.startsWith("ShapeActivityWhiteboardPoints")) {
        let arr = data.split("|");
        arr.shift();
        arr = arr.join("|");
        let obj = JSON.parse(arr);
        console.log(obj);
        if (true) {
          console.log("kddk", obj.coordinates);
          this.setState({
            shapesActivityTutorsWhiteBoardPoint: obj.coordinates,
            selected: 11,
            renderShapeChallenge: !this.state.renderShapeChallenge,
          });
        }
      }
      if (data.startsWith("AffirmationSelectedValue")) {
        let arr = data.split("|");
        arr.shift();
        arr = arr.join("|");
        let obj = JSON.parse(arr);
        this.setState({
          affirmationActivityStudentResponse: Number(obj?.value) + 1,
          showAffirmationNextButton: true,
          selected: 11,
        });
      }
      if (data.startsWith("ShowAffirmationStories")) {
        let arr = data.split("|");
        arr.shift();
        arr = arr.join("|");
        let obj = JSON.parse(arr);
        this.setState({
          showAffirmationStories: obj?.showAffirmationStories,
          selected: 11,
          showAffirmationNextButton: false,
          selectedActivity: obj?.selectedActivity,
          isCheckInActivity: obj?.isCheckInActivity,
          currentScreenName: obj?.currentScreenName,
        });
      }
      if (data.startsWith("showAffirmationPreviewImageStudent")) {
        let arr = data.split("|");
        console.log(arr, arr[1]);
        this.setState({
          isAffirmationPreviewImage: arr[1] == "true" ? true : false,
          selected: 11,
        });
      }
      if (data.startsWith("FeelingChartSelectedImg")) {
        let arr = data.split("|");
        arr.shift();

        let keys =
          this.state.selectedActivity === "Check In"
            ? "FeelingChartSelectedImg"
            : "FeelingChartSelectedImgCheckout";
        this.setState({
          [keys]: Number(arr[0]) + 1,

          selected: 11,
        });
      }
      if (data.startsWith("FeelingChartOpenDropBox")) {
        let arr = data.split("|");
        arr.shift();

        this.setState({
          feelingChartDropBox: true,
          selected: 11,
        });
      }

      if (data.startsWith("FlaggedQuestionsScreen")) {
        this.closeChatBox();
        this.setState({
          selected: 12,
        });
      }

      if (data.startsWith("PlayQuiz")) {
        this.closeChatBox();
        this.setState({
          currentScreenName: "MathZone",
        });
        let whiteBoardLinesnext = "";
        localStorage.setItem(
          "quizcanvasretainedlines",
          JSON.stringify(whiteBoardLinesnext)
        );

        if (!isSafari) {
          let audio = new Audio(
            "https://soundbible.com/mp3/Elevator%20Ding-SoundBible.com-685385892.mp3"
          );
          // audio.play();
        }
        let arr = data.split("|");
        arr.shift();

        let temp = JSON.parse(arr.join("|"));

        if (!temp?.logicalZoneTrue) {
          this.setState({
            currentScreenName: "MathZone",
          });
        } else {
          this.setState({
            currentScreenName: "LogicZone",
          });
        }

        let temp2 = { ...this.state, ...temp };
        let specialParticipant = ["liveadmin", "tech"];
        if (
          specialParticipant.includes(
            String(videoRoom?.localParticipant?.identity.trim())
          )
        ) {
          try {
            console.log(JSON.stringify(temp));
          } catch (e) {
            console.log(temp);
          }
        }
        temp2 = { ...temp2, mounting: false };
        this.setState({ ...temp2 });
        let id = setTimeout(() => {
          this.setState({ mounting: true });
          clearTimeout(id);
        }, 0);
      }
      if (data.startsWith("Geogebra")) {
        let temp = data.split("|");
        temp.shift();
        console.log(temp, "bbbb");

        let obj = JSON.parse(temp);
        console.log("Temp value", temp);
        this.setState({
          selected: obj.selected,
          geo_id: Number(obj?.geo_id || 0),
        });
      }

      if (data.startsWith("GeoGebraUpdateData")) {
        console.log("data tracks called", data.split("|"));
        this.setState({
          fetchagain: !this.state.fetchagain,
          fetchAginStudentId: data.split("|")[1].trim(" "),
        });
      }
      if (data.startsWith("1ReportSendingPlayQuiz")) {
        this.closeChatBox();
        if (!isSafari) {
          let audio = new Audio(
            "https://soundbible.com/mp3/Elevator%20Ding-SoundBible.com-685385892.mp3"
          );
          // audio.play();
        }

        console.log(String(videoRoom?.localParticipant?.identity).trim());
        let identityChecker = ["tutor", "parent"];
        if (
          !identityChecker.includes(
            String(videoRoom?.localParticipant?.identity).trim()
          )
        ) {
          console.log(
            "inside if",
            String(videoRoom?.localParticipant?.identity).trim() != "parent"
          );
          return;
        }
        let arr = data.split("|");
        let studentScore = JSON.parse(arr[1]);

        let temp2 = arr[2];
        let [studentId, studentName] = temp2.split("-");
        let obj = {
          studentScore: { ...studentScore },
          studentId,
          studentName,
        };

        let temp4 = this.state.allStudentScore;
        temp4.push({ ...obj });
        this.setState({ allStudentScore: [...temp4] });
      }

      if (data.startsWith("receivedMathzoneReceived")) {
        console.log("Math zone is here");
        let arr = data.split("|");
        let identity = arr[1];
        let response = JSON.parse(arr[2]);

        let temp = this.state.studentMathZoneResponseList[identity];
        temp = { ...temp, ...response };
        let y = { ...this.state.studentMathZoneResponseList, [identity]: temp };

        this.setState({ studentMathZoneResponseList: { ...y } });
      }
      //ending
      if (data.startsWith("RaiseHand")) {
        console.log("Raise Hand Data", data);
        var studentRaisedDoubt = data.replace("RaiseHand|", "");
        // var message = studentRaisedDoubt + " has a doubt";

        this.updateRaiseHandMessage(studentRaisedDoubt);
      }
      if (data.startsWith("StarReceived")) {
        var studentGotStar = data.replace("StarReceived|", "");
        onStarReceived(studentGotStar);
        this.setState({
          sendBigStar: true,
          countStar: this.state.countStar + 1,
        });
        if (this.state.countStar == 5) {
          setTimeout(() => {
            this.setState({
              countStar: this.state.countStar + 1,
            });
          }, 5000);
        }
        setTimeout(() => {
          this.setState({
            sendBigStar: false,
          });
        }, 3000);
      }
      if (data.startsWith("SmileyReceived")) {
        var studentGotSmiley = data.replace("SmileyReceived|", "");

        onSmileyReceived(studentGotSmiley);
      }
      if (data.startsWith("ThumbsUpReceived")) {
        var studentGotThumbsUp = data.replace("ThumbsUpReceived|", "");

        onThumbsUpReceived(studentGotThumbsUp);
      }
      if (data.startsWith("ClappingReceived")) {
        var studentGotClapping = data.replace("ClappingReceived|", "");

        onClappingReceived(studentGotClapping);
      }
      if (data.startsWith("ChatMessage")) {
        var dataArray = data.split("|");

        this.chatMessageUpdate(dataArray[1], dataArray[2]);
      }

      if (data.startsWith("ShareScreen")) {
        var studentIdentity = data.replace("ShareScreen|", "");

        onScreenShareReceived(studentIdentity);
      }
      if (data.startsWith("MuteAllStudents")) {
        var studentIdentity = videoRoom.localParticipant.identity;

        onMuteReceived(studentIdentity);
      }
      if (data.startsWith("MuteStudent")) {
        var studentIdentity = data.replace("MuteStudent|", "");
        onMuteReceived(studentIdentity);
      }
      if (data.startsWith("UnMuteAllStudents")) {
        var studentIdentity = videoRoom.localParticipant.identity;
        onUnMuteReceived(studentIdentity);
      }
      if (data.startsWith("UnMuteStudent")) {
        var studentIdentity = data.replace("UnMuteStudent|", "");
        onUnMuteReceived(studentIdentity);
      }
      if (data.startsWith("ChangeVideoState")) {
        var videoState = data.replace("ChangeVideoState|", "");
        var studentIdentity = videoRoom.localParticipant.identity;
        if (videoState == "play") {
          onMuteReceived(studentIdentity);
        } else {
          onUnMuteReceived(studentIdentity);
        }
        if (this.videoScreenElement.current != null)
          this.videoScreenElement.current.updateVideoState(videoState);
      }

      if (data.startsWith("ChangeLessonPage")) {
        var pageNumber = data.replace("ChangeLessonPage|", "");
        if (this.canvasScreenElement.current != null)
          this.canvasScreenElement.current.onPageNumberUpdate(pageNumber);
      }

      if (data.startsWith("HideHand")) {
        var hideHand = data.replace("HideHand|", "");
        this.hideRaiseHandMessage(hideHand);
      }
      if (data.startsWith("TakefullScreenStudentPicture")) {
        this.takeFullScreenShotComponent();
      }
      if (data.startsWith("TakeStudentPictureComponent")) {
        this.takeStudentPictureComponent();
      }

      if (data.startsWith("openPrePostTestReviewQuestionForStudent")) {
        var exercise_id = data.replace(
          "openPrePostTestReviewQuestionForStudent|",
          ""
        );
        this.studentPrePostReview.current.openPrepostReviewQuestion(
          exercise_id
        );
      }

      if (data.startsWith("changePrePostPagination")) {
        var val = data.replace("changePrePostPagination|", "");
        this.changePaginationStudentSideRef.current(Number(val));
      }
      if (data.startsWith("closePrePostTestReviewQues")) {
        //var val = data.replace("changePrePostPagination|", "");
        // this.changePaginationStudentSideRef.current(Number(val));

        this.studentPrePostReview.current.closePrePostReviewQuestion();
      }
    };

    const localParticipantView = () => {
      return (
        <Col>
          <h1>Local Participant</h1>
        </Col>
      );
    };

    const remoteParticipantsViewforSuccessManagers = remoteParticipants
      .filter(
        (participant) =>
          participant.identity !== "tutor" && participant.identity !== "tech"
      )
      .map((participant) => {
        const dyanmic_star_var = participant.identity + "_star";
        const dyanmic_mute_var = participant.identity + "_mute";
        const dyanmic_hand_var = participant.identity + "_hand";
        const dynamic_screen_var = participant.identity + "_screen";

        return (
          <Col
            style={{
              paddingLeft: "0",
              paddingRight: "0",
              marginTop: this.state.selected != 1 ? "10px" : "",
            }}
            onMouseEnter={() => this.showStar()}
            onMouseLeave={() => this.hideStar()}
            key={participant.sid}
            md={maxDevice}
            xs={12}
            className="remote-participant-view"
          >
            {this.state.selected != 1 && remoteParticipants.length + 1 > 5 ? (
              <React.Fragment>
                <div className="other-students">
                  <Row style={{ position: "relative" }}>
                    <Col md={12}>
                      <Row className="row-padding">
                        <Image
                          style={{ width: "45px" }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/avatar.png"
                          }
                          alt="Kid"
                        />

                        <div
                          style={{
                            position: "absolute",
                            left: "28%",
                            top: "2%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "none" }}>
                            <Participant
                              key={participant.sid}
                              participant={participant}
                              selected={this.state.selected}
                              rparticipants={remoteParticipants.length}
                            />
                          </div>
                          <p className="name-text">
                            <Signal
                              signalLevel={
                                videoRoom.localParticipant.networkQualityLevel
                              }
                            />
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "inline-block",
                              }}
                            >
                              {studentName(participant.identity)}
                            </span>
                          </p>
                          <p
                            style={{ marginTop: "-5px" }}
                            className="zero-margin"
                          >
                            {!this.smPresent(
                              videoRoom.localParticipant.identity
                            ) ? (
                              <a
                                onClick={() =>
                                  this.sendStarToStudent(participant.identity)
                                }
                              >
                                <Image
                                  className="star-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Star.svg"
                                  }
                                  alt="Star"
                                />

                                {this.state[participant.identity + "_star"] ==
                                undefined ? (
                                  <span style={{ fontSize: "9px" }}>0</span>
                                ) : (
                                  <span style={{ fontSize: "9px" }}>
                                    {this.state[participant.identity + "_star"]}
                                  </span>
                                )}
                              </a>
                            ) : null}
                          </p>
                          )
                        </div>

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.startStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StartSharing.svg"
                                }
                                alt="Start screen share"
                              />
                            </a>
                          ) : (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.stopStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StopSharing.svg"
                                }
                                alt="Stop screen share"
                              />
                            </a>
                          )
                        ) : this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StartSharing.svg"
                              }
                              alt="Start screen share"
                            />
                          </a>
                        ) : (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StopSharing.svg"
                              }
                              alt="Stop screen share"
                            />
                          </a>
                        )}

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                            <div>
                              <a
                                onClick={() =>
                                  this.muteClickBtn(participant.identity)
                                }
                              >
                                <Image
                                  style={{
                                    position: "absolute",
                                    right: "7%",
                                    top: "10px",
                                    cursor: "pointer",
                                    width: "25px",
                                  }}
                                  className=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Unmute.svg"
                                  }
                                  alt="Unmute"
                                />
                              </a>
                            </div>
                          ) : (
                            <a
                              onClick={() =>
                                this.unMuteClickBtn(participant.identity)
                              }
                            >
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  cursor: "pointer",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Mute.svg"
                                }
                                alt="Mute"
                              />
                            </a>
                          )
                        ) : this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                          <div>
                            <a>
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Unmute.svg"
                                }
                                alt="Unmute"
                              />
                            </a>
                          </div>
                        ) : (
                          <a>
                            <Image
                              style={{
                                position: "absolute",
                                right: "7%",
                                top: "10px",
                                width: "25px",
                              }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Mute.svg"
                              }
                              alt="Mute"
                            />
                          </a>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Participant
                  key={participant.sid}
                  participant={participant}
                  selected={this.state.selected}
                  rparticipants={remoteParticipants.length}
                />
              </React.Fragment>
            )}

            {this.state.selected === 1 ? (
              <React.Fragment>
                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "50%",
                  //     left: "36%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/SmileyAnimation.svg"
                  //   }
                  //   alt="Smiley Animation"
                  // />
                  <SmileyLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                  //   }
                  //   alt="Thumbs Up Animation"
                  // />
                  <ThumbsUpLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ClappingAnimation.svg"
                  //   }
                  //   alt="Clapping Animation"
                  // />
                  <ClappingLottie />
                ) : null}

                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "50%",
                  //     left: "36%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                <div
                  className="row-menu"
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "100%",
                    top: "5px",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      className="flex-p"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "15px 8px",
                      }}
                    >
                      <Signal signalLevel={participant.networkQualityLevel} />
                      {studentName(participant.identity)}
                    </p>
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendThumbsUpToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/ThumbsUp.svg"
                          }
                          alt="Thumbs Up Count"
                        />
                        {this.state[participant.identity + "_thumbsUp"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_thumbsUp"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/ThumbsUp.svg"
                          }
                          alt="Thumbs Up Count"
                        />
                        {this.state[participant.identity + "_thumbsUp"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_thumbsUp"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendClappingToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Clapping.svg"
                          }
                          alt="Clapping Count"
                        />
                        {this.state[participant.identity + "_clapping"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_clapping"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Clapping.svg"
                          }
                          alt="Clapping Count"
                        />
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendSmileyToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Smiley.svg"
                          }
                          alt="Smiley Count"
                        />
                        {this.state[participant.identity + "_smiley"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_smiley"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Smiley.svg"
                          }
                          alt="Smiley Count"
                        />
                        {this.state[participant.identity + "_smiley"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_smiley"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendStarToStudent(participant.identity)
                        }
                      >
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Star.svg"
                          }
                          alt="Star Count"
                        />

                        {this.state[participant.identity + "_star"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_star"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Star.svg"
                          }
                          alt="Star Count"
                        />
                        {this.state[participant.identity + "_star"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_star"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      this.state[dynamic_screen_var] == undefined ||
                      this.state[dynamic_screen_var] == false ? (
                        <a>
                          <Image
                            className=""
                            style={{ width: "44px", marginTop: "4px" }}
                            onClick={() =>
                              this.startStudentScreenShare(participant.identity)
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StartSharing.svg"
                            }
                            alt="Start screen share"
                          />
                        </a>
                      ) : (
                        <a>
                          <Image
                            className=""
                            style={{ width: "40px" }}
                            onClick={() =>
                              this.stopStudentScreenShare(participant.identity)
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StopSharing.svg"
                            }
                            alt="Stop screen share"
                          />
                        </a>
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      this.state[dyanmic_mute_var] == undefined ||
                      this.state[dyanmic_mute_var] == false ? (
                        <a
                          style={{ bottom: "5%" }}
                          onClick={() =>
                            this.muteClickBtn(participant.identity)
                          }
                        >
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                            }
                            alt="Settings"
                          />
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            this.unMuteClickBtn(participant.identity)
                          }
                        >
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-mutebtn.svg"
                            }
                            alt="Settings"
                          />
                        </a>
                      )
                    ) : this.state[dyanmic_mute_var] == undefined ||
                      this.state[dyanmic_mute_var] == false ? (
                      <a>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Allscreen-mutebtn.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    )}
                  </div>
                </div>

                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      right: "5%",
                      top: "5%",
                      cursor: "pointer",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/RaiseHandRequest.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-15px",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/student-images/Raised-Hand.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}

                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "9%",
                  //     transform: "translate(-50%,-50%)",
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/SmileyAnimation.svg"
                  //   }
                  //   alt="Smiley Animation"
                  // />
                  <SmileyLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ClappingAnimation.svg"
                  //   }
                  //   alt="Clapping Animation"
                  // />
                  <ClappingLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                  //   }
                  //   alt="Thumbs Up Animation"
                  // />
                  <ThumbsUpLottie />
                ) : null}
              </React.Fragment>
            )}

            {this.state.selected !== 1 && remoteParticipants.length + 1 <= 5 ? (
              <div
                className="adjustwidthformorethenfive"
                style={{
                  backgroundColor: "white",
                  padding: "5px ​10px",
                  borderRadius: "0px 0px 6px 6px",
                  boxShadow: "2px 2px 14px rgba(11, 66, 171, 0.15)",
                  height: "70px",
                }}
              >
                <Row style={{ padding: "7px 15px", position: "relative" }}>
                  <span
                    style={{
                      fontSize: "9px",
                      fontWeight: "600",
                      color: "#233584",
                      position: "absolute",
                      lineHeight: "25px",
                      left: "10%",
                      width: "30%",
                      display: "flex",
                    }}
                  >
                    <Signal
                      signalLevel={
                        videoRoom.localParticipant.networkQualityLevel
                      }
                    />
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "inline-block",
                      }}
                    >
                      {studentName(participant.identity)}
                    </span>
                  </span>

                  <a style={{ position: "absolute", left: "67%", top: "25%" }}>
                    <Image
                      onClick={() =>
                        this.sendStarToStudent(participant.identity)
                      }
                      className=""
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Star.svg"
                      }
                      alt="Star"
                    />
                    {this.state[participant.identity + "_star"] == undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <>
                        <span
                          style={{
                            marginLeft: "7px",
                            color: "#676971",
                            fontSize: "9px",
                          }}
                        >
                          {this.state[participant.identity + "_star"]}
                        </span>
                      </>
                    )}
                  </a>

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dynamic_screen_var] == undefined ||
                    this.state[dynamic_screen_var] == false ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "22%",
                          top: "0%",
                          width: "35px",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          className=""
                          onClick={() =>
                            this.startStudentScreenShare(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StartSharing.svg"
                          }
                          alt="Start screen share"
                        />
                      </a>
                    ) : (
                      <a
                        style={{
                          position: "absolute",
                          right: "22%",
                          top: "0%",
                          width: "35px",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          className=""
                          onClick={() =>
                            this.stopStudentScreenShare(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StopSharing.svg"
                          }
                          alt="Stop screen share"
                        />
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dyanmic_mute_var] == undefined ||
                    this.state[dyanmic_mute_var] == false ? (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.muteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Unmute.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.unMuteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Mute.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    )
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            ) : null}
          </Col>
        );
      });

    let id = this.props.students;
    let filtername = studentIDfullname(id);

    const remoteParticipantsViewforParent = remoteParticipants
      .filter(
        (participant) =>
          participant.identity !== "tutor" &&
          participant.identity !== "tech" &&
          participant.identity == filtername
      )
      .map((participant) => {
        const dyanmic_star_var = participant.identity + "_star";
        const dyanmic_mute_var = participant.identity + "_mute";
        const dyanmic_hand_var = participant.identity + "_hand";
        const dynamic_screen_var = participant.identity + "_screen";

        return (
          <Col
            style={{
              paddingLeft: "0",
              paddingRight: "0",
              marginTop: this.state.selected != 1 ? "10px" : "",
            }}
            onMouseEnter={() => this.showStar()}
            onMouseLeave={() => this.hideStar()}
            key={participant.sid}
            md={maxDevice}
            xs={12}
            className="remote-participant-view"
          >
            {this.state.selected != 1 && remoteParticipants.length + 1 > 5 ? (
              <React.Fragment>
                <div className="other-students">
                  <Row style={{ position: "relative" }}>
                    <Col md={12}>
                      <Row className="row-padding">
                        <Image
                          style={{ width: "45px" }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/avatar.png"
                          }
                          alt="Kid"
                        />

                        <div
                          style={{
                            position: "absolute",
                            left: "28%",
                            top: "2%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "none" }}>
                            <Participant
                              key={participant.sid}
                              participant={participant}
                              selected={this.state.selected}
                              rparticipants={remoteParticipants.length}
                            />
                          </div>
                          <p className="name-text">
                            <Signal
                              signalLevel={
                                videoRoom.localParticipant.networkQualityLevel
                              }
                            />
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "inline-block",
                              }}
                            >
                              {studentName(participant.identity)}
                            </span>
                          </p>
                          <p
                            style={{ marginTop: "-5px" }}
                            className="zero-margin"
                          >
                            {videoRoom.participant.identity != "parent" ? (
                              <a
                                onClick={() =>
                                  this.sendStarToStudent(participant.identity)
                                }
                              >
                                <Image
                                  className="star-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Star.svg"
                                  }
                                  alt="Star"
                                />

                                {this.state[participant.identity + "_star"] ==
                                undefined ? (
                                  <span style={{ fontSize: "9px" }}>0</span>
                                ) : (
                                  <span style={{ fontSize: "9px" }}>
                                    {this.state[participant.identity + "_star"]}
                                  </span>
                                )}
                              </a>
                            ) : null}
                          </p>
                          )
                        </div>

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.startStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StartSharing.svg"
                                }
                                alt="Start screen share"
                              />
                            </a>
                          ) : (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.stopStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StopSharing.svg"
                                }
                                alt="Stop screen share"
                              />
                            </a>
                          )
                        ) : this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StartSharing.svg"
                              }
                              alt="Start screen share"
                            />
                          </a>
                        ) : (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StopSharing.svg"
                              }
                              alt="Stop screen share"
                            />
                          </a>
                        )}

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                            <div>
                              <a
                                onClick={() =>
                                  this.muteClickBtn(participant.identity)
                                }
                              >
                                <Image
                                  style={{
                                    position: "absolute",
                                    right: "7%",
                                    top: "10px",
                                    cursor: "pointer",
                                    width: "25px",
                                  }}
                                  className=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Unmute.svg"
                                  }
                                  alt="Unmute"
                                />
                              </a>
                            </div>
                          ) : (
                            <a
                              onClick={() =>
                                this.unMuteClickBtn(participant.identity)
                              }
                            >
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  cursor: "pointer",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Mute.svg"
                                }
                                alt="Mute"
                              />
                            </a>
                          )
                        ) : this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                          <div>
                            <a>
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Unmute.svg"
                                }
                                alt="Unmute"
                              />
                            </a>
                          </div>
                        ) : (
                          <a>
                            <Image
                              style={{
                                position: "absolute",
                                right: "7%",
                                top: "10px",
                                width: "25px",
                              }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Mute.svg"
                              }
                              alt="Mute"
                            />
                          </a>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Participant
                  key={participant.sid}
                  participant={participant}
                  selected={this.state.selected}
                  rparticipants={remoteParticipants.length}
                />
              </React.Fragment>
            )}

            {this.state.selected === 1 ? (
              <React.Fragment>
                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "50%",
                  //     left: "36%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/SmileyAnimation.svg"
                  //   }
                  //   alt="Smiley Animation"
                  // />
                  <SmileyLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                  //   }
                  //   alt="Thumbs Up Animation"
                  // />
                  <ThumbsUpLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "35%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 60,
                  //     width:60
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ClappingAnimation.svg"
                  //   }
                  //   alt="Clapping Animation"
                  // />
                  <ClappingLottie />
                ) : null}

                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "50%",
                  //     left: "36%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                <div
                  className="row-menu"
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "100%",
                    top: "5px",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      className="flex-p"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "15px 8px",
                      }}
                    >
                      <Signal signalLevel={participant.networkQualityLevel} />
                      {studentName(participant.identity)}
                    </p>
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendThumbsUpToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/ThumbsUp.svg"
                          }
                          alt="Thumbs Up Count"
                        />
                        {this.state[participant.identity + "_thumbsUp"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_thumbsUp"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/ThumbsUp.svg"
                          }
                          alt="Thumbs Up Count"
                        />
                        {this.state[participant.identity + "_thumbsUp"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_thumbsUp"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendClappingToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Clapping.svg"
                          }
                          alt="Clapping Count"
                        />
                        {this.state[participant.identity + "_clapping"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_clapping"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Clapping.svg"
                          }
                          alt="Clapping Count"
                        />
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendSmileyToStudent(participant.identity)
                        }
                      >
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Smiley.svg"
                          }
                          alt="Smiley Count"
                        />
                        {this.state[participant.identity + "_smiley"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_smiley"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          style={{ height: 25, width: 25 }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Smiley.svg"
                          }
                          alt="Smiley Count"
                        />
                        {this.state[participant.identity + "_smiley"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_smiley"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.sendStarToStudent(participant.identity)
                        }
                      >
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Star.svg"
                          }
                          alt="Star Count"
                        />

                        {this.state[participant.identity + "_star"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_star"]}
                          </span>
                        )}
                      </a>
                    ) : (
                      <a style={{ cursor: "default" }}>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Star.svg"
                          }
                          alt="Star Count"
                        />
                        {this.state[participant.identity + "_star"] ==
                        undefined ? (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            0
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {this.state[participant.identity + "_star"]}
                          </span>
                        )}
                      </a>
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      this.state[dynamic_screen_var] == undefined ||
                      this.state[dynamic_screen_var] == false ? (
                        <a>
                          <Image
                            className=""
                            style={{ width: "44px", marginTop: "4px" }}
                            onClick={() =>
                              this.startStudentScreenShare(participant.identity)
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StartSharing.svg"
                            }
                            alt="Start screen share"
                          />
                        </a>
                      ) : (
                        <a>
                          <Image
                            className=""
                            style={{ width: "40px" }}
                            onClick={() =>
                              this.stopStudentScreenShare(participant.identity)
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StopSharing.svg"
                            }
                            alt="Stop screen share"
                          />
                        </a>
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      this.state[dyanmic_mute_var] == undefined ||
                      this.state[dyanmic_mute_var] == false ? (
                        <a
                          style={{ bottom: "5%" }}
                          onClick={() =>
                            this.muteClickBtn(participant.identity)
                          }
                        >
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                            }
                            alt="Settings"
                          />
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            this.unMuteClickBtn(participant.identity)
                          }
                        >
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-mutebtn.svg"
                            }
                            alt="Settings"
                          />
                        </a>
                      )
                    ) : this.state[dyanmic_mute_var] == undefined ||
                      this.state[dyanmic_mute_var] == false ? (
                      <a>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Allscreen-mutebtn.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    )}
                  </div>
                </div>

                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      right: "5%",
                      top: "5%",
                      cursor: "pointer",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/RaiseHandRequest.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-15px",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/student-images/Raised-Hand.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}

                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "35%",
                  //     left: "9%",
                  //     transform: "translate(-50%,-50%)",
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/SmileyAnimation.svg"
                  //   }
                  //   alt="Smiley Animation"
                  // />
                  <SmileyLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ClappingAnimation.svg"
                  //   }
                  //   alt="Clapping Animation"
                  // />
                  <ClappingLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  // <Image
                  //   className={
                  //     this.state[dyanmic_hand_var] == undefined ||
                  //     this.state[dyanmic_hand_var] == false
                  //       ? "hidden"
                  //       : "shown"
                  //   }
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "30%",
                  //     transform: "translate(-50%,-50%)",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                  //   }
                  //   alt="Thumbs Up Animation"
                  // />
                  <ThumbsUpLottie />
                ) : null}
              </React.Fragment>
            )}

            {this.state.selected !== 1 && remoteParticipants.length + 1 <= 5 ? (
              <div
                className="adjustwidthformorethenfive"
                style={{
                  backgroundColor: "white",
                  padding: "5px ​10px",
                  borderRadius: "0px 0px 6px 6px",
                  boxShadow: "2px 2px 14px rgba(11, 66, 171, 0.15)",
                  height: "70px",
                }}
              >
                <Row style={{ padding: "7px 15px", position: "relative" }}>
                  <span
                    style={{
                      fontSize: "9px",
                      fontWeight: "600",
                      color: "#233584",
                      position: "absolute",
                      lineHeight: "25px",
                      left: "10%",
                      width: "30%",
                      display: "flex",
                    }}
                  >
                    <Signal
                      signalLevel={
                        videoRoom.localParticipant.networkQualityLevel
                      }
                    />
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "inline-block",
                      }}
                    >
                      {studentName(participant.identity)}
                    </span>
                  </span>

                  <a style={{ position: "absolute", left: "67%", top: "25%" }}>
                    <Image
                      onClick={() =>
                        this.sendStarToStudent(participant.identity)
                      }
                      className=""
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Star.svg"
                      }
                      alt="Star"
                    />
                    {this.state[participant.identity + "_star"] == undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <>
                        <span
                          style={{
                            marginLeft: "7px",
                            color: "#676971",
                            fontSize: "9px",
                          }}
                        >
                          {this.state[participant.identity + "_star"]}
                        </span>
                      </>
                    )}
                  </a>

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dynamic_screen_var] == undefined ||
                    this.state[dynamic_screen_var] == false ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "22%",
                          top: "0%",
                          width: "35px",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          className=""
                          onClick={() =>
                            this.startStudentScreenShare(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StartSharing.svg"
                          }
                          alt="Start screen share"
                        />
                      </a>
                    ) : (
                      <a
                        style={{
                          position: "absolute",
                          right: "22%",
                          top: "0%",
                          width: "35px",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          className=""
                          onClick={() =>
                            this.stopStudentScreenShare(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StopSharing.svg"
                          }
                          alt="Stop screen share"
                        />
                      </a>
                    )
                  ) : (
                    ""
                  )}

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dyanmic_mute_var] == undefined ||
                    this.state[dyanmic_mute_var] == false ? (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.muteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Unmute.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.unMuteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Mute.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    )
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            ) : null}
          </Col>
        );
      });

    const remoteParticipantsView = remoteParticipants
      .filter(
        (participant) =>
          participant.identity !== "tutor" &&
          participant.identity !== "tech" &&
          participant.identity !== "parent" &&
          !this.smPresent(participant.identity)
      )
      .map((participant) => {
        const dyanmic_star_var = participant.identity + "_star";
        const dyanmic_mute_var = participant.identity + "_mute";
        const dyanmic_hand_var = participant.identity + "_hand";
        const dynamic_screen_var = participant.identity + "_screen";

        // if (this.state.muteAllState) {
        //   this.muteRemoteStudent(participant.identity);
        // } else {
        //   this.unMuteRemoteStudent(participant.identity);
        // }

        return (
          <Col
            style={{
              paddingLeft: "0",
              paddingRight: "0",
              marginTop: this.state.selected != 1 ? "10px" : "",
            }}
            onMouseEnter={() => this.showStar()}
            onMouseLeave={() => this.hideStar()}
            key={participant.sid}
            md={maxDevice}
            xs={12}
            className="remote-participant-view"
          >
            {this.state.selected != 1 && remoteParticipants.length + 1 > 5 ? (
              <React.Fragment>
                <div className="other-students">
                  <Row style={{ position: "relative" }}>
                    <Col md={12}>
                      <Row className="row-padding">
                        <Image
                          style={{ width: "45px" }}
                          className=""
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/avatar.png"
                          }
                          alt="Kid"
                        />

                        <div
                          style={{
                            position: "absolute",
                            left: "28%",
                            top: "2%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "none" }}>
                            <Participant
                              key={participant.sid}
                              participant={participant}
                              selected={this.state.selected}
                              rparticipants={remoteParticipants.length}
                            />
                          </div>
                          <p className="name-text">
                            <Signal
                              signalLevel={
                                videoRoom.localParticipant.networkQualityLevel
                              }
                            />
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "inline-block",
                              }}
                            >
                              {studentName(participant.identity)}
                            </span>
                          </p>
                          <p
                            style={{ marginTop: "-5px" }}
                            className="zero-margin"
                          >
                            {videoRoom.participant.identity != "parent" ? (
                              <a
                                onClick={() =>
                                  this.sendStarToStudent(participant.identity)
                                }
                              >
                                <Image
                                  className="star-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Star.svg"
                                  }
                                  alt="Star"
                                />

                                {this.state[participant.identity + "_star"] ==
                                undefined ? (
                                  <span style={{ fontSize: "9px" }}>0</span>
                                ) : (
                                  <span style={{ fontSize: "9px" }}>
                                    {this.state[participant.identity + "_star"]}
                                  </span>
                                )}
                              </a>
                            ) : null}
                          </p>
                          )
                        </div>

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.startStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StartSharing.svg"
                                }
                                alt="Start screen share"
                              />
                            </a>
                          ) : (
                            <a
                              style={{
                                position: "absolute",
                                right: "25%",
                                top: "0px",
                                width: "50px",
                                cursor: "pointer",
                              }}
                            >
                              <Image
                                className=""
                                onClick={() =>
                                  this.stopStudentScreenShare(
                                    participant.identity
                                  )
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/StopSharing.svg"
                                }
                                alt="Stop screen share"
                              />
                            </a>
                          )
                        ) : this.state[dynamic_screen_var] == undefined ||
                          this.state[dynamic_screen_var] == false ? (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StartSharing.svg"
                              }
                              alt="Start screen share"
                            />
                          </a>
                        ) : (
                          <a
                            style={{
                              position: "absolute",
                              right: "25%",
                              top: "0px",
                              width: "50px",
                            }}
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StopSharing.svg"
                              }
                              alt="Stop screen share"
                            />
                          </a>
                        )}

                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                            <div>
                              <a
                                onClick={() =>
                                  this.muteClickBtn(participant.identity)
                                }
                              >
                                <Image
                                  style={{
                                    position: "absolute",
                                    right: "7%",
                                    top: "10px",
                                    cursor: "pointer",
                                    width: "25px",
                                  }}
                                  className=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Unmute.svg"
                                  }
                                  alt="Unmute"
                                />
                              </a>
                            </div>
                          ) : (
                            <a
                              onClick={() =>
                                this.unMuteClickBtn(participant.identity)
                              }
                            >
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  cursor: "pointer",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Mute.svg"
                                }
                                alt="Mute"
                              />
                            </a>
                          )
                        ) : this.state[dyanmic_mute_var] == undefined ||
                          this.state[dyanmic_mute_var] == false ? (
                          <div>
                            <a>
                              <Image
                                style={{
                                  position: "absolute",
                                  right: "7%",
                                  top: "10px",
                                  width: "25px",
                                }}
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Unmute.svg"
                                }
                                alt="Unmute"
                              />
                            </a>
                          </div>
                        ) : (
                          <a>
                            <Image
                              style={{
                                position: "absolute",
                                right: "7%",
                                top: "10px",
                                width: "25px",
                              }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Mute.svg"
                              }
                              alt="Mute"
                            />
                          </a>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Participant
                  key={participant.sid}
                  participant={participant}
                  selected={this.state.selected}
                  rparticipants={remoteParticipants.length}
                />
              </React.Fragment>
            )}

            {this.state.selected === 1 ? (
              <React.Fragment>
                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "50%",
                  //     left: "36%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/StarAnimation.svg"
                  //   }
                  //   alt="Star Animation"
                  // />
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "40%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/SmileyAnimation.svg"
                  //   }
                  //   alt="Smiley Animation"
                  // />
                  <SmileyLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "40%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ClappingAnimation.svg"
                  //   }
                  //   alt="Clapping Animation"
                  // />
                  <ClappingLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  // <Image
                  //   style={{
                  //     position: "absolute",
                  //     top: "40%",
                  //     left: "40%",
                  //     transform: "translate(-50%,-50%)",
                  //     zIndex: "9999",
                  //     height: 120,
                  //     width: 120
                  //   }}
                  //   className="pulse"
                  //   src={
                  //     process.env.PUBLIC_URL +
                  //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                  //   }
                  //   alt="ThumbsUp Animation"
                  // />
                  <ThumbsUpLottie />
                ) : null}

                <div
                  className="row-menu"
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "100%",
                    top: "5px",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      className="flex-p"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "15px 8px",
                      }}
                    >
                      <Signal signalLevel={participant.networkQualityLevel} />
                      {studentName(participant.identity)}
                    </p>
                  </div>

                  <div
                    style={{
                      // border:"1px solid red",
                      display: "flex",
                      gap: "20px",
                      marginLeft: "5em",
                    }}
                  >
                    <div
                      style={{
                        // border:"1px solid teal",
                        display: "flex",
                        marginTop: "10px",
                        gap: "10px",
                      }}
                    >
                      <div>
                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.sendClappingToStudent(participant.identity)
                            }
                          >
                            <Image
                              style={{
                                height: 25,
                                width: 25,
                                marginRight: "5px",
                              }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Clapping.svg"
                              }
                              alt="Clapping Count"
                            />
                            {this.state[participant.identity + "_clapping"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_clapping"]}
                              </span>
                            )}
                          </a>
                        ) : (
                          <a style={{ cursor: "default" }}>
                            <Image
                              style={{ height: 25, width: 25 }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Clapping.svg"
                              }
                              alt="Clapping Count"
                            />
                            {this.state[participant.identity + "_clapping"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_clapping"]}
                              </span>
                            )}
                          </a>
                        )}
                      </div>

                      <div>
                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.sendThumbsUpToStudent(participant.identity)
                            }
                          >
                            <Image
                              style={{
                                height: 25,
                                width: 25,
                                marginRight: "5px",
                              }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/ThumbsUp.svg"
                              }
                              alt="Thumbs up Count"
                            />
                            {this.state[participant.identity + "_thumbsUp"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_thumbsUp"]}
                              </span>
                            )}
                          </a>
                        ) : (
                          <a style={{ cursor: "default" }}>
                            <Image
                              style={{ height: 25, width: 25 }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/ThumbsUp.svg"
                              }
                              alt="Thumbs Up Count"
                            />
                            {this.state[participant.identity + "_thumbsUp"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_thumbsUp"]}
                              </span>
                            )}
                          </a>
                        )}
                      </div>

                      <div>
                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.sendSmileyToStudent(participant.identity)
                            }
                          >
                            <Image
                              style={{ height: 25, width: 25 }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Smiley.svg"
                              }
                              alt="Star Count"
                            />
                            {this.state[participant.identity + "_smiley"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_smiley"]}
                              </span>
                            )}
                          </a>
                        ) : (
                          <a style={{ cursor: "default" }}>
                            <Image
                              style={{ height: 25, width: 25 }}
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Smiley.svg"
                              }
                              alt="Star Count"
                            />
                            {this.state[participant.identity + "_smiley"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_smiley"]}
                              </span>
                            )}
                          </a>
                        )}
                      </div>

                      <div>
                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.sendStarToStudent(participant.identity)
                            }
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Star.svg"
                              }
                              alt="Star Count"
                            />
                            {this.state[participant.identity + "_star"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_star"]}
                              </span>
                            )}
                          </a>
                        ) : (
                          <a style={{ cursor: "default" }}>
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Star.svg"
                              }
                              alt="Star Count"
                            />
                            {this.state[participant.identity + "_star"] ==
                            undefined ? (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                0
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginLeft: "7px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state[participant.identity + "_star"]}
                              </span>
                            )}
                          </a>
                        )}
                      </div>
                    </div>

                    <div>
                      {isTutorCheck(videoRoom.localParticipant.identity) ? (
                        this.state[dynamic_screen_var] == undefined ||
                        this.state[dynamic_screen_var] == false ? (
                          <a>
                            <Image
                              className=""
                              style={{ width: "44px", marginTop: "7px" }}
                              onClick={() =>
                                this.startStudentScreenShare(
                                  participant.identity
                                )
                              }
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StartSharing.svg"
                              }
                              alt="Start screen share"
                            />
                          </a>
                        ) : (
                          <a>
                            <Image
                              className=""
                              style={{ width: "40px" }}
                              onClick={() =>
                                this.stopStudentScreenShare(
                                  participant.identity
                                )
                              }
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StopSharing.svg"
                              }
                              alt="Stop screen share"
                            />
                          </a>
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div>
                      {isTutorCheck(videoRoom.localParticipant.identity) ? (
                        this.state[dyanmic_mute_var] == undefined ||
                        this.state[dyanmic_mute_var] == false ? (
                          <a
                            style={{ bottom: "5%" }}
                            onClick={() =>
                              this.muteClickBtn(participant.identity)
                            }
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                              }
                              alt="Settings"
                            />
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              this.unMuteClickBtn(participant.identity)
                            }
                          >
                            <Image
                              className=""
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/Allscreen-mutebtn.svg"
                              }
                              alt="Settings"
                            />
                          </a>
                        )
                      ) : this.state[dyanmic_mute_var] == undefined ||
                        this.state[dyanmic_mute_var] == false ? (
                        <a>
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                            }
                            alt="Unmute"
                          />
                        </a>
                      ) : (
                        <a>
                          <Image
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/Allscreen-mutebtn.svg"
                            }
                            alt="Mute"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      right: "5%",
                      top: "5%",
                      cursor: "pointer",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/RaiseHandRequest.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isTutorCheck(videoRoom.localParticipant.identity) ? (
                  <Image
                    onClick={() => this.hideRaiseHand(participant.identity)}
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-15px",
                    }}
                    className={
                      this.state[dyanmic_hand_var] == undefined ||
                      this.state[dyanmic_hand_var] == false
                        ? "hidden"
                        : "shown"
                    }
                    src={
                      process.env.PUBLIC_URL +
                      "/static/media/Images-v2/student-images/Raised-Hand.svg"
                    }
                    alt="Raise hand"
                  />
                ) : (
                  ""
                )}

                {this.state.starClicked &&
                this.state.starClickedStudent == participant.identity ? (
                  <StarLottie />
                ) : null}

                {this.state.smileyClicked &&
                this.state.smileyClickedStudent == participant.identity ? (
                  <SmileyLottie />
                ) : null}

                {this.state.clappingClicked &&
                this.state.clappingClickedStudent == participant.identity ? (
                  <ClappingLottie />
                ) : null}

                {this.state.thumbsUpClicked &&
                this.state.thumbsUpClickedStudent == participant.identity ? (
                  <ThumbsUpLottie />
                ) : null}
              </React.Fragment>
            )}

            {this.state.selected !== 1 && remoteParticipants.length + 1 <= 5 ? (
              <div
                className="adjustwidthformorethenfive"
                style={{
                  backgroundColor: "white",
                  padding: "5px ​10px",
                  borderRadius: "0px 0px 6px 6px",
                  boxShadow: "2px 2px 14px rgba(11, 66, 171, 0.15)",
                  height: "70px",
                }}
              >
                <Row style={{ padding: "7px 15px", position: "relative" }}>
                  <span
                    style={{
                      fontSize: "9px",
                      fontWeight: "600",
                      color: "#233584",
                      position: "absolute",
                      lineHeight: "25px",
                      left: "10%",
                      width: "30%",
                      display: "flex",
                    }}
                  >
                    <Signal
                      signalLevel={
                        videoRoom.localParticipant.networkQualityLevel
                      }
                    />
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "inline-block",
                      }}
                    >
                      {studentName(participant.identity)}
                    </span>
                  </span>

                  <a
                    style={{
                      position: "absolute",
                      left: "72%",
                      top: "62%",
                      cursor:
                        videoRoom.localParticipant.identity === "tutor"
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <br />
                    <Image
                      style={{ height: 25, width: 25 }}
                      onClick={() =>
                        this.sendThumbsUpToStudent(participant.identity)
                      }
                      className="thumbsUp"
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/ThumbsUp.svg"
                      }
                      alt="Thumbs Up"
                    />
                    {this.state[participant.identity + "_thumbsUp"] ==
                    undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        {this.state[participant.identity + "_thumbsUp"]}
                      </span>
                    )}
                  </a>

                  <a
                    style={{
                      position: "absolute",
                      left: "11%",
                      top: "62%",
                      cursor:
                        videoRoom.localParticipant.identity === "tutor"
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <br />
                    <Image
                      style={{ height: 25, width: 25 }}
                      onClick={() =>
                        this.sendClappingToStudent(participant.identity)
                      }
                      className="smileyIcon"
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Clapping.svg"
                      }
                      alt="Clapping"
                    />
                    {this.state[participant.identity + "_clapping"] ==
                    undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        {this.state[participant.identity + "_clapping"]}
                      </span>
                    )}
                  </a>

                  <a
                    style={{
                      position: "absolute",
                      left: "30%",
                      top: "12px",
                      cursor:
                        videoRoom.localParticipant.identity === "tutor"
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <br />
                    <Image
                      style={{ height: 25, width: 25 }}
                      onClick={() =>
                        this.sendSmileyToStudent(participant.identity)
                      }
                      className="smileyIcon"
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Smiley.svg"
                      }
                      alt="Smiley"
                    />
                    {this.state[participant.identity + "_smiley"] ==
                    undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        {this.state[participant.identity + "_smiley"]}
                      </span>
                    )}
                  </a>

                  {/* <a>
                    <Image
                      style={{
                        position: "absolute",
                        right: "12%",
                        top: "9px",
                        // display: "none",
                      }}
                      className=""
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Unmute.svg"
                      }
                      alt="Mute"
                    />
                  </a> */}

                  <a
                    style={{
                      position: "absolute",
                      left: "53%",
                      top: "36px",
                      cursor:
                        videoRoom.localParticipant.identity === "tutor"
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <Image
                      onClick={() =>
                        this.sendStarToStudent(participant.identity)
                      }
                      className=""
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/Star.svg"
                      }
                      alt="Star"
                    />
                    {this.state[participant.identity + "_star"] == undefined ? (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        0
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: "7px",
                          color: "#676971",
                          fontSize: "9px",
                        }}
                      >
                        {this.state[participant.identity + "_star"]}
                      </span>
                    )}
                  </a>

                  {/* {!isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[videoRoom.localParticipant.identity + "_mute"] ==
                      undefined ||
                    this.state[videoRoom.localParticipant.identity + "_mute"] ==
                      false ? (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.muteRemoteStudent(
                              videoRoom.localParticipant.identity
                            )
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Unmute.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.unMuteRemoteStudent(
                              videoRoom.localParticipant.identity
                            )
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Mute.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    )
                  ) : this.state[dyanmic_mute_var] == undefined ||
                    this.state[dyanmic_mute_var] == false ? (
                    <a>
                      <Image
                        style={{
                          position: "absolute",
                          right: "12%",
                          top: "9px",
                          cursor: "pointer",
                        }}
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/Unmute.svg"
                        }
                        alt="Unmute"
                      />
                    </a>
                  ) : (
                    <a>
                      <Image
                        style={{
                          position: "absolute",
                          right: "12%",
                          top: "9px",
                          cursor: "pointer",
                        }}
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/Mute.svg"
                        }
                        alt="Unmute"
                      />
                    </a>
                  )} */}

                  {/* {!isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[videoRoom.localParticipant.identity + "_mute"] ==
                      undefined ||
                    this.state[videoRoom.localParticipant.identity + "_mute"] ==
                      false ? (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.muteRemoteStudent(
                              videoRoom.localParticipant.identity
                            )
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Unmute.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.unMuteRemoteStudent(
                              videoRoom.localParticipant.identity
                            )
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Mute.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    )
                  ) : (
                    ""
                  )} */}

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dynamic_screen_var] == undefined ||
                    this.state[dynamic_screen_var] == false ? (
                      this.state.selected != 5 ? (
                        <a
                          style={{
                            position: "absolute",
                            right: "20%",
                            top: "0%",
                            width: "35px",
                            cursor: "pointer",
                          }}
                        >
                          <Image
                            className=""
                            onClick={() =>
                              this.startStudentScreenShare(participant.identity)
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StartSharing.svg"
                            }
                            alt="Start screen share"
                          />
                        </a>
                      ) : null
                    ) : this.state.selected != 5 ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "36%",
                          top: "0%",
                          width: "35px",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          className=""
                          onClick={() =>
                            this.stopStudentScreenShare(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StopSharing.svg"
                          }
                          alt="Stop screen share"
                        />
                      </a>
                    ) : null
                  ) : (
                    ""
                  )}

                  {isTutorCheck(videoRoom.localParticipant.identity) ? (
                    this.state[dyanmic_mute_var] == undefined ||
                    this.state[dyanmic_mute_var] == false ? (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.muteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Unmute.svg"
                          }
                          alt="Mute"
                        />
                      </a>
                    ) : (
                      <a>
                        <Image
                          style={{
                            position: "absolute",
                            right: "12%",
                            top: "9px",
                            cursor: "pointer",
                          }}
                          className=""
                          onClick={() =>
                            this.unMuteClickBtn(participant.identity)
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/Mute.svg"
                          }
                          alt="Unmute"
                        />
                      </a>
                    )
                  ) : this.state[dyanmic_mute_var] == undefined ||
                    this.state[dyanmic_mute_var] == false ? (
                    <a>
                      <Image
                        style={{
                          position: "absolute",
                          right: "12%",
                          top: "9px",
                          cursor: "pointer",
                        }}
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/Unmute.svg"
                        }
                        alt="Unmute"
                      />
                    </a>
                  ) : (
                    <a>
                      <Image
                        style={{
                          position: "absolute",
                          right: "12%",
                          top: "9px",
                          cursor: "pointer",
                        }}
                        className=""
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/Mute.svg"
                        }
                        alt="Unmute"
                      />
                    </a>
                  )}
                </Row>
              </div>
            ) : null}
          </Col>
        );
      });

    return (
      <EventSubscriber
        events={EVENTS}
        eventEmitterObject={videoRoom}
        onUpdate={this.update}
      >
        <PreviewPic
          show={this.state.showScreenShotPreview}
          onClose={this.handleRemoveScreenShotData}
          imageData={this.state.screenShotArray || []}
          onConfirm={this.uploadScreenShotToServer}
          onAllClose={() =>
            this.setState({ showScreenShotPreview: false, screenShotArray: [] })
          }
          allClose={true}
        />

        {/* {this.state.showUploadResourcesModel && (
          <div
            style={{
              position: "absolute",
              top: 80,
              left: 280,
              width: "50%",
              height: "80%",
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: 10,
              zIndex: 999,
            }}
          >
            <UploadResources />
          </div>
        )} */}

        <ConfirmationModal
          show={this.state.isShowConfirmationModal || false}
          onHide1={() => {
            this.handleCaptureStudentScreen("TakefullScreenStudentPicture");
          }}
          onHide2={() =>
            this.handleCaptureStudentScreen("TakeStudentPictureComponent")
          }
          msg1={"Entire Screen"}
          msg2={"Student Picture"}
          disabled={false}
          onClose={this.handleCloseScreenshotConfirmationModal}
        />
        <ConfirmationModal
          show={this.state.confirmationForGoingAllScreen}
          onHide1={() => {
            this.redirectToAllScreen();
          }}
          onHide2={() => {}}
          msg1={"Goto All Screen"}
          msg2={""}
          disabled={false}
          onClose={this.handleCloseScreenshotConfirmationModal}
          msg={"You can only click entire screen picutre on all screen tab."}
        />
        <canvas
          ref={this.canvas}
          style={{ display: "none" }}
          onClose={this.handleCloseScreenshotConfirmationModal}
        />

        <Card
          onMouseMove={(e) => this.setMouseMove(e)}
          className="video-tutorial"
          ref={this.props.componentRef}
        >
          <Card.Body style={{ padding: "0 1rem" }}>
            {/* {
              this.state.sharedScreenTrack ?
              <div style={{ position: "absolute",width: "400px", height: "300px",zIndex: "1", bottom: "0", right: "0",padding: "1rem",borderRadius: "5px" }}>
                <ScreenParticipant participant={this.state.sharedScreenTrack} /> 
              </div>

                :
                ""
            } */}
            {/* {!isTutorCheck(videoRoom.localParticipant.identity) &&
            this.state.sendBigStar &&
            this.state.countStar < 2 ? (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "2%",
                  zIndex: "9999",
                  textAlign: "center",
                }}
              >
                <BigStar />
              </div>
            ) : null} */}

            {!isTutorCheck(videoRoom.localParticipant.identity) &&
            this.state.countStar == 5 ? (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "2%",
                  zIndex: "9999",
                  textAlign: "center",
                }}
              >
                <FiveStar />
              </div>
            ) : null}

            {isTutorCheck(videoRoom.localParticipant.identity) ? (
              <div>
                {this.state.sharedScreenTrack ? (
                  <Rnd
                    style={{ ...style, position: "fixed" }}
                    size={{
                      width: this.state.width,
                      height: this.state.height,
                    }}
                    position={{ x: this.state.x, y: this.state.y }}
                    onDragStop={(e, d) => {
                      this.setState({ x: d.x, y: d.y });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                      this.setState({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                      });
                    }}
                  >
                    <ScreenParticipant
                      participant={this.state.sharedScreenTrack}
                    />
                  </Rnd>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="student-screenshare">
                {this.state.sharedScreenTrack ? (
                  <div
                    style={{
                      transform: "translate(-50%, -50%)",
                      borderRadius: "5px",
                      padding: "1rem",
                      zIndex: "10",
                      position: "fixed",
                      width: "80%",
                      height: "75%",
                      boxShadow: "2px 4px 8px rgba(6, 50, 163, 0.2)",
                      top: "40%",
                      left: "50%",
                      color: "#fff",
                      backgroundColor: "#fff",
                    }}
                  >
                    <ScreenParticipant
                      participant={this.state.sharedScreenTrack}
                    />
                  </div>
                ) : (
                  // <div id="backdrop" style={{ borderRadius: "5px", padding: "1rem", zIndex: "1", background: "#333", position: "absolute", width: "400px", height: "300px", bottom: "0", right: "0", color: "#fff" }}>
                  //   <h2>jalsfjldasj</h2>
                  //   {/* <ScreenParticipant participant={this.state.sharedScreenTrack} />  */}
                  // </div>

                  ""
                )}
              </div>
            )}

            {this.state.showLesson ? (
              <ListGroup
                onMouseEnter={() => this.showMenus(3)}
                className="video-dropdown"
                as="ul"
              >
                {/* <ListGroup.Item>
                  <a
                    onClick={() =>
                      this.openConfirmationChangeScreenModal(() => {
                        this.openLessonPdf(1, []);
                      })
                    }
                  >
                    Blank board
                  </a>
                </ListGroup.Item> */}

                {trial_pdfs?.length > 0 &&
                  this.props.class_type != "coding" &&
                  trial_pdfs?.map((list, index) => {
                    return (
                      <Accordion>
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={"Demo_123"}
                            >
                              Demo Concept
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={"Demo_123"}>
                            <Card.Body style={{ padding: "10px" }}>
                              <ul>
                                <li
                                  onClick={() =>
                                    this.openConfirmationChangeScreenModal(
                                      () => {
                                        this.openLessonPdf(1, list);
                                      }
                                    )
                                  }
                                  style={{
                                    paddingLeft: "1rem",
                                    color: "#183BCC",
                                    fontSize: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {list.name}
                                </li>
                              </ul>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                  })}
                <Accordion>
                  {concept_list
                    ?.filter(
                      (list) =>
                        list.pdfs !== "" && this.props.class_type != "coding"
                    )
                    .map((list) => {
                      if (list.pdfs.length == 0) {
                        return;
                      }
                      const videoList = list.video;
                      return (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={list.id}
                              style={{
                                color: list.today_class ? "green" : "#233584",
                              }}
                            >
                              {list.today_class ? (
                                <div style={{ display: "flex" }}>
                                  {list.name}
                                  <img
                                    style={{
                                      width: "50px",

                                      margin: "auto",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    src="/static/media/todayClassIconGreen.png"
                                  />
                                </div>
                              ) : (
                                list.name
                              )}
                              {/* {list.name} */}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={list.id}>
                            {/* {
                                 list.video.map((videoData) =>
                                   <Card.Body style={{ padding: "15px" }}>
                                     assdf
                                   </Card.Body>
                                 )
                               } */}
                            <Card.Body style={{ padding: "10px" }}>
                              {
                                <ul className="list-style-decoration">
                                  {list.pdfs.map((pdfData) => {
                                    return (
                                      <li
                                        onClick={() =>
                                          this.openConfirmationChangeScreenModal(
                                            () => {
                                              this.openLessonPdf(
                                                list.id,
                                                pdfData
                                              );
                                            }
                                          )
                                        }
                                        style={{
                                          color:
                                            pdfData.type === "ggb" ||
                                            pdfData.today_class
                                              ? "green"
                                              : "#183BCC",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {pdfData.type === "ggb" ? (
                                          "GGB Lesson" + "-" + pdfData.name
                                        ) : pdfData.today_class ? (
                                          <div style={{ display: "flex" }}>
                                            {pdfData.name}
                                            <img
                                              style={{
                                                width: "50px",

                                                margin: "auto",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                              src={
                                                "/static/media/todayClassIconGreen.png"
                                              }
                                            />
                                          </div>
                                        ) : (
                                          pdfData.name
                                        )}
                                        {/* {pdfData.name} */}
                                      </li>
                                    );
                                  })}
                                </ul>
                              }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                </Accordion>
              </ListGroup>
            ) : null}

            <Row>
              {this.state.showMenus ? (
                <ListGroup
                  onMouseEnter={() => this.showMenus(4)}
                  className="video-dropdown"
                  as="ul"
                >
                  {/* <a onClick={this.hideMenus} className="close-btn">
                    <i class="fa fa-times"></i>
                  </a> */}

                  <ListGroup.Item>
                    <Row>
                      {/* <Col md={3}>
                        <Image
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL + "/static/media/Now-playing.png"
                          }
                          alt="Recently playing"
                        />
                      </Col> */}
                      <Col md={9}>
                        <p style={{ margin: "0", fontSize: "12px" }}>
                          Now Playing
                        </p>
                        <p
                          onClick={this.resumePlayVideo}
                          style={{ margin: "0", fontSize: "16px" }}
                        >
                          {this.state.nowPlayingVideo}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                  </ListGroup.Item>

                  <Accordion>
                    {concept_list
                      ?.filter((list) => list.video !== "")
                      .map((list, index) => {
                        if (list.video.length == 0) {
                          return;
                        }
                        const videoList = list.video;
                        return (
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={list.id}
                              >
                                {list.name}
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={list.id}>
                              {/* {
                                list.video.map((videoData) =>
                                  <Card.Body style={{ padding: "15px" }}>
                                    assdf
                                  </Card.Body>
                                )
                              } */}

                              <Card.Body style={{ padding: "15px" }}>
                                {
                                  <ul>
                                    {list.video.map((videoData) => {
                                      return (
                                        <li
                                          onClick={() =>
                                            this.openConfirmationChangeScreenModal(
                                              () => {
                                                this.openPlayVideo(
                                                  list.id,
                                                  videoData
                                                );
                                              }
                                            )
                                          }
                                          style={{
                                            color: "#183BCC",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {videoData.name}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })}
                  </Accordion>
                </ListGroup>
              ) : null}

              {this.state.showSpeedMathMenus ? (
                <ListGroup
                  style={{ height: "36vh", top: "22rem" }}
                  onMouseEnter={() => this.showMenus(5)}
                  className="video-dropdown"
                  as="ul"
                >
                  {game_levels?.map((game) => {
                    return (
                      <ListGroup.Item>
                        <a
                          onClick={() =>
                            this.openConfirmationChangeScreenModal(() => {
                              this.openSpeedMath(game.id);
                            })
                          }
                        >
                          Level {game.name}
                        </a>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              ) : null}
              {this.state.showMainActivity ? (
                <ListGroup
                  style={{ height: "36vh", top: "25rem" }}
                  onMouseEnter={() => this.showMenus(11)}
                  className="video-dropdown"
                  as="ul"
                >
                  {activity_type?.map((activity, id) => {
                    return (
                      <ListGroup.Item key={id}>
                        <a
                          onClick={() =>
                            this.openConfirmationChangeScreenModal(() => {
                              this.openActivity(activity, id);
                            })
                          }
                        >
                          {activity}
                        </a>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              ) : null}
              {this.state.showMiscellaneous ? (
                <ListGroup
                  style={{
                    height: "36vh",
                    bottom:
                      this.props.class_type === "coding" ? "7rem" : "1rem",
                  }}
                  onMouseEnter={() => this.showMenus(12)}
                  className="video-dropdown"
                  as="ul"
                >
                  <Accordion>
                    {this.state?.miscellaneousOptions?.map((item, id) => {
                      return (
                        <>
                          <ListGroup.Item key={id}>
                            <a
                              onClick={() =>
                                this.openConfirmationChangeScreenModal(() => {
                                  this.openFlagQuestion(id, "", "", 0);
                                })
                              }
                            >
                              {item?.name}
                            </a>
                          </ListGroup.Item>
                        </>
                      );
                    })}

                    <Accordion>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={"Demo_123"}
                          >
                            Resources
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={"Demo_123"}>
                          <>
                            <Card.Body style={{ padding: "10px" }}>
                              <ul>
                                <li
                                  onClick={() =>
                                    this.openConfirmationChangeScreenModal(
                                      () => {
                                        this.openFlagQuestion(2, "", "", 0);
                                      }
                                    )
                                  }
                                  style={{
                                    paddingLeft: "1rem",
                                    color: "#183BCC",
                                    fontSize: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Upload Resources
                                </li>
                              </ul>
                            </Card.Body>

                            <Card.Body style={{ padding: "10px" }}>
                              {this.state.uploadedResources?.map(
                                (item, index) => {
                                  return (
                                    <ul>
                                      <li
                                        onClick={() =>
                                          this.openConfirmationChangeScreenModal(
                                            () => {
                                              this.openResourcesLessonScreen(
                                                item.image_data
                                              );
                                              console.log("index", index);
                                              // this.openLessonPdf(1, list);
                                            }
                                          )
                                        }
                                        style={{
                                          paddingLeft: "1rem",
                                          color: "#183BCC",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.name}
                                      </li>
                                    </ul>
                                  );
                                }
                              )}
                            </Card.Body>
                          </>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Accordion>
                </ListGroup>
              ) : null}

              {this.state.showLogicZone ? (
                <ListGroup
                  style={{ height: "19vh", top: "30rem" }}
                  onMouseEnter={() => this.showMenus(7)}
                  className="video-dropdown"
                  as="ul"
                >
                  <Accordion>
                    {logical_concept?.map((concept) => {
                      return (
                        <Card style={{ border: "none", boxShadow: "none" }}>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                              onClick={() => {
                                this.showOnClick();
                              }}
                            >
                              {concept.name}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body style={{ padding: "15px" }}>
                              <Accordion>
                                {concept.tags.map((tag) => {
                                  return (
                                    <Card
                                      style={{
                                        border: "none",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <Card.Header
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Accordion.Toggle
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                          as={Card.Header}
                                          eventKey="1"
                                        >
                                          <ListGroup.Item>
                                            {tag.name}
                                          </ListGroup.Item>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey="1">
                                        <Card.Body
                                          style={{ padding: "15px 35px" }}
                                        >
                                          <ul>
                                            {tag.levels.map((level) => {
                                              return (
                                                <li
                                                  onClick={() =>
                                                    this.openConfirmationChangeScreenModal(
                                                      () => {
                                                        this.onLogicalConceptClick(
                                                          concept.id,
                                                          tag.tag_id,
                                                          level,
                                                          concept?.name,
                                                          tag?.name
                                                        );
                                                      }
                                                    )
                                                  }
                                                  style={{
                                                    color: "#183BCC",
                                                    fontSize: "15px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {level}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })}
                              </Accordion>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}

                    <Card style={{ border: "none", boxShadow: "none" }}>
                      <Card.Header>
                        <Accordion.Toggle
                          onClick={() => {
                            this.openConfirmationChangeScreenModal(() => {
                              this.openCodingScreen();
                            });
                          }}
                          as={Button}
                          variant="link"
                          eventKey="2"
                        >
                          Coding
                        </Accordion.Toggle>
                      </Card.Header>
                    </Card>
                  </Accordion>
                </ListGroup>
              ) : null}

              <Modal show={this.state.connectionOffline}>
                <Modal.Body>
                  <Container>
                    <h3>Please check your Internet Connection</h3>
                    <Button
                      onClick={this.closeParentFeedback.bind(this)}
                      className="primary-button"
                    >
                      Okay
                    </Button>
                  </Container>
                </Modal.Body>
              </Modal>

              <Modal show={studentScreenShareConfirmation}>
                <Modal.Body>
                  <Container>
                    <h3>Teacher Confirmation</h3>
                    <Button
                      onClick={shareScreenAllowed}
                      className="primary-button"
                    >
                      Share Screen
                    </Button>
                  </Container>
                </Modal.Body>
              </Modal>

              <Modal
                size="lg"
                onHide={this.handleClose.bind(this)}
                show={this.state.demoFlag}
              >
                <Modal.Header closeButton>
                  <Row style={{ width: "100%" }}>
                    <Col md={12} className="text-center">
                      {this.state.demoStatus == "" && (
                        <h3 style={{ color: "red" }}>Please select one</h3>
                      )}
                      <h3
                        style={{
                          color: "#0069d9",
                          fontSize: "18px",
                          margin: "0",
                          fontWeight: "600",
                        }}
                      >
                        Teacher Feedback
                      </h3>
                    </Col>
                  </Row>
                </Modal.Header>

                <Modal.Body style={{ overflow: "auto" }}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <h6>Demo status</h6>
                        <div className="radio">
                          <label style={{ fontSize: "14px" }}>
                            <input
                              style={{ marginRight: "4px" }}
                              id="completed"
                              type="radio"
                              value="completed"
                              name="demoStatus"
                              checked={this.state.demoStatus === "completed"}
                              onChange={this.handleOptionChange}
                            />
                            Completed
                          </label>
                        </div>
                        <div className="radio">
                          <label style={{ fontSize: "14px" }}>
                            <input
                              style={{ marginRight: "4px" }}
                              id="incomplete"
                              type="radio"
                              value="incomplete"
                              name="demoStatus"
                              checked={this.state.demoStatus === "incomplete"}
                              onChange={this.handleOptionChange}
                            />
                            Incomplete
                          </label>
                        </div>
                      </Col>
                    </Row>

                    {this.state.demoStatus === "completed" ? (
                      <Form
                        className="teacher-feedback-form"
                        noValidate
                        method="post"
                        onSubmit={this.submitDemoFeedback.bind(this)}
                      >
                        <Row style={{ marginTop: "10px" }}>
                          <Col md={4}>
                            <Form.Group controlId="demoTopic">
                              <Form.Label>Child's first name</Form.Label>
                              <Form.Control
                                onChange={this.handleChange}
                                value={this.state.fields.firstName}
                                type="text"
                                required
                                name="firstName"
                                placeholder="Child's first name"
                              />
                              <p className="error-msg">
                                {this.state.errors.firstName}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="gender">
                              <Form.Label>Child's Gender</Form.Label>
                              <Form.Control
                                required
                                name="gender"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.gender}
                              >
                                <option key={"empty"} value={""}>
                                  Select child's gender
                                </option>
                                <option>Girl</option>
                                <option>Boy</option>
                              </Form.Control>

                              <p className="error-msg">
                                {this.state.errors.gender}
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="demoTopic">
                              <Form.Label>Demo topic</Form.Label>
                              <Form.Control
                                required
                                name="demoTopic"
                                onChange={this.handleChange}
                                value={this.state.fields.demoTopic}
                                type="text"
                                placeholder="Demo topic"
                              />
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.demoTopic}
                            </p>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col md={4}>
                            <Form.Group controlId="mathZone">
                              {this.state.ismzDropdownOpen}
                              <Form.Label>Math Zone</Form.Label>
                              <br />

                              <div className="dropdown">
                                <button
                                  onClick={() => this.showDropdown("mathzone")}
                                  className="btn btn-primary dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  Math zone
                                  <span className="caret"></span>
                                </button>
                                <ul
                                  className={
                                    this.state.ismzDropdownOpen === true
                                      ? "dropdown-menu show"
                                      : "dropdown-menu"
                                  }
                                >
                                  {mathZone}
                                </ul>
                              </div>
                              <p className="error-msg mathzone-error">
                                This field is required *
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="speedMath">
                              <Form.Label>Speed math</Form.Label>

                              <br />

                              <div className="dropdown">
                                <button
                                  onClick={() => this.showDropdown("speedmath")}
                                  className="btn btn-primary dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  Speed Math
                                  <span className="caret"></span>
                                </button>
                                <ul
                                  className={
                                    this.state.issmDropdownOpen === true
                                      ? "dropdown-menu show"
                                      : "dropdown-menu"
                                  }
                                >
                                  {speedMath}
                                </ul>
                              </div>
                              <p className="error-msg speedmath-error">
                                This field is required *
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="logicZone">
                              <Form.Label>Logic zone</Form.Label>

                              <br />

                              <div className="dropdown">
                                <button
                                  onClick={() => this.showDropdown("logiczone")}
                                  className="btn btn-primary dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  Logic zone
                                  <span className="caret"></span>
                                </button>
                                <ul
                                  className={
                                    this.state.islzDropdownOpen === true
                                      ? "dropdown-menu show"
                                      : "dropdown-menu"
                                  }
                                >
                                  {logicZone}
                                </ul>
                              </div>
                              <p className="error-msg logiczone-error">
                                This field is required *
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col md={4}>
                            <Form.Group controlId="learningStyle">
                              <Form.Label>Learning style</Form.Label>
                              <Form.Control
                                required
                                name="learningStyle"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.learningStyle}
                              >
                                <option key={"empty"} value={""}>
                                  Select Learning style
                                </option>
                                <option>Keen listener</option>
                                <option>Quick learner</option>
                                <option>Social learner</option>
                                <option>Active learner</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.learningStyle}
                            </p>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="communication">
                              <Form.Label>Communication</Form.Label>
                              <Form.Control
                                required
                                name="communication"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.communication}
                              >
                                <option key={"empty"} value={""}>
                                  Select Communication
                                </option>
                                <option>Open & Friendly</option>
                                <option>Shy & Quiet</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.communication}
                            </p>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="conceptualKnowledge">
                              <Form.Label>Conceptual Knowledge</Form.Label>
                              <Form.Control
                                required
                                name="conceptualKnowledge"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.conceptualKnowledge}
                              >
                                <option key={"empty"} value={""}>
                                  Select Conceptual Knowledge
                                </option>
                                <option>Strong</option>
                                <option>Needs Support</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.conceptualKnowledge}
                            </p>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col md={4}>
                            <Form.Group controlId="higherOrder">
                              <Form.Label>Higher Order</Form.Label>
                              <Form.Control
                                required
                                name="higherOrder"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.higherOrder}
                              >
                                <option key={"empty"} value={""}>
                                  Select Higher Order
                                </option>
                                <option>Above average</option>
                                <option>To be developed</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.higherOrder}
                            </p>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="coding">
                              <Form.Label>Coding</Form.Label>
                              <Form.Control
                                required
                                name="coding"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.coding}
                              >
                                <option key={"empty"} value={""}>
                                  Select Coding
                                </option>
                                <option>Quick</option>
                                <option>Took time</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.coding}
                            </p>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="childInterest">
                              <Form.Label>
                                Most Interesting for child
                              </Form.Label>
                              <Form.Control
                                required
                                name="childInterest"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.childInterest}
                              >
                                <option key={"empty"} value={""}>
                                  Select child's most interesting
                                </option>
                                <option>Whiteboard lesson</option>
                                <option>Math game</option>
                                <option>Speed math</option>
                                <option>Video</option>
                                <option>Coding</option>
                                <option>Logical</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.childInterest}
                            </p>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col md={4}>
                            <Form.Group controlId="classBehaviour">
                              <Form.Label>In class behaviour</Form.Label>
                              <Form.Control
                                required
                                name="classBehaviour"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.classBehaviour}
                              >
                                <option key={"empty"} value={""}>
                                  Select child's class behaviour
                                </option>
                                <option>Open to challenges</option>
                                <option>Inclined to be in comfort zone </option>
                                <option>Bored and Distracted</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.classBehaviour}
                            </p>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="begalileoUseful">
                              <Form.Label>
                                How begalileo will be useful ?
                              </Form.Label>
                              <Form.Control
                                required
                                name="begalileoUseful"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.fields.begalileoUseful}
                              >
                                <option key={"empty"} value={""}>
                                  Select begalileo's useful
                                </option>
                                <option>Strengthening concepts</option>
                                <option>Developing Logical Thinking</option>
                                <option>Building application of math</option>
                              </Form.Control>
                            </Form.Group>
                            <p className="error-msg">
                              {this.state.errors.begalileoUseful}
                            </p>
                          </Col>

                          <Row style={{ margin: "auto" }}>
                            <Col md={12}>
                              <div className="form-group">
                                <h5>Comments for Internal Team</h5>
                                <textarea
                                  // ref={textInput}
                                  // value={
                                  //   this.state.fields.commentsForInternalTeam
                                  // }
                                  required
                                  name="internal_comments"
                                  onChange={this.handleChange}
                                  rows="5"
                                  placeholder="Your comments !!!"
                                  className="form-control"
                                >
                                  {this.state.fields.internal_comments}
                                </textarea>
                                <p className="error-msg">
                                  {this.state.errors.internal_comments}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                          <Col md={12} className="text-center">
                            <Button
                              type="submit"
                              className="primary-button"
                              variant="primary"
                            >
                              Submit Feedback
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      ""
                    )}
                  </Container>
                </Modal.Body>
                {this.state.demoStatus === "completed" ? (
                  ""
                ) : (
                  <Modal.Footer>
                    <Button
                      onClick={this.closeWithoutDemoFeebback}
                      className="primary-button"
                      variant="primary"
                    >
                      End Session
                    </Button>
                  </Modal.Footer>
                )}
              </Modal>
              {this.state.showQuizMenus ? (
                <ListGroup
                  onMouseEnter={() => this.showMenus(6)}
                  className="video-dropdown"
                  as="ul"
                >
                  <Accordion>
                    {concept_list?.map((list) => {
                      const quizConceptId = list.id;
                      let warmUp = JSON.stringify(list.tags).includes(
                        "Warm Up"
                      );
                      let wrapUp = JSON.stringify(list.tags).includes(
                        "Wrap Up"
                      );

                      return (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={list.id}
                              onClick={() => {
                                this.turnOffLogicZone();
                              }}
                              style={{
                                color: list.today_class ? "green" : "#233584",
                              }}
                            >
                              {list.today_class ? (
                                <div style={{ display: "flex" }}>
                                  {list.name}
                                  <img
                                    style={{
                                      width: "50px",

                                      margin: "auto",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    src={
                                      "/static/media/todayClassIconGreen.png"
                                    }
                                  />
                                </div>
                              ) : (
                                list.name
                              )}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={list.id}>
                            <Card.Body style={{ padding: "15px" }}>
                              {!warmUp && (
                                <ListGroup as="ul">
                                  <Accordion>
                                    <Accordion.Toggle
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        boxShadow: "none",
                                      }}
                                      as={Card.Header}
                                      onClick={() =>
                                        this.openConfirmationChangeScreenModal(
                                          () => {
                                            this.openPrePostTestPlay(
                                              quizConceptId,
                                              "pre-post",
                                              list.name
                                            );
                                          }
                                        )
                                      }
                                    >
                                      <ListGroup.Item>Pre-Test</ListGroup.Item>
                                    </Accordion.Toggle>
                                  </Accordion>
                                </ListGroup>
                              )}

                              <ListGroup as="ul">
                                {list.tags.map((tag) => {
                                  return (
                                    <Accordion>
                                      <Accordion.Toggle
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          boxShadow: "none",
                                          color: tag.today_class
                                            ? "green"
                                            : "#183BCC",
                                        }}
                                        as={Card.Header}
                                        eventKey={tag.name}
                                      >
                                        <ListGroup.Item>
                                          {tag.today_class ? (
                                            <div style={{ display: "flex" }}>
                                              {tag.name}
                                              <img
                                                style={{
                                                  width: "50px",

                                                  margin: "auto",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                src={
                                                  "/static/media/todayClassIconGreen.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            tag.name
                                          )}
                                        </ListGroup.Item>
                                      </Accordion.Toggle>

                                      <Accordion.Collapse eventKey={tag.name}>
                                        <Card.Body
                                          style={{ padding: "15px 35px" }}
                                        >
                                          <ul>
                                            {tag.levels.map((level) => {
                                              return (
                                                <li
                                                  onClick={() =>
                                                    this.openConfirmationChangeScreenModal(
                                                      () => {
                                                        this.openQuizPlay(
                                                          quizConceptId,
                                                          tag.tag_id,
                                                          level,
                                                          list.name,
                                                          tag.name,
                                                          ""
                                                        );
                                                      }
                                                    )
                                                  }
                                                  style={{
                                                    color: "#183BCC",
                                                    fontSize: "15px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {level}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Accordion>
                                  );
                                })}
                              </ListGroup>
                              {!wrapUp && (
                                <ListGroup as="ul">
                                  <Accordion>
                                    <Accordion.Toggle
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        boxShadow: "none",
                                      }}
                                      as={Card.Header}
                                      onClick={() =>
                                        this.openConfirmationChangeScreenModal(
                                          () => {
                                            this.openPrePostTestPlay(
                                              quizConceptId,
                                              "post-post",
                                              list.name
                                            );
                                          }
                                        )
                                      }
                                    >
                                      <ListGroup.Item>Post-Test</ListGroup.Item>
                                    </Accordion.Toggle>
                                  </Accordion>
                                </ListGroup>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                        // <ListGroup.Item onClick={() => this.openQuizPlay(quizConceptId)} >
                        //   <a>
                        //     {list.name}
                        //   </a>
                        // </ListGroup.Item>
                      );
                    })}
                  </Accordion>
                </ListGroup>
              ) : null}
              {isTutorCheck(videoRoom.localParticipant.identity) ? (
                <Col
                  md={1}
                  xs={1}
                  sm={1}
                  style={{
                    position: "absolute",
                    zIndex: "1000",
                    marginRight: "10px",
                    cursor: "pointer",
                    transition: "smooth",
                  }}
                  className="text-center"
                >
                  <Image
                    style={{
                      width: "28px",
                      height: "28px",
                      marginTop: "15px",
                      marginBottom: "5px",
                      marginLeft: "-40px",
                      transform: "-90deg",
                    }}
                    className="img-fluid"
                    // src={process.env.PUBLIC_URL + "/static/media/Next-btn.svg"}
                    src={"/static/media/HamburgerIconLiveClass.png"}
                    alt="Logo"
                    onClick={this.collapsTeacherScreen}
                  />
                </Col>
              ) : (
                <Col
                  md={1}
                  xs={1}
                  sm={1}
                  style={{
                    position: "absolute",
                    zIndex: "1000",
                    marginRight: "5px",
                    transition: "smooth",
                    display:
                      videoRoom.localParticipant.identity == "parent" ||
                      videoRoom.localParticipant.identity == "sm"
                        ? "none"
                        : "",
                  }}
                  className="text-center"
                >
                  <Image
                    style={{
                      marginTop: "8px",
                      marginBottom: "5px",
                      marginRight: "45px",

                      transform: "-90deg",
                    }}
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/static/media/Logo.svg"}
                    alt="Logo"
                  />
                </Col>
              )}

              <Col
                md={1}
                xs={1}
                sm={1}
                className="left-col"
                style={{
                  position: "fixed",
                  zIndex: "999",
                  flex: leftFlex,
                  maxWidth: isTutorCheck(videoRoom.localParticipant.identity)
                    ? leftMaxWidth
                    : 0,
                  display:
                    isTutorCheck(videoRoom.localParticipant.identity) &&
                    this.state.collapsScreenTeacher
                      ? "block"
                      : "none",
                }}
              >
                <div className="left-menu">
                  <Row>
                    <Col md={12} className="text-center">
                      <Image
                        style={{ marginTop: "25px", marginBottom: "25px" }}
                        className="img-fluid"
                        src={process.env.PUBLIC_URL + "/static/media/Logo.svg"}
                        alt="Logo"
                      />
                    </Col>
                  </Row>

                  {/* {
                    isTutorCheck(videoRoom.localParticipant.identity) ?

                      <div> */}
                  {leftMenu}
                  {/* </div> : null
                  } */}
                </div>
              </Col>

              <Col
                onMouseEnter={() => this.hideMenus(4)}
                onTouchStart={() => this.hideMenus(4)}
                md={this.state.selected === 1 ? "11" : "9"}
                sm={this.state.selected === 1 ? "11" : "9"}
                xs={this.state.selected === 1 ? "11" : "9"}
                style={{
                  flex: middleFlex,
                  maxWidth: middleMaxWidth,
                  paddingLeft:
                    isTutorCheck(videoRoom.localParticipant.identity) ||
                    videoRoom.localParticipant.identity == "tech"
                      ? "15px"
                      : "1px",
                  paddingRight:
                    isTutorCheck(videoRoom.localParticipant.identity) ||
                    videoRoom.localParticipant.identity == "tech"
                      ? "1px"
                      : "1px",
                }}
                className={
                  this.state.selected === 1 ? "allscreen" : "otherscreen"
                }
              >
                {videoRoom.localParticipant.identity != "parent" &&
                !this.smPresent(videoRoom.localParticipant.identity) ? (
                  <Row className="topbarresponsiveforsmall">
                    <Navbar
                      style={{ width: "100%" }}
                      bg="light"
                      className="navbar-live-class"
                    >
                      <Navbar.Brand
                        className="live-class-name"
                        style={{
                          marginLeft:
                            this.state.selected == 1 ? "50px" : "20px",
                        }}
                      >
                        Coach : {this.props.tutorName}
                      </Navbar.Brand>
                      {videoRoom.localParticipant.identity == "tutor" && (
                        <>
                          <Navbar.Brand
                            className="live-class-name"
                            style={{
                              marginLeft:
                                this.state.selected == 1 ? "50px" : "20px",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                marginTop: "2.5px",
                              }}
                            >
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                title="Rejoin"
                                src="/static/media/reconnectNewIconLiveClass.png"
                                onClick={() => this.rejoinTeacherClass()}
                              />
                            </div>
                          </Navbar.Brand>

                          <Navbar.Brand
                            className="live-class-name"
                            style={{
                              marginLeft:
                                this.state.selected == 1 ? "50px" : "20px",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                marginTop: "2.5px",
                              }}
                            >
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                title="Whiteboard"
                                src="/static/media/whiteboardNewIconLiveClass.png"
                                onClick={() => this.openLessonPdf(1, [])}
                              />
                            </div>
                          </Navbar.Brand>
                        </>
                      )}

                      {videoRoom.localParticipant.identity != "tech" &&
                      videoRoom.localParticipant.identity != "tutor" ? (
                        <Navbar.Brand className="live-class-name">
                          We are on : {this.state.currentScreenName}
                        </Navbar.Brand>
                      ) : null}

                      {videoRoom.localParticipant.identity == "tech" ? (
                        <>
                          <Navbar.Brand className="live-class-name">
                            <div style={{ cursor: "pointer" }}>
                              <button
                                className="mute-all"
                                variant="default"
                                cursor="pointer"
                                onClick={() => this.showErrorLogstoTech()}
                              >
                                Show Network Error
                              </button>
                            </div>
                          </Navbar.Brand>
                          <Navbar.Brand className="live-class-name">
                            <div style={{ cursor: "pointer" }}>
                              <button
                                className="mute-all"
                                variant="default"
                                cursor="pointer"
                                onClick={() => this.showDeviceInfoToTech()}
                              >
                                Device Info
                              </button>
                            </div>
                          </Navbar.Brand>
                        </>
                      ) : null}

                      <div className="parentjoindiv">
                        {isTutorCheck(videoRoom.localParticipant.identity) &&
                        this.state.showit ? (
                          <div className="blink">
                            <img
                              className="parentjoinindicator"
                              src={RedIndicator}
                            ></img>
                          </div>
                        ) : null}
                      </div>

                      <Navbar.Collapse className="justify-content-end">
                        <ButtonGroup
                          className="button-group"
                          aria-label="Basic example"
                        >
                          {isTutorCheck(
                            videoRoom.localParticipant.identity
                          ) && (
                            <Button
                              className="mute-all"
                              variant="default"
                              onClick={() => this.muteAllStudents()}
                            >
                              {muteAllState ? (
                                <Image
                                  className="img-fluid"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/UnmuteAll.svg"
                                  }
                                  alt="Mute all"
                                />
                              ) : (
                                <Image
                                  className="img-fluid"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/MuteAll.svg"
                                  }
                                  alt="Mute all"
                                />
                              )}

                              <span
                                style={{ color: "#233584", fontSize: "12px" }}
                              >
                                {" "}
                                {muteAllState ? "UnMute All" : "Mute All"}
                              </span>
                            </Button>
                          )}

                          {videoRoom.localParticipant.identity == "tutor" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {this.state.screenShotCaptureButton && (
                                <div
                                  onClick={() =>
                                    this.handleShowScreenShotCaptureModal()
                                  }
                                  title="Take a pic"
                                >
                                  {" "}
                                  <span
                                    style={{
                                      color: "#3f6fff",
                                      fontSize: "13px",
                                      textAlign: "center",
                                      alignItems: "center",
                                      marginLeft: "2px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src="/static/media/camera1.png"
                                      style={{
                                        width: "25px",
                                      }}
                                    />
                                  </span>
                                </div>
                              )}
                              <ScreenRecording
                                userJoined={true}
                                screenRecordingRef2={
                                  this.props.screenRecordingRef2
                                }
                                ref={this.screenRecordingElement}
                                room={this.props.videoRoom}
                                setRecordingEnabledStatus={
                                  this.props.setRecordingEnabledStatus
                                }
                                recordingEnabled={this.props.recordingEnabled}
                                teacherGiveRecordingPermission={
                                  this.teacherGiveRecordingPermission
                                }
                                userOperatingSystemName={
                                  this.state.userOperatingSystemName
                                }
                              />
                              {/* <Button className="record" variant="default">
                                <Form>
                                  <Form.Check
                                    onClick={() => this.startExtension()}
                                    // onClick={() =>
                                    //   this.temporaryChromeExtensionPopup()
                                    // }
                                    className="record-switch"
                                    type="switch"
                                    id="custom-switch"
                                    label="Record"
                                    checked={this.state.status}
                                  />
                                </Form>f
                              </Button> */}

                              <div
                                style={{
                                  width: "250px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#3f6fff",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    marginLeft: "2px",
                                  }}
                                >
                                  {" "}
                                  Change background
                                </span>
                                {/* <Button
                            onClick={() => this.changeBackground()}
                            // onChange={this.onchangebg()}
                            variant="default"
                          ></Button> */}

                                <div
                                  className={styles.effects}
                                  style={{
                                    width: "100px",
                                    gap: "5px",
                                    display: "flex",
                                    marginLeft: "10px",
                                  }}
                                  onChange={this.onChangeValue}
                                >
                                  <label>
                                    <input
                                      style={{ marginTop: "5px" }}
                                      type="radio"
                                      value="none"
                                      name="gender"
                                    />{" "}
                                    <img
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                      src={BlurOff}
                                    ></img>
                                  </label>
                                  <label>
                                    <input
                                      style={{ marginTop: "5px" }}
                                      type="radio"
                                      value="blur"
                                      name="gender"
                                    />{" "}
                                    <img
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                      src={blurEffect}
                                    ></img>
                                  </label>
                                  <label>
                                    <input
                                      style={{ marginTop: "5px" }}
                                      type="radio"
                                      value="custom"
                                      name="gender"
                                    />{" "}
                                    <img
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginTop: "5px",
                                      }}
                                      src={CustomBackground}
                                    ></img>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          {this.state.showLoading ? (
                            <div className="loadingdiv_container">
                              <Row>
                                <Col className="text-center" md={12}>
                                  <div className="loading">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12} className="text-center">
                                  <p style={{ color: "#233584" }}>
                                    Applying changes please wait ...
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                          {this.state.temporaryChromeExtensionPopupModal ? (
                            <div className="loadingdiv_container_extension">
                              <Row>
                                <Col md={12} className="text-center">
                                  <p
                                    style={{
                                      color: "#233584",
                                      alignItems: "center",
                                      marginTop: "18px",
                                    }}
                                  >
                                    Extension is not found. Please install
                                    begalileo extension from chrome extension
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          ) : null}

                          <Button
                            onClick={onCallTechSupport}
                            className="record"
                            variant="default"
                          >
                            <span
                              style={{ color: "#233584", fontSize: "12px" }}
                            >
                              <Image
                                style={{ marginTop: "-6px" }}
                                className="img-fluid"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Tech-support.svg"
                                }
                                alt="Technical Support"
                              />
                              Call Tech Support
                            </span>
                          </Button>

                          {!this.state.screenShotPrgress &&
                            isTutorCheck(videoRoom.localParticipant.identity) &&
                            !this.state.screenShotPrgress && (
                              <Button
                                onClick={this.takeScreenshot}
                                className="settings"
                                variant="default"
                              >
                                <Image
                                  className="img-fluid"
                                  style={{ width: "18px", height: "18px" }}
                                  title="Take screenshot"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/media/Images-v2/Screenshot.svg"
                                  }
                                  alt="Screen capture"
                                />
                              </Button>
                            )}
                          {/* {!isTutorCheck(
                            videoRoom.localParticipant.identity
                          ) ? ( */}
                          <div style={{ position: "relative" }}>
                            <div>
                              <Button
                                onClick={() => {
                                  this.openClassEndforStudent();
                                }}
                                className="record"
                                variant="default"
                              >
                                {/* {!this.state.openEndClassforChild ? ( */}
                                <Image
                                  style={{
                                    marginTop: "-1px",
                                    width: "31px",
                                    height: "26px",
                                  }}
                                  className="img-fluid"
                                  src={menusecond}
                                  alt="End Class"
                                />
                                {/* ) : (
                                  <Image
                                    style={{
                                      marginTop: "-1px",
                                      width: "31px",
                                      height: "28px",
                                    }}
                                    className="img-fluid"
                                    src={CloseHamburger}
                                    alt="End Class"
                                  />
                                )} */}
                              </Button>
                            </div>
                            {this.state.openEndClassforChild ? (
                              <div className="hoverNewDivforchild">
                                <Row>
                                  <div className="hoverNewDivforchildStudent">
                                    <div>
                                      <Col md={12} className="text-center">
                                        {this.props.isMuteEnabled ? (
                                          <Image
                                            className="img-fluid"
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/MuteButton.svg"
                                            }
                                            onClick={() => {
                                              this.unMuteClickBtn(
                                                videoRoom.localParticipant
                                                  .identity
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                            alt="Mute"
                                          />
                                        ) : (
                                          <Image
                                            className="img-fluid"
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/MuteUnmute.svg"
                                            }
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              this.muteClickBtn(
                                                videoRoom.localParticipant
                                                  .identity
                                              );
                                            }}
                                            alt="Unmute"
                                          />
                                        )}
                                      </Col>
                                    </div>
                                    <div>
                                      <Col md={12} className="text-center">
                                        <Image
                                          className="img-fluid"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/EndCall.svg"
                                          }
                                          onClick={onLeaveClicked}
                                          style={{
                                            width: "45px",
                                            height: "40px",
                                            cursor: "pointer",
                                          }}
                                          alt="End call"
                                        />
                                      </Col>
                                    </div>
                                    <div>
                                      {isTutorCheck(
                                        videoRoom.localParticipant.identity
                                      ) ? (
                                        this.props.isScreenSharingEnabled ? (
                                          <Image
                                            className="img-fluid"
                                            onClick={() => onScreenShare()}
                                            disabled={
                                              !this.props
                                                .isScreenSharingSupported
                                            }
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/StartSharing.svg"
                                            }
                                            alt="Start Sharing"
                                          />
                                        ) : (
                                          <Image
                                            className="img-fluid"
                                            onClick={() => onScreenShare()}
                                            disabled={
                                              !this.props
                                                .isScreenSharingSupported
                                            }
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/StopSharing.svg"
                                            }
                                            alt="Stop Sharing"
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {!isTutorCheck(
                                        videoRoom.localParticipant.identity
                                      ) ? (
                                        <Col md={12} className="text-center">
                                          <Image
                                            className="img-fluid"
                                            onClick={() =>
                                              onRaiseHandClicked(
                                                videoRoom.localParticipant
                                                  .identity
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/HandRaise.svg"
                                            }
                                            alt="Raise Hand"
                                          />
                                        </Col>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </ButtonGroup>
                      </Navbar.Collapse>
                    </Navbar>
                  </Row>
                ) : (
                  <>
                    {this.smPresent(videoRoom.localParticipant.identity) ? (
                      <br />
                    ) : (
                      <>
                        <br />
                        <br />
                        <br />
                        <br />{" "}
                      </>
                    )}
                    {/* <br />
                    <br />
                    <br />
                    <br /> */}

                    <div>
                      <Modal
                        centered
                        show={this.state.showsfinalalertofstayorleave}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <h3
                                  style={{
                                    marginBottom: "40px",
                                    marginTop: "0",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "#676971",
                                  }}
                                  className="text-center"
                                >
                                  Thank you for the feedback, would you like to
                                  stay in class or leave
                                </h3>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Col md={12} className="text-center">
                                <Button
                                  // onClick={this.ParentFeedbackSubmit.bind(this)}
                                  className="primary-button"
                                  variant="primary"
                                  onClick={this.stayinclass}
                                >
                                  Stay in class
                                </Button>

                                <Button
                                  // onClick={this.ParentFeedbackSubmit.bind(this)}
                                  onClick={this.leaveclass}
                                  className="primary-button"
                                  variant="primary"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Leave
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div>
                      <Modal
                        centered
                        show={this.state.showspeedmathchildscore}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <h3
                                  style={{
                                    marginBottom: "40px",
                                    marginTop: "0",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "#676971",
                                  }}
                                  className="text-center"
                                >
                                  {this.state.child_name} score is :
                                  {this.state.child_score}
                                </h3>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div>
                      <Modal
                        centered
                        show={this.state.showspeedmathprogressmodal}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <h3
                                  style={{
                                    marginBottom: "40px",
                                    marginTop: "0",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "#676971",
                                  }}
                                  className="text-center"
                                >
                                  Speed Math is in Progress, please wait
                                </h3>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div>
                      <Modal
                        centered
                        show={this.state.showraiseAlarm}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <h3
                                  style={{
                                    marginBottom: "40px",
                                    marginTop: "0",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "#676971",
                                  }}
                                  className="text-center"
                                >
                                  Would you like to take screenshot, then please
                                  click on yes else no!
                                </h3>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Col md={12} className="text-center">
                                <Button
                                  // onClick={this.ParentFeedbackSubmit.bind(this)}
                                  className="primary-button"
                                  variant="primary"
                                  onClick={this.takeScreenshotforparent}
                                >
                                  Yes
                                </Button>

                                <Button
                                  // onClick={this.ParentFeedbackSubmit.bind(this)}
                                  onClick={this.closeraisealert}
                                  className="primary-button"
                                  variant="primary"
                                  style={{ marginLeft: "10px" }}
                                >
                                  No
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div>
                      <Modal
                        centered
                        show={this.state.showRateSuggestion}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div>
                                  <div>
                                    <Row style={{ marginBottom: "27px" }}>
                                      <Col md={12} className="text-center">
                                        <p
                                          className="text-center"
                                          style={{
                                            marginBottom: "32px",
                                            fontSize: "26px",
                                            color: "#676971",
                                          }}
                                        >
                                          {this.state.feedbackValue}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div>
                                  <label for="customRange1">
                                    How was you Experience?
                                  </label>
                                  <input
                                    onChange={this.handleSliderChange.bind(
                                      this
                                    )}
                                    type="range"
                                    // value={
                                    //   this.state.everything_seems_happy
                                    //     .how_happy
                                    // }
                                    // value={this.state.feedbackValue}
                                    class="custom-range"
                                    min="0"
                                    max="5"
                                    step="1"
                                    id="customRange1"
                                    defaultValue="0"
                                    name="how_happy"
                                  />

                                  <label for="customRange2">
                                    Would you rate us to your friends?
                                  </label>
                                  <input
                                    onChange={this.handleSliderChange.bind(
                                      this
                                    )}
                                    // value={
                                    //   this.state.everything_seems_happy
                                    //     .improvement
                                    // }
                                    name="improvement"
                                    type="range"
                                    class="custom-range"
                                    min="0"
                                    max="5"
                                    step="1"
                                    id="customRange2"
                                    defaultValue="0"
                                  />
                                  <label for="customRange3">
                                    Would you like to rate us on playstore?
                                  </label>
                                  <input
                                    onChange={this.handleSliderChange.bind(
                                      this
                                    )}
                                    name="positive_feeling"
                                    // value={
                                    //   this.state.everything_seems_happy
                                    //     .positive_feeling
                                    // }
                                    type="range"
                                    class="custom-range"
                                    min="0"
                                    max="5"
                                    step="1"
                                    defaultValue="0"
                                    id="customRange3"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Col md={12} className="text-center">
                                <Button
                                  onClick={this.ParentFeedbackSubmitEverytingisgood.bind(
                                    this
                                  )}
                                  className="primary-button"
                                  variant="primary"
                                >
                                  Submit Feedback
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>
                    <div>
                      <Modal
                        centered
                        show={this.state.showParentSuggestion}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className="form-group">
                                  <textarea
                                    // ref={textInput}
                                    value={this.state.parentfeedbackvalue}
                                    onChange={this.parentfeedbackvaluefn.bind(
                                      this
                                    )}
                                    rows="5"
                                    placeholder="Your comments !!!"
                                    className="form-control"
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Col md={12} className="text-center">
                                <Button
                                  onClick={this.ParentFeedbackSubmit.bind(this)}
                                  className="primary-button"
                                  variant="primary"
                                >
                                  Submit Feedback
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div>
                      <Modal
                        centered
                        show={this.state.showeverythinggood}
                        onHide={this.closeParentFeedback.bind(this)}
                      >
                        <Modal.Body>
                          <Container>
                            <Row>
                              <Col md={12} className="text-center">
                                <h1
                                  style={{
                                    marginBottom: "40px",
                                    marginTop: "0",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    color: "#676971",
                                  }}
                                  className="text-center"
                                >
                                  Thank you for your time , we appreciate your
                                  concern & involvement with your child’s
                                  learning. Please help us rate
                                </h1>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Col md={12} className="text-center">
                                <Button
                                  // onClick={this.ParentFeedbackSubmit.bind(this)}
                                  className="primary-button"
                                  variant="primary"
                                  onClick={this.showRateSuggestionfn}
                                >
                                  Rate Us
                                </Button>
                                <br />
                                <Button
                                  onClick={this.leaveclass.bind(this)}
                                  className="primary-button"
                                  variant="primary"
                                >
                                  Will Disconnect & Do later
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>

                    {/* <Container class=".container-sm">
                      <Row>
                        <Col md={12}>
                          <div className="form-group">
                            <label>Comments (optional)</label>
                            <textarea
                              // ref={textInput}
                              rows="5"
                              placeholder="Your comments !!!"
                              className="form-control"
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </Container> */}

                    <div
                      style={{
                        display: this.smPresent(
                          videoRoom.localParticipant.identity
                        )
                          ? "none"
                          : "",
                      }}
                      className="containerforparentbigdiv"
                    >
                      {/* <div className="containerhowertext">
                        <span className="containerhowertextstyle">
                          {this.state.hover
                            ? "This is to be used when you notice a serious unprofessional breach or anything illegal or unethical"
                            : ""}

                          {this.state.hoversecond
                            ? "This is to be used if you see something that needs to be corrected or can be improved. Anything which you think can improve the class efficiency or didn’t look right to you"
                            : ""}
                        </span>
                      </div> */}
                      <div className="containerforparent">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.EverythinggoodParentbutton(e)}
                        >
                          Everything Seems Good
                        </button>
                        <div style={{ position: "relative" }}>
                          <div>
                            <button
                              onMouseOver={this.handleMouseInsecond}
                              onMouseOut={this.handleMouseOutsecond}
                              type="button"
                              id="random"
                              class="btn btn-warning"
                              onClick={(e) => this.SuggestionParentbutton(e)}
                            >
                              Send a Suggestion or Concern
                            </button>
                          </div>
                          {this.state.hoversecond ? (
                            <div className="hoverNewDivSecond">
                              This is to be used if you see something that needs
                              to be corrected or can be improved. Anything which
                              you think can improve the class efficiency or
                              didn’t look right to you
                            </div>
                          ) : null}
                        </div>
                        <div style={{ position: "relative" }}>
                          <div>
                            <button
                              onMouseOver={this.handleMouseIn}
                              onMouseOut={this.handleMouseOut}
                              onClick={(e) => this.raiseAlarmbutton(e)}
                              type="button"
                              class="btn btn-danger"
                              style={{ cursor: "pointer" }}
                            >
                              Raise An Alarm
                            </button>
                          </div>
                          {this.state.hover ? (
                            <div className="hoverNewDiv">
                              This is to be used when you notice a serious
                              unprofessional breach or anything illegal or
                              unethical
                            </div>
                          ) : null}
                        </div>
                        <div style={{ position: "relative" }}>
                          <div>
                            <button
                              onClick={(e) => this.LeaveSessionForParent(e)}
                              type="button"
                              class="btn btn-danger"
                              style={{ cursor: "pointer" }}
                            >
                              Leave Session
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <Row>
                  {this.state.selected !== 1 ? (
                    <Col
                      onMouseEnter={() => this.hideMenus(4)}
                      md={12}
                      sm={12}
                      xs={12}
                      className="right-side-student-padding"
                      style={{
                        marginLeft:
                          this.props.videoRoom.localParticipant.identity ==
                            "tutor" && this.state.selected == 3
                            ? "-25px"
                            : "0px",
                      }}
                    >
                      {this.state.selected === 2 ? (
                        <div id="tutor" style={{ position: "relative" }}>
                          {this.props.videoRoom.localParticipant.identity ==
                          "tutor" ? (
                            <React.Fragment>
                              <LocalParticipant
                                participant={videoRoom.localParticipant}
                                selected={this.state.selected}
                              />

                              <p
                                className="flex-p"
                                style={{
                                  position: "absolute",
                                  left: "5%",
                                  bottom: "5%",
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                  margin: "0",
                                  color: "#fff",
                                }}
                              >
                                <Signal
                                  signalLevel={
                                    videoRoom.localParticipant
                                      .networkQualityLevel
                                  }
                                />{" "}
                                Coach{" "}
                              </p>
                            </React.Fragment>
                          ) : (
                            remoteParticipants.map((participant) => {
                              if (participant.identity == "tutor") {
                                return (
                                  <React.Fragment>
                                    <Participant
                                      key={participant.sid}
                                      participant={participant}
                                      selected={this.state.selected}
                                    />
                                    <p className="flex-p" style={childName}>
                                      <Signal
                                        signalLevel={
                                          participant.networkQualityLevel
                                        }
                                      />{" "}
                                      Coach
                                    </p>
                                  </React.Fragment>
                                );
                              }
                            })
                          )}
                        </div>
                      ) : this.state.selected === 3 ||
                        this.state.selected === 15 ? (
                        <AllImportFile
                          state={this.state.selected}
                          ref={this.canvasScreenElement}
                          videoRoom={videoRoom}
                          board={showBoard}
                          IpadState={this.state.studentIpadStatesss}
                          SelectedCurrentState={this.state.selected}
                          // tutorCollapseState={this.state.collapsScreenTeacher}
                          clearCanvas={this.props.onClearCanvas}
                          openGraphImage={this.props.openGraphImage}
                          changeLessonPageNumber={
                            this.props.onChangeLessonPageNumber
                          }
                          onSendWhiteBoardLines={this.sendWhiteBoardLines}
                          onStudentIpadState={this.StudentIpadState}
                          identity={videoRoom.localParticipant.identity}
                          text="White board"
                          selPdfUrl={selectedPdfUrl}
                          refreshTime={this.state.refreshTime}
                          selPdfIndex={selectedPdfIndex}
                          classId={this.state.mLiveClassId}
                          subItemId={this.state.selectedSubItemId}
                          changechildlessonpdf={this.changechildlessonpdf}
                          changepenstrokesforchild={
                            this.changepenstrokesforchild
                          }
                          fetchagain={this.state.fetchagain}
                          fetchAginStudentId={this.state.fetchAginStudentId}
                          geoGebraUpdateData={this.geoGebraUpdateData}
                          updatestateforchild={this.updatestateforchild}
                          openRoughBoardScreen={this.props.openRoughBoardScreen}
                          remoteParticipants={remoteParticipants}
                          selectGGbDataInConceptsList={
                            this.state.selectGGbDataInConceptsList
                          }
                        />
                      ) : this.state.selected === 4 ? (
                        <AllImportFile
                          state={this.state.selected}
                          text="Video"
                          ref={this.videoScreenElement}
                          changeVideoState={this.props.onChangeVideoState}
                          videoRoom={videoRoom}
                          identity={videoRoom.localParticipant.identity}
                          selVideoTime={nowPlayingVideoTime}
                          setVideoStart={this.checkplayingornot}
                          selVideoUrl={selectedVideoUrl}
                          setResumeVideoTime={this.setResumeVideoTime}
                          classId={this.state.mLiveClassId}
                          subItemId={this.state.selectedSubItemId}
                        />
                      ) : this.state.selected === 5 ? (
                        <AllImportFile
                          state={this.state.selected}
                          videoRoom={videoRoom}
                          ref={this.speedMathScreenElement}
                          gameMode={this.state.speedMathGameMode}
                          gameId={this.state.speedMathGameId}
                          level={this.state.selectedSpeedMathLevel}
                          text="Speed Math"
                          classId={this.state.mLiveClassId}
                          runSpeedMath={this.runSpeedMath}
                          changeStatefunction={this.changeStatefunction}
                          onGameTimerStartStop={this.props.onGameTimerStartStop}
                          rparticipants={finalRemoteParticipant.length}
                          identity={videoRoom.localParticipant.identity}
                          onUpdateLiveSpeedMathScore={
                            this.props.onUpdateLiveSpeedMathScore
                          }
                          speedMathScoreDataItem={
                            this.state.speedMathScoreDataItem
                          }
                          currentChildgameid={this.handleSpeedChildState}
                        />
                      ) : this.state.selected === 6 ? (
                        //* some changing done by anish
                        <>
                          <AllImportFile
                            state={this.state.selected}
                            ref={this.quizScreenElement}
                            text="Quiz"
                            identity={videoRoom.localParticipant.identity}
                            onStudentIpadState={this.StudentIpadState}
                            quizURL={selectedQuizUrl}
                            refreshQuiz={this.refreshQuizPlay}
                            updatestateforchild={this.updatestateforchild}
                            startPractice={this.startPracticeAction}
                            openRoughBoardScreen={
                              this.props.openRoughBoardScreen
                            }
                            onSendQuizCanvasLines={
                              this.props.sendQuizCanvasLines
                            }
                            clearCanvas={this.props.onClearCanvas}
                            onSendWhiteBoardLines={this.sendWhiteBoardLines}
                            updateQuestionState={this.state.updateQuestionState}
                            quizApiCall={this.state.quizApiCall}
                            mounting={this.state.mounting}
                            errorState={this.state.errorState}
                            waitingState={this.state.waitingState}
                            practiceId={this.state.practiceId}
                            obj={this.state.quizObj}
                            state2={this.props}
                            responseUrlAnswer={this.state?.responseUrlAnswer}
                            changeStateByItsChild={this.resettingMathZoneResult}
                            setScoreUpdate={this.mathZoneScoreUpdate}
                            studentScore={this.state.onlineQuizMathzoneScore}
                            selectedQuizLevel={this.state.selectedQuizLevel}
                            sendMessageState={this.sendStudentReportMathzone}
                            allStudentScore={this.state.allStudentScore}
                            studentResponseSenderMathZone={
                              this.studentResponseSenderMathZone
                            }
                            studentMathZoneResponseList={
                              this.state.studentMathZoneResponseList
                            }
                            allId={this.state.all_ids}
                            conceptName={this?.state?.conceptName}
                            conceptTag={this?.state?.conceptTag}
                            mathZoneQuizLevel={this?.state?.mathZoneQuizLevel}
                          />
                        </>
                      ) : //ending
                      // <MainOnlineQuizPage />
                      this.state.selected === 7 ||
                        this.state.selected === 13 ? (
                        <AllImportFile
                          state={this.state.selected}
                          text="COding"
                          codeFrameUrl={this.state.codeFrameUrl}
                          classId={this.state.mLiveClassId}
                        />
                      ) : this.state.selected === 8 ? (
                        <AllImportFile
                          state={this.state.selected}
                          text="logical reason"
                          classId={this.state.mLiveClassId}
                        />
                      ) : this.state.selected === 9 ? (
                        <AllImportFile
                          state={this.state.selected}
                          text="Scratch"
                          classId={this.state.mLiveClassId}
                          identity={videoRoom.localParticipant.identity}
                          env={this.props.env}
                          showLessonScratch={this.showLessonScratch}
                        />
                      ) : this.state.selected === 10 ? (
                        <AllImportFile
                          state={this.state.selected}
                          text="Geogebra"
                          fetchagain={this.state.fetchagain}
                          geoGebraUpdateData={this.geoGebraUpdateData}
                          geo_id={this.state.geo_id}
                          nextQuestionGeogebra={this.nextQuestionGeogebra}
                          identity={videoRoom.localParticipant.identity}
                        />
                      ) : this.state.selected === 11 ? (
                        //* some changing done by anish

                        <>
                          {this.state.mounting && (
                            <AllImportFile
                              state={this.state.selected}
                              identity={videoRoom.localParticipant.identity}
                              participantMuted={
                                this.state[
                                  videoRoom?.localParticipant?.identity +
                                    "_mute"
                                ]
                              }
                              type={this.state.selectedActivity}
                              isCheckInActivity={this.state.isCheckInActivity}
                              showActivityNotification={
                                this.state.showActivityNotification
                              }
                              handleShowActivityNotification={
                                this.handleShowActivityNotification
                              }
                              remoteParticipants={this?.state?.all_ids || []}
                              cicoApiData={this.state.cicoApiData || {}}
                              sendCicoDataToStudent={this.sendCicoDataToStudent}
                              handleCloseActivity={this.handleCloseActivity}
                              sendShapeChallengeImage={
                                this.sendShapeChallengeImage
                              }
                              shapeActivityStudentResponse={
                                this.state?.shapeActivityStudentResponse || {}
                              }
                              shapesActivityTutorsWhiteBoardPoint={
                                this.state
                                  ?.shapesActivityTutorsWhiteBoardPoint || []
                              }
                              sendActiveWhiteBoardData={
                                this.sendActiveWhiteBoardData
                              }
                              renderShapeChallenge={
                                this.state.renderShapeChallenge
                              }
                              onStudentIpadState={this.StudentIpadState}
                              IpadState={this.state.studentIpadStatesss}
                              sendSelectedAffirmationValue={
                                this.sendSelectedAffirmationValue
                              }
                              affirmationActivityStudentResponse={
                                this.state
                                  ?.affirmationActivityStudentResponse || ""
                              }
                              onSendStoriesToStudent={
                                this.onSendStoriesToStudent
                              }
                              showAffirmationStories={
                                this.state.showAffirmationStories
                              }
                              storyBookPageNumber={
                                this.state.storyBookPageNumber
                              }
                              handleChangePageNumber={
                                this.handleChangeActivityPageNumber
                              }
                              showEndActivityAnimation={
                                this.state.showEndActivityAnimation
                              }
                              tutorName={this.props.tutorName}
                              teacherActivityResponseSaved={
                                this.state.teacherActivityResponseSaved
                              }
                              handleSendTeacherActivityResponse={
                                this.handleSendTeacherActivityResponse
                              }
                              showAffirmationNextButton={
                                this.state.showAffirmationNextButton
                              }
                              tutorMuteStatus={this.props.isMuteEnabled}
                              FeelingChartSelectedImg={
                                this.state.FeelingChartSelectedImg
                              }
                              onSendSelectedFeelingImg={
                                this.onSendSelectedFeelingImg
                              }
                              onSendOpenDropBox={this.onSendOpenDropBox}
                              resetActivityState={this.resetActivityState}
                              feelingChartDropBox={
                                this.state.feelingChartDropBox
                              }
                              FeelingChartSelectedImgCheckout={
                                this.state.FeelingChartSelectedImgCheckout
                              }
                              isAffirmationPreviewImage={
                                this.state.isAffirmationPreviewImage
                              }
                              handleShowPreviewImageAffrimation={
                                this.handleShowPreviewImageAffrimation
                              }
                              isStudentActivityEnd={
                                this.state.isStudentActivityEnd
                              }
                              isActivityScreenShotCapture={
                                this.state.isActivityScreenShotCapture
                              }
                              showCheckoutAffirmationNextBtn={
                                this.state.showCheckoutAffirmationNextBtn
                              }
                              onAffirmationCheckoutNextButton={
                                this.onAffirmationCheckoutNextButton
                              }
                              handleShapeChallengeStudentResponseSaved={
                                this.handleShapeChallengeStudentResponseSaved
                              }
                              isStudentShapeChallengeResponseSaved={
                                this.state.isStudentShapeChallengeResponseSaved
                              }
                              handleEndCheckOutActivity={
                                this.handleEndCheckOutActivity
                              }
                              handleEndCheckInActivity={
                                this.handleEndCheckInActivity
                              }
                            />
                          )}
                        </>
                      ) : this.state.selected === 12 ? (
                        <AllImportFile
                          state={this.state.selected}
                          identity={videoRoom.localParticipant.identity}
                          miscellaneousId={this.state?.miscellaneousId}
                          flagQuestionLevel={this.state.flagQuestionLevel}
                          conceptName={this.state.conceptName}
                          conceptTag={this.state.conceptTag}
                          selectedConceptId={this.state.selectedConceptId}
                          selectedTagId={this.state.selectedTagId}
                          clearCanvas={this.props.onClearCanvas}
                          onSendWhiteBoardLines={this.sendWhiteBoardLines}
                          reference={this.quizScreenElement}
                          openRoughBoardScreen={this.props.openRoughBoardScreen}
                          updatestateforchild={this.updatestateforchild}
                          openHomeWorkResponse={this.openHomeWorkResponse}
                          displayHomeWorkQuestion={
                            this.state.displayHomeWorkQuestion || false
                          }
                          homewWorkStudentName={
                            this.state.homewWorkStudentName || ""
                          }
                          homeWorkStudentId={this.state.homeWorkStudentId || ""}
                          homeWorkCurrentQuestion={
                            this.state.homeWorkCurrentQuestion || 0
                          }
                          quizId={this.state.homework_tag_quiz_id || ""}
                          liveClassId={this.state.mLiveClassId}
                          currentUserId={this.state.currentUserId}
                          handleFlagQuestionChange={
                            this.handleFlagQuestionChange
                          }
                          currentFlagQuestion={
                            this.state.currentFlagQuestion || 0
                          }
                          flagQuestionMarksAsCompleted={
                            this.state.flagQuestionMarksAsCompleted || false
                          }
                          flagQuestionConceptList={
                            this.state.flagQuestionConceptList
                          }
                          openFlagQuestion={this.openFlagQuestion}
                          showFlagQuestion={this.state.showFlagQuestion}
                          fetchFlaggedQuestionList={
                            this.getFlagQuestionConceptList
                          }
                          homeWorkId={this.state.homeWorkId || ""}
                          fetchAgainHomeWorkQuestion={
                            this.state.fetchAgainHomeWorkQuestion
                          }
                          callUploadResourceApiAgain={
                            this.callUploadResourceList
                          }
                          uploadedResources={this.state.uploadedResources}
                          openWhiteBoardUploadResources={
                            this.openResourcesLessonScreen
                          }
                        />
                      ) : this.state.selected === 14 ? (
                        <AllImportFile
                          state={this.state.selected}
                          ref={this.lessonScratchScreenElement}
                          videoRoom={videoRoom}
                          board={showBoard}
                          SelectedCurrentState={this.state.selected}
                          clearCanvas={this.props.onClearCanvas}
                          openGraphImage={this.props.openGraphImage}
                          changeLessonPageNumber={
                            this.props.onChangeLessonPageNumber
                          }
                          onSendWhiteBoardLines={this.sendWhiteBoardLines}
                          onStudentIpadState={this.StudentIpadState}
                          identity={videoRoom.localParticipant.identity}
                          text="White board"
                          selPdfUrl={selectedPdfUrl}
                          refreshTime={this.state.refreshTime}
                          selPdfIndex={selectedPdfIndex}
                          classId={this.state.mLiveClassId}
                          subItemId={this.state.selectedSubItemId}
                          changechildlessonpdf={this.changechildlessonpdf}
                          changepenstrokesforchild={
                            this.changepenstrokesforchild
                          }
                          fetchagain={this.state.fetchagain}
                          updatestateforchild={this.updatestateforchild}
                          openRoughBoardScreen={this.props.openRoughBoardScreen}
                          remoteParticipants={remoteParticipants}
                          closeLessonScratch={this.closeLessonScratch}
                          hideBackButtonInLessonScratch={
                            this.state.hideBackButtonInLessonScratch
                          }
                          // selectGGbDataInConceptsList={
                          //   this.state.selectGGbDataInConceptsList
                          // }
                        />
                      ) : this.state.selected === 16 ? (
                        this.state.prepostMouting ? (
                          <AllImportFile
                            state={this.state.selected}
                            ref={this.quizScreenElement}
                            text="Quiz"
                            identity={videoRoom.localParticipant.identity}
                            quizURL={selectedQuizUrl}
                            refreshQuiz={this.refreshPrePostTestQuizPlay}
                            updatestateforchild={this.updatestateforchild}
                            startPractice={this.startPracticeAction}
                            openRoughBoardScreen={
                              this.props.openRoughBoardScreen
                            }
                            onSendQuizCanvasLines={
                              this.props.sendQuizCanvasLines
                            }
                            clearCanvas={this.props.onClearCanvas}
                            onSendWhiteBoardLines={this.sendWhiteBoardLines}
                            updateQuestionState={this.state.updateQuestionState}
                            quizApiCall={this.state.quizApiCall}
                            mounting={this.state.mounting}
                            errorState={this.state.errorState}
                            waitingState={this.state.waitingState}
                            practiceId={this.state.practiceId}
                            obj={this.state.quizObj}
                            state2={this.props}
                            responseUrlAnswer={this.state?.responseUrlAnswer}
                            changeStateByItsChild={this.resettingMathZoneResult}
                            setScoreUpdate={this.mathZoneScoreUpdate}
                            studentScore={this.state.onlineQuizMathzoneScore}
                            selectedQuizLevel={this.state.selectedQuizLevel}
                            sendMessageState={this.sendStudentReportMathzone}
                            allStudentScore={this.state.allStudentScore}
                            studentResponseSenderMathZone={
                              this.studentResponseSenderMathZone
                            }
                            studentMathZoneResponseList={
                              this.state.studentMathZoneResponseList
                            }
                            allId={this.state.allStudentPrePostIds || []}
                            currentSelectedStudentId={
                              this.state.currentSelectedStudentId
                            }
                            conceptName={this?.state?.conceptName}
                            conceptTag={this?.state?.conceptTag}
                            mathZoneQuizLevel={this?.state?.mathZoneQuizLevel}
                            pre_post_test_id={this.state.pre_post_test_id}
                            liveClassId={this.state.mLiveClassId}
                            test_type={this.state.test_type}
                            liveClassID={this.state.liveClassIdWhenJoining}
                            sub_concept_id={this.state.prepostSubConceptId}
                            swithTabPrePostTestTeacher={
                              this.swithTabPrePostTestTeacher
                            }
                            setCurrentSelectedStudentId={
                              this.setCurrentSelectedStudentId
                            }
                            nextPrePostTestQuizPlay={
                              this.nextPrePostTestQuizPlay
                            }
                            prepostConceptName={this.state.prepostConceptName}
                            openPrePostTestReviewQuestionForStudent={
                              this.props.openPrePostTestReviewQuestionForStudent
                            }
                            studentPrePostReview={this.studentPrePostReview}
                            changePrePostPagination={
                              this.props.changePrePostPagination
                            }
                            closePrePostTestReviewQues={
                              this.props.closePrePostTestReviewQues
                            }
                            changePaginationStudentSide={
                              this.changePaginationStudentSideRef
                            }
                            onStudentIpadState={this.StudentIpadState}
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Col>
                  ) : null}

                  <Col
                    onMouseEnter={() => this.hideMenus(4)}
                    md={this.state.selected === 1 ? "12" : "2"}
                    xs={this.state.selected === 1 ? "12" : "2"}
                  >
                    <Row>
                      {videoRoom.localParticipant.identity != "parent" &&
                      !this.smPresent(videoRoom.localParticipant.identity) ? (
                        <div
                          style={{
                            zIndex: "9999",
                            position: "fixed",
                            bottom: "0px",
                            right: this.state.selected === 1 ? "0%" : "17%",
                          }}
                          className="chatbutton"
                        >
                          {this.state.newMessageCounter > 0 ? (
                            <span
                              style={{
                                backgroundColor: "#F24A4A",
                                color: "white",
                                position: "absolute",
                                top: "-4px",
                                right: "10px",
                                padding: "4px 8px",
                                width: "22px",
                                height: "22px",
                                fontSize: "10px",
                                borderRadius: "15px",
                              }}
                            >
                              {this.state.newMessageCounter}
                            </span>
                          ) : (
                            ""
                          )}

                          <Image
                            style={{ cursor: "pointer" }}
                            onTouchStart={() => this.openChatBox()}
                            onClick={() => this.openChatBox()}
                            className="img-fluid chatbox-open"
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/StartChat.svg"
                            }
                            alt="Start Chat"
                          />
                        </div>
                      ) : null}

                      <section
                        style={{ zIndex: "1" }}
                        className="chatbox-popup"
                      >
                        <header className="chatbox-popup__header">
                          <aside style={{ flex: "8" }}>
                            <h1
                              style={{
                                color: "#233584",
                                fontSize: "14px",
                                opacity: "0.6",
                                fontWeight: "600",
                                textAlign: "left",
                              }}
                            >
                              Chat
                            </h1>
                          </aside>

                          <aside style={{ flex: "1" }}>
                            <Image
                              onClick={this.closeChatBox}
                              style={{ cursor: "pointer" }}
                              className="chatbox-close img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Close-chat.svg"
                              }
                              alt="Chat with tutor"
                            />
                          </aside>
                        </header>

                        <main className="chatbox-popup__main">
                          {this.state.chatMessageArray.map((dataElement) => {
                            return (
                              <>
                                {this.appendMessage(dataElement.message) ? (
                                  <Row style={{ marginBottom: "10px" }}>
                                    <Col
                                      className="justify-content-md-center"
                                      md={12}
                                    >
                                      <h5
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "#777",
                                          opacity: "0.8",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {studentName(dataElement.identity) ==
                                        "tutor"
                                          ? "Coach"
                                          : studentName(
                                              dataElement.identity
                                            )}{" "}
                                      </h5>
                                    </Col>

                                    <Col
                                      md={12}
                                      style={{
                                        padding: "0px 10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          padding: "8px",
                                          background: "blue",
                                          borderRadius: "16px",
                                        }}
                                      >
                                        <a
                                          href={dataElement.message}
                                          target="_blank"
                                          style={{
                                            margin: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {dataElement.message}
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row style={{ marginBottom: "10px" }}>
                                    <Col
                                      className="justify-content-md-center"
                                      md={12}
                                    >
                                      <h5
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "#777",
                                          opacity: "0.8",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {studentName(dataElement.identity) ==
                                        "tutor"
                                          ? "Coach"
                                          : studentName(
                                              dataElement.identity
                                            )}{" "}
                                      </h5>
                                    </Col>

                                    <Col
                                      md={12}
                                      style={{
                                        padding: "0px 10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          padding: "8px",
                                          background: "blue",
                                          borderRadius: "16px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            margin: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {dataElement.message}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </>
                            );
                          })}
                        </main>

                        <footer className="chatbox-popup__footer">
                          <aside style={{ flex: "11" }}>
                            <textarea
                              className="chat-textarea"
                              value={this.state.textareaValue}
                              onKeyDown={this.handleKeyPress.bind(this)}
                              onChange={this.handleChangeTextarea.bind(this)}
                              style={{ fontSize: "14px" }}
                              type="text"
                              placeholder="Your message here"
                              autoFocus
                            ></textarea>
                          </aside>

                          <button
                            disabled={
                              !this.state.textareaValue ||
                              this.state.textareaValue.trim() == ""
                            }
                            onClick={() => this.dropChatMessage()}
                            style={{
                              flex: "1",
                              color: "#888",
                              textAlign: "center",
                            }}
                          >
                            <Image
                              style={{ cursor: "pointer" }}
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Send-btn.svg"
                              }
                              alt="Send"
                            />
                          </button>
                        </footer>
                      </section>

                      {this.state.selected === 1 ? (
                        <React.Fragment>
                          {videoRoom.localParticipant.identity == "tech" ? (
                            <Col
                              style={{ paddingLeft: "0", paddingRight: "0" }}
                              onMouseEnter={() => this.showStar()}
                              onMouseLeave={() => this.hideStar()}
                              key={videoRoom.localParticipant.sid}
                              md={maxDevice}
                              xs={12}
                              className="tech-view"
                            >
                              <LocalParticipant
                                participant={videoRoom.localParticipant}
                                selected={this.state.selected}
                              />

                              <p className="flex-p" style={childName}>
                                <Signal
                                  signalLevel={
                                    videoRoom.localParticipant
                                      .networkQualityLevel
                                  }
                                />{" "}
                                {videoRoom.localParticipant.identity}
                              </p>
                            </Col>
                          ) : (
                            ""
                          )}

                          {videoRoom.localParticipant.identity == "tutor" ? (
                            <Col
                              onMouseEnter={() => this.showStar()}
                              onMouseLeave={() => this.hideStar()}
                              key={videoRoom.localParticipant.sid}
                              md={maxDevice}
                              xs={12}
                              className="tutor-view"
                              style={{ padding: "0" }}
                            >
                              <div
                                className={
                                  this.state.selected === 2
                                    ? "centerTutor"
                                    : null
                                }
                              >
                                <LocalParticipant
                                  participant={videoRoom.localParticipant}
                                  selected={this.state.selected}
                                />

                                <p className="flex-p" style={childName}>
                                  <Signal
                                    signalLevel={
                                      videoRoom.localParticipant
                                        .networkQualityLevel
                                    }
                                  />{" "}
                                  Coach{" "}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            <Col
                              style={{
                                paddingLeft: "0",
                                paddingRight: "0",
                                display: this.state.hideteacherfromchildView
                                  ? "none"
                                  : "",
                              }}
                              onMouseEnter={() => this.showStar()}
                              onMouseLeave={() => this.hideStar()}
                              key={videoRoom.localParticipant.sid}
                              md={maxDevice}
                              xs={12}
                            >
                              {remoteParticipants.map((participant) => {
                                if (participant.identity == "tutor") {
                                  return (
                                    <div
                                      className={
                                        this.state.selected === 2
                                          ? "centerTutor"
                                          : null
                                      }
                                    >
                                      <Participant
                                        key={participant.sid}
                                        participant={participant}
                                        selected={this.state.selected}
                                      />
                                      <p className="flex-p" style={childName}>
                                        <Signal
                                          signalLevel={
                                            participant.networkQualityLevel
                                          }
                                        />{" "}
                                        Coach
                                      </p>
                                    </div>
                                  );
                                }
                              })}
                            </Col>
                          )}

                          {remoteParticipants.map((participant) => {
                            if (participant.identity == "tech") {
                              return (
                                <Col
                                  style={{
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                  }}
                                  md={maxDevice}
                                  xs={12}
                                >
                                  <Participant
                                    key={participant.sid}
                                    participant={participant}
                                    selected={this.state.selected}
                                  />

                                  <p className="flex-p" style={childName}>
                                    <Signal
                                      signalLevel={
                                        participant.networkQualityLevel
                                      }
                                    />{" "}
                                    {participant.identity}
                                  </p>
                                </Col>
                              );
                            }
                          })}

                          {!isTutorCheck(
                            videoRoom.localParticipant.identity
                          ) ? (
                            <Col
                              style={{ paddingLeft: "0", paddingRight: "0" }}
                              onMouseEnter={() => this.showStar()}
                              className="not-tutor-view"
                              onMouseLeave={() => this.hideStar()}
                              key={videoRoom.localParticipant.sid}
                              md={maxDevice}
                              xs={12}
                            >
                              <LocalParticipant
                                participant={videoRoom.localParticipant}
                                selected={this.state.selected}
                              />
                              <p className="flex-p" style={childName}>
                                <Signal
                                  signalLevel={
                                    videoRoom.localParticipant
                                      .networkQualityLevel
                                  }
                                />
                                {studentName(
                                  videoRoom.localParticipant.identity
                                )}
                              </p>
                              {this.state.starClicked &&
                              this.state.starClickedStudent ==
                                videoRoom.localParticipant.identity ? (
                                // <Image
                                //   style={{
                                //     position: "absolute",
                                //     top: "50%",
                                //     left: "36%",
                                //     transform: "translate(-50%,-50%)",
                                //   }}
                                //   className="pulse"
                                //   src={
                                //     process.env.PUBLIC_URL +
                                //     "/static/media/Images-v2/StarAnimation.svg"
                                //   }
                                //   alt="Star Animation"
                                // />
                                <StarLottie />
                              ) : null}

                              {this.state.smileyClicked &&
                              this.state.smileyClickedStudent ==
                                videoRoom.localParticipant.identity ? (
                                // <Image
                                //   style={{
                                //     position: "absolute",
                                //     top: "40%",
                                //     left: "40%",
                                //     transform: "translate(-50%,-50%)",
                                //     height: 120,
                                //     width: 120
                                //   }}
                                //   className="pulse"
                                //   src={
                                //     process.env.PUBLIC_URL +
                                //     "/static/media/Images-v2/SmileyAnimation.svg"
                                //   }
                                //   alt="Smiley Animation"
                                // />
                                <SmileyLottie />
                              ) : null}

                              {this.state.clappingClicked &&
                              this.state.clappingClickedStudent ==
                                videoRoom.localParticipant.identity ? (
                                // <Image
                                //   style={{
                                //     position: "absolute",
                                //     top: "40%",
                                //     left: "40%",
                                //     transform: "translate(-50%,-50%)",
                                //     height: 120,
                                //     width: 120
                                //   }}
                                //   className="pulse"
                                //   src={
                                //     process.env.PUBLIC_URL +
                                //     "/static/media/Images-v2/ClappingAnimation.svg"
                                //   }
                                //   alt="Clapping Animation"
                                // />
                                <ClappingLottie />
                              ) : null}

                              {this.state.thumbsUpClicked &&
                              this.state.thumbsUpClickedStudent ==
                                videoRoom.localParticipant.identity ? (
                                // <Image
                                //   style={{
                                //     position: "absolute",
                                //     top: "40%",
                                //     left: "40%",
                                //     transform: "translate(-50%,-50%)",
                                //     height: 120,
                                //     width: 120
                                //   }}
                                //   className="pulse"
                                //   src={
                                //     process.env.PUBLIC_URL +
                                //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                                //   }
                                //   alt="ThumbsUp Animation"
                                // />
                                <ThumbsUpLottie />
                              ) : null}

                              <Image
                                onClick={() =>
                                  this.hideRaiseHand(
                                    videoRoom.localParticipant.identity
                                  )
                                }
                                style={{
                                  position: "absolute",
                                  top: "5%",
                                  right: "4%",
                                }}
                                className={
                                  this.state[
                                    videoRoom.localParticipant.identity +
                                      "_hand"
                                  ] == undefined ||
                                  this.state[
                                    videoRoom.localParticipant.identity +
                                      "_hand"
                                  ] == false
                                    ? "hidden"
                                    : "shown"
                                }
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/RaiseHandRequest.svg"
                                }
                                alt="Raise hand"
                              />

                              {videoRoom.localParticipant.identity !=
                              "parent" ? (
                                <a
                                  style={{
                                    position: "absolute",
                                    right: "38%",
                                    top: "18px",
                                    // bottom: "12%",
                                    cursor: "default",
                                  }}
                                >
                                  <Image
                                    style={{ height: 25, width: 25 }}
                                    className=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/static/media/Images-v2/ThumbsUp.svg"
                                    }
                                    alt="Thumbs Up"
                                  />

                                  {this.state[
                                    videoRoom.localParticipant.identity +
                                      "_thumbsUp"
                                  ] == undefined ? (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      0
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        this.state[
                                          videoRoom.localParticipant.identity +
                                            "_thumbsUp"
                                        ]
                                      }
                                    </span>
                                  )}
                                </a>
                              ) : null}

                              {videoRoom.localParticipant.identity !=
                              "parent" ? (
                                <a
                                  style={{
                                    position: "absolute",
                                    right: "48%",
                                    top: "18px",
                                    // bottom: "12%",
                                    cursor: "default",
                                  }}
                                >
                                  <Image
                                    style={{ height: 25, width: 25 }}
                                    className=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/static/media/Images-v2/Clapping.svg"
                                    }
                                    alt="Clapping"
                                  />

                                  {this.state[
                                    videoRoom.localParticipant.identity +
                                      "_clapping"
                                  ] == undefined ? (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      0
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        this.state[
                                          videoRoom.localParticipant.identity +
                                            "_clapping"
                                        ]
                                      }
                                    </span>
                                  )}
                                </a>
                              ) : null}

                              {videoRoom.localParticipant.identity !=
                              "parent" ? (
                                <a
                                  style={{
                                    position: "absolute",
                                    right: "28%",
                                    top: "18px",
                                    // bottom: "12%",
                                    cursor: "default",
                                  }}
                                >
                                  <Image
                                    style={{ height: 25, width: 25 }}
                                    className=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/static/media/Images-v2/Smiley.svg"
                                    }
                                    alt="Smiley"
                                  />

                                  {this.state[
                                    videoRoom.localParticipant.identity +
                                      "_smiley"
                                  ] == undefined ? (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      0
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        this.state[
                                          videoRoom.localParticipant.identity +
                                            "_smiley"
                                        ]
                                      }
                                    </span>
                                  )}
                                </a>
                              ) : null}

                              {videoRoom.localParticipant.identity !=
                              "parent" ? (
                                <a
                                  style={{
                                    position: "absolute",
                                    right: "20%",
                                    top: "20px",
                                    // bottom: "12%",
                                    cursor: "default",
                                  }}
                                >
                                  <Image
                                    className=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/static/media/Images-v2/Star.svg"
                                    }
                                    alt="Star"
                                  />

                                  {this.state[
                                    videoRoom.localParticipant.identity +
                                      "_star"
                                  ] == undefined ? (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      0
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "7px",
                                        color: "white",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        this.state[
                                          videoRoom.localParticipant.identity +
                                            "_star"
                                        ]
                                      }
                                    </span>
                                  )}
                                </a>
                              ) : null}

                              {videoRoom.localParticipant.identity !=
                              "parent" ? (
                                this.state[
                                  videoRoom.localParticipant.identity + "_mute"
                                ] == undefined ||
                                this.state[
                                  videoRoom.localParticipant.identity + "_mute"
                                ] == false ? (
                                  <a
                                    style={{
                                      position: "absolute",
                                      right: "3%",
                                      // bottom: "5.5%",
                                      top: "6px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.muteClickBtn(
                                        videoRoom.localParticipant.identity
                                      )
                                    }
                                  >
                                    <Image
                                      className=""
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                                      }
                                      alt="Settings"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    style={{
                                      position: "absolute",
                                      right: "3%",
                                      // bottom: "5.5%",
                                      top: "6px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.unMuteClickBtn(
                                        videoRoom.localParticipant.identity
                                      )
                                    }
                                  >
                                    <Image
                                      className=""
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Images-v2/Allscreen-mutebtn.svg"
                                      }
                                      alt="Settings"
                                    />
                                  </a>
                                )
                              ) : // this.props.isMuteEnabled ? (
                              //   <a
                              //     onClick={() =>
                              //       this.muteRemoteStudent(
                              //         videoRoom.localParticipant.identity
                              //       )
                              //     }
                              //     style={{
                              //       position: "absolute",
                              //       right: "3%",
                              //       // bottom: "5.5%",
                              //       top: "6px",
                              //       cursor: "pointer",
                              //     }}
                              //   >
                              //     <Image
                              //       className=""
                              //       src={
                              //         process.env.PUBLIC_URL +
                              //         "/static/media/Images-v2/Allscreen-mutebtn.svg"
                              //       }
                              //       alt="Settings"
                              //     />
                              //   </a>
                              // ) : (
                              //   <a
                              //     onClick={() =>
                              //       this.unMuteRemoteStudent(
                              //         videoRoom.localParticipant.identity
                              //       )
                              //     }
                              //     style={{
                              //       position: "absolute",
                              //       right: "3%",
                              //       // bottom: "5.5%",
                              //       top: "6px",
                              //       cursor: "pointer",
                              //     }}
                              //   >
                              //     <Image
                              //       className=""
                              //       src={
                              //         process.env.PUBLIC_URL +
                              //         "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                              //       }
                              //       alt="Settings"
                              //     />
                              //   </a>

                              // )
                              null}
                            </Col>
                          ) : (
                            // <a
                            //   style={{
                            //     position: "absolute",
                            //     right: "3%",
                            //     // bottom: "5.5%",
                            //     top: "6px",
                            //     cursor: "pointer",
                            //   }}
                            // >
                            //   <Image
                            //     className=""
                            //     src={
                            //       process.env.PUBLIC_URL +
                            //       "/static/media/Images-v2/Allscreen-unmutebtn.svg"
                            //     }
                            //     alt="Settings"
                            //   />
                            // </a>
                            ""
                          )}

                          {videoRoom.localParticipant.identity == "parent"
                            ? remoteParticipantsViewforParent
                            : this.smPresent(
                                videoRoom.localParticipant.identity
                              )
                            ? remoteParticipantsViewforSuccessManagers
                            : remoteParticipantsView}
                        </React.Fragment>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              </Col>

              {this.state.selected != 1 ? (
                <Col
                  md={2}
                  className="right-thumbnail"
                  style={{
                    flex: rightFlex,
                    maxWidth: rightMaxWidth,
                  }}
                >
                  <React.Fragment>
                    {videoRoom.localParticipant.identity == "tech" ? (
                      <Col
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                        onMouseEnter={() => this.showStar()}
                        onMouseLeave={() => this.hideStar()}
                        key={videoRoom.localParticipant.sid}
                        md={this.state.selected === 1 ? "4" : "12"}
                        xs={12}
                      >
                        <LocalParticipant
                          participant={videoRoom.localParticipant}
                          selected={this.state.selected}
                        />

                        <p className="flex-p" style={childName}>
                          <Signal
                            signalLevel={
                              videoRoom.localParticipant.networkQualityLevel
                            }
                          />
                          {videoRoom.localParticipant.identity}
                        </p>
                      </Col>
                    ) : (
                      ""
                    )}

                    {videoRoom.localParticipant.identity == "tutor" ? (
                      <Col
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          marginTop: "10px",
                        }}
                        onMouseEnter={() => this.showStar()}
                        onMouseLeave={() => this.hideStar()}
                        key={videoRoom.localParticipant.sid}
                        md={this.state.selected === 1 ? "4" : "12"}
                        xs={12}
                        className="student-side-tutor"
                      >
                        {this.state.selected != 2 ? (
                          <div>
                            <LocalParticipant
                              participant={videoRoom.localParticipant}
                              selected={this.state.selected}
                            />

                            <p className="flex-p" style={childName}>
                              <Signal
                                signalLevel={
                                  videoRoom.localParticipant.networkQualityLevel
                                }
                              />{" "}
                              Coach{" "}
                            </p>
                          </div>
                        ) : null}
                      </Col>
                    ) : (
                      <Col
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                        onMouseEnter={() => this.showStar()}
                        onMouseLeave={() => this.hideStar()}
                        key={videoRoom.localParticipant.sid}
                        md={this.state.selected === 1 ? "4" : "12"}
                        xs={12}
                        className="student-side-coach"
                      >
                        {remoteParticipants.map((participant) => {
                          if (participant.identity == "tutor") {
                            return (
                              <React.Fragment>
                                {this.state.selected != 2 ? (
                                  <div
                                    className={
                                      this.state.selected === 2
                                        ? "centerTutor"
                                        : null
                                    }
                                  >
                                    <Participant
                                      key={participant.sid}
                                      participant={participant}
                                      selected={this.state.selected}
                                    />
                                    <p className="flex-p" style={childName}>
                                      <Signal
                                        signalLevel={
                                          participant.networkQualityLevel
                                        }
                                      />{" "}
                                      Coach
                                    </p>
                                  </div>
                                ) : null}
                              </React.Fragment>
                            );
                          }
                        })}
                      </Col>
                    )}

                    {remoteParticipants.map((participant) => {
                      if (participant.identity == "tech") {
                        return (
                          <Col
                            style={{ paddingLeft: "0", paddingRight: "0" }}
                            md={this.state.selected === 1 ? "4" : "12"}
                            xs={12}
                          >
                            <Participant
                              key={participant.sid}
                              participant={participant}
                              selected={this.state.selected}
                            />

                            <p className="flex-p" style={childName}>
                              <Signal
                                signalLevel={participant.networkQualityLevel}
                              />{" "}
                              {participant.identity}
                            </p>
                          </Col>
                        );
                      }
                    })}

                    {/* {this.state.selected !== 1 ?
                        <p style={{ color: "#676971", fontSize: "12px", paddingBottom: "8px", paddingTop: "20px", margin: "0" }}>
                          Students
                          </p>
                        :
                        null

                      } */}

                    {!isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <Col
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          marginTop: "10px",
                        }}
                        onMouseEnter={() => this.showStar()}
                        onMouseLeave={() => this.hideStar()}
                        key={videoRoom.localParticipant.sid}
                        md={this.state.selected === 1 ? "4" : "12"}
                        xs={12}
                        className="student-side-not-tutor"
                      >
                        {remoteParticipants.length + 1 > 5 ? null : (
                          <LocalParticipant
                            participant={videoRoom.localParticipant}
                            selected={this.state.selected}
                          />
                        )}

                        <Image
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "5%",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.hideRaiseHand(
                              videoRoom.localParticipant.identity
                            )
                          }
                          className={
                            this.state[
                              videoRoom.localParticipant.identity + "_hand"
                            ] == undefined ||
                            this.state[
                              videoRoom.localParticipant.identity + "_hand"
                            ] == false
                              ? "hidden"
                              : "shown"
                          }
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/RaiseHandRequest.svg"
                          }
                          alt="Raise hand"
                        />
                        {this.state.starClicked &&
                        this.state.starClickedStudent ==
                          videoRoom.localParticipant.identity ? (
                          // <Image
                          //   style={{
                          //     position: "absolute",
                          //     top: "50%",
                          //     left: "36%",
                          //     transform: "translate(-50%,-50%)",
                          //   }}
                          //   className="pulse"
                          //   src={
                          //     process.env.PUBLIC_URL +
                          //     "/static/media/Images-v2/StarAnimation.svg"
                          //   }
                          //   alt="Star Animation"
                          // />
                          <StarLottie />
                        ) : null}

                        {this.state.smileyClicked &&
                        this.state.smileyClickedStudent ==
                          videoRoom.localParticipant.identity ? (
                          // <Image
                          //   style={{
                          //     position: "absolute",
                          //     top: "40%",
                          //     left: "40%",
                          //     transform: "translate(-50%,-50%)",
                          //     height: 120,
                          //     width: 120
                          //   }}
                          //   className="pulse"
                          //   src={
                          //     process.env.PUBLIC_URL +
                          //     "/static/media/Images-v2/SmileyAnimation.svg"
                          //   }
                          //   alt="Smiley Animation"
                          // />
                          <SmileyLottie />
                        ) : null}

                        {this.state.clappingClicked &&
                        this.state.clappingClickedStudent ==
                          videoRoom.localParticipant.identity ? (
                          // <Image
                          //   style={{
                          //     position: "absolute",
                          //     top: "40%",
                          //     left: "40%",
                          //     transform: "translate(-50%,-50%)",
                          //     height: 120,
                          //     width: 120
                          //   }}
                          //   className="pulse"
                          //   src={
                          //     process.env.PUBLIC_URL +
                          //     "/static/media/Images-v2/ClappingAnimation.svg"
                          //   }
                          //   alt="Clapping Animation"
                          // />
                          <ClappingLottie />
                        ) : null}

                        {this.state.thumbsUpClicked &&
                        this.state.thumbsUpClickedStudent ==
                          videoRoom.localParticipant.identity ? (
                          // <Image
                          //   style={{
                          //     position: "absolute",
                          //     top: "40%",
                          //     left: "40%",
                          //     transform: "translate(-50%,-50%)",
                          //     height: 120,
                          //     width: 120
                          //   }}
                          //   className="pulse"
                          //   src={
                          //     process.env.PUBLIC_URL +
                          //     "/static/media/Images-v2/ThumbsUpAnimation.svg"
                          //   }
                          //   alt="ThumbsUp Animation"
                          // />
                          <ThumbsUpLottie />
                        ) : null}

                        {/* <Image onClick={() => this.hideRaiseHand(participant.identity)} style={{
                      position: "absolute",
                      right: "5%",
                      top: "5%",
                      cursor: "pointer"
                    }} className={this.state[dyanmic_hand_var] == undefined || this.state[dyanmic_hand_var] == false ? 'hidden' : 'shown'}
                      src={
                        process.env.PUBLIC_URL + "/static/media/Images-v2/RaiseHandRequest.svg"
                      }
                      alt="Raise hand"
                    /> */}

                        {this.state.selected === 1 ? (
                          <React.Fragment>
                            <p className="flex-p" style={childName}>
                              <Signal
                                signalLevel={
                                  videoRoom.localParticipant.networkQualityLevel
                                }
                              />{" "}
                              {studentName(videoRoom.localParticipant.identity)}
                            </p>

                            <Image
                              onClick={() =>
                                this.hideRaiseHand(
                                  videoRoom.localParticipant.identity
                                )
                              }
                              style={{
                                position: "absolute",
                                right: "2%",
                                top: "5%",
                              }}
                              className={
                                this.state[
                                  videoRoom.localParticipant.identity + "_hand"
                                ] == undefined ||
                                this.state[
                                  videoRoom.localParticipant.identity + "_hand"
                                ] == false
                                  ? "hidden"
                                  : "shown"
                              }
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/RaiseHandRequest.svg"
                              }
                              alt="Raise hand"
                            />

                            <a style={starCount}>
                              <Image
                                className=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/media/Images-v2/Star.svg"
                                }
                                alt="Settings"
                              />

                              {this.state[
                                videoRoom.localParticipant.identity + "_star"
                              ] == undefined ? (
                                <span
                                  style={{
                                    marginLeft: "7px",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  0
                                </span>
                              ) : (
                                <span
                                  style={{
                                    marginLeft: "7px",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  {
                                    this.state[
                                      videoRoom.localParticipant.identity +
                                        "_star"
                                    ]
                                  }
                                </span>
                              )}
                            </a>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {remoteParticipants.length + 1 > 5 ? (
                              <React.Fragment>
                                <div className="other-students">
                                  <Row style={{ position: "relative" }}>
                                    <Col md={12}>
                                      <Row className="row-padding">
                                        <Image
                                          style={{ width: "45px" }}
                                          className=""
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/avatar.png"
                                          }
                                          alt="Kid"
                                        />

                                        <div
                                          style={{
                                            position: "absolute",
                                            left: "25%",
                                            top: "10%",
                                          }}
                                        >
                                          <p className="name-text">
                                            <Signal
                                              signalLevel={
                                                videoRoom.localParticipant
                                                  .networkQualityLevel
                                              }
                                            />
                                            {studentName(
                                              videoRoom.localParticipant
                                                .identity
                                            )}
                                          </p>

                                          <p className="zero-margin">
                                            <a>
                                              <Image
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/static/media/Images-v2/Star.svg"
                                                }
                                                alt="Star"
                                              />

                                              {this.state[
                                                videoRoom.localParticipant
                                                  .identity + "_star"
                                              ] == undefined ? (
                                                <span
                                                  style={{ fontSize: "9px" }}
                                                >
                                                  0
                                                </span>
                                              ) : (
                                                <span
                                                  style={{ fontSize: "9px" }}
                                                >
                                                  {
                                                    this.state[
                                                      videoRoom.localParticipant
                                                        .identity + "_star"
                                                    ]
                                                  }
                                                </span>
                                              )}
                                            </a>
                                          </p>
                                        </div>

                                        <a
                                          style={{
                                            position: "absolute",
                                            right: "25%",
                                            top: "0px",
                                            width: "50px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Image
                                            className=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/StartSharing.svg"
                                            }
                                            alt="Start screen share"
                                          />
                                        </a>

                                        <a>
                                          <Image
                                            style={{
                                              position: "absolute",
                                              right: "7%",
                                              top: "10px",
                                              cursor: "pointer",
                                              width: "25px",
                                            }}
                                            className=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/static/media/Images-v2/Unmute.svg"
                                            }
                                            alt="Settings"
                                          />
                                        </a>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </React.Fragment>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: "5px ​10px",
                                  borderRadius: "0px 0px 6px 6px",
                                  boxShadow:
                                    "2px 2px 14px rgba(11, 66, 171, 0.15)",
                                  height: "70px",
                                }}
                              >
                                <Row
                                  style={{
                                    padding: "7px 15px",
                                    position: "relative",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "9px",
                                      fontWeight: "600",
                                      color: "#233584",
                                      position: "absolute",
                                      lineHeight: "25px",
                                      left: "10%",
                                      width: "30%",
                                      display: "flex",
                                    }}
                                  >
                                    <Signal
                                      signalLevel={
                                        videoRoom.localParticipant
                                          .networkQualityLevel
                                      }
                                    />
                                    <span
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        display: "inline-block",
                                      }}
                                    >
                                      {studentName(
                                        videoRoom.localParticipant.identity
                                      )}
                                    </span>
                                  </span>

                                  {videoRoom.localParticipant.identity !=
                                  "parent" ? (
                                    <a
                                      style={{
                                        position: "absolute",
                                        left: "72%",
                                        top: "33px",
                                        display: "flex",
                                        cursor:
                                          videoRoom.localParticipant
                                            .identity === "tutor"
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      <Image
                                        style={{ height: 25, width: 25 }}
                                        className=""
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Images-v2/ThumbsUp.svg"
                                        }
                                        alt="Thumbs Up"
                                      />
                                      {this.state[
                                        videoRoom.localParticipant.identity +
                                          "_thumbsUp"
                                      ] == undefined ? (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                            marginTop: "9px",
                                          }}
                                        >
                                          0
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "12px",
                                            marginTop: "9px",
                                          }}
                                        >
                                          {
                                            this.state[
                                              videoRoom.localParticipant
                                                .identity + "_thumbsUp"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </a>
                                  ) : null}

                                  {videoRoom.localParticipant.identity !=
                                  "parent" ? (
                                    <a
                                      style={{
                                        position: "absolute",
                                        left: "12%",
                                        top: "36px",
                                        display: "flex",
                                        cursor:
                                          videoRoom.localParticipant
                                            .identity === "tutor"
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      <Image
                                        style={{ height: 25, width: 25 }}
                                        className=""
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Images-v2/Clapping.svg"
                                        }
                                        alt="Clapping"
                                      />
                                      {this.state[
                                        videoRoom.localParticipant.identity +
                                          "_clapping"
                                      ] == undefined ? (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          0
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {
                                            this.state[
                                              videoRoom.localParticipant
                                                .identity + "_clapping"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </a>
                                  ) : null}

                                  {videoRoom.localParticipant.identity !=
                                  "parent" ? (
                                    <a
                                      style={{
                                        position: "absolute",
                                        left: "30%",
                                        top: "36px",
                                        cursor:
                                          videoRoom.localParticipant
                                            .identity === "tutor"
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      <Image
                                        style={{ height: 25, width: 25 }}
                                        className=""
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Images-v2/Smiley.svg"
                                        }
                                        alt="Smiley"
                                      />
                                      {this.state[
                                        videoRoom.localParticipant.identity +
                                          "_smiley"
                                      ] == undefined ? (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          0
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {
                                            this.state[
                                              videoRoom.localParticipant
                                                .identity + "_smiley"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </a>
                                  ) : null}

                                  {videoRoom.localParticipant.identity !=
                                  "parent" ? (
                                    <a
                                      style={{
                                        position: "absolute",
                                        left: "53%",
                                        top: "36px",
                                        cursor:
                                          videoRoom.localParticipant
                                            .identity === "tutor"
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      <Image
                                        className=""
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Images-v2/Star.svg"
                                        }
                                        alt="Star"
                                      />
                                      {this.state[
                                        videoRoom.localParticipant.identity +
                                          "_star"
                                      ] == undefined ? (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          0
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            color: "#676971",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {
                                            this.state[
                                              videoRoom.localParticipant
                                                .identity + "_star"
                                            ]
                                          }
                                        </span>
                                      )}
                                    </a>
                                  ) : null}

                                  {isTutorCheck(
                                    videoRoom.localParticipant.identity
                                  ) ? (
                                    this.state[dynamic_screen_var] ==
                                      undefined ||
                                    this.state[dynamic_screen_var] == false ? (
                                      <a
                                        style={{
                                          position: "absolute",
                                          right: "27%",
                                          top: "0%",
                                          width: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Image
                                          className=""
                                          onClick={() =>
                                            this.startStudentScreenShare(
                                              participant.identity
                                            )
                                          }
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/StartSharing.svg"
                                          }
                                          alt="Start screen share"
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        style={{
                                          position: "absolute",
                                          right: "27%",
                                          top: "0%",
                                          width: "35px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Image
                                          className=""
                                          onClick={() =>
                                            this.stopStudentScreenShare(
                                              participant.identity
                                            )
                                          }
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/StopSharing.svg"
                                          }
                                          alt="Stop screen share"
                                        />
                                      </a>
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {videoRoom.localParticipant.identity !=
                                  "parent" ? (
                                    this.state[
                                      videoRoom.localParticipant.identity +
                                        "_mute"
                                    ] == undefined ||
                                    this.state[
                                      videoRoom.localParticipant.identity +
                                        "_mute"
                                    ] == false ? (
                                      <a>
                                        <Image
                                          style={{
                                            position: "absolute",
                                            right: "12%",
                                            top: "9px",
                                            cursor: "pointer",
                                          }}
                                          className=""
                                          onClick={() =>
                                            this.muteClickBtn(
                                              videoRoom.localParticipant
                                                .identity
                                            )
                                          }
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/Unmute.svg"
                                          }
                                          alt="Mute"
                                        />
                                      </a>
                                    ) : (
                                      <a>
                                        <Image
                                          style={{
                                            position: "absolute",
                                            right: "12%",
                                            top: "9px",
                                            cursor: "pointer",
                                          }}
                                          className=""
                                          onClick={() =>
                                            this.unMuteClickBtn(
                                              videoRoom.localParticipant
                                                .identity
                                            )
                                          }
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/static/media/Images-v2/Mute.svg"
                                          }
                                          alt="Unmute"
                                        />
                                      </a>
                                    )
                                  ) : (
                                    // this.props.isMuteEnabled ? (
                                    //   <a>
                                    //     <Image
                                    //       style={{
                                    //         position: "absolute",
                                    //         right: "12%",
                                    //         top: "9px",
                                    //       }}
                                    //       className=""
                                    //       src={
                                    //         process.env.PUBLIC_URL +
                                    //         "/static/media/Images-v2/mute.svg"
                                    //       }
                                    //       alt="Mute"
                                    //     />
                                    //   </a>
                                    // ) : (
                                    //   <a>
                                    //     <Image
                                    //       style={{
                                    //         position: "absolute",
                                    //         right: "12%",
                                    //         top: "9px",
                                    //       }}
                                    //       className=""
                                    //       src={
                                    //         process.env.PUBLIC_URL +
                                    //         "/static/media/Images-v2/Unmute.svg"
                                    //       }
                                    //       alt="Mute"
                                    //     />
                                    //   </a>
                                    // )
                                    ""
                                  )}
                                </Row>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                        {/* <p>H : {this.state[videoRoom.localParticipant.identity+ "_hand"]}</p> */}
                      </Col>
                    ) : (
                      ""
                    )}

                    {videoRoom.localParticipant.identity == "parent"
                      ? remoteParticipantsViewforParent
                      : this.smPresent(videoRoom.localParticipant.identity)
                      ? remoteParticipantsViewforSuccessManagers
                      : remoteParticipantsView}

                    {/* {remoteParticipants.length + 1 >= 4 ?  */}

                    {/* :

null

} */}
                  </React.Fragment>
                </Col>
              ) : null}
            </Row>
          </Card.Body>
        </Card>
        <Modal show={this.state.showDeviceInfoToTech}>
          <Modal.Body>
            <Container>
              <h3>Device Information</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <table className="deviceInfoTech">
                  <tr className="deviceInfoTech">
                    <th className="deviceInfoTech">Name</th>
                    <th className="deviceInfoTech">Browser</th>
                    <th className="deviceInfoTech">Version</th>
                    <th className="deviceInfoTech">Plateform</th>
                    <th className="deviceInfoTech">OS</th>
                    <th className="deviceInfoTech">Device</th>
                  </tr>
                  {this.state.finalDeviceDataToShowTech?.map((val, key) => {
                    return (
                      <tr className="deviceInfoTech">
                        <td className="deviceInfoTech">{val?.name}</td>
                        <td className="deviceInfoTech">{val?.browser}</td>
                        <td className="deviceInfoTech">{val?.version}</td>
                        <th className="deviceInfoTech">{val?.platform}</th>
                        <th className="deviceInfoTech">{val?.os}</th>
                        <th className="deviceInfoTech">
                          {val?.desktop
                            ? "Desktop"
                            : val?.android
                            ? "Android"
                            : val?.ios
                            ? "IOS"
                            : val?.tablet
                            ? "Tablet"
                            : ""}
                        </th>
                      </tr>
                    );
                  })}
                </table>
              </div>

              <Button
                onClick={this.closeParentFeedback.bind(this)}
                className="primary-button"
              >
                Close
              </Button>
            </Container>
          </Modal.Body>
        </Modal>
        <ConfirmationModal2
          msg={
            "Please complete the  activity before going to other screen.<br />Do you still want to go other screen?. "
          }
          msg1={"Yes"}
          msg2={"No"}
          show={this.state.changeScreenConfirmationModal}
          onHide1={this.handleYesConfirmationModalCico}
          onHide2={this.handleCloseConfirmationModalCico}
        />

        <>
          <Modal show={this.state.errorWhileReconnecting}>
            <Modal.Body
              style={{ padding: "40px", border: "1px solid #E2E4EE" }}
            >
              <Row>
                <Col md={12} className="text-center">
                  <h3
                    style={{ color: "#233584", fontWeight: "bold" }}
                    className="text-center"
                  >
                    Error
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <Image
                    style={{ width: "250px" }}
                    src={
                      process.env.PUBLIC_URL + "/static/media/Error-image.png"
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <h6 className="page-sub-head text-center">Uh Ohhhh</h6>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <p
                    style={{ fontSize: "18px", color: "#233584" }}
                    className="text-center"
                  >
                    {this.state.errorWhileReconnectingMessage}
                  </p>
                </Col>
              </Row>

              <br />

              <Container>
                <Row className="text-right">
                  <Col md={12} className="text-center">
                    <Button
                      style={{ margin: "0 10px" }}
                      className="primary-button"
                      variant="primary"
                      onClick={this.props.onCallTechSupport}
                    >
                      Call Tech Support
                    </Button>
                    <Button
                      style={{ margin: "0 10px" }}
                      className="primary-button"
                      variant="secondary"
                      onClick={() =>
                        this.setState({
                          errorWhileReconnecting: false,
                        })
                      }
                    >
                      Close
                    </Button>
                    <Button
                      style={{ margin: "0 10px" }}
                      className="primary-button"
                      variant="secondary"
                      onClick={() => window.location.reload()}
                    >
                      Retry
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </>
      </EventSubscriber>
    );
  }
}

export default VideoRoom;
