import React from "react";
import { Modal, Button, Container } from "react-bootstrap";

export default function ConfirmationModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      style={{ maxWidth: "550px", margin: "auto" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="danger"
            style={{ borderRadius: 10 }}
            onClick={props.onClose}
          >
            X
          </Button>
        </div>
        <Container>
          <h4>{props?.msg ?? "Do you want to Capture?"}</h4>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "10px",
              alignItems: "center",
            }}
          >
            {props?.msg2 && (
              <Button onClick={props.onHide2} style={{ height: "fit-content" }}>
                {props?.msg2}
              </Button>
            )}
            {!props.disabled && (
              <Button onClick={props.onHide1} style={{ height: "fit-content" }}>
                {props?.msg1}
              </Button>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
