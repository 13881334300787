import React from "react";
import { Modal, Button, Container, Row } from "react-bootstrap";
import HtmlParser from "react-html-parser";

export default function ConfirmationModal2(props) {
  return (
    <Modal show={props?.show} style={{zIndex:99999}}>
    <Modal.Body>
      <Container>
        <h4>{HtmlParser(props?.msg??"")}</h4>
        <Row className="justify-content-center" style={{ gap: "10px" }}>
          <Button
            onClick={() => props?.onHide1()}
            className="primary-button"
            style={{ background: "green" }}
          >
             <h4>{HtmlParser(props?.msg1??"")}</h4>
          </Button>

          <Button
            className="primary-button"
            style={{ background: "red" }}
            onClick={() => props?.onHide2()}
          >
           <h4>{HtmlParser(props?.msg2??"")}</h4>
          </Button>
        </Row>
       
      </Container>
    </Modal.Body>
  </Modal>
  );
}