import { forwardRef, lazy, Suspense, useEffect, useRef, useState } from "react";

const AllImportFile = forwardRef((props, ref) => {
  let ComponentRef = useRef({ Component: "", prevState: null });
  const [state, setState] = useState(false);

  useEffect(() => {
    ComponentRef.current.prevState = props?.state || "";
    switch (props.state) {
      case 3:
        ComponentRef.current.Component = lazy(() =>
          import("../Whiteboard/Whiteboard")
        );
        break;
      case 4:
        ComponentRef.current.Component = lazy(() => import("../Video/Video"));
        break;
      case 5:
        ComponentRef.current.Component = lazy(() =>
          import("../SpeedMath/SpeedMath")
        );
        break;
      case 6:
        ComponentRef.current.Component = lazy(() => import("../myPages/demo"));
        break;
      case 7:
        ComponentRef.current.Component = lazy(() => import("../Coding/Coding"));
        break;
      case 8:
        ComponentRef.current.Component = lazy(() =>
          import("../LogicalReasoning/LogicalReasoning")
        );
        break;
      case 9:
        ComponentRef.current.Component = lazy(() =>
          import("../Scratch/Scratch")
        );
        break;
      case 10:
        ComponentRef.current.Component = lazy(() =>
          import("../Geogebra/Geogebra")
        );
        break;
      case 11:
        ComponentRef.current.Component = lazy(() =>
          import("../../StudentActivitys/MainActivity")
        );
        break;
      case 12:
        ComponentRef.current.Component = lazy(() =>
          import("../Miscellaneous/Miscellaneous")
        );
        break;
      case 13:
        ComponentRef.current.Component = lazy(() => import("../Coding/Coding"));
        break;
      case 14:
        ComponentRef.current.Component = lazy(() =>
          import("../Scratch/LessonsScratch")
        );
        break;
      case 15:
        ComponentRef.current.Component = lazy(() =>
          import("../Whiteboard/Whiteboard")
        );
        break;
      case 16:
        ComponentRef.current.Component = lazy(() =>
          import("../PrePostTestOuter/demo")
        );
        break;

      default:
        ComponentRef.current.Component = <h3>hello</h3>;
    }

    setState(!state);
  }, [props.state]);
  if (
    !ComponentRef.current.Component ||
    ComponentRef.current.prevState !== props?.state
  )
    return null;
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <ComponentRef.current.Component {...props} ref={ref} />
    </Suspense>
  );
});

export default AllImportFile;
