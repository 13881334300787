import React, { useEffect, useState } from "react";
import { LocalAudioTrack } from "twilio-video";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
} from "@material-ui/core";
import useDevices from "../hooks/useDevices";
import Video from "twilio-video";

import AudioLevelIndicator from "../AudioTesting/AudioLevelIndicator";

export default function AudioInputList({ audioEnable }) {
  const { audioInputDevices } = useDevices();

  const [audioTracks, setAudioTracks] = useState("");

  useEffect(() => {
    if (audioTracks == "") {
      getAudioTracks();
    }
  }, [audioTracks]);

  const getAudioTracks = async () => {
    let localAudioTrack = await Video.createLocalAudioTrack();

    console.log("localAudioTrack", localAudioTrack);

    if (localAudioTrack.kind === "audio") {
      setAudioTracks(localAudioTrack);
    }
  };

  // const [audioDevices, setAudioDevices] = useState("");

  // const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   var arr = [];
  //   let close = true;

  //   if (close) {
  //     if (audioInputDevices.length > 1) {
  //       audioInputDevices.map((device) => {
  //         console.log("deviceId", device.deviceId);
  //         arr.push(device.deviceId);
  //       });
  //     }

  //     if (audioDevices == "" && arr[0] != undefined) {
  //       console.log("setting first time now useEffect close");
  //       setAudioDevices(arr[0]);
  //       close = false;
  //     } else {
  //       setRefresh(!refresh);
  //     }
  //   }
  // }, []);

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Audio Input
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexBasis: "98%" }}>
          <div className="inputSelect">
            {audioInputDevices.length > 1 ? (
              <FormControl fullWidth>
                <Select value={""} variant="outlined">
                  {audioInputDevices.map((device) => (
                    <MenuItem value={device.deviceId} key={device.deviceId}>
                      {device.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography>No Local Audio</Typography>
            )}
          </div>
        </div>
        <div style={{ flexBasis: "2%" }}>
          <AudioLevelIndicator
            audioTrack={audioTracks}
            color="black"
            audioEnable={audioEnable}
          />
        </div>
      </div>
    </div>
  );
}
