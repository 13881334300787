import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import Swoosh from "./Icons/swoosh";
import VideoLogo from "./Icons/VideoLogo";
import TwilioLogo from "./Icons/TwilioLogo";
// import { useAppState } from '../../state';
// import UserMenu from "./UserMenu/UserMenu";
// import { useLocation } from "react-router-dom";
import DeviceSelectionScreen from "./DeviceSelectionScreen";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "100%",
  },
  container: {
    position: "relative",
    flex: "1",
    marginTop: "120px",
    marginBottom: "250px",
  },
  innerContainer: {
    display: "flex",
    width: "888px",
    height: "379px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px",
    },
  },
  swooshContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: Swoosh,
    backgroundSize: "cover",
    width: "296px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100px",
      backgroundPositionY: "140px",
    },
  },
  logoContainer: {
    position: "absolute",
    width: "210px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      width: "90%",
      textAlign: "initial",
      "& svg": {
        height: "64px",
      },
    },
  },
  twilioLogo: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: "20px",
  },
  content: {
    background: "white",
    width: "100%",
    padding: "3em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
  },
  title: {
    color: "white",
    margin: "1em 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      fontSize: "1.1rem",
    },
  },
}));

const IntroContainer = (props) => {
  const classes = useStyles();
  //   const { user } = useAppState();
  //   const location = useLocation();

  return (
    <div className={classes.background}>
      {/* <span>Twilo logo</span> */}
      {/* <TwilioLogo className={classes.twilioLogo} /> */}

      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <div className="videoLogoMain">
                <VideoLogo />
              </div>

              <Typography
                style={{ marginTop: "80px" }}
                variant="h6"
                className={classes.title}
              >
                beGalileo Online Class
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            {/* {props.children} */}
            <DeviceSelectionScreen startLiveClass={props?.startLiveClass} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
