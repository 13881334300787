import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import VideoOffIcon from "../Icons/VideoOffIcon";
import VideoOnIcon from "../Icons/VideoOnIcon";

export default function ToggleVideoButton(props) {
  const [isVideoEnabled, toggleVideoEnabled] = useState(true);

  const toggleVideo = () => {
    toggleVideoEnabled(!isVideoEnabled);
    props.handleToggleVideo(!isVideoEnabled);
  };

  return (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={!props.videoTracks}
      style={{ textTransform: "none", boxShadow: "none" }}
      startIcon={
        isVideoEnabled && props.videoTracks ? (
          <div className="videoIconOn">
            <VideoOnIcon />
          </div>
        ) : (
          <div className="videoIconOff">
            <VideoOffIcon />
          </div>
        )
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "120px",
          marginLeft: "10px",
        }}
      >
        <div>
          {isVideoEnabled && props.videoTracks ? (
            <span>Start Video</span>
          ) : (
            <span>Stop Video</span>
          )}
        </div>
      </div>
    </Button>
  );
}
