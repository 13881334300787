import React from "react";
import { Modal, Card, Row, Button } from "react-bootstrap";

export default function PreviewPic({
  show,
  onConfirm,
  imageData,
  onClose,
  allClose,
  onAllClose,
}) {
  return (
    <Modal show={show}>
      <Modal.Body>
        <div
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {allClose && (
            <Button
              variant="danger"
              style={{ borderRadius: 10 }}
              onClick={onAllClose}
            >
              X
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {imageData?.map(
            (item, id) =>
              item?.data instanceof Blob && (
                <Card style={{ width: "18rem" }} key={id}>
                  <Card.Body>
                    <div style={{ marginTop: "1rem" }}>
                      {" "}
                      <img src={URL.createObjectURL(item.data)} />
                    </div>
                    <Card.Link onClick={() => onConfirm(id)}>Confirm</Card.Link>
                    <Card.Link
                      onClick={() => onClose(id)}
                      text="danger"
                      variant="danger"
                      style={{ color: "red" }}
                    >
                      Close
                    </Card.Link>
                  </Card.Body>
                </Card>
              )
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
