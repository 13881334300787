import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import MicIcon from "../Icons/MicIcon";
import MicOffIcon from "../Icons/MicOffIcon";
import AudioLevelIndicator from "../AudioTesting/AudioLevelIndicator";

export default function ToggleAudioButton(props) {
  const [isAudioEnabled, toggleAudioEnabled] = useState(true);

  const toggleAudio = () => {
    props.handleToggleAudio(!isAudioEnabled);
    toggleAudioEnabled(!isAudioEnabled);
  };

  return (
    <Button
      className={props.className}
      onClick={toggleAudio}
      disabled={!props.audioTracks}
      elevation={0}
      // startIcon={
      //   props.audioEnable ? (
      //     <>
      //       <AudioLevelIndicator
      //         audioTrack={props.audioTracks}
      //         color="black"
      //         audioEnable={props.audioEnable}
      //       />
      //     </>
      //   ) : (
      //     <AudioLevelIndicator
      //       audioTrack={props.audioTracks}
      //       color="black"
      //       audioEnable={props.audioEnable}
      //     />
      //   )
      // }
      data-cy-audio-toggle
      style={{ textTransform: "none" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "120px",
          marginLeft: "20px",
        }}
      >
        <div style={{ marginTop: "3px", marginLeft: "10px" }}>
          {isAudioEnabled && props.audioTracks ? (
            <span>Mute</span>
          ) : (
            <span>Unmute</span>
          )}
        </div>
        {props.audioEnable && props.audioTracks ? (
          <div
            style={{
              marginLeft: "28px",
              width: "20px",
              height: "20px",
            }}
            className="micIconSvgOutside"
          >
            <AudioLevelIndicator
              audioTrack={props.audioTracks}
              color="black"
              audioEnable={props.audioEnable}
            />
          </div>
        ) : (
          <div
            style={{
              marginLeft: "10px",
              width: "20px",
              height: "20px",
            }}
            className="micIconSvgOutside"
          >
            <AudioLevelIndicator
              audioTrack={props.audioTracks}
              color="black"
              audioEnable={props.audioEnable}
            />
          </div>
        )}
      </div>
    </Button>
  );
}
