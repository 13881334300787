import React, { useState, useRef } from "react";

import SettingsIcon from "../Icons/SettingsIcon";
import Button from "@material-ui/core/Button";
import MenuContainer from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import AboutDialog from "../MoreSettings/AboutDialoge";
import DeviceSelectionDialog from "../MoreSettings/DeviceSelectionDialog";

export default function SettingsMenu({
  enabled,
  track,
  isLocal,
  camerastate,
  microphonestate,
  audioEnable,
  audioTracks,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);
  const anchorRef = useRef(null);
  return (
    <div>
      <Button
        ref={anchorRef}
        onClick={() => setMenuOpen(true)}
        startIcon={
          <div className="settingIcon">
            <SettingsIcon />
          </div>
        }
        style={{ textTransform: "none" }}
      >
        <div style={{ marginLeft: "30px" }}>Settings</div>
      </Button>

      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        style={{ marginLeft: "100px" }}
        anchorOrigin={{
          vertical: "top",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => setAboutOpen(true)}>
          <Typography variant="body1">About</Typography>
        </MenuItem>
        <MenuItem
          disabled={!track || !audioTracks}
          onClick={() => setDeviceSettingsOpen(true)}
        >
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>
      </MenuContainer>

      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        enabled={enabled}
        track={track}
        isLocal={isLocal}
        camerastate={camerastate}
        microphonestate={microphonestate}
        audioEnable={audioEnable}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </div>
  );
}
