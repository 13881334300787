import React, { useRef, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { Track } from "twilio-video";
import useMediaStreamTrack from "./useMediaStreamTrack";

import avatar from "../Icons/avatarIcon.jpeg";
import CAMERA_BLOCKED from "../../Test/CAMERA_BLOCKED.png";
import AudioLevelIndicator from "../AudioTesting/AudioLevelIndicator";

const Video = styled("video")({
  width: "100%",
  height: "100%",
});

export default function VideoTrack({
  track,
  isLocal,
  enabled,
  camerastate,
  microphonestate,
  audioEnable,
  audioTracks,
  showMicIcon,
  refresh,
}) {
  const ref = useRef();
  const mediaStreamTrack = useMediaStreamTrack(track);

  //   const dimensions = useVideoTrackDimensions(track);
  //   const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    if (enabled) {
      const el = ref.current;
      el.muted = true;
      // if (track.setPriority && priority) {
      //   track.setPriority(priority);
      // }
      track.attach(el);
      return () => {
        track.detach(el);

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;

        // if (track.setPriority && priority) {
        //   // Passing `null` to setPriority will set the track's priority to that which it was published with.
        //   track.setPriority(null);
        // }
      };
    }
  }, [track]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== "environment";
  const style = {
    transform: isLocal && isFrontFacing ? "scaleX(-1)" : "",
    // objectFit: track.name.includes("screen") ? "contain" : "cover",
  };

  return (
    <div>
      {!camerastate || microphonestate == "denied" ? (
        <div
          style={{
            width: "220px",
            height: "190px",
          }}
        >
          <img src={CAMERA_BLOCKED}></img>
        </div>
      ) : (
        <div>
          {enabled ? (
            <>
              <div
                style={{
                  width: "240px",
                  height: "150px",
                  justifyContent: "center",
                  marginTop: "20px",
                  // marginLeft: "10px",
                }}
              >
                <Video ref={ref} style={style} />
              </div>
              {/* {showMicIcon && (
                <div
                  style={{
                    width: 40,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "black",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <AudioLevelIndicator
                    audioTrack={audioTracks}
                    color="black"
                    audioEnable={audioEnable}
                  />
                </div>
              )} */}
            </>
          ) : (
            <>
              <div
                style={{
                  width: "240px",
                  height: "150px",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "240px",
                    height: "150px",
                    background: "rgb(40, 42, 43)",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                  className="videoPreviewavtar"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={avatar}
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "10px",
                      }}
                    ></img>
                  </div>
                </div>
              </div>
              {/* {showMicIcon && (
                <div
                  style={{
                    width: 40,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "black",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <AudioLevelIndicator
                    audioTrack={audioTracks}
                    color="black"
                    audioEnable={audioEnable}
                  />
                </div>
              )} */}
            </>
          )}
        </div>
      )}
    </div>
  );
}
