import React, { PureComponent } from "react";

import participantPropType from "../../propTypes/participant";
import EventSubscriber from "../EventSubscriber/EventSubscriber";
import Participant from "./Participant";
import html2canvas from "html2canvas";
import { uploadUserEmotion, emotionDetectionAPI } from "../../api";
import { FACE_IMAGE_INTERVAL_TIME } from "../../utils/Config";

const EVENTS = ["trackPublished", "trackPublicationFailed"];

class LocalParticipant extends PureComponent {
  static propTypes = {
    participant: participantPropType.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  componentDidMount() {
    console.log("Component Did Mount LocalParticipant");
    // let intervalId = setInterval(() => {
    //   console.log(
    //     "Taking Screen shot " + this.state.intervalId + " " + new Date()
    //   );
    //   this.takeScreenShot();
    // }, FACE_IMAGE_INTERVAL_TIME);

    // this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    console.log("ComponentWill Unmount LocalParticipant");

    // if (this.state.intervalId != null && this.state.intervalId != 0) {
    //   clearInterval(this.state.intervalId);
    // }
  }

  takeScreenShot = () => {
    html2canvas(document.querySelector("#capture"))
      .then((canvas) => {
        var img = canvas.toDataURL("image/png").split(";base64,")[1];

        this.uploadToServer(img);
      })
      .catch((err) => {
        console.log("Screen shot failed", err);
      });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  uploadToServer = (screenShot) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let roomID = params.get("roomID");
    let userID = params.get("userID");
    let liveClassID = params.get("liveClassID");
    let imageName = userID + "_" + liveClassID + "_" + Date.now() + ".png";

    var blob = this.b64toBlob(screenShot);
    console.log("BLOB", blob);
    console.log("Uploading Screenshot to azure");
    emotionDetectionAPI(blob).then((res) => {
      console.log("Face detect API Status ", res);
      if (res.status == 200) {
        const emotion = res.data[0].faceAttributes?.emotion;
        console.log("Emotion ", emotion);
        const bodyFormData = new FormData();
        bodyFormData.append("live_class_id", liveClassID);
        bodyFormData.append("user_id", userID);
        bodyFormData.append("screenshot", blob, imageName);

        //Emotion varibales added
        bodyFormData.append("anger", emotion.anger);
        bodyFormData.append("contempt", emotion.contempt);
        bodyFormData.append("disgust", emotion.disgust);
        bodyFormData.append("fear", emotion.fear);
        bodyFormData.append("happiness", emotion.happiness);
        bodyFormData.append("neutral", emotion.neutral);
        bodyFormData.append("sadness", emotion.sadness);
        bodyFormData.append("surprise", emotion.surprise);
        uploadUserEmotion(bodyFormData);
      } else {
        console.log("API Failed", res);
      }
    });
  };

  update = () => this.forceUpdate();

  render() {
    const { selected, participant } = this.props;

    return (
      <EventSubscriber
        events={EVENTS}
        eventEmitterObject={participant}
        onUpdate={this.update}
      >
        <div id="capture">
          <Participant participant={participant} selected={selected} />
        </div>
      </EventSubscriber>
    );
  }
}

export default LocalParticipant;
