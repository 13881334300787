import React from "react";

import AudioInputList from "../AudioLists/AudioInputList";
import AudioOutputList from "../AudioLists/AudioOuputList";
import VideoInputList from "../VideoLists/VideoInputList";
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  Hidden,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import MaxGalleryViewParticipants from './MaxGalleryViewParticipants/MaxGalleryViewParticipants';
// import { useKrispToggle } from '../../hooks/useKrispToggle/useKrispToggle';
// import SmallCheckIcon from '../../icons/SmallCheckIcon';
// import InfoIconOutlined from '../../icons/InfoIconOutlined';
// import KrispLogo from '../../icons/KrispLogo';
// import { useAppState } from '../../state';
// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme) => ({
  container: {
    width: "600px",
    minHeight: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 32px)",
    },
    "& .inputSelect": {
      width: "calc(100% - 35px)",
    },
  },
  button: {
    float: "right",
  },
  paper: {
    [theme.breakpoints.down("xs")]: {
      margin: "16px",
    },
  },
  headline: {
    marginBottom: "1.3em",
    fontSize: "1.1rem",
  },
  listSection: {
    margin: "2em 0 0.8em",
    "&:first-child": {
      margin: "1em 0 2em 0",
    },
  },
  noiseCancellationContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  krispContainer: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      "&:not(:last-child)": {
        margin: "0 0.3em",
      },
    },
  },
  krispInfoText: {
    margin: "0 0 1.5em 0.5em",
  },
}));

export default function DeviceSelectionDialog({
  open,
  onClose,
  enabled,
  track,
  isLocal,
  camerastate,
  microphonestate,
  audioEnable,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Audio and Video Settings</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Video
          </Typography>
          <VideoInputList
            enabled={enabled}
            track={track}
            isLocal={isLocal}
            camerastate={camerastate}
            microphonestate={microphonestate}
            showMicIcon={false}
          />
        </div>
        <Divider />
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Audio
          </Typography>

          <AudioInputList audioEnable={audioEnable} />
        </div>
        <div className={classes.listSection}>
          <AudioOutputList />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
