import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Section,
  Columns,
  Button,
  Form,
  Notification,
} from "react-bulma-components";
import { callTechSupport } from "../../api/index";
import videoRoomPropType from "../../propTypes/videoRoom";
import VideoRoom from "../VideoRoom/VideoRoom";
import FieldInput from "../Fields/FieldInput";
import CamMicTest from "../Test/CamMicTest";
// import Camtestexample from "../Test/Camtestexample";
import MicTest from "../Test/MicTest";
import AppContainer from "./AppContainer";
import { isParent, isTutorCheck } from "../../utils";
import {
  Row,
  Col,
  Card,
  Image,
  Navbar,
  Modal,
  Container,
} from "react-bootstrap";
import ErrorPage from "./ErrorPage";
import ErrorPageShowPopup from "./ErrorPageShowPopup";
import ErrorPageformicrophone from "./ErrorPageformicrophone";
import ShowModalMessage from "./ShowModalMessage";
import DeviceSelectionScreen from "../TwilioLoginPage/DeviceSelectionScreen";
import IntroContainer from "../TwilioLoginPage/IntroContainer";

const ParentRaisealarm = [
  {
    label: "No issues",
    value: "No issues",
  },
  {
    label: "Teacher is not teaching well",
    value: "Teacher is not teaching well",
  },
  {
    label: "Teacher Voice is low",
    value: "Teacher Voice is low",
  },
  {
    label: "No issue just end",
    value: "No issue just end",
  },
];

const App = ({
  videoRoom,
  tutorName,
  students,
  students_arr,
  new_coding_plan,
  class_type,
  show_new_codings,
  class_start_time,
  env,
  userName,
  roomName,
  isJoining,
  isVideoSupported,
  isAudioSupported,
  isScreenSharingSupported,
  isScreenSharingEnabled,
  canJoin,
  onJoin,
  onLeave,
  onShare,
  onWhiteBoard,
  onGeoGebraLinks,
  onMyScreen,
  onPlayVideo,
  onResumePlayVideo,
  showWhiteBoard,
  startLiveClass,
  onSpeedMath,
  onPlayQuiz,
  sendingReportToTeacher,
  onPlayQuizForTeacher,
  onGeogebra,
  onEndQuiz,
  onSendWhiteBoardLines,
  onSendQuizCanvasLines,
  currentStudentState,
  onClearCanvas,
  onUserNameChange,
  onRoomNameChange,
  onSubmitFeedback,
  onStudentScreenShare,
  onMuteAllStudents,
  onUnMuteAllStudents,
  onUpdateLiveSpeedMathScore,
  onStudentResponseReceivedMathzone,
  openGraphImage,
  onMuteUser,
  onUnMuteUser,
  onMuteRemoteStudent,
  onUnMuteRemoteStudent,
  errorMessage,
  errorMessageshowPopup,
  errorMessageformic,
  recordingEnabled,
  setRecordingEnabledStatus,
  flashMessage,
  onAllScreen,
  onErrorMessageHide,
  onSubmitTeacherFeedback,
  onChangeLessonPageNumber,
  onRaiseHandDoubt,
  onSendStarToStudent,
  onSendSmileyToStudent,
  onSendThumbsUpToStudent,
  onSendClappingToStudent,
  onChangeVideoState,
  onOpenRoughBoardScreen,
  hideRaiseHandStudent,
  onHideModalMessage,
  onOpenCodingScreen,
  onOpenLessonScratch,
  onScratchScreen,
  onDropChatMessage,
  onGameTimerStartStop,
  updateBackground,
  custombg,
  closeSpeedMath,
  currentState,
  openActivity,
  handleShowActivityNotification,
  sendShapeChallengeImage,
  student_grade,
  onSendSelectedFeelingImg,
  onSendOpenDropBox,
  handleCloseActivity,
  sendActiveWhiteBoardData,
  sendSelectedAffirmationValue,
  onSendStoriesToStudent,
  handleSendTeacherActivityResponse,
  handleShowPreviewImageAffrimation,
  isGroupClass,
  openFlagQuestion,
  changeStateDynamic,
  checkInternetInLiveClass,
  geoGebraUpdateData,
  openHomeWorkResponse,
  handleFlagQuestionChange,
  handleCaptureStudentScreen,
  onAffirmationCheckoutNextButton,
  handleShapeChallengeStudentResponseSaved,
  changeAffirmationStoriesPageNumber,
  screenRecordingRef,
  screenRecordingRef2,
  userSharingScreen,
  // updateTeacherQuestionCountInComputerMode,
  startPrePostTest,
  stundentClickedNextPrePostTestQue,
  setStudentGlobalIdForCheckPrePostTest,
  openPrePostTestReviewQuestionForStudent,
  changePrePostPagination,
  closePrePostTestReviewQues,
}) => {
  const videoRoomRef = React.useRef();
  const sectionAppRef = React.useRef(null);
  const rejoinIdRef = React.useRef(null);
  const [state, setState] = useState("");
  const [recordingState, setRecordingState] = useState(false);
  const [techDidNotJoinTimer, setTechDidNotJoinTimer] = useState(false);
  const [rejoinState, setRejoinState] = useState(false);
  // const [currentdata, setCurrentstate] = useState(1);

  useEffect(() => {
    let check = sessionStorage.getItem("rejoin");
    if (check === "true") {
      setRejoinState(true);
      rejoinIdRef.current = setTimeout(() => {
        setRejoinState(false);
        startLiveClass();
      }, 2500);
    }
    return () => clearTimeout(rejoinIdRef.current);
  }, []);

  let textInput = React.createRef();
  const onRaiseHandClicked = (participantIdentity) => {
    onRaiseHandDoubt(participantIdentity);
    updateLocalHandClick();
  };
  const updateLocalHandClick = () => {
    videoRoomRef.current.updateRaiseHandMessage(
      videoRoom.localParticipant.identity
    );
  };

  const stopClassRecording = () => {
    videoRoomRef.current.stopClassRecordingOnClassLeave();
    onClassEndConfirmed();
  };

  const getRecordUploadState = (e) => {
    setRecordingState(e);
    console.log("getRecordState", e);
  };

  const onClassEndConfirmed = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let demo = params.get("demo");
    // if (isTutorCheck(videoRoom.localParticipant.identity))
    //   stopClassRecording();

    if (demo == "true" && videoRoom.localParticipant.identity == "tutor") {
      videoRoomRef.current.showDemoForm();
    } else {
      if (isTutorCheck(videoRoom.localParticipant.identity))
        setTeacherFeedbackModal(true);
      else setFeedbackModal(true);
    }
  };

  const onLeaveClicked = () => {
    let whiteBoardLinesnext = "";
    localStorage.setItem(
      "quizcanvasretainedlines",
      JSON.stringify(whiteBoardLinesnext)
    );

    console.log("On leave Clicked");
    if (isTutorCheck(videoRoom.localParticipant.identity)) {
      let recStatus = videoRoomRef.current.checkRecordingStatus();

      console.log("RecStaus", recStatus);
      onClassEndConfirmed();
      screenRecordingRef.current = videoRoomRef.current;
      return;
      if (recStatus.toString() == "true") {
        console.log("Recording is active");
        // setRecordCompleteConfirmationModal(true);
      } else {
        console.log("Recording is Not active");
      }
    } else onClassEndConfirmed();
  };

  // const handleChange = (event) => {
  //   setState({ value: event.target.value });
  // };

  // const handleSubmit = (event) => {
  //   alert("A name was submitted: " + this.state.value);
  //   event.preventDefault();
  // };

  async function onCallTechSupport() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let liveClassID = params.get("liveClassID");
    let userId = params.get("userID");
    const response = await callTechSupport(liveClassID, userId);
    if (response.data.status) {
      setTechDidNotJoinTimer(true);
    }
    // onErrorMessageHide();
    setNotificationMessage(response.data.message);

    setTimeout(
      function () {
        hideNotification();
      }.bind(this),
      2000
    );
  }

  function hideNotification() {
    // alert("DDDDD");
    setNotificationMessage(null);
  }

  function onUserMutedStatus(status) {
    setIsMuteEnabled(status);
  }

  let content = null;

  const issues = [
    {
      id: "1",
      name: "Class started late",
      checked: false,
    },
    {
      id: "2",
      name: "Couldn't hear my teacher",
      checked: false,
    },
    {
      id: "3",
      name: "Couldn't see the teacher",
      checked: false,
    },
    {
      id: "4",
      name: "Video did not work",
      checked: false,
    },
    {
      id: "5",
      name: "Class was not interesting",
      checked: false,
    },
    {
      id: "6",
      name: "Whiteboard was not working",
      checked: false,
    },
    {
      id: "7",
      name: "Teacher left the class early",
      checked: false,
    },
    {
      id: "8",
      name: "Teacher was not friendly",
      checked: false,
    },
  ];

  const ipad =
    /Macintosh/.test(navigator.userAgent) && "ontouchend" in document;

  const positiveComments = [
    {
      id: "1",
      name: "Teacher was good",
      checked: false,
    },
    {
      id: "2",
      name: "Class was interesting",
      checked: false,
    },
  ];

  const teacherComments = [
    {
      id: "1",
      name: "All well, no issues at all",
      checked: false,
    },
    {
      id: "2",
      name: "Whiteboard not working",
      checked: false,
    },
    {
      id: "3",
      name: "Document did not get uploaded",
      checked: false,
    },
    {
      id: "4",
      name: "Audio issues",
      checked: false,
    },
    {
      id: "5",
      name: "Cannot see the child",
      checked: false,
    },
    {
      id: "6",
      name: "Child cannot see me",
      checked: false,
    },
    {
      id: "7",
      name: "Lesson video did not play",
      checked: false,
    },
    {
      id: "8",
      name: "Quiz did not work",
      checked: false,
    },

    {
      id: "9",
      name: "Speed math did not work",
      checked: false,
    },
    {
      id: "10",
      name: "Error in content observed",
      checked: false,
    },
  ];

  const [isMuteEnabled, setIsMuteEnabled] = useState(false);
  const [isClassCompleted, setClassCompleted] = useState(false);

  const [feedbackValue, setFeedbackValue] = useState(0);

  const [checkCount, setcheckCount] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [recordCompleteConfirmationModal, setRecordCompleteConfirmationModal] =
    useState(false);
  const [teacherFeedbackModal, setTeacherFeedbackModal] = useState(false);
  const [issueModal, setIssueModal] = useState(false);
  const [isChecked, setisChecked] = useState({});
  const [positiveCommentModal, setPositiveCommentModal] = useState(false);
  const [mouseMoving, setMouseMoving] = useState(false);

  const handleClose = () => setFeedbackModal(false);

  // const Issues = issues.map(obj => {
  //   return <span key={obj.id}><input onChange={() => checkIssues.bind(this)} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} />
  //     <label htmlFor={"issue-" + obj.id}>{obj.label}</label>
  //   </span>
  // })

  const Checkbox = ({
    type = "checkbox",
    id,
    name,
    checked = false,
    onChange,
  }) => {
    return (
      <input
        type={type}
        id={id}
        name={name}
        checked={checked}
        onChange={checkIssues}
        className="issues-checkbox"
      />
    );
  };

  const checkIssues = (event) => {
    console.log("Event live class", event.target.name);
    setisChecked({ ...isChecked, [event.target.name]: event.target.checked });
  };

  const checkClassCompleted = (event) => {
    setClassCompleted(event.target.checked);
  };

  const closeIssueModal = () => {
    setIssueModal(false);
  };
  const closePostivieCommentModal = () => {
    setPositiveCommentModal(false);
  };
  const checkRating = () => {
    if (feedbackValue == 2) {
      setFeedbackModal(false);
      setIssueModal(true);
    } else {
      setFeedbackModal(false);
      setPositiveCommentModal(true);
    }
  };

  const submitIssues = (event) => {
    var feedBackText = "";
    var comments = "";

    if (feedbackValue == 0) {
      feedBackText = "Very Good";
    } else if (feedbackValue == 1) {
      feedBackText = "Good";
    } else if (feedbackValue == 2) {
      feedBackText = "Very Bad";
    }

    event.preventDefault();

    const champObject = Object.entries(isChecked);
    champObject.forEach(([key, value]) => {
      comments = comments + key + " ,";
    });

    console.log("Feeback Text", feedBackText);
    console.log("Comments", comments);

    setFeedbackModal(false);
    setIssueModal(false);
    onSubmitFeedback(feedBackText, comments);
    //onLeave();
  };

  function onNotficationMessageHide() {
    setNotificationMessage(null);
  }

  function getMouseStatus(x, y) {
    let screenInnerHeight =
      document.querySelector(".card-body").offsetHeight - 50;

    if (y > screenInnerHeight) {
      setMouseMoving(true);
    } else {
      setMouseMoving(false);
    }
  }

  const skipIssues = () => {
    onLeave();
  };

  const handleSliderChange = (event) => {
    setFeedbackValue(event.currentTarget.value);
  };

  const closeTeacherFeedback = () => {
    setTeacherFeedbackModal(false);
  };

  const submitTeacherFeedback = (event) => {
    var feebBackText = " ";

    setTeacherFeedbackModal(false);

    const champObject = Object.entries(isChecked);

    if (champObject.length > 0) {
      champObject.forEach(([key, value]) => {
        feebBackText = feebBackText + key + " ,";
      });

      if (isClassCompleted)
        onSubmitTeacherFeedback(feebBackText, textInput.current.value, "yes");
      else onSubmitTeacherFeedback(feebBackText, textInput.current.value, "no");
    } else {
      alert("Please select at least one option to submit");
    }
  };

  if (!isVideoSupported) {
    content = <div>Video is not supported</div>;
  } else if (!isAudioSupported && !rejoinState) {
    content = <IntroContainer startLiveClass={startLiveClass} />;
    //  <CamMicTest startLiveClass={startLiveClass} />;
  } else if (rejoinState) {
    content = (
      <div>
        <Row style={{ height: "100%" }}>
          <Col
            style={{
              backgroundColor: "#F5F6FB",
              width: "100%",
              height: "100%",
            }}
            md={12}
            className="text-center"
          >
            <Modal.Dialog centered>
              <Modal.Body
                style={{ padding: "40px", border: "1px solid #E2E4EE" }}
              >
                <Row>
                  <Col md={12} className="text-center">
                    <Image
                      className="img-fluid"
                      src={process.env.PUBLIC_URL + "/static/media/bg-Logo.svg"}
                      alt="begalileo Logo"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="loading">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="text-center">
                    <p style={{ color: "#233584" }}>Reloading please wait...</p>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal.Dialog>
          </Col>
        </Row>
      </div>
    );
  } else {
    content = videoRoom ? (
      <>
        {
          <VideoRoom
            ref={videoRoomRef}
            screenRecordingRef2={screenRecordingRef2}
            tutorName={tutorName}
            students={students}
            students_arr={students_arr}
            videoRoom={videoRoom}
            changeAffirmationStoriesPageNumber={
              changeAffirmationStoriesPageNumber
            }
            new_coding_plan={new_coding_plan}
            class_type={class_type}
            show_new_codings={show_new_codings}
            env={env}
            class_start_time={class_start_time}
            recordingEnabled={recordingEnabled}
            setRecordingEnabledStatus={setRecordingEnabledStatus}
            showBoard={showWhiteBoard}
            onWhiteBoard={onWhiteBoard}
            onGeoGebraLinks={onGeoGebraLinks}
            onMyScreen={onMyScreen}
            onPlayVideo={onPlayVideo}
            onResumePlayVideo={onResumePlayVideo}
            onSpeedMath={onSpeedMath}
            onPlayQuiz={onPlayQuiz}
            sendingReportToTeacher={sendingReportToTeacher}
            onPlayQuizForTeacher={onPlayQuizForTeacher}
            onEndQuiz={onEndQuiz}
            onAllScreen={onAllScreen}
            onLeave={onLeave}
            onGeogebra={onGeogebra}
            currentStudentState={currentStudentState}
            onChangeLessonPageNumber={onChangeLessonPageNumber}
            sendWhiteBoardLines={onSendWhiteBoardLines}
            sendQuizCanvasLines={onSendQuizCanvasLines}
            onClearCanvas={onClearCanvas}
            onMuteAllStudents={onMuteAllStudents}
            onUnMuteAllStudents={onUnMuteAllStudents}
            onSendStarToStudent={onSendStarToStudent}
            onSendSmileyToStudent={onSendSmileyToStudent}
            onSendClappingToStudent={onSendClappingToStudent}
            onSendThumbsUpToStudent={onSendThumbsUpToStudent}
            onMuteRemoteStudent={onMuteRemoteStudent}
            onUnMuteRemoteStudent={onUnMuteRemoteStudent}
            onChangeVideoState={onChangeVideoState}
            onDropChatMessage={onDropChatMessage}
            onStudentResponseReceivedMathzone={
              onStudentResponseReceivedMathzone
            }
            onUpdateLiveSpeedMathScore={onUpdateLiveSpeedMathScore}
            hideRaiseHandStudent={hideRaiseHandStudent}
            onUserMutedStatus={onUserMutedStatus}
            onScreenShare={onShare}
            studentScreenShare={onStudentScreenShare}
            onCallTechSupport={onCallTechSupport}
            onLeaveClicked={onLeaveClicked}
            onMuteClick={onMuteClick}
            onUnMuteClick={onUnMuteClick}
            onRaiseHandClicked={onRaiseHandClicked}
            openCodingScreen={onOpenCodingScreen}
            openLessonScratch={onOpenLessonScratch}
            onScratchScreen={onScratchScreen}
            openRoughBoardScreen={onOpenRoughBoardScreen}
            openGraphImage={openGraphImage}
            getMouseStatus={getMouseStatus}
            onGameTimerStartStop={onGameTimerStartStop}
            updateBackground={updateBackground}
            custombg={custombg}
            closeSpeedMath={closeSpeedMath}
            isMuteEnabled={isMuteEnabled}
            setIsMuteEnabled={setIsMuteEnabled}
            isScreenSharingEnabled={isScreenSharingEnabled}
            changeStateDynamic={changeStateDynamic}
            checkInternetInLiveClass={checkInternetInLiveClass}
            getRecordUploadState={getRecordUploadState}
            geoGebraUpdateData={geoGebraUpdateData}
            handleCloseActivity={handleCloseActivity}
            openActivity={openActivity}
            onSendStoriesToStudent={onSendStoriesToStudent}
            sendActiveWhiteBoardData={sendActiveWhiteBoardData}
            handleShowActivityNotification={handleShowActivityNotification}
            sendShapeChallengeImage={sendShapeChallengeImage}
            sendSelectedAffirmationValue={sendSelectedAffirmationValue}
            onSendSelectedFeelingImg={onSendSelectedFeelingImg}
            onSendOpenDropBox={onSendOpenDropBox}
            handleSendTeacherActivityResponse={
              handleSendTeacherActivityResponse
            }
            handleShowPreviewImageAffrimation={
              handleShowPreviewImageAffrimation
            }
            isGroupClass={isGroupClass}
            student_grade={student_grade}
            openFlagQuestion={openFlagQuestion}
            openHomeWorkResponse={openHomeWorkResponse}
            handleFlagQuestionChange={handleFlagQuestionChange}
            handleCaptureStudentScreen={handleCaptureStudentScreen}
            componentRef={sectionAppRef}
            onAffirmationCheckoutNextButton={onAffirmationCheckoutNextButton}
            handleShapeChallengeStudentResponseSaved={
              handleShapeChallengeStudentResponseSaved
            }
            userSharingScreen={userSharingScreen}
            // updateTeacherQuestionCountInComputerMode={
            //   updateTeacherQuestionCountInComputerMode
            // }
            techDidNotJoinTimer={techDidNotJoinTimer}
            startPrePostTest={startPrePostTest}
            stundentClickedNextPrePostTestQue={
              stundentClickedNextPrePostTestQue
            }
            setStudentGlobalIdForCheckPrePostTest={
              setStudentGlobalIdForCheckPrePostTest
            }
            openPrePostTestReviewQuestionForStudent={
              openPrePostTestReviewQuestionForStudent
            }
            changePrePostPagination={changePrePostPagination}
            closePrePostTestReviewQues={closePrePostTestReviewQues}
          />
        }
        <Form.Field kind="group" align="centered" style={{ marginTop: "1rem" }}>
          {ipad ? (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <Navbar
                    fixed="bottom"
                    className="text-center bottom-btns bottom-navbar"
                    style={{
                      display: "none",
                    }}
                  >
                    <Row
                      className="bottom-navbar-res"
                      style={{ width: "100%" }}
                    >
                      <Col md={12} className="text-center">
                        {isMuteEnabled ? (
                          <Image
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/MuteButton.svg"
                            }
                            onClick={onUnMuteClick}
                            alt="Mute"
                          />
                        ) : (
                          <Image
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/MuteUnmute.svg"
                            }
                            onClick={onMuteClick}
                            alt="Unmute"
                          />
                        )}

                        {/* </Button> */}
                        {/* <Button className="fab-btn-end"> */}
                        <Image
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/EndCall.svg"
                          }
                          onClick={() => onLeaveClicked()}
                          alt="End call"
                        />
                        <Modal
                          centered
                          show={teacherFeedbackModal}
                          onHide={closeTeacherFeedback.bind(this)}
                        >
                          <Modal.Body>
                            <Container>
                              <h4
                                style={{
                                  marginBottom: "32px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#233584",
                                }}
                                className="text-center"
                              >
                                Teacher Feedback
                              </h4>

                              {teacherComments.map((obj) => {
                                return (
                                  <span key={obj.id}>
                                    <Checkbox
                                      name={obj.name}
                                      id={"issue-" + obj.id}
                                      checked={isChecked[obj.name]}
                                      onChange={checkIssues}
                                    />

                                    {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                    <label htmlFor={"issue-" + obj.id}>
                                      {obj.name}
                                    </label>
                                  </span>
                                );
                              })}
                              <br />

                              <Row style={{ marginTop: "1rem" }}>
                                <Col md={12}>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      id="classCompleted"
                                      className="custom-control-input"
                                      name="isClassCompleted"
                                      type="checkbox"
                                      checked={isClassCompleted}
                                      style={{
                                        backgroundColor: "red",
                                        display: "block",
                                      }}
                                      onChange={checkClassCompleted}
                                    />

                                    <label
                                      style={{ color: "#666" }}
                                      title=""
                                      for="classCompleted"
                                      className="custom-control-label"
                                    >
                                      Class completed
                                    </label>
                                  </div>
                                </Col>
                              </Row>

                              <br />

                              <Row>
                                <Col md={12}>
                                  <div className="form-group">
                                    <label>Comments (optional)</label>
                                    <textarea
                                      ref={textInput}
                                      rows="5"
                                      placeholder="Your comments !!!"
                                      className="form-control"
                                    ></textarea>
                                  </div>
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Col md={12} className="text-center">
                                  <Button
                                    onClick={submitTeacherFeedback.bind(this)}
                                    className="primary-button"
                                    variant="primary"
                                  >
                                    Submit Teacher Feedback
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>

                        <Modal show={feedbackModal} onHide={handleClose}>
                          <Modal.Body>
                            <Container>
                              <h4
                                style={{
                                  marginBottom: "32px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#233584",
                                }}
                                className="text-center"
                              >
                                Rate Your Class
                              </h4>

                              <h1
                                style={{
                                  marginBottom: "40px",
                                  marginTop: "0",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  color: "#676971",
                                }}
                                className="text-center"
                              >
                                How was <br /> today's class?
                              </h1>

                              {feedbackValue == 0 ? (
                                <div>
                                  <Row style={{ marginBottom: "57px" }}>
                                    <Col md={12} className="text-center">
                                      <Image
                                        style={{ width: "16%" }}
                                        className="img-fluid text-center"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Very-good.svg"
                                        }
                                        alt="Very Good"
                                      />
                                    </Col>
                                  </Row>

                                  <p
                                    className="text-center"
                                    style={{
                                      marginBottom: "32px",
                                      fontSize: "20px",
                                      color: "#676971",
                                    }}
                                  >
                                    Very Good
                                  </p>
                                </div>
                              ) : feedbackValue == 1 ? (
                                <div>
                                  <Row style={{ marginBottom: "57px" }}>
                                    <Col md={12} className="text-center">
                                      <Image
                                        style={{ width: "16%" }}
                                        className="img-fluid text-center"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Good.svg"
                                        }
                                        alt="Good"
                                      />
                                    </Col>
                                  </Row>

                                  <p
                                    className="text-center"
                                    style={{
                                      marginBottom: "32px",
                                      fontSize: "20px",
                                      color: "#676971",
                                    }}
                                  >
                                    Good
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <Row style={{ marginBottom: "57px" }}>
                                    <Col md={12} className="text-center">
                                      <Image
                                        style={{ width: "16%" }}
                                        className="img-fluid text-center"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/static/media/Not-good.svg"
                                        }
                                        alt="Not Good"
                                      />
                                    </Col>
                                  </Row>

                                  <p
                                    className="text-center"
                                    style={{
                                      marginBottom: "32px",
                                      fontSize: "20px",
                                      color: "#676971",
                                    }}
                                  >
                                    Not Good
                                  </p>
                                </div>
                              )}

                              <Row style={{ marginBottom: "30px" }}>
                                <Col md={12}>
                                  <input
                                    onChange={handleSliderChange.bind(this)}
                                    value={feedbackValue}
                                    className="range-slider__range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    step="1"
                                  ></input>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12} className="text-center">
                                  <Button
                                    onClick={checkRating.bind(this)}
                                    className="primary-button"
                                  >
                                    Done
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={positiveCommentModal}
                          onHide={closePostivieCommentModal.bind(this)}
                        >
                          <Modal.Body>
                            <Container>
                              <h4
                                style={{
                                  marginBottom: "32px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#233584",
                                }}
                                className="text-center"
                              >
                                Rate Your Class
                              </h4>

                              <h1
                                style={{
                                  marginBottom: "40px",
                                  marginTop: "0",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  color: "#676971",
                                }}
                                className="text-center"
                              >
                                Thank you <br /> What did you like?
                              </h1>

                              <Row>
                                <Col md={12} className="text-center">
                                  {positiveComments.map((obj) => {
                                    return (
                                      <span key={obj.id}>
                                        <Checkbox
                                          name={obj.name}
                                          id={"issue-" + obj.id}
                                          checked={isChecked[obj.name]}
                                          onChange={checkIssues}
                                        />

                                        {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                        <label htmlFor={"issue-" + obj.id}>
                                          {obj.name}
                                        </label>
                                      </span>
                                    );
                                  })}
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Col md={12} className="text-center">
                                  <Button
                                    onClick={submitIssues.bind(this)}
                                    className="primary-button"
                                    variant="primary"
                                  >
                                    Submit Feedback
                                  </Button>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12} className="text-center">
                                  <a
                                    className="primary-link-text"
                                    onClick={skipIssues.bind(this)}
                                  >
                                    Skip This Step
                                  </a>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>

                        <Modal
                          show={issueModal}
                          onHide={closeIssueModal.bind(this)}
                        >
                          <Modal.Body>
                            <Container>
                              <h4
                                style={{
                                  marginBottom: "32px",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#233584",
                                }}
                                className="text-center"
                              >
                                Rate Your Class
                              </h4>

                              <h1
                                style={{
                                  marginBottom: "40px",
                                  marginTop: "0",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  color: "#676971",
                                }}
                                className="text-center"
                              >
                                What went <br /> wrong ?
                              </h1>

                              <Row>
                                <Col md={12} className="text-center">
                                  {issues.map((obj) => {
                                    return (
                                      <span key={obj.id}>
                                        <Checkbox
                                          name={obj.name}
                                          id={"issue-" + obj.id}
                                          checked={isChecked[obj.name]}
                                          onChange={checkIssues}
                                        />

                                        {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                        <label htmlFor={"issue-" + obj.id}>
                                          {obj.name}
                                        </label>
                                      </span>
                                    );
                                  })}
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Col md={12} className="text-center">
                                  <Button
                                    onClick={submitIssues.bind(this)}
                                    className="primary-button"
                                    variant="primary"
                                  >
                                    Submit Issues
                                  </Button>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12} className="text-center">
                                  <a
                                    className="primary-link-text"
                                    onClick={skipIssues.bind(this)}
                                  >
                                    Skip This Step
                                  </a>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>

                        {/* </Button> */}
                        {/* <Button className="fab-btn"> */}
                        {/* <Image className="img-fluid"
                      src={
                        process.env.PUBLIC_URL + "/static/media/video-btn.svg"
                      }
                      onClick={() => onLeave()}
                      alt="Settings"
                    /> */}
                        {isTutorCheck(videoRoom.localParticipant.identity) ? (
                          isScreenSharingEnabled ? (
                            <Image
                              className="img-fluid"
                              onClick={() => onShare()}
                              disabled={!isScreenSharingSupported}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StartSharing.svg"
                              }
                              alt="Start Sharing"
                            />
                          ) : (
                            <Image
                              className="img-fluid"
                              onClick={() => onShare()}
                              disabled={!isScreenSharingSupported}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/Images-v2/StopSharing.svg"
                              }
                              alt="Stop Sharing"
                            />
                          )
                        ) : (
                          ""
                        )}
                        {!isTutorCheck(videoRoom.localParticipant.identity) ? (
                          <Image
                            className="img-fluid"
                            onClick={() =>
                              onRaiseHandClicked(
                                videoRoom.localParticipant.identity
                              )
                            }
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/Images-v2/HandRaise.svg"
                            }
                            alt="Raise Hand"
                          />
                        ) : (
                          ""
                        )}

                        {/* </Button> */}
                        {/* 
                    <Image className="img-fluid"
                    onClick={()=>onRaiseHandDoubt(videoRoom.localParticipant.identity)}
                      src={
                        process.env.PUBLIC_URL + "/static/media/video-off.svg"
                      }
                      alt="Settings"
                    /> */}
                      </Col>
                    </Row>
                  </Navbar>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Navbar
                fixed="bottom"
                className="text-center bottom-btns bottom-navbar"
                style={{
                  width: "50%",
                  // display:
                  //   //isTutorCheck(videoRoom.localParticipant.identity) ||
                  //   videoRoom.localParticipant.identity == "parent"
                  //     ? "flex"
                  //     : "none",
                  display: "none",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  padding: "0",
                }}
              >
                <Row>
                  <Col md={12} className="text-center">
                    {isMuteEnabled ? (
                      <Image
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/MuteButton.svg"
                        }
                        onClick={onUnMuteClick}
                        alt="Mute"
                      />
                    ) : (
                      <Image
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/MuteUnmute.svg"
                        }
                        onClick={onMuteClick}
                        alt="Unmute"
                      />
                    )}

                    {/* </Button> */}
                    {/* <Button className="fab-btn-end"> */}
                    <Image
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/static/media/Images-v2/EndCall.svg"
                      }
                      onClick={() => onLeaveClicked()}
                      alt="End call"
                    />
                    <Modal
                      centered
                      show={teacherFeedbackModal}
                      onHide={closeTeacherFeedback.bind(this)}
                    >
                      <Modal.Body>
                        <Container>
                          <h4
                            style={{
                              marginBottom: "32px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#233584",
                            }}
                            className="text-center"
                          >
                            Teacher Feedback
                          </h4>

                          {teacherComments.map((obj) => {
                            return (
                              <span key={obj.id}>
                                <Checkbox
                                  name={obj.name}
                                  id={"issue-" + obj.id}
                                  checked={isChecked[obj.name]}
                                  onChange={checkIssues}
                                />

                                {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                <label htmlFor={"issue-" + obj.id}>
                                  {obj.name}
                                </label>
                              </span>
                            );
                          })}
                          <br />

                          <Row style={{ marginTop: "1rem" }}>
                            <Col md={12}>
                              <div className="custom-control custom-checkbox">
                                <input
                                  id="classCompleted"
                                  className="custom-control-input"
                                  name="isClassCompleted"
                                  type="checkbox"
                                  checked={isClassCompleted}
                                  style={{
                                    backgroundColor: "red",
                                    display: "block",
                                  }}
                                  onChange={checkClassCompleted}
                                />

                                <label
                                  style={{ color: "#666" }}
                                  title=""
                                  for="classCompleted"
                                  className="custom-control-label"
                                >
                                  Class completed
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <br />

                          {/* <Row>
                            <Col md={12}>
                              <div>
                                <label>
                                  
                                  <input
                                    name="isClassCompleted"
                                    type="checkbox"
                                    checked={isClassCompleted}
                                    style = {{ backgroundColor : "red",display : 'block' }}
                                    onChange={checkClassCompleted} />
                                          Class Completed ?
                                </label>
                               

                              </div>
                            </Col>
                          </Row> */}
                          <Row>
                            <Col md={12}>
                              <div className="form-group">
                                <label>Comments (optional)</label>
                                <textarea
                                  ref={textInput}
                                  rows="5"
                                  placeholder="Your comments !!!"
                                  className="form-control"
                                ></textarea>
                              </div>
                            </Col>
                          </Row>

                          <Row
                            style={{ marginBottom: "15px", marginTop: "15px" }}
                          >
                            <Col md={12} className="text-center">
                              <Button
                                onClick={submitTeacherFeedback.bind(this)}
                                className="primary-button"
                                variant="primary"
                              >
                                Submit Teacher Feedback
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Body>
                    </Modal>

                    <Modal show={recordCompleteConfirmationModal}>
                      <Modal.Body>
                        <Container>
                          <h4>Do you want to complete the recording</h4>
                          <Row
                            className="justify-content-center"
                            style={{ gap: "10px" }}
                          >
                            <Button
                              onClick={() => {
                                setRecordCompleteConfirmationModal(false);
                                stopClassRecording();
                              }}
                              className="primary-button"
                              style={{ background: "green" }}
                            >
                              Yes : Complete Now
                            </Button>

                            <Button
                              onClick={() => {
                                setRecordCompleteConfirmationModal(false);
                                onClassEndConfirmed();
                              }}
                              className="primary-button"
                              style={{ background: "red" }}
                            >
                              No : Will Complete Later
                            </Button>
                          </Row>
                          <Row>
                            <Col md={12} className="text-center">
                              <Button
                                onClick={() => {
                                  setRecordCompleteConfirmationModal(false);
                                }}
                                className="primary-button"
                              >
                                Close
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Body>
                    </Modal>

                    {videoRoom.localParticipant.identity != "parent" ? (
                      <Modal show={feedbackModal} onHide={handleClose}>
                        <Modal.Body>
                          <Container>
                            <h4
                              style={{
                                marginBottom: "32px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#233584",
                              }}
                              className="text-center"
                            >
                              Rate Your Class
                            </h4>

                            <h1
                              style={{
                                marginBottom: "40px",
                                marginTop: "0",
                                fontSize: "30px",
                                fontWeight: "bold",
                                color: "#676971",
                              }}
                              className="text-center"
                            >
                              How was <br /> today's class?
                            </h1>

                            {feedbackValue == 0 ? (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Very-good.svg"
                                      }
                                      alt="Very Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Very Good
                                </p>
                              </div>
                            ) : feedbackValue == 1 ? (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Good.svg"
                                      }
                                      alt="Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Good
                                </p>
                              </div>
                            ) : (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Not-good.svg"
                                      }
                                      alt="Not Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Not Good
                                </p>
                              </div>
                            )}

                            <Row style={{ marginBottom: "30px" }}>
                              <Col md={12}>
                                <input
                                  onChange={handleSliderChange.bind(this)}
                                  value={feedbackValue}
                                  className="range-slider__range"
                                  type="range"
                                  min="0"
                                  max="2"
                                  step="1"
                                ></input>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} className="text-center">
                                <Button
                                  onClick={checkRating.bind(this)}
                                  className="primary-button"
                                >
                                  Done
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    ) : (
                      <Modal show={feedbackModal} onHide={handleClose}>
                        <Modal.Body>
                          <Container>
                            <h4
                              style={{
                                marginBottom: "32px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#233584",
                              }}
                              className="text-center"
                            >
                              Rate Your Experience
                            </h4>

                            <h1
                              style={{
                                marginBottom: "40px",
                                marginTop: "0",
                                fontSize: "30px",
                                fontWeight: "bold",
                                color: "#676971",
                              }}
                              className="text-center"
                            >
                              How was <br /> today's Experience?
                            </h1>

                            {feedbackValue == 0 ? (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Very-good.svg"
                                      }
                                      alt="Very Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Very Good
                                </p>
                              </div>
                            ) : feedbackValue == 1 ? (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Good.svg"
                                      }
                                      alt="Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Good
                                </p>
                              </div>
                            ) : (
                              <div>
                                <Row style={{ marginBottom: "57px" }}>
                                  <Col md={12} className="text-center">
                                    <Image
                                      style={{ width: "16%" }}
                                      className="img-fluid text-center"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/media/Not-good.svg"
                                      }
                                      alt="Not Good"
                                    />
                                  </Col>
                                </Row>

                                <p
                                  className="text-center"
                                  style={{
                                    marginBottom: "32px",
                                    fontSize: "20px",
                                    color: "#676971",
                                  }}
                                >
                                  Not Good
                                </p>
                              </div>
                            )}

                            <Row style={{ marginBottom: "30px" }}>
                              <Col md={12}>
                                <input
                                  onChange={handleSliderChange.bind(this)}
                                  value={feedbackValue}
                                  className="range-slider__range"
                                  type="range"
                                  min="0"
                                  max="2"
                                  step="1"
                                ></input>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} className="text-center">
                                <Button
                                  onClick={checkRating.bind(this)}
                                  className="primary-button"
                                >
                                  Done
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    )}
                    <Modal
                      show={positiveCommentModal}
                      onHide={closePostivieCommentModal.bind(this)}
                    >
                      <Modal.Body>
                        <Container>
                          <h4
                            style={{
                              marginBottom: "32px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#233584",
                            }}
                            className="text-center"
                          >
                            Rate Your Class
                          </h4>

                          <h1
                            style={{
                              marginBottom: "40px",
                              marginTop: "0",
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "#676971",
                            }}
                            className="text-center"
                          >
                            Thank you <br /> What did you like?
                          </h1>

                          <Row>
                            <Col md={12} className="text-center">
                              {positiveComments.map((obj) => {
                                return (
                                  <span key={obj.id}>
                                    <Checkbox
                                      name={obj.name}
                                      id={"issue-" + obj.id}
                                      checked={isChecked[obj.name]}
                                      onChange={checkIssues}
                                    />

                                    {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                    <label htmlFor={"issue-" + obj.id}>
                                      {obj.name}
                                    </label>
                                  </span>
                                );
                              })}
                            </Col>
                          </Row>

                          <Row
                            style={{ marginBottom: "15px", marginTop: "15px" }}
                          >
                            <Col md={12} className="text-center">
                              <Button
                                onClick={submitIssues.bind(this)}
                                className="primary-button"
                                variant="primary"
                              >
                                Submit Feedback
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} className="text-center">
                              <a
                                className="primary-link-text"
                                onClick={skipIssues.bind(this)}
                              >
                                Skip This Step
                              </a>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={issueModal}
                      onHide={closeIssueModal.bind(this)}
                    >
                      <Modal.Body>
                        <Container>
                          <h4
                            style={{
                              marginBottom: "32px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#233584",
                            }}
                            className="text-center"
                          >
                            Rate Your Class
                          </h4>

                          <h1
                            style={{
                              marginBottom: "40px",
                              marginTop: "0",
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "#676971",
                            }}
                            className="text-center"
                          >
                            What went <br /> wrong ?
                          </h1>

                          <Row>
                            <Col md={12} className="text-center">
                              {issues.map((obj) => {
                                return (
                                  <span key={obj.id}>
                                    <Checkbox
                                      name={obj.name}
                                      id={"issue-" + obj.id}
                                      checked={isChecked[obj.name]}
                                      onChange={checkIssues}
                                    />

                                    {/* <input onChange={checkIssues} name={obj.name} className="issue-checkbox" type="checkbox" id={"issue-" + obj.id} checked={isChecked[obj.name]} /> */}
                                    <label htmlFor={"issue-" + obj.id}>
                                      {obj.name}
                                    </label>
                                  </span>
                                );
                              })}
                            </Col>
                          </Row>

                          <Row
                            style={{ marginBottom: "15px", marginTop: "15px" }}
                          >
                            <Col md={12} className="text-center">
                              <Button
                                onClick={submitIssues.bind(this)}
                                className="primary-button"
                                variant="primary"
                              >
                                Submit Issues
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} className="text-center">
                              <a
                                className="primary-link-text"
                                onClick={skipIssues.bind(this)}
                              >
                                Skip This Step
                              </a>
                            </Col>
                          </Row>
                        </Container>
                      </Modal.Body>
                    </Modal>

                    {/* </Button> */}
                    {/* <Button className="fab-btn"> */}
                    {/* <Image className="img-fluid"
                      src={
                        process.env.PUBLIC_URL + "/static/media/video-btn.svg"
                      }
                      onClick={() => onLeave()}
                      alt="Settings"
                    /> */}
                    {isTutorCheck(videoRoom.localParticipant.identity) ? (
                      isScreenSharingEnabled ? (
                        <Image
                          className="img-fluid"
                          onClick={() => onShare()}
                          disabled={!isScreenSharingSupported}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StartSharing.svg"
                          }
                          alt="Start Sharing"
                        />
                      ) : (
                        <Image
                          className="img-fluid"
                          onClick={() => onShare()}
                          disabled={!isScreenSharingSupported}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/Images-v2/StopSharing.svg"
                          }
                          alt="Stop Sharing"
                        />
                      )
                    ) : (
                      ""
                    )}
                    {!isTutorCheck(videoRoom.localParticipant.identity) ? (
                      <Image
                        className="img-fluid"
                        onClick={() =>
                          onRaiseHandClicked(
                            videoRoom.localParticipant.identity
                          )
                        }
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/Images-v2/HandRaise.svg"
                        }
                        alt="Raise Hand"
                      />
                    ) : (
                      ""
                    )}

                    {/* </Button> */}
                    {/* 
                    <Image className="img-fluid"
                    onClick={()=>onRaiseHandDoubt(videoRoom.localParticipant.identity)}
                      src={
                        process.env.PUBLIC_URL + "/static/media/video-off.svg"
                      }
                      alt="Settings"
                    /> */}
                  </Col>
                </Row>
              </Navbar>
            </React.Fragment>
          )}
        </Form.Field>

        {/* <div className="modalforparent"></div>

        <div className="modalforparent">
          <div className="body">
            <div className="content">
              <input
                className="modalforparentinput"
                type="textarea"
                name="textValue"
                onChange={() => handleChange}
              />
            </div>
          </div>
        </div> */}

        {/* {videoRoom.localParticipant.identity == "parent" ? (
          <div className="raisealarm">
            <form className="raisealarmform" onSubmit={() => handleSubmit()}>
              <select value={state.fruit} onChange={() => handleChange()}>
                {ParentRaisealarm.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
              <input type="submit" value="Submit" />
            </form>
          </div>
        ) : null} */}
      </>
    ) : (
      // <div>
      //   <img
      //     src="https://gifimage.net/wp-content/uploads/2017/09/animated-gif-loading-10.gif"
      //     style={{ height: "400px", width: "500px" }}
      //   ></img>
      // </div>

      <Row style={{ height: "100%" }}>
        <Col
          style={{ backgroundColor: "#F5F6FB", width: "100%", height: "100%" }}
          md={12}
          className="text-center"
        >
          <Modal.Dialog centered>
            <Modal.Body
              style={{ padding: "40px", border: "1px solid #E2E4EE" }}
            >
              <Row>
                <Col md={12} className="text-center">
                  <Image
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/static/media/bg-Logo.svg"}
                    alt="begalileo Logo"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="loading">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <p style={{ color: "#233584" }}>
                    {recordingEnabled
                      ? "Recording is in progress please Wait... "
                      : "Loading please wait ..."}
                  </p>
                </Col>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
        </Col>
      </Row>
    );
  }
  return (
    <Section id="main-app-section">
      {notificationMessage && (
        <Notification color="success">
          {notificationMessage}
          <Button onClick={onNotficationMessageHide} remove />
          <div></div>
        </Notification>
      )}
      {flashMessage && (
        <ShowModalMessage
          message={flashMessage}
          hideModalMessage={onHideModalMessage}
        />
      )}

      {errorMessage ? (
        <ErrorPage
          errMessage={errorMessage}
          callSupport={onCallTechSupport}
          hideErrorPage={onErrorMessageHide}
        />
      ) : errorMessageshowPopup ? (
        <ErrorPageShowPopup
          errMessage={errorMessageshowPopup}
          callSupport={onCallTechSupport}
          hideErrorPage={onErrorMessageHide}
        />
      ) : errorMessageformic ? (
        <ErrorPageformicrophone
          errMessage={errorMessageformic}
          callSupport={onCallTechSupport}
          hideErrorPage={onErrorMessageHide}
        />
      ) : (
        content
      )}
      {/* {
        errorMessage &&
        <ErrorPage errMessage={errorMessage} callSupport={onCallTechSupport} hideErrorPage={onErrorMessageHide} />
      }
      {content} */}

      {/* {errorMessage && (

        <Notification color="danger">
          {errorMessage}
          <Button onClick={onErrorMessageHide} remove />
          <div>
            <br />
            <Button onClick={onCallTechSupport}>Call Tech Support</Button>
          </div>

        </Notification>
      )} */}
    </Section>
  );

  function onMuteClick() {
    setIsMuteEnabled(true);
    onMuteUser(videoRoom);

    videoRoomRef.current.onLocalParticipantMuted(true);
  }

  function onUnMuteClick() {
    setIsMuteEnabled(false);
    onUnMuteUser(videoRoom);
    videoRoomRef.current.onLocalParticipantMuted(false);
  }
};

App.propTypes = {
  videoRoom: videoRoomPropType,
  tutorName: PropTypes.string.isRequired,
  // students: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  isJoining: PropTypes.bool.isRequired,
  isVideoSupported: PropTypes.bool.isRequired,
  isAudioSupported: PropTypes.bool.isRequired,
  isScreenSharingSupported: PropTypes.bool.isRequired,
  isScreenSharingEnabled: PropTypes.bool.isRequired,
  canJoin: PropTypes.bool.isRequired,
  onLeave: PropTypes.func.isRequired,
  currentStudentState: PropTypes.func.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  onSubmitTeacherFeedback: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onStudentScreenShare: PropTypes.func.isRequired,
  onWhiteBoard: PropTypes.func.isRequired,
  onGeoGebraLinks: PropTypes.func.isRequired,
  onMyScreen: PropTypes.func.isRequired,
  onPlayVideo: PropTypes.func.isRequired,
  onResumePlayVideo: PropTypes.func.isRequired,
  onSpeedMath: PropTypes.func.isRequired,
  onEndQuiz: PropTypes.func.isRequired,
  onAllScreen: PropTypes.func.isRequired,
  onMuteAllStudents: PropTypes.func.isRequired,
  onUnMuteAllStudents: PropTypes.func.isRequired,
  onChangeLessonPageNumber: PropTypes.func.isRequired,
  showWhiteBoard: PropTypes.string.isRequired,
  onSendWhiteBoardLines: PropTypes.func.isRequired,
  onSendQuizCanvasLines: PropTypes.func.isRequired,
  onClearCanvas: PropTypes.func.isRequired,
  startLiveClass: PropTypes.func.isRequired,
  onUserNameChange: PropTypes.func.isRequired,
  onRoomNameChange: PropTypes.func.isRequired,
  openGraphImage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMessageshowPopup: PropTypes.string,
  errorMessageformic: PropTypes.string,
  flashMessage: PropTypes.string,
  onMuteUser: PropTypes.func.isRequired,
  onUnMuteUser: PropTypes.func.isRequired,
  onUpdateLiveSpeedMathScore: PropTypes.func.isRequired,
  onErrorMessageHide: PropTypes.func.isRequired,
  onRaiseHandDoubt: PropTypes.func.isRequired,
  onSendStarToStudent: PropTypes.func.isRequired,
  onSendSmileyToStudent: PropTypes.func.isRequired,
  onSendClappingToStudent: PropTypes.func.isRequired,
  onSendThumbsUpToStudent: PropTypes.func.isRequired,
  onMuteRemoteStudent: PropTypes.func.isRequired,
  hideRaiseHandStudent: PropTypes.func.isRequired,
  onUnMuteRemoteStudent: PropTypes.func.isRequired,
  onChangeVideoState: PropTypes.func.isRequired,
  onOpenCodingScreen: PropTypes.func.isRequired,
  onScratchScreen: PropTypes.func.isRequired,
  onOpenRoughBoardScreen: PropTypes.func.isRequired,
  onDropChatMessage: PropTypes.func.isRequired,
  onHideModalMessage: PropTypes.func.isRequired,
  onGameTimerStartStop: PropTypes.func.isRequired,

  changeStateDynamic: PropTypes.func.isRequired,
};

App.defaultProps = {
  videoRoom: null,
  errorMessage: null,
  errorMessageshowPopup: null,
  errorMessageformic: null,
  flashMessage: null,
  showWhiteBoard: false,
};

const render = (containerProps) => <App {...containerProps} />;
export default (props) => <AppContainer render={render} {...props} />;
