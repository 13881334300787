import React from "react";

export default function begalileoIcon() {
  return (
    <svg
      width="175"
      height="71"
      viewBox="0 0 175 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.121422 69.3805L2.27667 64.4636C2.42845 64.1256 2.70165 63.9105 3.06591 63.9105H3.15698C3.52125 63.9105 3.79445 64.1256 3.94622 64.4636L6.10147 69.3805C6.13183 69.4727 6.16218 69.5649 6.16218 69.6571C6.16218 70.0259 5.88898 70.3332 5.52471 70.3332C5.22116 70.3332 5.00867 70.1488 4.85689 69.8722L4.43191 68.8888H1.69991L1.27493 69.9337C1.15351 70.2102 0.941023 70.3639 0.637467 70.3639C0.2732 70.3639 0 70.0873 0 69.7186C0.0303556 69.5956 0.0607111 69.5034 0.121422 69.3805ZM3.94622 67.6596L3.09627 65.6006L2.24631 67.6596H3.94622Z"
        fill="#0F173A"
      />
      <path
        d="M7.13362 64.8932C7.10326 64.801 7.07291 64.7088 7.07291 64.6473C7.07291 64.2478 7.37646 63.9712 7.77108 63.9712C8.07464 63.9712 8.34784 64.1864 8.43891 64.4629L9.68348 68.3043L10.9281 64.4937C11.0191 64.1864 11.262 63.9405 11.6262 63.9405H11.7173C12.0512 63.9405 12.2941 64.1556 12.4155 64.4937L13.6601 68.3043L14.9046 64.4629C14.9957 64.1864 15.2082 63.9712 15.5725 63.9712C15.9367 63.9712 16.2403 64.2478 16.2403 64.6473C16.2403 64.7395 16.2099 64.8317 16.1796 64.9239L14.4493 69.8408C14.3279 70.1788 14.0547 70.3939 13.7511 70.3939H13.5994C13.2654 70.3939 13.0226 70.1788 12.9012 69.8408L11.687 66.2453L10.4727 69.8408C10.3513 70.2096 10.1085 70.3939 9.77455 70.3939H9.62277C9.28886 70.3939 9.04602 70.1788 8.9246 69.8408L7.13362 64.8932Z"
        fill="#0F173A"
      />
      <path
        d="M17.1509 69.3798L19.3061 64.4322C19.4579 64.0942 19.7311 63.8791 20.0954 63.8791H20.1864C20.5507 63.8791 20.8239 64.0942 20.9757 64.4322L23.1309 69.3798C23.1613 69.472 23.1916 69.5642 23.1916 69.6564C23.1916 70.0252 22.9184 70.3325 22.5542 70.3325C22.2506 70.3325 22.0381 70.1481 21.8863 69.8715L21.4614 68.8881H18.7294L18.3044 69.933C18.183 70.2096 17.9705 70.3632 17.6669 70.3632C17.3026 70.3632 17.0294 70.0866 17.0294 69.7179C17.0902 69.595 17.1205 69.5028 17.1509 69.3798ZM21.006 67.6589L20.1561 65.6L19.3061 67.6589H21.006Z"
        fill="#0F173A"
      />
      <path
        d="M25.1039 64.6473C25.1039 64.2478 25.4074 63.9712 25.7717 63.9712C26.1359 63.9712 26.4395 64.2786 26.4395 64.6473V66.737L28.959 64.2171C29.1411 64.0327 29.2929 63.9405 29.5358 63.9405C29.9304 63.9405 30.1732 64.2478 30.1732 64.5859C30.1732 64.801 30.0822 64.9546 29.9304 65.1083L28.2001 66.7677L30.1125 69.1954C30.2036 69.3184 30.2946 69.472 30.2946 69.6871C30.2946 70.0866 29.9911 70.3632 29.5965 70.3632C29.3233 70.3632 29.1715 70.2403 29.0197 70.0252L27.2591 67.6896L26.4699 68.4272V69.6871C26.4699 70.0866 26.1663 70.3632 25.802 70.3632C25.4378 70.3632 25.1342 70.0559 25.1342 69.6871V64.6473H25.1039Z"
        fill="#0F173A"
      />
      <path
        d="M32.207 69.5951V64.709C32.207 64.3095 32.5105 64.0329 32.8748 64.0329H36.305C36.6389 64.0329 36.9121 64.3095 36.9121 64.6475C36.9121 64.9855 36.6389 65.2621 36.305 65.2621H33.5426V66.5221H35.9103C36.2443 66.5221 36.5175 66.7986 36.5175 67.1367C36.5175 67.4747 36.2443 67.7513 35.9103 67.7513H33.5426V69.0727H36.3353C36.6692 69.0727 36.9424 69.3493 36.9424 69.6873C36.9424 70.0253 36.6692 70.3019 36.3353 70.3019H32.8444C32.5105 70.2712 32.207 69.9639 32.207 69.5951Z"
        fill="#0F173A"
      />
      <path
        d="M38.946 64.6784C38.946 64.2789 39.2496 64.0023 39.6139 64.0023H39.7656C40.0996 64.0023 40.2817 64.156 40.4638 64.4018L43.1048 67.9051V64.6784C43.1048 64.3096 43.4083 64.0023 43.7726 64.0023C44.1369 64.0023 44.4404 64.3096 44.4404 64.6784V69.6568C44.4404 70.0563 44.1369 70.3328 43.7726 70.3328H43.7422C43.4083 70.3328 43.2262 70.1792 43.044 69.9333L40.312 66.3071V69.6568C40.312 70.0255 40.0085 70.3328 39.6442 70.3328C39.28 70.3328 38.9764 70.0255 38.9764 69.6568V64.6784H38.946Z"
        fill="#0F173A"
      />
      <path
        d="M46.8085 64.648C46.8085 64.2485 47.1121 63.972 47.4764 63.972C47.871 63.972 48.1442 64.2793 48.1442 64.648V69.6571C48.1442 70.0566 47.8406 70.3332 47.4764 70.3332C47.0817 70.3332 46.8085 70.0259 46.8085 69.6571V64.648Z"
        fill="#0F173A"
      />
      <path
        d="M50.5421 64.6784C50.5421 64.2789 50.8456 64.0023 51.2099 64.0023H51.3617C51.6956 64.0023 51.8777 64.156 52.0598 64.4018L54.7008 67.9051V64.6784C54.7008 64.3096 55.0043 64.0023 55.3686 64.0023C55.7329 64.0023 56.0364 64.3096 56.0364 64.6784V69.6568C56.0364 70.0563 55.7329 70.3328 55.3686 70.3328H55.3382C55.0043 70.3328 54.8222 70.1792 54.6401 69.9333L51.9081 66.3071V69.6568C51.9081 70.0255 51.6045 70.3328 51.2402 70.3328C50.876 70.3328 50.5724 70.0255 50.5724 69.6568V64.6784H50.5421Z"
        fill="#0F173A"
      />
      <path
        d="M58.0701 67.1679C58.0701 65.3548 59.4361 63.9105 61.3182 63.9105C62.2592 63.9105 62.8663 64.1256 63.4431 64.5251C63.5645 64.6173 63.7163 64.8017 63.7163 65.0782C63.7163 65.447 63.4127 65.7543 63.0484 65.7543C62.8663 65.7543 62.7449 65.6928 62.6235 65.6006C62.2592 65.3241 61.8646 65.1704 61.2575 65.1704C60.2557 65.1704 59.4665 66.0616 59.4665 67.1372V67.1679C59.4665 68.3049 60.2557 69.1654 61.3485 69.1654C61.8342 69.1654 62.2896 69.0425 62.6235 68.7966V67.9054H61.7128C61.3789 67.9054 61.1057 67.6596 61.1057 67.3216C61.1057 66.9835 61.3789 66.707 61.7128 66.707H63.2609C63.6556 66.707 63.9288 67.0143 63.9288 67.383V68.9196C63.9288 69.3191 63.777 69.5956 63.4431 69.8107C62.927 70.1181 62.1985 70.4254 61.2878 70.4254C59.4058 70.3946 58.0701 69.0118 58.0701 67.1679Z"
        fill="#0F173A"
      />
      <path
        d="M71.0017 65.293H69.6964C69.3625 65.293 69.0589 65.0164 69.0589 64.6477C69.0589 64.3096 69.3321 64.0023 69.6964 64.0023H73.673C74.0069 64.0023 74.3104 64.2789 74.3104 64.6477C74.3104 64.9857 74.0372 65.293 73.673 65.293H72.3677V69.6568C72.3677 70.0563 72.0641 70.3328 71.6999 70.3328C71.3052 70.3328 71.032 70.0255 71.032 69.6568V65.293H71.0017Z"
        fill="#0F173A"
      />
      <path
        d="M76.1017 64.648C76.1017 64.2485 76.4052 63.972 76.7695 63.972C77.1641 63.972 77.4373 64.2793 77.4373 64.648V66.4919H79.9568V64.648C79.9568 64.2485 80.2604 63.972 80.6246 63.972C80.9889 63.972 81.2925 64.2793 81.2925 64.648V69.6571C81.2925 70.0566 80.9889 70.3332 80.6246 70.3332C80.2604 70.3332 79.9568 70.0259 79.9568 69.6571V67.7826H77.4373V69.6571C77.4373 70.0566 77.1337 70.3332 76.7695 70.3332C76.3749 70.3332 76.1017 70.0259 76.1017 69.6571V64.648Z"
        fill="#0F173A"
      />
      <path
        d="M83.6297 69.5951V64.709C83.6297 64.3095 83.9333 64.0329 84.2976 64.0329H87.7581C88.092 64.0329 88.3652 64.3095 88.3652 64.6475C88.3652 64.9855 88.092 65.2621 87.7581 65.2621H84.9957V66.5221H87.3635C87.6974 66.5221 87.9706 66.7986 87.9706 67.1367C87.9706 67.4747 87.6974 67.7513 87.3635 67.7513H84.9957V69.0727H87.7884C88.1224 69.0727 88.3956 69.3493 88.3956 69.6873C88.3956 70.0253 88.1224 70.3019 87.7884 70.3019H84.2976C83.9333 70.2712 83.6297 69.9639 83.6297 69.5951Z"
        fill="#0F173A"
      />
      <path
        d="M93.5863 67.1679C93.5863 65.3548 94.9523 63.9105 96.8343 63.9105C97.7753 63.9105 98.3824 64.1256 98.9592 64.5251C99.0806 64.6173 99.2324 64.8017 99.2324 65.0782C99.2324 65.447 98.9288 65.7543 98.5646 65.7543C98.3824 65.7543 98.261 65.6928 98.1396 65.6006C97.7753 65.3241 97.3807 65.1704 96.7736 65.1704C95.7719 65.1704 94.9826 66.0616 94.9826 67.1372V67.1679C94.9826 68.3049 95.7719 69.1654 96.8647 69.1654C97.3504 69.1654 97.8057 69.0425 98.1396 68.7966V67.9054H97.2289C96.895 67.9054 96.6218 67.6596 96.6218 67.3216C96.6218 66.9835 96.895 66.707 97.2289 66.707H98.7771C99.1717 66.707 99.4449 67.0143 99.4449 67.383V68.9196C99.4449 69.3191 99.2931 69.5956 98.9592 69.8107C98.4432 70.1181 97.7146 70.4254 96.804 70.4254C94.8916 70.3946 93.5863 69.0118 93.5863 67.1679Z"
        fill="#0F173A"
      />
      <path
        d="M101.6 69.5951V64.709C101.6 64.3095 101.904 64.0329 102.268 64.0329H105.698C106.032 64.0329 106.305 64.3095 106.305 64.6475C106.305 64.9855 106.032 65.2621 105.698 65.2621H102.936V66.5221H105.304C105.638 66.5221 105.911 66.7986 105.911 67.1367C105.911 67.4747 105.638 67.7513 105.304 67.7513H102.936V69.0727H105.729C106.063 69.0727 106.336 69.3493 106.336 69.6873C106.336 70.0253 106.063 70.3019 105.729 70.3019H102.238C101.904 70.2712 101.6 69.9639 101.6 69.5951Z"
        fill="#0F173A"
      />
      <path
        d="M108.339 64.6784C108.339 64.2789 108.642 64.0023 109.007 64.0023H109.158C109.492 64.0023 109.674 64.156 109.857 64.4018L112.497 67.9051V64.6784C112.497 64.3096 112.801 64.0023 113.165 64.0023C113.53 64.0023 113.833 64.3096 113.833 64.6784V69.6568C113.833 70.0563 113.53 70.3328 113.165 70.3328H113.135C112.801 70.3328 112.619 70.1792 112.437 69.9333L109.705 66.3071V69.6568C109.705 70.0255 109.401 70.3328 109.037 70.3328C108.673 70.3328 108.369 70.0255 108.369 69.6568V64.6784H108.339Z"
        fill="#0F173A"
      />
      <path
        d="M116.201 64.648C116.201 64.2485 116.505 63.972 116.869 63.972C117.264 63.972 117.537 64.2793 117.537 64.648V69.6571C117.537 70.0566 117.233 70.3332 116.869 70.3332C116.474 70.3332 116.201 70.0259 116.201 69.6571V64.648Z"
        fill="#0F173A"
      />
      <path
        d="M119.813 67.5982V64.648C119.813 64.2485 120.117 63.972 120.481 63.972C120.876 63.972 121.149 64.2793 121.149 64.648V67.5674C121.149 68.5815 121.665 69.104 122.485 69.104C123.304 69.104 123.82 68.5815 123.82 67.5982V64.648C123.82 64.2485 124.124 63.972 124.488 63.972C124.852 63.972 125.156 64.2793 125.156 64.648V67.5367C125.156 69.442 124.094 70.3639 122.454 70.3639C120.815 70.3639 119.813 69.442 119.813 67.5982Z"
        fill="#0F173A"
      />
      <path
        d="M127.312 69.6264C127.16 69.5342 127.069 69.3191 127.069 69.104C127.069 68.7352 127.342 68.4586 127.706 68.4586C127.888 68.4586 128.01 68.5201 128.101 68.5815C128.556 68.9196 129.072 69.1347 129.679 69.1347C130.226 69.1347 130.56 68.9196 130.56 68.5508V68.5201C130.56 68.182 130.347 67.9977 129.315 67.7211C128.07 67.4138 127.251 67.045 127.251 65.8158V65.785C127.251 64.648 128.161 63.9105 129.406 63.9105C130.165 63.9105 130.802 64.0948 131.379 64.4636C131.531 64.5558 131.683 64.7402 131.683 65.0168C131.683 65.3855 131.41 65.6621 131.045 65.6621C130.924 65.6621 130.802 65.6314 130.711 65.5699C130.256 65.2933 129.831 65.1397 129.406 65.1397C128.89 65.1397 128.617 65.3855 128.617 65.6928V65.7236C128.617 66.1231 128.89 66.2767 129.952 66.5533C131.197 66.8913 131.926 67.3523 131.926 68.4279V68.4586C131.926 69.7186 130.985 70.3947 129.649 70.3947C128.829 70.3639 127.979 70.1181 127.312 69.6264Z"
        fill="#0F173A"
      />
      <path
        d="M137.419 64.648C137.419 64.2485 137.723 63.972 138.087 63.972C138.482 63.972 138.755 64.2793 138.755 64.648V69.6571C138.755 70.0566 138.452 70.3332 138.087 70.3332C137.693 70.3332 137.419 70.0259 137.419 69.6571V64.648Z"
        fill="#0F173A"
      />
      <path
        d="M141.154 64.6784C141.154 64.2789 141.457 64.0023 141.821 64.0023H141.973C142.307 64.0023 142.489 64.156 142.671 64.4018L145.312 67.9051V64.6784C145.312 64.3096 145.616 64.0023 145.98 64.0023C146.344 64.0023 146.648 64.3096 146.648 64.6784V69.6568C146.648 70.0563 146.344 70.3328 145.98 70.3328H145.95C145.616 70.3328 145.434 70.1792 145.252 69.9333L142.52 66.3071V69.6568C142.52 70.0255 142.216 70.3328 141.852 70.3328C141.487 70.3328 141.184 70.0255 141.184 69.6568V64.6784H141.154Z"
        fill="#0F173A"
      />
      <path
        d="M154.054 67.8747L152.051 65.1397C151.96 65.0168 151.869 64.8324 151.869 64.648C151.869 64.2793 152.172 63.972 152.537 63.972C152.84 63.972 153.053 64.1563 153.205 64.4022L154.722 66.5841L156.24 64.3715C156.422 64.1256 156.604 63.972 156.908 63.972C157.333 63.972 157.576 64.2793 157.576 64.648C157.576 64.8324 157.485 65.0168 157.394 65.1397L155.39 67.8747V69.6878C155.39 70.0873 155.087 70.3639 154.722 70.3639C154.328 70.3639 154.054 70.0566 154.054 69.6878V67.8747Z"
        fill="#0F173A"
      />
      <path
        d="M158.729 67.1679C158.729 65.3548 160.126 63.9105 162.038 63.9105C163.95 63.9105 165.316 65.3548 165.316 67.1372V67.1679C165.316 68.9503 163.92 70.3947 162.008 70.3947C160.095 70.3947 158.729 68.9503 158.729 67.1679ZM163.89 67.1679C163.89 66.0616 163.101 65.1704 162.008 65.1704C160.915 65.1704 160.156 66.0616 160.156 67.1372V67.1679C160.156 68.2435 160.945 69.1347 162.038 69.1347C163.131 69.1347 163.89 68.2435 163.89 67.1679Z"
        fill="#0F173A"
      />
      <path
        d="M167.259 67.5982V64.648C167.259 64.2485 167.563 63.972 167.927 63.972C168.292 63.972 168.595 64.2793 168.595 64.648V67.5674C168.595 68.5815 169.111 69.104 169.931 69.104C170.75 69.104 171.266 68.5815 171.266 67.5982V64.648C171.266 64.2485 171.57 63.972 171.934 63.972C172.329 63.972 172.602 64.2793 172.602 64.648V67.5367C172.602 69.442 171.54 70.3639 169.9 70.3639C168.261 70.3639 167.259 69.442 167.259 67.5982Z"
        fill="#0F173A"
      />
      <path
        d="M0.941223 28.6931C0.941223 27.8941 1.54833 27.2795 2.30722 27.2795C3.09647 27.2795 3.70358 27.8941 3.70358 28.6931V38.9264C5.191 36.6831 7.3766 34.7778 10.7764 34.7778C15.2083 34.7778 19.6099 38.3118 19.6099 44.4579V44.5194C19.6099 50.6348 15.2387 54.261 10.7764 54.261C7.34625 54.261 5.13029 52.3864 3.70358 50.2967V52.6323C3.70358 53.4005 3.12682 54.0459 2.33758 54.0459C1.54833 54.0459 0.941223 53.4005 0.941223 52.6323V28.6931ZM16.7565 44.5809V44.5194C16.7565 40.0942 13.7513 37.2977 10.2604 37.2977C6.8302 37.2977 3.58216 40.2171 3.58216 44.4887V44.5501C3.58216 48.8831 6.8302 51.7718 10.2604 51.7718C13.812 51.7718 16.7565 49.0983 16.7565 44.5809Z"
        fill="#00ADEE"
      />
      <path
        d="M10.7458 54.7521C8.13519 54.7521 5.91923 53.738 4.15861 51.7098V52.6624C4.15861 53.738 3.33901 54.5677 2.33728 54.5677C1.30519 54.5677 0.455231 53.7073 0.455231 52.6624V28.6925C0.455231 27.617 1.27483 26.7872 2.27656 26.7872C3.33901 26.7872 4.15861 27.617 4.15861 28.6925V37.4815C5.94959 35.3303 8.13519 34.2855 10.7458 34.2855C15.2687 34.2855 20.0346 37.8503 20.0346 44.4573V44.5188C20.0649 51.1873 15.2687 54.7521 10.7458 54.7521ZM3.21759 48.7596L4.0979 50.0503C5.79781 52.5395 8.04412 53.8302 10.7761 53.8302C14.8134 53.8302 19.1239 50.6035 19.1239 44.5803V44.5188C19.1239 40.0629 16.2098 35.2996 10.7761 35.2996C8.07448 35.2996 5.88888 36.5903 4.0979 39.2331L3.21759 40.5238V28.6925C3.21759 28.1701 2.82296 27.7706 2.30692 27.7706C1.82123 27.7706 1.42661 28.1701 1.42661 28.6925V52.6317C1.42661 53.1541 1.85159 53.5536 2.33728 53.5536C2.82296 53.5536 3.21759 53.1541 3.21759 52.6317V48.7596ZM10.2601 52.2629C6.73883 52.2629 3.09616 49.3742 3.09616 44.5495V44.4881C3.09616 42.3677 3.88541 40.4009 5.28177 38.9566C6.61741 37.6044 8.43874 36.8054 10.2601 36.8054C14.2367 36.8054 17.2419 40.1243 17.2419 44.5188V44.5803C17.2419 49.0977 14.3581 52.2629 10.2601 52.2629ZM10.2601 37.7581C6.43528 37.7581 4.06754 41.2306 4.06754 44.4573V44.5188C4.06754 48.7289 7.22452 51.2795 10.2601 51.2795C13.8117 51.2795 16.2705 48.5138 16.2705 44.5803V44.5188C16.2705 40.5853 13.751 37.7581 10.2601 37.7581Z"
        fill="#00ADEE"
      />
      <path
        d="M31.2965 54.3224C26.2271 54.3224 22.0684 50.3889 22.0684 44.5808V44.5193C22.0684 39.1415 25.8325 34.7777 30.9322 34.7777C36.3963 34.7777 39.4925 39.2951 39.4925 44.2427C39.4925 45.011 38.8854 45.5334 38.2176 45.5334H24.8611C25.2558 49.5899 28.0788 51.8639 31.3572 51.8639C33.6339 51.8639 35.3338 50.9727 36.6695 49.682C36.8819 49.4977 37.1248 49.344 37.4587 49.344C38.1265 49.344 38.6729 49.8972 38.6729 50.5425C38.6729 50.8805 38.5211 51.1879 38.2479 51.4337C36.5177 53.1853 34.4231 54.3224 31.2965 54.3224ZM36.7605 43.5052C36.4873 40.0941 34.5446 37.144 30.8715 37.144C27.6538 37.144 25.2254 39.8483 24.8611 43.5052H36.7605Z"
        fill="#00ADEE"
      />
      <path
        d="M31.2965 54.7828C25.7718 54.7828 21.6131 50.3883 21.6131 44.5802V44.5188C21.6131 38.8029 25.7111 34.3162 30.9626 34.3162C36.9123 34.3162 40.0086 39.3253 40.0086 44.2729C40.0086 45.2563 39.2193 46.0553 38.2479 46.0553H25.4075C25.9236 49.282 28.261 51.4331 31.3572 51.4331C33.2696 51.4331 34.8481 50.7878 36.3659 49.4049C36.7302 49.0976 37.0944 48.944 37.4891 48.944C38.4301 48.944 39.189 49.6815 39.189 50.6342C39.189 51.1258 38.9765 51.5561 38.6122 51.8941C36.4873 53.8916 34.241 54.7828 31.2965 54.7828ZM30.9323 35.2689C26.1664 35.2689 22.5541 39.2331 22.5541 44.5188V44.5802C22.5541 49.8659 26.3182 53.8301 31.2965 53.8301C33.9678 53.8301 36.032 53.0004 37.9444 51.1566C38.1265 51.0029 38.2176 50.7878 38.2176 50.6034C38.2176 50.2039 37.8837 49.8966 37.4891 49.8966C37.3373 49.8966 37.1855 49.9581 37.0034 50.1117C35.3642 51.6483 33.5125 52.3858 31.3876 52.3858C27.5931 52.3858 24.8004 49.6815 24.4058 45.6251L24.3451 45.1026H38.2176C38.6122 45.1026 39.0372 44.7953 39.0372 44.2729C39.0372 39.7863 36.2445 35.2689 30.9323 35.2689ZM37.2766 43.9656H24.3451L24.4058 43.4432C24.8004 39.4482 27.4717 36.6517 30.8715 36.6517C34.3928 36.6517 36.8819 39.3253 37.2462 43.4432L37.2766 43.9656ZM25.4075 43.013H36.2141C35.8499 40.3087 34.2714 37.6044 30.8412 37.6044C28.1092 37.6044 25.9236 39.817 25.4075 43.013Z"
        fill="#00ADEE"
      />
      <path
        d="M92.1593 48.4529V48.3914C92.1593 44.4579 95.377 42.3375 100.052 42.3375C102.42 42.3375 104.089 42.6755 105.728 43.1365V42.4911C105.728 39.1415 103.694 37.3899 100.234 37.3899C98.3822 37.3899 96.8037 37.8201 95.4377 38.4347C95.2556 38.4962 95.0735 38.5576 94.952 38.5576C94.3146 38.5576 93.7378 38.0045 93.7378 37.3591C93.7378 36.7753 94.1324 36.345 94.4967 36.1914C96.318 35.3924 98.1697 34.9314 100.537 34.9314C103.148 34.9314 105.152 35.6075 106.518 36.9904C107.762 38.2503 108.4 40.0634 108.4 42.4604V52.6937C108.4 53.4927 107.823 54.0766 107.064 54.0766C106.275 54.0766 105.728 53.4927 105.728 52.7859V51.0957C104.453 52.7859 102.298 54.3224 99.05 54.3224C95.6502 54.261 92.1593 52.2942 92.1593 48.4529ZM105.789 47.0085V45.1954C104.423 44.7959 102.602 44.3964 100.355 44.3964C96.8948 44.3964 94.952 45.9022 94.952 48.2685V48.33C94.952 50.6655 97.1073 52.0484 99.5964 52.0484C102.996 52.0791 105.789 49.9587 105.789 47.0085Z"
        fill="#00ADEE"
      />
      <path
        d="M99.0806 54.7521C95.4075 54.7521 91.7042 52.601 91.7042 48.4523V48.3909C91.7042 44.3652 94.9219 41.876 100.082 41.876C102.329 41.876 103.968 42.1833 105.273 42.5213V42.4906C105.273 38.68 102.541 37.881 100.234 37.881C98.6252 37.881 97.1378 38.219 95.6504 38.8951C95.4075 38.9873 95.1647 39.0488 94.9522 39.0488C94.0415 39.0488 93.2523 38.2805 93.2523 37.3586C93.2523 36.5596 93.7987 35.945 94.3147 35.7606C96.3789 34.8694 98.2913 34.4699 100.538 34.4699C103.27 34.4699 105.395 35.2074 106.852 36.6825C108.187 38.0347 108.885 40.0014 108.885 42.4906V52.7239C108.885 53.7687 108.127 54.5677 107.094 54.5677C106.062 54.5677 105.303 53.7995 105.303 52.7854V52.3551C103.998 53.5844 102.025 54.7521 99.0806 54.7521ZM100.082 42.8286C95.4379 42.8286 92.6755 44.9183 92.6755 48.3909V48.4523C92.6755 51.9556 95.8932 53.7995 99.0806 53.7995C102.389 53.7995 104.332 52.14 105.395 50.7571L106.244 49.6201V52.7546C106.244 53.2463 106.578 53.5844 107.094 53.5844C107.58 53.5844 107.944 53.2156 107.944 52.6932V42.4599C107.944 40.2473 107.368 38.5263 106.184 37.3279C104.939 36.0679 103.027 35.4226 100.568 35.4226C98.4735 35.4226 96.6521 35.7913 94.7094 36.6518C94.5272 36.7132 94.254 36.9898 94.254 37.3586C94.254 37.7274 94.6183 38.0654 94.9826 38.0654C95.0736 38.0654 95.1951 38.0347 95.3165 38.0039C96.9253 37.2664 98.5342 36.9284 100.295 36.9284C104.089 36.9284 106.275 38.9566 106.275 42.5213V43.812L105.668 43.6276C104.211 43.1974 102.541 42.8286 100.082 42.8286ZM99.5966 52.5395C96.6218 52.5395 94.4665 50.7571 94.4665 48.3294V48.268C94.4665 45.5944 96.7128 43.9042 100.325 43.9042C102.207 43.9042 104.029 44.1808 105.88 44.7339L106.214 44.8261V47.008C106.244 50.1733 103.391 52.5395 99.5966 52.5395ZM100.356 44.8876C97.2896 44.8876 95.4379 46.1475 95.4379 48.268V48.3294C95.4379 50.542 97.5931 51.5561 99.5966 51.5561C102.845 51.5561 105.303 49.5894 105.303 46.9773V45.5329C103.634 45.1027 102.025 44.8876 100.356 44.8876Z"
        fill="#00ADEE"
      />
      <path
        d="M113.074 28.6931C113.074 27.9248 113.681 27.2794 114.44 27.2794C115.229 27.2794 115.836 27.9248 115.836 28.6931V52.6322C115.836 53.4312 115.26 54.0458 114.47 54.0458C113.681 54.0458 113.074 53.4312 113.074 52.6322V28.6931Z"
        fill="#00ADEE"
      />
      <path
        d="M114.471 54.537C113.408 54.537 112.589 53.7072 112.589 52.6317V28.6925C112.589 27.6477 113.408 26.7872 114.41 26.7872C115.442 26.7872 116.292 27.6477 116.292 28.6925V52.6317C116.292 53.738 115.503 54.537 114.471 54.537ZM114.44 27.7706C113.955 27.7706 113.56 28.2008 113.56 28.6925V52.6317C113.56 53.1541 113.955 53.5536 114.471 53.5536C114.987 53.5536 115.351 53.1541 115.351 52.6317V28.6925C115.351 28.2008 114.926 27.7706 114.44 27.7706Z"
        fill="#00ADEE"
      />
      <path
        d="M120.784 29.3084C120.784 28.4172 121.513 27.8333 122.393 27.8333C123.334 27.8333 124.032 28.4172 124.032 29.3084V29.7693C124.032 30.6298 123.304 31.2444 122.393 31.2444C121.513 31.2444 120.784 30.6298 120.784 29.7693V29.3084ZM121.027 36.4072C121.027 35.6389 121.634 34.9935 122.393 34.9935C123.182 34.9935 123.789 35.6082 123.789 36.4072V52.6329C123.789 53.4319 123.213 54.0465 122.423 54.0465C121.634 54.0465 121.027 53.4319 121.027 52.6329V36.4072Z"
        fill="#00ADEE"
      />
      <path
        d="M122.424 54.5371C121.361 54.5371 120.542 53.7074 120.542 52.6318V36.4061C120.542 35.3612 121.361 34.5008 122.363 34.5008C123.426 34.5008 124.245 35.3305 124.245 36.4061V52.6318C124.275 53.7381 123.486 54.5371 122.424 54.5371ZM122.393 35.4841C121.908 35.4841 121.513 35.9144 121.513 36.4061V52.6318C121.513 53.1542 121.908 53.5537 122.424 53.5537C122.94 53.5537 123.304 53.1542 123.304 52.6318V36.4061C123.304 35.8836 122.909 35.4841 122.393 35.4841ZM122.393 31.7657C121.21 31.7657 120.299 30.936 120.299 29.799V29.338C120.299 28.201 121.179 27.3713 122.393 27.3713C123.608 27.3713 124.518 28.201 124.518 29.338V29.799C124.518 30.9053 123.608 31.7657 122.393 31.7657ZM122.393 28.3239C121.817 28.3239 121.27 28.6312 121.27 29.338V29.799C121.27 30.3829 121.756 30.8131 122.393 30.8131C123.061 30.8131 123.547 30.3829 123.547 29.799V29.338C123.547 28.6312 122.97 28.3239 122.393 28.3239Z"
        fill="#00ADEE"
      />
      <path
        d="M129.011 28.6931C129.011 27.9248 129.618 27.2794 130.377 27.2794C131.167 27.2794 131.774 27.9248 131.774 28.6931V52.6322C131.774 53.4312 131.197 54.0458 130.408 54.0458C129.618 54.0458 129.011 53.4312 129.011 52.6322V28.6931Z"
        fill="#00ADEE"
      />
      <path
        d="M130.377 54.537C129.315 54.537 128.495 53.7072 128.495 52.6317V28.6925C128.495 27.6477 129.315 26.7872 130.317 26.7872C131.349 26.7872 132.199 27.6477 132.199 28.6925V52.6317C132.229 53.738 131.44 54.537 130.377 54.537ZM130.347 27.7706C129.861 27.7706 129.467 28.2008 129.467 28.6925V52.6317C129.467 53.1541 129.861 53.5536 130.377 53.5536C130.893 53.5536 131.258 53.1541 131.258 52.6317V28.6925C131.258 28.2008 130.863 27.7706 130.347 27.7706Z"
        fill="#00ADEE"
      />
      <path
        d="M144.796 54.3224C139.726 54.3224 135.568 50.3889 135.568 44.5808V44.5193C135.568 39.1415 139.332 34.7777 144.431 34.7777C149.895 34.7777 152.992 39.2951 152.992 44.2427C152.992 45.011 152.385 45.5334 151.717 45.5334H138.36C138.755 49.5899 141.578 51.8639 144.856 51.8639C147.133 51.8639 148.833 50.9727 150.169 49.682C150.381 49.4977 150.624 49.344 150.958 49.344C151.626 49.344 152.172 49.8972 152.172 50.5425C152.172 50.8805 152.02 51.1879 151.747 51.4337C149.986 53.1853 147.922 54.3224 144.796 54.3224ZM150.26 43.5052C149.986 40.0941 148.044 37.144 144.371 37.144C141.153 37.144 138.725 39.8483 138.36 43.5052H150.26Z"
        fill="#00ADEE"
      />
      <path
        d="M144.796 54.7828C139.271 54.7828 135.112 50.3883 135.112 44.5802V44.5188C135.112 38.8029 139.21 34.3162 144.462 34.3162C150.412 34.3162 153.508 39.3253 153.508 44.2729C153.508 45.2563 152.719 46.0553 151.747 46.0553H138.937C139.453 49.282 141.791 51.4331 144.887 51.4331C146.799 51.4331 148.378 50.7878 149.896 49.4049C150.26 49.0976 150.624 48.944 151.019 48.944C151.96 48.944 152.719 49.6815 152.719 50.6342C152.719 51.1258 152.506 51.5561 152.142 51.8941C149.987 53.8916 147.74 54.7828 144.796 54.7828ZM144.432 35.2689C139.666 35.2689 136.053 39.2331 136.053 44.5188V44.5802C136.053 49.8659 139.817 53.8301 144.796 53.8301C147.467 53.8301 149.531 53.0004 151.444 51.1566C151.626 51.0029 151.717 50.7878 151.717 50.6034C151.717 50.2039 151.383 49.8966 150.988 49.8966C150.837 49.8966 150.685 49.9581 150.503 50.1117C148.863 51.6483 147.012 52.3858 144.887 52.3858C141.092 52.3858 138.3 49.6815 137.905 45.6251L137.844 45.1026H151.717C152.111 45.1026 152.536 44.7953 152.536 44.2729C152.536 39.7863 149.744 35.2689 144.432 35.2689ZM150.776 43.9656H137.844L137.905 43.4432C138.3 39.4482 140.971 36.6517 144.371 36.6517C147.892 36.6517 150.381 39.3253 150.745 43.4432L150.776 43.9656ZM138.907 43.013H149.713C149.349 40.3087 147.771 37.6044 144.34 37.6044C141.608 37.6044 139.393 39.817 138.907 43.013Z"
        fill="#00ADEE"
      />
      <path
        d="M155.299 44.6115V44.55C155.299 39.2644 159.367 34.7777 164.953 34.7777C170.477 34.7777 174.575 39.2029 174.575 44.4578V44.5193C174.575 49.805 170.477 54.2916 164.892 54.2916C159.337 54.3224 155.299 49.8972 155.299 44.6115ZM171.722 44.6115V44.55C171.722 40.5243 168.747 37.2362 164.861 37.2362C160.855 37.2362 158.123 40.5243 158.123 44.4578V44.5193C158.123 48.545 161.037 51.8025 164.953 51.8025C168.929 51.8025 171.722 48.545 171.722 44.6115Z"
        fill="#00ADEE"
      />
      <path
        d="M164.861 54.7828C159.215 54.7828 154.814 50.3268 154.814 44.611V44.5495C154.814 38.8029 159.245 34.3162 164.922 34.3162C170.568 34.3162 175 38.7721 175 44.488V44.5495C175.03 50.2961 170.568 54.7828 164.861 54.7828ZM164.922 35.2689C159.792 35.2689 155.755 39.356 155.755 44.5495V44.611C155.755 49.7737 159.761 53.8301 164.861 53.8301C170.022 53.8301 174.059 49.743 174.059 44.5495V44.488C174.059 39.2946 170.052 35.2689 164.922 35.2689ZM164.922 52.2936C160.824 52.2936 157.637 48.8825 157.637 44.5495V44.488C157.637 40.0936 160.733 36.7747 164.861 36.7747C168.959 36.7747 172.207 40.1857 172.207 44.5495V44.611C172.207 49.0054 169.081 52.2936 164.922 52.2936ZM164.861 37.7273C161.279 37.7273 158.578 40.616 158.578 44.488V44.5495C158.578 48.3601 161.37 51.341 164.922 51.341C168.534 51.341 171.236 48.4523 171.236 44.6417V44.5802C171.236 40.7082 168.443 37.7273 164.861 37.7273Z"
        fill="#00ADEE"
      />
      <path
        d="M83.7133 13.0993C81.0723 10.5487 77.8547 8.52047 74.1209 7.29124C61.3109 3.05042 46.8616 10.2721 42.4297 23.1482C37.1478 38.4521 47.1045 55.3539 64.1036 57.0748C73.5442 58.0275 82.7115 53.3564 87.6595 45.1821C91.9396 38.114 92.4557 30.2163 90.1487 23.3633L76.2762 30.3085C76.4583 31.8143 76.3369 33.4123 75.7905 34.9488C74.0299 40.0808 68.5051 42.9387 63.3751 41.4022C57.8503 39.7428 54.8755 33.781 56.6968 28.3417C58.3967 23.2404 63.7697 20.1981 69.4462 21.9497C70.0229 22.1341 70.6604 22.0727 71.2068 21.7961L83.2883 15.7729C84.29 15.2197 84.5025 13.8676 83.7133 13.0993Z"
        fill="#66CDF5"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="41"
        y="6"
        width="51"
        height="52"
      >
        <path
          d="M83.7132 13.0993C81.0723 10.5487 77.8546 8.52048 74.1209 7.29126C61.3108 3.05043 46.8616 10.2721 42.4297 23.1483C37.1478 38.4521 47.1044 55.3539 64.1036 57.0748C73.5441 58.0275 82.7115 53.3564 87.6595 45.1821C91.9396 38.1141 92.4557 30.2163 90.1486 23.3634L76.2761 30.3085C76.4583 31.8143 76.3368 33.4123 75.7905 34.9488C74.0298 40.0808 68.5051 42.9388 63.375 41.4022C57.8503 39.7428 54.8755 33.781 56.6968 28.3417C58.3967 23.2404 63.7696 20.1981 69.4461 21.9498C70.0229 22.1341 70.6604 22.0727 71.2068 21.7961L83.2883 15.7729C84.29 15.2198 84.5025 13.8676 83.7132 13.0993Z"
          fill="#66CDF5"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.3399 18.0598C43.3399 20.8871 41.9132 27.0025 44.9184 28.1702C47.5897 29.2458 50.2914 27.74 51.5967 25.343C54.0858 20.8563 56.15 18.0906 61.7961 19.5042C63.6478 19.9651 64.3763 21.01 65.9245 22.0548C67.503 23.1304 69.0511 23.2533 70.9028 23.3148C74.0294 23.4377 77.6417 22.7616 80.2523 21.0714C82.5593 19.5656 86.1109 17.8447 86.3538 14.8639C86.5359 12.7434 86.4752 8.77919 84.8967 7.33486C83.4093 5.98271 80.7683 5.61394 78.8863 5.09152C74.6669 3.95449 70.4778 3.18622 66.1066 2.94038C60.3087 2.60234 54.6929 3.12476 48.9861 4.04668C46.5576 4.44618 42.7935 4.38472 40.7901 6.10563C38.392 8.16458 36.5403 14.9253 39.3634 17.1072C40.4865 17.9984 42.1257 17.5067 43.3399 18.0598Z"
          fill="#86C440"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.9136 33.9476C42.6725 33.8247 43.5528 33.9476 44.3421 34.1627C46.7402 34.808 48.1062 36.1909 49.29 38.3728C50.1096 39.8786 50.5346 41.538 50.7775 43.2282C51.3542 47.2539 55.3611 50.5421 54.6023 54.9058C54.3594 56.1965 53.2059 58.0404 51.5363 57.7638C50.2007 57.5487 49.199 55.3975 48.5311 54.2912C46.1938 50.5421 43.1886 46.7008 41.3369 42.6751C40.4869 40.8312 39.7887 38.0655 40.0316 36.0373C40.153 34.6851 40.9423 34.132 41.9136 33.9476Z"
          fill="#00ADEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M67.8372 69.7795C62.3732 66.8601 51.9612 62.0968 56.636 53.6766C58.6091 50.1119 61.6446 50.7265 65.2266 50.7265C72.4816 50.7265 75.0922 45.994 77.0046 39.7557C78.4009 35.2076 82.8025 31.1511 87.5683 34.4086C93.8216 38.6801 90.3914 51.1567 87.4165 56.0736C85.0792 59.915 82.8025 63.9407 79.008 66.3684C76.7921 67.8127 69.1425 71.6541 67.8372 69.7795Z"
          fill="#00ADEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M81.1328 25.5888C81.5274 26.2034 82.256 26.8488 82.5595 27.5556C83.0452 28.7541 82.4077 29.9219 82.5292 31.0896C82.6809 32.534 82.6506 32.9335 84.3505 32.9027C87.2039 32.8413 87.6896 30.5057 89.1163 33.7632C89.6324 34.9002 89.4806 35.9451 91.1805 35.9143C92.4554 35.9143 93.5786 34.5007 93.8822 33.5173C94.55 31.3047 94.4286 26.3571 92.4858 24.882C89.4806 22.6387 83.2577 24.206 81.1328 25.5888Z"
          fill="#86C440"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.94 13.6961C45.3736 13.5732 49.0467 11.422 54.1768 13.6346C57.3034 14.9868 60.8853 17.1379 64.4977 16.1238C66.1065 15.6629 66.5618 14.4029 67.8671 13.7268C69.4153 12.9278 70.8723 13.5732 72.6633 13.1122C77.4291 11.8523 81.1022 4.47692 74.2721 2.66381C68.2921 1.09655 59.3069 1.92628 53.1447 3.1555C49.6841 3.83158 46.4664 5.36811 43.8559 7.73437C41.822 9.5782 40.6078 11.9137 39.94 13.6961Z"
          fill="#71B444"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.0704 54.5985C49.138 52.4781 50.5344 44.3038 56.393 45.7481C57.6983 46.0554 58.5482 47.3154 59.8535 47.592C61.3713 47.8993 62.4338 47.0695 63.7694 46.9159C67.9281 46.4549 63.9515 60.8368 58.6697 61.3593C54.1467 61.8202 46.6489 59.4847 45.0704 54.5985Z"
          fill="#86C440"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M62.6463 61.1134C62.2517 59.331 60.8554 57.4565 60.0358 55.8277C59.2769 54.3219 59.0037 52.5703 58.2448 51.126C57.0002 48.7597 54.1164 48.9441 52.4772 50.9416C50.0791 53.7995 48.8953 57.0262 52.7504 59.0544C55.4521 60.4988 59.5501 60.8676 62.6463 61.1134Z"
          fill="#71B444"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125.004 6.35147C123.395 2.38722 120.451 -0.132692 118.478 0.697035L116.596 1.4653L117.021 2.47941C116.474 4.16959 116.717 6.75097 117.779 9.36307C118.842 11.9752 120.481 13.9727 122.06 14.7717L122.485 15.7858L124.336 15.0175C126.309 14.1878 126.613 10.3157 125.004 6.35147Z"
        fill="#86C440"
      />
      <path
        d="M122.488 15.8126C124.469 14.9791 124.762 11.1014 123.141 7.15166C121.52 3.20189 118.599 0.675703 116.617 1.50926C114.635 2.34282 114.343 6.22047 115.964 10.1702C117.585 14.12 120.506 16.6462 122.488 15.8126Z"
        fill="#629B35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M121.331 14.2185L95.2254 25.1893L91.0971 15.1096L116.93 3.49348C116.93 3.49348 119.905 2.8174 121.847 7.98015C123.79 13.1736 121.331 14.2185 121.331 14.2185Z"
        fill="#86C440"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.3055 32.8719L70.4859 33.1485L68.8163 28.5082L69.4538 28.1702L71.3055 32.8719Z"
        fill="#808184"
      />
      <path
        d="M95.2361 25.2073C97.125 24.4128 97.728 21.507 96.583 18.7171C95.4379 15.9272 92.9785 14.3096 91.0897 15.1041C89.2008 15.8986 88.5978 18.8043 89.7428 21.5942C90.8878 24.3841 93.3473 26.0017 95.2361 25.2073Z"
        fill="#629B35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M91.0971 16.769L68.5126 28.6311L70.2125 33.2714L94.2238 24.421C95.863 23.3147 95.7112 21.5323 94.9523 19.627C94.1327 17.7524 93.0095 16.3695 91.0971 16.769Z"
        fill="#86C440"
      />
      <path
        d="M70.1815 33.2777C70.449 33.1782 70.2852 32.0518 69.8156 30.7619C69.346 29.4719 68.7484 28.5069 68.4809 28.6064C68.2134 28.7058 68.3772 29.8322 68.8468 31.1221C69.3164 32.4121 69.914 33.3771 70.1815 33.2777Z"
        fill="#629B35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.5752 31.8885L64.9005 33.8552C64.5969 33.9782 64.2327 33.8245 64.1112 33.5172L63.8077 32.7797C63.6863 32.4724 63.838 32.1036 64.1416 31.9807L68.8164 30.0139L69.5752 31.8885Z"
        fill="#86C440"
      />
    </svg>
  );
}
