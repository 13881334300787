import axios from "axios";
import axiosRetry from "axios-retry";
// export const baseURL = "https://www.begalileo.com/";
export const baseURL = "https://staging.begalileo.com/";
// export const baseURL = "http://192.168.201.141:3000/";/
//export const baseURL = "https://testserver.begalileo.com/";
const ApiForSmileys = "app_students/create_stars";
export const imageUrl = "https://www.begalileo.com";
export const baseURL2 = baseURL;

//export const baseURL = "https://testserver.begalileo.com/";

axiosRetry(axios, {
  retries: 3, // number of retries

  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);

    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    console.log("error retry condition");
    // if retry condition is not specified, by defa_ult idempotent requests are retried
    return error?.response?.status === 503;
  },
});

export const recordingFailedError = (body) => {
  let url = baseURL + "app_students/create_recording_error_logs";
  return axios.post(url, body);
};
export const sendNoRecordingFoundInfo = (body) => {
  let url = "http://localhost:3000/noPart";
  return axios.post(url, body);
};

export const getToken = async (user, live_class_id) =>
  axios.get(baseURL + "app_students/video_call_token", {
    params: {
      user,
      live_class_id,
    },
  });

export const getRoomToken = async (user, room_id, live_class_id) =>
  axios.get(baseURL + "app_students/video_call_token", {
    params: {
      user,
      room_id,
      live_class_id,
    },
  });

export const getDeviceInfoForTech = async (live_class_id, user_id) =>
  axios.get(baseURL + "app_students/get_device_details", {
    params: {
      live_class_id,
      user_id,
    },
  });

export const updateNextQuestion = async (
  live_class_id,
  sub_concept_id,
  tag_id,
  level
) =>
  axios.get(baseURL + "app_students/next_question", {
    params: {
      live_class_id,
      sub_concept_id,
      tag_id,
      level,
    },
  });

export const submitAStar = async (live_class_id, student_id) =>
  axios.get(baseURL + "app_students/create_stars", {
    params: {
      live_class_id,
      student_id,
    },
  });
export const submitASmiley = async (live_class_id, student_id, smiley) =>
  axios.get(baseURL + ApiForSmileys, {
    params: {
      live_class_id,
      student_id,
      smiley: smiley,
    },
  });
export const submitAThumbsUp = async (live_class_id, student_id, thumb) =>
  axios.get(baseURL + ApiForSmileys, {
    params: {
      live_class_id,
      student_id,
      thumbsup: thumb,
    },
  });
export const submitAClapping = async (live_class_id, student_id, clap) =>
  axios.get(baseURL + ApiForSmileys, {
    params: {
      live_class_id,
      student_id,
      clap: clap,
    },
  });
export const getStudentStar = async (live_class_id, student_id) =>
  axios.get(baseURL + "app_students/get_stars", {
    params: {
      live_class_id,
      student_id,
    },
  });
// export const getStudentSmiley = async (live_class_id, student_id, smiley) =>
//   axios.get(baseURL + "app_students/create_live_class_claps_and_smileys", {
//     params: {
//       live_class_id,
//       student_id,
//       smiley
//     },
//   });
// export const getStudentClapping = async (live_class_id, student_id,clap) =>
//   axios.get(baseURL + "app_students/create_live_class_claps_and_smileys", {
//     params: {
//       live_class_id,
//       student_id,
//       clap
//     },
//   });

export const closeLiveSession = async (live_class_id) =>
  axios.get(baseURL + "app_students/closed_live_session", {
    params: {
      live_class_id,
    },
  });

export const getConceptList = async (live_class_id) =>
  axios.get(baseURL + "app_students/concept_list", {
    params: {
      live_class_id,
    },
  });

export const getUploadResourcesList = async (live_class_id) =>
  axios.get(baseURL + "app_teachers/view_resources", {
    params: {
      live_class_id,
    },
  });

export const getNetworkTestToken = async () =>
  axios.get(baseURL + "app_students/test_pre_flight", {
    params: {},
  });

export const updateDuration = async (live_class_id, user_id) =>
  axios.get(baseURL + "app_students/update_class_duration", {
    params: {
      live_class_id,
      user_id,
    },
  });

export const callTechSupport = async (live_class_id, user_id) =>
  axios.get(baseURL + "app_students/create_tech_support", {
    params: {
      live_class_id,
      user_id,
    },
  });

export const submitErrorLog = async (
  user_id,
  live_class_id,
  message,
  error_code,
  network_quality
) =>
  axios
    .get(baseURL + "app_students/create_error_log", {
      params: {
        user_id,
        live_class_id,
        message,
        error_code,
        network_quality,
      },
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data, "h1");
      } else if (error.request) {
        console.log(error.request, "h2");
      } else {
        console.log("Error", error.message, "h3");
      }
    });

export const storeVideoLog = async (live_class_id, lesson_id, video_id) =>
  axios
    .get(baseURL + "app_students/live_class_video_lesson_logs", {
      params: {
        live_class_id,
        lesson_id,
        video_id,
      },
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data, "h1");
      } else if (error.request) {
        console.log(error.request, "h2");
      } else {
        console.log("Error", error.message, "h3");
      }
    });

export const storeCodingLog = async (live_class_id, coding_id) =>
  axios
    .get(baseURL + "app_students/live_class_video_lesson_logs", {
      params: {
        live_class_id,
        coding_id,
      },
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data, "h1");
      } else if (error.request) {
        console.log(error.request, "h2");
      } else {
        console.log("Error", error.message, "h3");
      }
    });

export const storeCodingLogNewCurriculam = async (
  live_class_id,
  coding_learning_outcome_id
) =>
  axios
    .get(baseURL + "app_students/live_class_video_lesson_logs", {
      params: {
        live_class_id,
        coding_learning_outcome_id,
      },
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data, "h1");
      } else if (error.request) {
        console.log(error.request, "h2");
      } else {
        console.log("Error", error.message, "h3");
      }
    });

export const checkSkippedQuestionPrePostTest = async (
  pre_post_test_id,
  skipped_review
) =>
  axios.get(baseURL + "app_teachers/check_skipped_reviews", {
    params: {
      pre_post_test_id,
      skipped_review,
    },
  });

export const storeGeogebraLog = async (
  live_class_id,
  lesson_id,
  simulation_id
) =>
  axios.get(baseURL + "app_students/live_class_video_lesson_logs", {
    params: {
      live_class_id,
      lesson_id,
      simulation_id,
    },
  });

export const updateStudentsFeedback = async (
  live_class_id,
  user_id,
  feedback,
  comments
) =>
  axios.get(baseURL + "app_students/update_student_feedback", {
    params: {
      live_class_id,
      user_id,
      feedback,
      comments,
    },
  });

export const updateIncompleteTeachersDemo = async (
  live_class_id,
  user_id,
  demo_status
) =>
  axios.get(baseURL + "app_students/update_student_feedback", {
    params: {
      user_id,
      live_class_id,
      demo_status,
    },
  });

export const startSpeedMathGame = async (level_id, live_class_id, play_with) =>
  axios.get(baseURL + "app_students/start_game", {
    params: {
      level_id,
      live_class_id,
      play_with,
    },
  });

export const createSpeedMathGame = async (game_id, player_id) =>
  axios.get(baseURL + "app_students/create_game_players", {
    params: {
      game_id,
      player_id,
    },
  });

export const activateActivityToStudent = async (
  student_ids,
  coding_activity_id,
  status
) =>
  axios.get(baseURL + "app_students/activate_activity_to_students", {
    params: {
      student_ids,
      coding_activity_id,
      status,
    },
  });

// export const activateActivityToStudent = async (bodyFormData) =>
//   axios({
//     method: "get",
//     url: baseURL + "app_students/activate_activity_to_students",
//     data: bodyFormData,
//     headers: { "Content-Type": "multipart/form-data" },
//   });

export const getThunkableLinks = async (live_class_id) =>
  axios.get(baseURL + "app_students/generate_thunkable_link", {
    params: {
      live_class_id,
    },
  });

export const showScratchTeacher = async (live_class_id, user_id) =>
  axios.get(baseURL + "app_students/codings", {
    params: {
      live_class_id,
      user_id,
    },
  });

export const showScratchStudent = async (user_id, live_class_id) =>
  axios.get(baseURL + "app_students/student_projects", {
    params: {
      user_id,
      live_class_id,
    },
  });

export const recordingUpdateToServer = async (
  live_class_id,
  recording_links,
  recording_id
) =>
  axios.get(baseURL + "app_students/check_recording_api", {
    params: {
      live_class_id,
      recording_links,
      recording_id,
    },
  });

export const showScratchStudentsendflag = async (student_activity_id) =>
  axios.get(baseURL + "access_codings/update_student_project_status", {
    params: {
      student_activity_id,
    },
  });

export const getGameResult = async (
  game_id,
  live_class_id,
  player_id,
  computer_score
) =>
  axios.get(baseURL + "app_students/game_result", {
    params: {
      game_id,
      live_class_id,
      player_id,
      computer_score,
    },
  });

export const getSpeedMathLevels = async (live_class_id) =>
  axios.get(baseURL + "app_students/game_levels", {
    params: {
      live_class_id,
    },
  });

export const checkRecordUploadId = async (live_class_id, userId) =>
  axios.get(baseURL + "app_students/share_live_class_upload_id_status", {
    params: {
      live_class_id,
      user_id: userId,
    },
  });
export const checkRecordingStatus = async (live_class_id) =>
  axios.get(baseURL + "app_students/show_recording_status", {
    params: {
      live_class_id,
    },
  });
export const doCreateLiveClassRecordings = async (
  live_class_id,
  upload_id,
  file_key
) =>
  axios.get(baseURL + "app_students/create_live_class_recording", {
    params: {
      live_class_id,
      upload_id,
      file_key,
    },
  });
export const getAllRecordingUploadId = async (live_class_id) =>
  axios.get(baseURL + "app_students/get_live_class_recording_details", {
    params: {
      live_class_id,
    },
  });

export const doPartUploadingStatus = async (recording_id, part, etag) => {
  return;
  return axios.get(baseURL + "app_students/create_recording_parts", {
    params: {
      recording_id,
      part,
      etag,
    },
  });
};

export const storeGameResponse = async (
  player_id,
  game_id,
  question_id,
  correct,
  answer
) =>
  axios.get(baseURL + "app_students/store_game_response", {
    params: {
      player_id,
      game_id,
      question_id,
      correct,
      answer,
    },
  });

export const updateTeachersDemoFeedback = async (
  live_class_id,
  user_id,
  first_name,
  gender,
  demo_topic,
  demo_status,
  math_zone,
  speed_math,
  logic_zone,
  learning_style,
  knowledge,
  communication,
  higher_order,
  coding,
  interest,
  behaviour,
  useful,
  internal_comments
) =>
  axios.get(baseURL + "app_students/update_student_feedback", {
    params: {
      user_id,
      live_class_id,
      first_name,
      gender,
      demo_topic,
      demo_status,
      math_zone,
      speed_math,
      logic_zone,
      learning_style,
      knowledge,
      communication,
      higher_order,
      coding,
      interest,
      behaviour,
      useful,
      internal_comments,
    },
  });

export const updateTeachersFeedback = async (
  live_class_id,
  user_id,
  feedback,
  comments,
  is_completed
) =>
  axios.get(baseURL + "app_students/update_student_feedback", {
    params: {
      live_class_id,
      user_id,
      feedback,
      comments,
      is_completed,
    },
  });

export const createLiveClassTicket = async (bodyFormData) =>
  axios({
    method: "post",
    url: baseURL + "app_students/create_live_class_ticket",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const uploadUserEmotion = async (bodyFormData) =>
  axios({
    method: "post",
    url: baseURL + "app_students/capture_live_class_emotions",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const ParentFeedbackApi = async (bodyFormData) =>
  axios({
    method: "post",
    url: baseURL + "app_students/update_live_class_parent_feedback",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const techNotPresentApi = async (live_class_id) =>
  axios.get(baseURL + "app_teachers/tech_not_joined_notification", {
    params: {
      live_class_id,
    },
  });
export const studentNotPresentApi = async (live_class_id, joined_time) =>
  axios.get(baseURL + "app_teachers/student_not_joined_notification", {
    params: {
      live_class_id,
      joined_time,
    },
  });
export const teacherNotPresentApi = async (
  live_class_id,
  student_id,
  joined_time
) =>
  axios.get(baseURL + "app_teachers/teacher_not_joined_notification", {
    params: {
      live_class_id,
      student_id,
      joined_time,
    },
  });

export const emotionDetectionAPI = async (bodyFormData) =>
  axios({
    method: "post",
    url: "https://begalileo-com.cognitiveservices.azure.com/face/v1.0/detect?detectionModel=detection_01&returnFaceId=true&returnFaceAttributes=smile,emotion",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/octet-stream",
      "Ocp-Apim-Subscription-Key": "b3d784635b5441389065a8e656159ea6",
      mime: "application/octet-stream",
    },
  });

export const getWhiteBoard = (async) =>
  axios.get(baseURL + "app_students/get_board_url");

//ParentFeedbackApi

export const liveOnlineQuizStartQuestion = async (url) => axios.get(url);
export const PrePostStartTest = async (url) => axios.get(url);

export const updateLiveClassQuizQuestion = async (url) => {
  return axios.get(url);
};

export const updatePrePostTestQuizQuestion = async (url) => {
  return axios.get(url);
};

export const updateCurrentQuestionTeacherPrePostTest = async (url) => {
  return axios.get(url);
};
export const StudentAnswerResponse = async (url, data) => {
  let config = {
    method: "post",
    url: `${baseURL2}${url}`,
    data: data,
  };
  return axios(config);
};

export const teacherUploadResources = async (data) => {
  console.log("data", data);
  let config = {
    method: "get",
    url: `${baseURL2}app_teachers/upload_resource`,
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  };

  return axios(config);
};

export const StudentResultMathZone = async (url) => {
  return axios(`${baseURL2}app_teachers/result${url}`);
};

export const StudentResultPrePostTest = async (url) => {
  return axios(`${baseURL2}app_teachers/pre_post_test_report${url}`);
};

export const GetStudentResultResponse = async (practiceId) => {
  return axios(
    `${baseURL2}app_teachers/view_questions?live_class_practice_id=${practiceId}`
  );
};

export const GetStudentResultResponsePrePostTest = async (
  live_class_id,
  pre_post_test_id
) => {
  return axios(
    `${baseURL2}app_teachers/view_questions?live_class_id=${live_class_id}&pre_post_test_id=${pre_post_test_id}`
  );
};

export const getReviewResultData = async (practiceId, user_id, studentId) => {
  return axios(
    `${baseURL2}app_teachers/review_result?live_class_practice_id=${practiceId}&user_id=${user_id}&student_id=${studentId}`
  );
};

export const getReviewResultDataPrePostTest = async (
  pre_post_test_id,
  user_id,
  student_id,
  exercise_id
) => {
  return axios(
    `${baseURL2}app_teachers/review_pre_post_test_result?pre_post_test_id=${pre_post_test_id}&user_id=${user_id}&student_id=${student_id}&exercise_id=${exercise_id}`
  );
};

export const studentCheckInGetData = (student_id, live_class_id) => {
  return axios.get(
    `${baseURL2}live_class_checkinout_activities/checkin_activity`,
    {
      params: {
        student_id,
        live_class_id,
      },
    }
  );
};
export const studentCheckOutGetData = (obj) => {
  return axios.get(
    `${baseURL2}live_class_checkinout_activities/checkout_activity`,
    {
      params: obj,
    }
  );
};
export const StudentActivityResponseSave = (body) => {
  const config = {
    method: "post",
    url: baseURL2 + "live_class_checkinout_activities/store_student_response",
    data: body,
  };
  return axios(config);
};
export const StudentActivityTeacherResponseSave = (body) => {
  const config = {
    method: "post",
    url: baseURL2 + "live_class_checkinout_activities/store_teacher_response",
    data: body,
  };
  return axios(config);
};
export const getStudentActivityResponse = (student_id, live_class_id) => {
  return axios.get(
    `${baseURL2}live_class_checkinout_activities/checkin_out_activity_responses`,
    {
      params: {
        student_id,
        live_class_id,
      },
    }
  );
};

export const getStudentSHomeWorkDetail = (practiceId) => {
  return axios.get(
    `${baseURL}app_teachers/view_homeworks?live_class_id=${practiceId}`
  );
};
export const getStudentSHomeWorkIncorrectQuestionDate = (
  liveClassID,
  tagQuizId,
  homeWorkId
) => {
  //44308 48062
  return axios(
    `${baseURL}app_teachers/homework_review?live_class_id=${liveClassID}&tag_quiz_id=${tagQuizId}&homework_id=${homeWorkId}`
  );
};

export const fetchFlagQuestion = (conceptId, liveClassID, tagId) => {
  return axios(
    `${baseURL}app_teachers/flagged_questions?sub_concept_id=${conceptId}&tag_id=${tagId}&live_class_id=${liveClassID}`
  );
};

export const markAsResolvedFlagQuestion = (flagQuestionId, userId) => {
  return axios(
    `${baseURL}app_teachers/mark_as_resolved/?flagged_question_id=${flagQuestionId}&user_id=${userId}`
  );
};
export const getFlagQuestionConceptList = (liveClassID) => {
  return axios(
    `${baseURL}app_teachers/flagged_concepts?live_class_id=${liveClassID}`
  );
};

export const markAsResolvedHomeWorkQuestion = (
  question_id,
  userId,
  liveClassID,
  homework_id
) => {
  return axios(`${baseURL}app_teachers/mark_as_homework_question_resolved?question_id=${question_id}&user_id=${userId}&live_class_id=${liveClassID}&homework_id=${homework_id}
  `);
};

export const updateStatusofCicoActivity = (
  liveClassID,
  activityId,
  duration
) => {
  return axios(
    `${baseURL}live_class_checkinout_activities/update_status?live_class_id=${liveClassID}&checkin_ativity_id=${activityId}&duration=${duration}`
  );
};
export const uploadStudentScreenShot = (body) => {
  const config = {
    method: "post",
    url: baseURL2 + "app_teachers/create_user_gallery",
    data: body,
  };
  return axios(config);
};
